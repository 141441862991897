var UserProfile = (function() {
    var full_id= "";
    var full_token = "";
    var full_email = "";
  




    var getId = function() {
      return full_id;    // Or pull this from cookie/localStorage
    };
  
    var setId = function(id) {
      full_id = id;     
      // Also set this in cookie/localStoragef
    };
  


    var getToken = function() {
      return full_token;    // Or pull this from cookie/localStorage
    };
  
    var setToken = function(token) {
      full_token = token;     
      // Also set this in cookie/localStoragef
    };
  

    var getEmail = function() {
      return full_email;    // Or pull this from cookie/localStorage
    };
  
    var setEmail = function(email) {
      full_email = email;     
      // Also set this in cookie/localStoragef
    };






    return {
      getToken: getToken,
      setToken: setToken,
      getEmail: getEmail,
      setEmail: setEmail,
      getId: getId,
      setId: setId
    }
  
  })();
  
  export default UserProfile;