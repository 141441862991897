import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";

import Swal from "sweetalert2";

import axios from "axios";

import verimg from "../../img/ver.png";
// import listicon from "../../img/list.png";
// import ReactPaginate from "react-paginate";

const DemandReport = (props) => {

const [t, i18n] = useTranslation("demandReport")
  
  const [ offers, setoffers ] = useState([]);
  // const [ offer, setoffer ] = useState([]);

  const list = async () => {
    // setoffers([])
    setoffers([
      {
        "id" : 1,
        "activity" : {"id":"1", "name": "soldador"},
        "cant" : 5
      },
      {
        "id" : 1,
        "activity" : {"id":"3", "name": "Perforador"},
        "cant" : 4
      }
    ])
    // axios
    //     .get(`${process.env.REACT_APP_BASE_URL}/api/xxx`, {
    //       headers: {
    //         Authorization: `Bearer  ${UserProfile.getToken()}`,
    //       },
    //       params:{
    //         page: 1
    //     }}
    //   )
    //   .then((res) => {
    //     console.log(res);
    // if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
    //     setcompanies( res.data.data.companies )
    //   })
    //   .catch((error) => console.log(error));
  };

  
  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

    return (
      <>
        <div class="card">
            <h5 class="card-header">{t('ttl1')}</h5>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">{t('ttl2')}</th>
                            <th scope="col">{t('ttl3')}</th>
                            {/* <th scope="col">{t('ttl4')}</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {   
                          offers.map((offer)=>(
                              <tr>
                                  <td>{offer.activity.name}</td>
                                  <td>{offer.cant}</td>
                                  {/* <td>
                                      <div class="col-5 col-sm-5 col-md-3">
                                          {" "}
                                          <a
                                            href="javascript:void(0)"
                                            title={t('lbl1')}
                                            onClick={() => {
                                              console.log("Searching companies ...")
                                            }}
                                          >
                                              <img
                                              width="30"
                                              height="30"
                                              class=" mx-3"
                                              src={verimg}
                                              />
                                          </a>
                                      </div>
                                  </td> */}
                              </tr>
                          ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
      </>
    );
}
export default DemandReport;
