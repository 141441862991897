import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";

// import UserProfile from "../Login/UserProfile";
// import imgform from "../img/imgfroms.png"
import geojobscanada from "../img/formhrh.png";

import ChangePasswordform from "../PasswordRecovery/ChangePasswordform"

const ChangePassword = (props) => {

  const [t, i18n] = useTranslation("login")

  const [ chekContras, setchekContras ] = useState(false)
  const [ form, setform ] = useState({
                                                    email: "",
                                                    code: "",
                                                    password: "",
                                                    password2: ""
                                                  })

  return (
    <>
      <section class="">
        <div class="container py-5 ">
          <div class="row d-flex  justify-content-center ">
            <div class="col-md-12 col-lg-5 col-xl-5 offset-xl-1">
              <div class="square xyz-in" xyz="fade up big" >
                <div class="form-group  row justify-content-center ">
                    <img src={geojobscanada}  class="img-fluid  imgform"  />
                </div>

                <div class="mt-4">
                  <ChangePasswordform history={props.history} />
                </div>
                
                <div class="divider d-flex align-items-center my-2">
                  <div
                    class="tamanoLetra textbold  color-fuente-negro"
                    onClick={()=>props.history.push('/login')}
                  >
                    {t("lbl14")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChangePassword;
