import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import Home from "../Home/Home";

import googleIco from "../img/google_ico.png";
import homeimg from "../img/housedoorwhite.png";
import logoico from "../img/formhrh.png";
// import logoico from "../img/logohrhshort.png";
import espaniol from "../img/espaniaflag.png";
import ingles from "../img/canadaflag.png";
import frances from "../img/franciaflag.png";


const Header = (props) => {

 const [t, i18n] = useTranslation("header")

  const [ opc, setopc ] = useState("1");
  const [ idreg, setidreg ] = useState(1);
  const [ timechange, settimechange ] = useState(new Date);
  
  const languages = {
    1 : {
      lang: "en",
      icon: ingles
    },
    2 : {
      lang: "es",
      icon: espaniol
    },
    3 : {
      lang: "fr",
      icon: frances
    }
  }

  const changeLanguage = async (opc) => {
    let ln = languages[opc]
    localStorage.setItem('lngselect', ln.lang)
    localStorage.setItem('lngiconselect', ln.icon)
    i18n.changeLanguage(localStorage.getItem('lngselect'))
  }
 
  const changeOpc = async ({ opc = 1, data }) => {
    setidreg(data);
    hederClick(opc);
  };

  const hederClick = async (item) => {
    setopc(item)
    switch (item) {
      case "6":
        props.history.push("/login");
        break;
    }
  };

  useEffect(()=>{
    changeLanguage(1)
  },[])

    return (
      <>
        <header class="header sticky-top">
          <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <div class="container-fluid">
              <div class="" >
                  <img
                    src={logoico}
                    alt=""
                    width="200"
                    class="img-fluid margin-left"
                    onClick={()=>settimechange(new Date())} 
                  />
              </div>
              <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  >
                  <span class="navbar-toggler-icon"></span>
                </button>
              <div
                class="navbar-collapse collapse alingend"
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a
                      class={
                        opc === "1"
                          ? " mx-3 active fuente  barra"
                          : " mx-3  fuente  barra"
                      }
                      aria-current="page"
                      href="javascript:void(0)"
                      onClick={() => {
                        hederClick("1");
                      }}
                    >
                      <img src={homeimg} alt="" width="20" height="20"  class="text-white" />
                    </a>
                  </li>
                  {/* <li class="nav-item">
                    <a
                      href="javascript:void(0)"
                      class={
                        opc === "2"
                          ? "   nav-link active fuente "
                          : "   nav-link  fuente "
                      }
                      onClick={() => {
                        hederClick("2");
                      }}
                    >
                      {t("aboutus")}
                    </a>
                  </li> */}

                  <li class="nav-item">
                    <a
                      href="javascript:void(0)"
                      class={
                        opc === "3"
                          ? "   nav-link active fuente "
                          : "   nav-link  fuente "
                      }
                      onClick={() => {
                        hederClick("3");
                      }}
                    >
                      {t("canada")}
                    </a>
                  </li>

                  {/* <li class="nav-item">
                    <a
                      class={
                        opc === "4"
                          ? "   nav-link active fuente "
                          : "   nav-link fuente "
                      }
                      href="javascript:void(0)"
                      onClick={() => {
                        hederClick("4");
                      }}
                    >
                      {t("mandv")}
                    </a>
                  </li> */}

                  <li class="nav-item">
                    <a
                      class={
                        opc === "5"
                          ? "   nav-link active fuente "
                          : "   nav-link  fuente "
                      }
                      href="javascript:void(0)"
                      onClick={() => {
                        hederClick("5");
                      }}
                    >
                      {t("contactus")}
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class={
                        opc === "6"
                          ? "   nav-link active fuente "
                          : "   nav-link  fuente "
                      }
                      href="javascript:void(0)"
                      role="button"
                      onClick={() => {
                        hederClick("6");
                      }}
                    >
                      {t("login")}
                    </a>
                  </li>

                  <li class="nav-item dropdown">
                    <div class="text-end  fuente">
                      <a class="btn text-white text-white dropdown-toggle" href="#" role="button" id="dropdownLanguage" data-bs-toggle="dropdown" aria-expanded="false">
                        {/* {localStorage.getItem('lngselect')} */}
                        <img
                          width="25"
                          height="17"
                          class="rounded-circle mx-3"
                          src={localStorage.getItem('lngiconselect')}
                        />
                      </a>

                      <ul class="dropdown-menu dropdown-menu-cus" width="25" aria-labelledby="dropdownLanguage">
                        <li 
                          onClick={() => {
                            changeLanguage(1);
                          }}>
                            <a class="dropdown-item">
                              <img
                                width="25"
                                height="17"
                                class="rounded-circle mx-3"
                                src={ingles}
                              />
                            </a>
                            {/* <a class="dropdown-item">{t("en")}</a> */}
                        </li>
                        <li 
                          onClick={() => {
                            changeLanguage(2);
                          }}>
                            <a class="dropdown-item">
                              <img
                                width="25"
                                height="17"
                                class="rounded-circle mx-3"
                                src={espaniol}
                              />
                            </a>
                            {/* <a class="dropdown-item">{t("es")}</a> */}
                        </li>
                        <li 
                          onClick={() => {
                            changeLanguage(3);
                          }}>
                            <a class="dropdown-item">
                              <img
                                width="25"
                                height="17"
                                class="rounded-circle mx-3"
                                src={frances}
                              />
                            </a>
                            {/* <a class="dropdown-item">{t("fr")}</a> */}
                        </li>
                      </ul>

                    </div>
                  </li>
                </ul>

              </div>
            </div>
          </nav>

          {/* <div class="container mx-2">
            <h1 class="inicio">
              {
                <div class="item-group" xyz="fade">
                  <div class="square xyz-in">{opcText}</div>
                </div>
              }
            </h1>

            <div class="d-flex align-items-left">
              <div class="indicador">{opcText}/</div>

              <div class="ruta mx-1">{opcText}</div>
            </div>
          </div> */}
        </header>

        <Home opc={opc} changeOpc={changeOpc} dataxtr={idreg} timechange={timechange} history={props.history} match={props.match} />
      </>
    );
}

export default Header;
