import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import { components } from "react-select";
import DatePicker from "react-datepicker"
// import ReactPDF from "@react-pdf/renderer";
// import ReactDOM from "react-dom";
// import { PDFViewer } from "@react-pdf/renderer";

import userchat from "../../img/userchat.png";
import basuraimg from "../../img/Basura.png";
import listplas from "../../img/listplas.png";
import sendimg from "../../img/send.png";
import userimg from "../../img/user.png";
// import imgmodal from "../../img/imgmodal.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Image } from "react-bootstrap";
import Resizer from "react-image-file-resizer";

//import PrintApplicant from "./AdminPrint/PrintApplicant";
import UserProfile from "../../Login/UserProfile";

import RegisterLanguage from "../../components/RegisterLanguage"
import RegisterEducation from "../../components/RegisterEducation"
import RegisterLevelEducation from "../../components/RegisterLevelEducation"
import RegisterXpCompany from "../../components/RegisterXpCompany"
import RegisterPosition from "../../components/RegisterPosition"
import RegisterSkill from "../../components/RegisterSkill"


const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          class="form-check-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const AspiranteApplicant = (props) => { 

    const [t, i18n] = useTranslation("detailapplap")
    // i18n.changeLanguage("")

    // fileChangedHandler = fileChangedHandler.bind(this);
    // filedocChangedHandler = filedocChangedHandler.bind(this);
    
    const [ limitdescr, setlimitdescr ] = useState(500);

    const [ idApplicant, setidApplicant ] = useState(0);
    // const [ applicant, setapplicant ] = useState([]);
    // const [ id_type, setid_type ] = useState([]);
    // const [ countr, setcountr ] = useState([]);
    // const [ depart, setdepart ] = useState([]);
    // const [ applicantTem, setapplicantTem ] = useState([]);
    // const [ countr_loc, setcountr_loc ] = useState([]);
    // const [ depart_loc, setdepart_loc ] = useState([]);
    // const [ languages_applicant, setlanguages_applicant ] = useState([]);
    // const [ social_networks, setsocial_networks ] = useState([]);
    // const [ studies, setstudies ] = useState([]);
    // const [ experiences, setexperiences ] = useState([]);
    
    // const [ status, setstatus ] = useState(0);
    // const [ errors, seterrors ] = useState([]);
    
    // const [ nameapplicant, setnameapplicant ] = useState("");
    const [ activities, setactivities ] = useState([]);
    const [ comments, setcomments ] = useState([]);
    
    const [ first_nameRes, setfirst_nameRes ] = useState("");
    const [ middle_nameRes, setmiddle_nameRes ] = useState("");
    const [ first_lastnameRes, setfirst_lastnameRes ] = useState("");
    const [ second_lasnameRes, setsecond_lasnameRes ] = useState("");
    const [ titleRes, settitleRes ] = useState("");
    const [ descriptionRes, setdescriptionRes ] = useState("");
    const [ identification_typeRes, setidentification_typeRes ] = useState("");
    const [ identificationRes, setidentificationRes ] = useState("");
    const [ countryRes, setcountryRes ] = useState("");
    const [ departmentRes, setdepartmentRes ] = useState("");
    const [ cityRes, setcityRes ] = useState("");
    const [ country_nacionalityRes, setcountry_nacionalityRes ] = useState("");
    const [ department_nacionalityRes, setdepartment_nacionalityRes ] = useState("");
    const [ emailRes, setemailRes ] = useState("");
    const [ civil_statusRes, setcivil_statusRes ] = useState("");
    const [ img_perfilRes, setimg_perfilRes ] = useState("");
    const [ video_presentationRes, setvideo_presentationRes ] = useState("");
    
    const [ birthdayRes, setbirthdayRes ] = useState("");
    // const [ networkRes, setnetworkRes ] = useState("");
    // const [ nicknameRes, setnicknameRes ] = useState("");
    // const [ urlRes, seturlRes ] = useState("");
    
    const [ statusRes, setstatusRes ] = useState(0);
    
    const [ languageRes, setlanguageRes ] = useState([]);
    const [ educationRes, seteducationRes ] = useState([]);
    const [ experienceRes, setexperienceRes ] = useState([]);
    const [ socialNetworkRes, setsocialNetworkRes ] = useState([]);

    // const [ actividadesres, setactividadesres ] = useState([]);
    
    const [ pais, setpais ] = useState([]);
    const [ paisLocation, setpaisLocation ] = useState([]);
    const [ departamento, setdepartamento ] = useState([]);
    const [ city, setcity ] = useState([]);
    
    const [ departamentoNacionality, setdepartamentoNacionality ] = useState([]);
    
    const [ civilStatus, setcivilStatus ] = useState([]);
    const [ socialNetwork, setsocialNetwork ] = useState([]);
    
    const [ languagetipo, setlanguagetipo ] = useState([]);
    const [ nivelLanguage, setnivelLanguage ] = useState([]);
    const [ levelStudy, setlevelStudy ] = useState([]);
    const [ educacionInstitucion, seteducacionInstitucion ] = useState([]);
    const [ unidad, setunidad ] = useState([]);
    const [ positions, setpositions ] = useState([]);
    
    const [ companyWork, setcompanyWork ] = useState([]);
    const [ conta, setconta ] = useState(0);
    const [ reponsable, setreponsable ] = useState(0);
    const [ cargo, setcargo ] = useState(0);
    const [ identificationType, setidentificationType ] = useState([]);
    
    const [ actividades, setactividades ] = useState([]);
    const [ optionSelected, setoptionSelected ] = useState(null);
    
    // const [ splitDataURI, setsplitDataURI ] = useState([]);
    const [ estadoDeparment, setestadoDeparment ] = useState("");
    const [ estadoDeparment2, setestadoDeparment2 ] = useState([]);
    const [ estadoCity, setestadoCity ] = useState([]);
    
    const [ selectedFile, setselectedFile ] = useState([]);
    const [ newImage, setnewImage ] = useState("");
    const [ pdfcoding, setpdfcoding ] = useState("");

    const [ starting_date, setstarting_date ] = useState(new Date());
    const [ finishing_date, setfinishing_date ] = useState(new Date());
    


  const filedocChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      var ext = event.target.files[0].name.split('.').pop();
      if(ext == "pdf"){
        if(event.target.files[0].size > 5242880){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("mg1"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        let fileToLoad = event.target.files[0];
        let file = null;
        let fileName = null;
        fileName = fileToLoad.name;
        // FileReader function for read the file.
        let fileReader = new FileReader();
        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
            file = fileLoadedEvent.target.result;
            setpdfcoding(file)
        };
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
      }
      else
      {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("msg2"),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      }
    }
  }

  const fileChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          500,
          500,
          "png",
          85,
          0,
          (uri) => {
            setnewImage(uri)
          },
          "base64"
        );
      } catch (err) {
        console.log(err);
      }
    }
  }



  const addlanguagetipo = (data) => {
    setlanguagetipo([...languagetipo, data])
  }

  const addNewEducation = (data) => {
    seteducacionInstitucion([...educacionInstitucion, data])
  }

  const addNewlevelEducation = (data) => {
    setlevelStudy([...levelStudy, data])
  }

  const addNewXpCompany = (data) => {
    setcompanyWork([...companyWork, data])
  }

  const addNewPosition = (data) => {
    setpositions([...positions, data])
  }

  const addNewSkill = (data) => {
    setactividades([...actividades, data])
    renderList()
    document.getElementById("clocedModal").click()
  }

  const close = async (values) => {
    document.getElementById("clocedModal").click();
  };

  const closeEducation = async (values) => {
    document.getElementById("clocedModalEducation").click();
  };

  const addSocialNetwork = (val) => {
    var net = socialNetwork.find( item => item.id == val.network )
    const temp = {
      "network": net,
      "nickname": val.nickname,
      "url": val.url
    }
    setsocialNetworkRes([...socialNetworkRes, temp])
  };

  const deleteSocialNetwork = (val) => {
    setsocialNetworkRes(socialNetworkRes.filter((soc,itm) => itm !== val ))
  };

  const   addLanguages = (val) => {
    var len = languagetipo.find( item => item.id == val.language )
    var lvl = nivelLanguage.find( item => item.id == val.level )

    const temp = {
      "language": len,
      "level": lvl
    }
    setlanguageRes([...languageRes, temp])
  };

  const deleteLanguages = (val) => {
    setlanguageRes(languageRes.filter((lng,itm)=> itm !== val))
  };

  const addEducation = (val) => {
    var inst = educacionInstitucion.find( item => item.id == val.educational_institution )
    var lvl = levelStudy.find( item => item.id == val.level_study )
    // var uni = unidad.find( item => item.id == val.unit_measurement )

    const temp = {
      "name": val.name,
      "educational_institution": inst,
      "level_study": lvl,
      "inprogress": parseInt(val.inprogress) ? parseInt(val.inprogress) : 0,
      "starting_date": val.starting_date,
      "finishing_date": val.finishing_date,
    }
    seteducationRes([...educationRes, temp])

  };

  const deleteEducation = (val) => {
    seteducationRes(educationRes.filter((ed,itm) => itm !== val))
  };

  const closeExperience = () => {
    document.getElementById("clocedModalExperience").click();
  };

  const addExperience = (val) => {
    var comp = companyWork.find( item => item.id == val.company )
    var posi = positions.find( item => item.id == val.position )
    var uni = unidad.find( item => item.id == val.unit_measurement )
    const temp = {
      "functions": val.functions,
      "goals": val.goals,
      "initial_year": val.initial_year,
      "company": comp,
      "position": posi,
      "duration_time": val.duration_time,
      "unit_measurement": uni
    }

    setexperienceRes([...experienceRes, temp])
  };

  const deleteExperience = (val) => {
    setexperienceRes(experienceRes.filter((xp, itm) => itm !== val))
  };

  const closeAcount = (value) => {
    Swal.fire({
      text: t("lbl51"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t("lbl52")
    }).then((result) => {
      if (result.isConfirmed) {
        changeStateAcount()
      }
    })
  }

  const changeStateAcount = () => {
    const valuesupdate = {
      name: first_nameRes,
      email: emailRes,
      type: 2,
      status: 2
    };
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/users/${UserProfile.getId()}`,
        valuesupdate
      )
      .then(() => {
        props.history.push("/");
        localStorage.setItem("");
        localStorage.setItem("");
        localStorage.setItem("");
      })
      .catch((error) => console.log(error));
  }
  
  const saveEditApplicant = (val) => {
    console.log("editando aplicante");
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/${idApplicant}`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data.code == "new") {
          registro(val)
          return
        }
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        Swal.fire({
          icon: "success",
          title: "success",
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      })
      .catch((error) => console.log(error));
  };

  const registro = (val) => {
    console.log("tregistrando aplicante");
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/applicants`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        Swal.fire({
          icon: "success",
          title: "success",
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (selected) => {
    setoptionSelected(selected)
  };

  const getLevelStudy = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/level_study`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setlevelStudy(res.data.data.level_study)
      });
  };


  const getCompanyWork = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/company_experience`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setcompanyWork(res.data.data.company_experience)
      });
  };

  const getPositions = async () => {
    await axios
      // .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
      .post(`${process.env.REACT_APP_BASE_URL}/api/attrs/filterpositions/2`, [], {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        console.log(res)
        setpositions(res.data.data)
      });
  };

  const getInitMeasure = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/unit_measurement`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setunidad(res.data.data.unit_measurement)
      });
  };

  const getEducationInstitution = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/educational_institutions`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        seteducacionInstitucion(res.data.data.educational_institutions)
      });
  };

  const getLanguage = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/languages`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setlanguagetipo(res.data.data.languages)
      });
  };

  const getLevelLanguage = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/language_level`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setnivelLanguage(res.data.data.language_level)
      });
  };

  const getSocialNetwork = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/social_network`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setsocialNetwork(res.data.data.social_network)
      });
  };

  const getCivilStatus = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/civil_status`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setcivilStatus(res.data.data.civil_status)
      });
  };

  const getIdentificationType = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/identification_type`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setidentificationType(res.data.data.identification_type)
      });
  };

  const getActividad = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/activities`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setactividades(res.data.data.activities)
      });
  };

  const getCargo = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpositions(res.data.data.positions)
      });
  };

  const getDeparment = async (val, opc) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (opc === "1")
          setdepartamento(res.data.data.department)
          else setdepartamentoNacionality(res.data.data.department)
      });
  };



  const getCities = async (val) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/cities/by_department/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcity(res.data.data.city)
      });
  };


  const getCountry = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpais(res.data.data.country)
        setpaisLocation(res.data.data.country)
      });
  };


  const renderList = () => {
    return actividades.map((data) => ({
      label:  props.lng == 'en' ?
                data.name :
              props.lng == 'es' ?
                data.name_es :
              props.lng == 'fr' ?
                data.name_fr : "",
      value: data.id,
    }));
  }

  const renderListActividad = () => {
    var list = optionSelected == null ? [] : optionSelected  
    list.map((data) => ({
      activity: data.value,
    }));
    return list
  }



/****////////////////////////////////////// */

const getApplicant = async () => {
    var name = "";
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/profile`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setidApplicant(res.data.data.id)
        setfirst_nameRes(res.data.data.first_name)
        setmiddle_nameRes(res.data.data.second_name)
        setsecond_lasnameRes(res.data.data.second_lastname)
        setfirst_lastnameRes(res.data.data.first_lastname)
        settitleRes(res.data.data.title)
        setdescriptionRes(res.data.data.description)
        setidentification_typeRes(res.data.data.identification_type ? res.data.data.identification_type.id : "") 
        setidentificationRes(res.data.data.identification)
        setimg_perfilRes(res.data.data.img_perfil)

        setcountryRes(res.data.data.country.id)
        getDeparment(res.data.data.country.id,"1")

        setdepartmentRes(res.data.data.department ? res.data.data.department.id : "")
        getCities(res.data.data.department ? res.data.data.department.id : "")
        setcityRes(res.data.data.city ? res.data.data.city.id : "")

        setcountry_nacionalityRes(res.data.data.country_nacionality.id)
        getDeparment(res.data.data.country_nacionality.id,"2")
        setdepartment_nacionalityRes(res.data.data.department_nacionality ? res.data.data.department_nacionality.id : "")

        setemailRes(res.data.data.email)
        setcivil_statusRes(res.data.data.civil_status.id)

        //falta img
        setvideo_presentationRes(res.data.data.video_presentation)
        setstatusRes(res.data.data.status.id)

        setbirthdayRes(res.data.data.birthday)
        
        // multiples
        setlanguageRes(res.data.data.languages_applicant)
        seteducationRes(res.data.data.studies)
        setexperienceRes(res.data.data.experiences)
        setsocialNetworkRes(res.data.data.applicant_social_networks)

        // setactividadesres(res.data.data.applicant_activities)
        let opactsel = []
        for (const acti of res.data.data.applicant_activities) {
          opactsel.push({"value":acti.activity.id, "label":acti.activity.name})
        }
        setoptionSelected(opactsel)

      })
      .catch((error) => console.log(error));
  };

  const printCurriculum = (applicant) => {
    /*
    return ReactDOM.render(
      <PDFViewer style={{width:"100%", height:"100vh"}}>
        <PrintApplicant applicant={applicant.applicant}/>
      </PDFViewer>
    , document.getElementById('printing'))*/
  };

  const listChat = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/commentcv/`+idApplicant, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcomments(res.data.data.comment_cv)
      });
  };

  const send = async () => {
    if (document.getElementById("mensaje").value != "") {
      var data = {
        comment: document.getElementById("mensaje").value,
        applicant: idApplicant,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/comment_cv`, data, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            listChat();
          }
        })
        .catch((error) => console.log(error));
      document.getElementById("mensaje").value = "";
    }
  };


  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      UserProfile.setId(localStorage.getItem("id"));
      getCountry();
      getIdentificationType();
      getCivilStatus();
      getLevelStudy();
      getSocialNetwork();
      getLanguage();
      getLevelLanguage();
      getEducationInstitution();
      getActividad();
      getPositions(); 
      getCompanyWork();
      getInitMeasure();
      getCargo();

      /***************** */
      getApplicant();

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg4"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

  
    return (
      <>
          <div>
            <section class="">
              <div class="container">
                 <div class="row"> {/* comments with admin */}
                  <div class="col text-end">
                    <div class="text-left ">
                      <div className="mb-4">
                        <div className="container ">
                          <div class="row  my-1 ">
                            <div class="col-11 text-left txtSmallAzulModal ">
                              <div class="dropdownn">
                                <button
                                  class="btn btn-lightModal buttonSmallAzulModal"
                                  type="button"
                                  id="dropdownMenuButton2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={() => listChat()}
                                >
                                  {" "}
                                  <span>
                                    <img
                                      className="img-fluid "
                                      width="12px"
                                      height="12px"
                                      src={listplas}
                                    />{" "}
                                  </span>{" "}
                                  {t("lbl54")}
                                </button>
                                <div
                                  class="dropdown-menu fondoAzulModal"
                                  aria-labelledby="themes"
                                >
                                  <div class="container">
                                    <div class="form-group mb-1 text-center ">
                                      <img
                                        className=" mt-2 img-fluid"
                                        width="20px"
                                        height="20px"
                                        src={userimg}
                                      />
                                    </div>
                                    <div class="form-group mb-2 text-center ">
                                      {t("lbl53")}
                                    </div>
                                  </div>
                                  <div className="linea lineamovil"></div>
                                  <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                                    <div className="anyClass anyClassMovil" id="topabajo">
                                      {comments ? 
                                        comments.map((i) => {
                                          if (i.type == 2) {
                                            return (
                                              <>
                                                <div class="form-group mb-2 text-left mt-2 ">
                                                  <div class="row  my-1 ">
                                                    <div class="col-2 col-sm-2 col-md-2 ">
                                                      <img
                                                        className=" mt-2 img-fluid ingMpvilchat"
                                                        width="50px"
                                                        height="50px"
                                                        src={userchat}
                                                      />
                                                    </div>

                                                    <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                                                      <div className="txtSmallGris">
                                                      {i.name}
                                                      </div>

                                                      <p className="text-left"> {i.comment} </p>
                                                      
                                                      <div class="txtSmallGris"> {i.created_at} </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <div class="form-group mb-2  mt-2 ">
                                                  <div class="row  my-1   justify-content-end    ">
                                                    <div class=" col-5 col-sm-5 col-md-5 text-end  mt-1 globo">
                                                      <div className="txtSmallGris">
                                                        {i.first_name}
                                                      </div>
                                                      <p className="">{i.comment}</p>
                                                      <div class="txtSmallGris"> {i.created_at} </div>
                                                    </div>
                                                    <div class="col-2 col-sm-2 col-md-2 ">
                                                      <img
                                                        className=" mt-2 img-fluid ingMpvilchat"
                                                        width="50px"
                                                        height="50px"
                                                        src={userchat}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        }): "" 
                                      } 
                                    </div>
                                  </div>

                                  <div class="container">
                                    <div class="row  my-1 ">
                                      <div class="col-10 ">
                                        <input
                                          id="mensaje"
                                          type="text"
                                          name="mensaje"
                                          className="mt-1 form-control imputTextModal"
                                          aria-describedby="emailHelp"
                                          placeholder={t("lbl55")}
                                        />
                                      </div>

                                      <div class="col-2 text-left ">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            send();
                                          }}
                                        >
                                          <img
                                            className=" mt-2 img-fluid"
                                            width="20px"
                                            height="20px"
                                            src={sendimg}
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{/* END comments with admin */}
                </div>
                <div class="row">
                  <div class="container py-5 h-100">
                    <div class="row justify-content-center">
                      <div class="col-md-2 col-sm-12">
                        <Image src={img_perfilRes || 'https://www.focusedu.org/wp-content/uploads/2018/12/circled-user-male-skin-type-1-2.png'} roundedCircle fluid/>
                      </div>
                    </div>
                    <div class="row d-flex  justify-content-center h-100">
                      <div class="col-md-12 col-sm-12">
                      
                          <Formik
                            initialValues={{
                              firstName: first_nameRes,
                              middleName: middle_nameRes,
                              firstLastName: first_lastnameRes,
                              secondLastMame: second_lasnameRes,
                              title: titleRes,
                              description: descriptionRes,
                              idTypeidentification: identification_typeRes,
                              identification: identificationRes,

                              idCountry: countryRes,
                              idDepartament: departmentRes,
                              idcity:cityRes,

                              idCountryNacionality: country_nacionalityRes,
                              // idDepartamentNacionality: department_nacionalityRes,

                              emailr: emailRes,
                              videoPresentation: video_presentationRes,
                              civilStatus: civil_statusRes,
                              birthday: birthdayRes,
                            
                            }}
                            validate={(values) => {
                              const pattern = new RegExp(
                                "^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$",
                                "i"
                              );
        
                              const errors = {};
        
                              if (!values.firstName)
                                errors.firstName = t("fr");
                              else if (!pattern.test(values.firstName))
                                errors.firstName = t("ot");
        
                              // if (!values.middleName)
                              //   errors.middleName = t("fr");
                              // else if (!pattern.test(values.middleName))
                              //   errors.middleName = t("ot");
        
                              if (!values.firstLastName)
                                errors.firstLastName = t("fr");
                              else if (!pattern.test(values.firstLastName))
                                errors.firstLastName = t("ot");
        
                              // if (!values.secondLastMame)
                              //   errors.secondLastMame = t("fr");
                              // else if (!pattern.test(values.secondLastMame))
                              //   errors.secondLastMame = t("ot");
        
                              if (!values.title) errors.title = t("fr");
                              else if (!pattern.test(values.title))
                                errors.title = t("ot");
        
                              // if (!values.description){
                              //   errors.description = t("fr")
                              // }
                              setlimitdescr(500 - (values.description != null ? values.description.length : 0) )
        
                              if (!values.idCountry) {
                                errors.idCountry = t("fr");
                              } else {
                                if (values.idCountry != estadoDeparment) {
                                  getDeparment(values.idCountry, "1");
                                  setestadoDeparment(values.idCountry)
                                }
                              }

                              if (!values.idDepartament) {
                                errors.idDepartament = t("fr");
                              } else {
                                if (
                                  values.idDepartament !=
                                  estadoCity
                                ) {
                                  getCities(values.idDepartament);
                                  setestadoCity(values.idDepartament)
                                }
                              }
        
                              // if (!values.idCountryNacionality) {
                              //   errors.idCountryNacionality = t("fr");
                              // } else {
                              //   if (
                              //     values.idCountryNacionality !=
                              //     estadoDeparment2
                              //   ) {
                              //     getDeparment(values.idCountryNacionality, "2");
                              //     setestadoDeparment2(values.idCountryNacionality)
                              //   }
                              // }
        
                              return errors;
                            }}
        
                            validationSchema={Yup.object().shape({
                              idTypeidentification:
                                Yup.string(),
                              // identification: Yup.string(),
        
                              // idcity: Yup.string().required(t("fr")),
        
                              // idCountryNacionality:
                              //   Yup.string().required(t("fr")),
        
                              civilStatus: Yup.string(),
                  
                              emailr: Yup.string()
                                .required(t("fr"))
                                .email(t("ei")),

                              birthday: Yup.string().required(t("fr")),
                            })}

                            onSubmit={(val, { resetForm }) => {
                              // resetForm();
                              // if(languageRes.length > 0 && educationRes.length>0 && experienceRes.length>0){
          
                                const data ={
                                  first_name: val.firstName ? val.firstName.trim() : "",
                                  first_lastname: val.firstLastName ? val.firstLastName.trim() : "",
                                  title: val.title ? val.title.trim() : "",
                                  description: val.description ? val.description.trim() : "" ,
                                  identification_type: val.idTypeidentification ,
                                  identification: val.identification ? val.identification.trim() : "" ,
                                  birthday : val.birthday,
                                  country:  val.idCountry,
                                  department: val.idDepartament,
                                  city: val.idcity,
                                  country_nacionality: val.idCountryNacionality,
                                  department_nacionality: val.idDepartamentNacionality,
                                  email: val.emailr ?  val.emailr.trim() : "",
                                  civil_status: val.civilStatus,
                                  img_perfil:newImage ? newImage : "",
                                  document:pdfcoding ? pdfcoding.replace('data:application/pdf;base64,', '') : "", 
                                  video_presentation: val.videoPresentation ? val.videoPresentation.trim().replace('watch?v=', 'embed/') : "",
                                  status: 1,
                                  activities: renderListActividad(),


                                  networks:  socialNetworkRes,
                                  languagues:  languageRes,
                                  studies:  educationRes,
                                  experiences:  experienceRes,
                                }
                                
                                saveEditApplicant(data)
          
                              // }else{
          
                              //   Swal.fire({
                              //     icon: "error",
                              //     title: "Oops...",
                              //     text: t("msg5"),
                              //     confirmButtonColor: "#071b30",
                              //     confirmButtonText: t("ok"),
                              //   });
                              // }
                            }}
                            enableReinitialize
                          >
                          { ({handleSubmit}) => ( 
                            <Form class="container" onSubmit={handleSubmit}>

                              <div class="form-group mb-4 text-end">
                                <div class="color-fuente-gris textbold">
                                  {t("lbl1")}
                                </div>
                              </div>

                              <div class="row">

                                <div class="col-md-6 col-sm-12 col-lg-6" >
                                  <div class="form-group mb-4">
                                    <label for="firstName" class="form-label">{t("lbl2")} *</label>
                                    <Field
                                      id="firstName"
                                      name="firstName"
                                      type="numeric"
                                      class="form-control imputText"
                                      placeholder={t("lbl2")}
                                    />

                                    <ErrorMessage
                                      name="firstName"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="middleName" class="form-label">{t("lbl3")}</label>
                                    <Field
                                      id="middleName"
                                      name="middleName"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl3")}
                                    />

                                    <ErrorMessage
                                      name="middleName"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="firstLastName" class="form-label">{t("lbl4")} *</label>
                                    <Field
                                      id="firstLastName"
                                      name="firstLastName"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl4")}
                                    />

                                    <ErrorMessage
                                      name="firstLastName"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="secondLastMame" class="form-label">{t("lbl5")}</label>
                                    <Field
                                      id="secondLastMame"
                                      name="secondLastMame"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl5")}
                                    />

                                    <ErrorMessage
                                      name="secondLastMame"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="title" class="form-label">{t("lbl6")} *</label>
                                    <Field
                                      id="title"
                                      name="title"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl6")}
                                    />

                                    <ErrorMessage
                                      name="title"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="emailr" class="form-label">{t("lbl16")} *</label>
                                    <Field
                                      id="emailr"
                                      name="emailr"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl16")}
                                    />

                                    <ErrorMessage
                                      name="emailr"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="idTypeidentification" class="form-label">{t("lbl9")}</label> 
                                    <Field
                                      as="select"
                                      id="idTypeidentification"
                                      name="idTypeidentification"
                                      className="form-select fondoamarillo imputText"
                                      aria-label={t("lbl8")}
                                    >
                                      <option
                                        className="fondo fw-bolder"
                                        value=""
                                        selected
                                      >
                                        {t("lbl9")}
                                      </option>

                                      {identificationType.map((i) => {
                                        return (
                                          <option
                                            className="fondo fw-bolder"
                                            value={i.id}
                                          >
                                            {
                                                props.lng == 'en' ?
                                                  i.description :
                                                props.lng == 'es' ?
                                                  i.description_es :
                                                props.lng == 'fr' ?
                                                  i.description_fr : ""
                                              }
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="idTypeidentification"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="identification" class="form-label">{t("lbl10")}</label> 
                                    <Field
                                      id="identification"
                                      name="identification"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl10")}
                                    />

                                    <ErrorMessage
                                      name="identification"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>
                                </div>

                                <div class="col-md-6 col-sm-12 col-lg-6" >
                                  <div class="form-group mb-4">
                                    <label for="birthday" class="form-label">{t("lbl24")} *</label>
                                    <Field
                                      id="birthday"
                                      name="birthday"
                                      type="date"
                                      class="form-control imputText"
                                      placeholder={t("lbl24")}
                                    />

                                    <ErrorMessage
                                      name="birthday"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>
                                  
                                  <div class="form-group mb-4">
                                    <label for="civilStatus" class="form-label">{t("lbl17")}</label>
                                    <Field
                                      as="select"
                                      id="civilStatus"
                                      name="civilStatus"
                                      className="form-select fondoamarillo imputText"
                                      aria-label={t("lbl8")}
                                    >
                                      <option
                                        className="fondo fw-bolder"
                                        value=""
                                        selected
                                      >
                                        {t("lbl17")}
                                      </option>

                                      {civilStatus.map((i) => {
                                        return (
                                          <option
                                            className="fondo fw-bolder"
                                            value={i.id}
                                          >
                                            {
                                                props.lng == 'en' ?
                                                  i.description :
                                                props.lng == 'es' ?
                                                  i.description_es :
                                                props.lng == 'fr' ?
                                                  i.description_fr : ""
                                              }
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="civilStatus"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="idCountryNacionality" class="form-label">{t("lbl14")} *</label>
                                    <Field
                                      as="select"
                                      id="idCountryNacionality"
                                      name="idCountryNacionality"
                                      className="form-select fondoamarillo imputText"
                                      aria-label={t("lbl8")}
                                    >
                                      <option
                                        className="fondo fw-bolder"
                                        value=""
                                        selected
                                      >
                                        {t("lbl14")}
                                      </option>

                                      {paisLocation.map((i) => {
                                        return (
                                          <option
                                            className="fondo fw-bolder"
                                            value={i.id}
                                          >
                                            {
                                                props.lng == 'en' ?
                                                  i.name :
                                                props.lng == 'es' ?
                                                  i.name_es :
                                                props.lng == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="idCountryNacionality"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="card mb-3">
                                    <div class="card-body">
                                      <h5 class="card-title">{t("lbl58")}</h5>
                                      <div class="form-group mb-4">
                                        <label for="idCountry" class="form-label">{t("lbl11")} *</label>
                                        <Field
                                          as="select"
                                          id="idCountry"
                                          name="idCountry"
                                          className="form-select fondoamarillo imputText"
                                          
                                        >
                                          <option
                                            className="fondo fw-bolder"
                                            value=""
                                            selected
                                          >
                                            {t("lbl11")}
                                          </option>

                                          {pais.map((i) => {
                                            return (
                                              <option
                                                className="fondo fw-bolder"
                                                value={i.id}
                                              >
                                                {
                                                props.lng == 'en' ?
                                                  i.name :
                                                props.lng == 'es' ?
                                                  i.name_es :
                                                props.lng == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                              </option>
                                            );
                                          })}
                                        </Field>

                                        <ErrorMessage
                                          name="idCountry"
                                          component="div"
                                          className="msgerror mx-2"
                                        />
                                      </div>

                                      <div class="form-group mb-4">
                                        <label for="idDepartament" class="form-label">{t("lbl12")} *</label>
                                        <Field
                                          as="select"
                                          id="idDepartament"
                                          name="idDepartament"
                                          className="form-select fondoamarillo imputText"
                                          
                                        >
                                          <option
                                            className="fondo fw-bolder"
                                            value=""
                                            selected
                                          >
                                            {t("lbl12")}
                                          </option>

                                          {departamento.map((i) => {
                                            return (
                                              <option
                                                className="fondo fw-bolder"
                                                value={i.id}
                                              >
                                                {
                                                props.lng == 'en' ?
                                                  i.name :
                                                props.lng == 'es' ?
                                                  i.name_es :
                                                props.lng == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                              </option>
                                            );
                                          })}
                                        </Field>

                                        <ErrorMessage
                                          name="idDepartament"
                                          component="div"
                                          className="msgerror mx-2"
                                        />
                                      </div>

                                      <div class="form-group mb-4">
                                        <label for="idcity" class="form-label">{t("lbl13")} *</label>
                                        <Field
                                          as="select"
                                          id="idcity"
                                          name="idcity"
                                          className="form-select fondoamarillo imputText"
                                        >
                                          <option
                                            className="fondo fw-bolder"
                                            value=""
                                            
                                          >
                                            {t("lbl13")}
                                          </option>

                                          {city.map((i) => {
                                            return (
                                              <option
                                                className="fondo fw-bolder"
                                                value={i.id}
                                              >
                                              {
                                                props.lng == 'en' ?
                                                  i.name :
                                                props.lng == 'es' ?
                                                  i.name_es :
                                                props.lng == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                              </option>
                                            );
                                          })}
                                        </Field>

                                        <ErrorMessage
                                          name="idcity"
                                          component="div"
                                          className="msgerror mx-2"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
<hr/>

                              <div class="row" >
                                <div class="col-md-6 col-sm-12 col-lg-6">
                                  <div class="row border rounded-3">
                                    <div class="form-group mb-4">
                                      <div class="row d-flex justify-content-end my-2">
                                        <div class=" col-5 col-sm-5 col-md-5 text-end">
                                          <button
                                            type="button"
                                            class="btn btn-primary  btn-block"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalEducation"
                                          >
                                            {t("lbl27")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>


                                    <div class="form-group">
                                      {educationRes.map((i, index) => {
                                        return (
                                          <>
                                            <div class="row">
                                              <div class="col">
                                                <p>{i.name}</p>
                                              </div>
                                              <div class="col">
                                                <p>{
                                                    props.lng == 'en' ?
                                                      i.level_study.description :
                                                    props.lng == 'es' ?
                                                      i.level_study.description_es :
                                                    props.lng == 'fr' ?
                                                      i.level_study.description_fr : ""
                                                  }
                                                </p>
                                              </div>
                                              <div class="col">
                                                <p>{i.educational_institution.name}</p>
                                              </div>
                                              <div class="col">
                                                <p class="fw-bold" > {t("lbl59")} </p> {i.starting_date}
                                              </div>
                                              <div class="col">
                                                <p class="fw-bold" > {t("lbl60")} </p> {i.finishing_date}
                                              </div>
                                              <div class="col">
                                                <p>{i.inprogress == 1 ? "in progress" : "Finish"}</p>
                                              </div>
                                              <div class="col">
                                                <div
                                                  onClick={deleteEducation.bind(this, index)}
                                                >
                                                  <img
                                                    className=" mt-2 img-fluid"
                                                    width="15px"
                                                    src={basuraimg}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>

                                  <div class="row border rounded-3">
                                    <div class="form-group mb-4">
                                      <div class="row d-flex justify-content-end my-2">
                                        <div class=" col-5 col-sm-5 col-md-5 text-end">
                                          <button
                                            type="button"
                                            class="btn btn-primary btn-block"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalLanguage"
                                          >
                                            {t("lbl26")}
                                          </button>
                                        </div>
                                      </div>
                                    </div> 
                                    
                                    <div class="form-group">
                                    {
                                      languageRes.map((i, index) => {
                                        return (
                                            <div class="row">
                                              <div class="col">
                                                <p class="">
                                                  {i.language.name}
                                                </p>
                                              </div>
                                              <div class="col">
                                                <p class="">
                                                  {i.level.description}
                                                </p>
                                              </div>
                                              <div class="col">
                                                <div
                                                  onClick={deleteLanguages.bind(this, index)}
                                                >
                                                  <img
                                                    className=" mt-2 img-fluid"
                                                    width="15px"
                                                    src={basuraimg}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                        )
                                      })
                                    }
                                    </div>
                                  </div>

                                </div>

                                <div class="col-md-6 col-sm-12 col-lg-6">
                                  <div class="row border rounded-3">
                                    <div class="form-group mb-4">
                                      <div class="row d-flex justify-content-end my-2">
                                        <div class=" col-5 col-sm-5 col-md-5 text-end">
                                          <button
                                            type="button"
                                            class="btn btn-primary  btn-block"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalExperience"
                                          >
                                            {t("lbl28")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="form-group">
                                      {experienceRes.map((i, index) => {
                                        return (
                                          <div class="row">
                                            <div class="col">
                                              <p>{i.position.name}</p>
                                            </div>
                                            <div class="col">
                                              <p>{i.duration_time} - {
                                                    props.lng == 'en' ?
                                                      i.unit_measurement.name :
                                                    props.lng == 'es' ?
                                                      i.unit_measurement.name_es :
                                                    props.lng == 'fr' ?
                                                      i.unit_measurement.name_fr : ""
                                                  }</p>
                                            </div>
                                            <div class="col">
                                              <p>{i.company.name}</p>
                                            </div>
                                            <div class="col">
                                              <p>{i.initial_year}</p>
                                            </div>
                                            <div class="col">
                                              <div
                                                onClick={deleteExperience.bind(this, index)}
                                              >
                                                <img
                                                  className=" mt-2 img-fluid"
                                                  width="15px"
                                                  src={basuraimg}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>

                                  <div class="row border rounded-3">
                                    <div class="form-group mb-4">
                                      <div class="row d-flex justify-content-end my-2">
                                        <div class=" col-5 col-sm-5 col-md-5 text-end">
                                          <button
                                            type="button"
                                            class="btn btn-primary  btn-block"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalSocialNetwork"
                                          >
                                            {t("lbl25")}
                                          </button>
                                        </div>
                                      </div>
                                    </div> 
                                    
                                    <div class="form-group">
                                    {socialNetworkRes.map((i, index) => (
                                          <div class="row">
                                            <div class="col">
                                              <p class="">
                                                {i.network.name}
                                              </p>
                                            </div>
                                            <div class="col">
                                              <p class="">
                                                {i.nickname}
                                              </p>
                                            </div>
                                            <div class="col">
                                              <div
                                                onClick={deleteSocialNetwork.bind(this, index)}
                                              >
                                                <img
                                                  className=" mt-2 img-fluid"
                                                  width="15px"
                                                  src={basuraimg}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                      ))
                                    }
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row mt-4">
                                <div class="col-md-6 col-sm-12 col-lg-6">
                                  <div class="form-group mb-4">
                                    <div class="color-fuente-gris textbold">
                                      {" "}
                                      {t("lbl18")}
                                    </div>

                                    <input
                                      id="file"
                                      type="file"
                                      class="form-control imputText field-input"
                                      accept="image/png, image/jpeg,image/jpg "
                                      title={t("lbl19")}
                                      webkitRelativePath
                                      directory 

                                    onChange={fileChangedHandler}
                                  
                                    /> 
                                    <ErrorMessage
                                      name="file"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>
                                </div>

                                <div class="col-md-6 col-sm-12 col-lg-6" >
                                  <div clasS="row align-items-center">
                                    <div class="col-md-10">
                                      <div class="form-group mb-4">
                                        <label for="applicantActivites" class="form-label">{t("lbl56")}</label>
                                        {
                                          actividades ?
                                            <Select
                                              id="applicantActivites"
                                              name="applicantActivites"
                                              placeholder={
                                                <div className="fondoamarillo">
                                                  {t("lbl56")}
                                                </div>
                                              }
                                              options={renderList()}
                                              isMulti
                                              closeMenuOnSelect={false}
                                              hideSelectedOptions={false}
                                              components={{
                                                Option,
                                              }}
                                              onChange={handleChange}
                                              allowSelectAll={true}
                                              value={optionSelected}
                                            />
                                          : ""
                                        }
                                        <ErrorMessage
                                          name="applicantActivites"
                                          component="div"
                                          className="msgerror mx-2"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-2">
                                      <button
                                        className="btn fondo buttonAzulModal"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#mdlnewskill"
                                      >
                                        {t("ttl11")}
                                      </button>
                                    </div>
                                  </div>
                                </div>

                              </div>

<hr/>

                              <div class="row" >
                                <div class="col-md-6 col-sm-12">
                                  <div class="form-group mb-4">
                                    <label for="videoPresentation" class="form-label">{t("lbl23")}</label>
                                    <Field
                                      id="videoPresentation"
                                      name="videoPresentation"
                                      type="text"
                                      class="form-control imputText field-input"
                                      placeholder="https://youtube.com/HRH"
                                    />

                                    <ErrorMessage
                                      name="videoPresentation"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-6 col-sm-12 col-lg-6" >
                                  <div class="form-group mb-4">
                                    <div class="color-fuente-gris textbold">
                                      {" "}
                                      {t("lbl20")}  &nbsp;
                                    </div>

                                    <div class="alert alert-light" role="alert">
                                      {t("lbl21")}
                                    </div>

                                    <input
                                      id="filedoc"
                                      type="file"
                                      class="form-control imputText field-input"
                                      accept="application/pdf"
                                      title={t("lbl22")}
                                      webkitRelativePath
                                      directory 

                                      onChange={filedocChangedHandler}
                                    />

                                    <ErrorMessage
                                      name="filedoc"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row" >
                                <div class="col-md-12 col-sm-12 col-lg-12" >
                                  <div class="form-group mb-4">
                                    <label for="description" class="form-label">{t("lbl7")}</label>
                                    <Field
                                      id="description"
                                      name="description"
                                      type="textarea"
                                      class="form-control rounded-0 textArea"
                                      component="textarea"
                                      aria-describedby="emailHelp"
                                      placeholder={t("lbl7")}
                                      rows="3"
                                      maxlength="500"
                                    />

                                    <ErrorMessage
                                      name="description"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                    <label for="description" class="form-label">{limitdescr}</label>
                                    
                                  </div>
                                </div>
                              </div>



                              <div class="divider d-flex align-items-center my-2">
                                <button
                                  type="submit"
                                  class="btn btn-warning  btn-block  buttons "
                                >
                                  {t("lbl29")}
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-right align-items-end  mb-3">
                  <div class="col">
                    <button
                      type="button"
                      class="btn btn-primary btn-block"
                      onClick={closeAcount.bind()}
                    >
                      {t('lbl50')}
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>


      {/* modal add other skills */}
      <div
          class="modal fade"
          id="mdlnewskill"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl11")}
                </h5>
                <button
                  id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <RegisterSkill addNewSkill={addNewSkill}/>
              </div>
            </div>
          </div>
        </div>
   

      {/* modal add language */}
      <div
        class="modal fade"
        id="modalLanguage"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title txtSmallerGrisModal "
                id="staticBackdropLabel"
              >
                {t("lbl30")}
              </h5>
              <button
                id="clocedModal"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="tab-o" data-bs-toggle="tab" data-bs-target="#actuallanguage" type="button" role="tab" aria-controls="actuallanguage" aria-selected="true">{t("lbl30")}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link " id="tab-t" data-bs-toggle="tab" data-bs-target="#newlanguage" type="button" role="tab" aria-controls="newlanguage" aria-selected="false">{t("ttl5")}</button>
                </li>
              </ul>

              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade " id="newlanguage" role="tabpanel" aria-labelledby="tab-t">
                  {/* componente de registro de lenguaje */}
                  <RegisterLanguage addlanguagetipo={addlanguagetipo}/>
                </div>
                <div class="tab-pane fade show active" id="actuallanguage" role="tabpanel" aria-labelledby="tab-o">
                  <Formik
                    initialValues={{
                      language: "",
                      level: "",
                    }}

                    validationSchema={Yup.object().shape({
                      language: Yup.string().required(t("fr")),
                      level: Yup.string().required(t("fr")),
                    })}

                    onSubmit={(val, { resetForm }) => {
                      resetForm();
                      close();
                      addLanguages(val);
                    }}
                    render={({ errors, status, touched }) => (

                      <Form class="container">
                        <div class="form-group mb-4">
                          <Field
                            as="select"
                            id="language"
                            name="language"
                            className="form-select fondoamarillo imputText"
                          >
                            <option className="fondo fw-bolder" value="" selected>
                              {t("lbl31")}
                            </option>

                            {languagetipo.map((i) => {
                              return (
                                <option className="fondo fw-bolder" value={i.id}>
                                  {
                                    props.lng == 'en' ?
                                      i.name :
                                    props.lng == 'es' ?
                                      i.name_es :
                                    props.lng == 'fr' ?
                                      i.name_fr : ""
                                  }
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="language"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>

                        <div class="form-group mb-4">
                          <Field
                            as="select"
                            id="level"
                            name="level"
                            className="form-select fondoamarillo imputText"
                            aria-label="Default select example"
                          >
                            <option className="fondo fw-bolder" value="" selected>
                              {t("lbl32")}
                            </option>

                            {nivelLanguage.map((i) => {
                              return (
                                <option className="fondo fw-bolder" value={i.id}>
                                  {
                                    props.lng == 'en' ?
                                      i.description :
                                    props.lng == 'es' ?
                                      i.description_es :
                                    props.lng == 'fr' ?
                                      i.description_fr : ""
                                  }
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="level"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>

                        <div class="divider d-flex align-items-center my-2">
                          <button
                            type="submit"
                            class="btn btn-warning  btn-block  buttons "
                          >
                            {t("lbl33")}
                          </button>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal add social network */}

      <div
        class="modal fade"
        id="modalSocialNetwork"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title txtSmallerGrisModal "
                id="staticBackdropLabel"
              >
                {t("lbl34")}
              </h5>
              <button
                id="clocedModal"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
                    <Formik
                      initialValues={{
                        network: "",
                        nickname: "",
                        url: "",
                      }}

                      validationSchema={Yup.object().shape({
                        network: Yup.string().required(t("fr")),
                        nickname: Yup.string().required(t("fr")),
                        url: Yup.string().required(t("fr")),
                      })}

                      onSubmit={(val, { resetForm }) => {
                        resetForm();
                        close();
                        addSocialNetwork(val);
                      }}
                      render={({ errors, status, touched }) => (

                        <Form class="container mt-3">
                          <div class="form-group mb-4">
                            <Field
                              as="select"
                              id="network"
                              name="network"
                              className="form-select fondoamarillo imputText"
                            >
                              <option className="fondo fw-bolder" value="" selected>
                                {t("lbl35")}
                              </option>

                              {socialNetwork.map((i) => {
                                return (
                                  <option className="fondo fw-bolder" value={i.id}>
                                    {i.name}
                                  </option>
                                );
                              })}
                            </Field>

                            <ErrorMessage
                              name="network"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <Field
                              id="nickname"
                              name="nickname"
                              type="text"
                              class="form-control imputText"
                              placeholder={t("lbl36")}
                            />

                            <ErrorMessage
                              name="nickname"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <Field
                              id="url"
                              name="url"
                              type="text"
                              class="form-control imputText"
                              placeholder={t("lbl37")}
                            />

                            <ErrorMessage
                              name="url"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="divider d-flex align-items-center my-2">
                            <button
                              type="submit"
                              class="btn btn-warning  btn-block  buttons "
                            >
                              {t("lbl33")}
                            </button>
                          </div>
                        </Form>
                      )}
                    />
            </div>
          </div>
        </div>
      </div>

      {/* modal add Education */}

      <div
        class="modal fade"
        id="modalEducation"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title txtSmallerGrisModal "
                id="staticBackdropLabel"
              >
                {t("lbl38")}
              </h5>
              <button
                id="clocedModalEducation"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="tab-o-edu" data-bs-toggle="tab" data-bs-target="#actualeducation" type="button" role="tab" aria-controls="actualeducation" aria-selected="true">{t("ttl2")}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link " id="tab-t-edu" data-bs-toggle="tab" data-bs-target="#neweducation" type="button" role="tab" aria-controls="neweducation" aria-selected="false">{t("ttl6")}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link " id="tab-tr-edu" data-bs-toggle="tab" data-bs-target="#newleveleducation" type="button" role="tab" aria-controls="newleveleducation" aria-selected="false">{t("ttl7")}</button>
                </li>
              </ul>

              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade " id="neweducation" role="tabpanel" aria-labelledby="tab-t-edu">
                  <RegisterEducation addNewEducation={addNewEducation}/>
                </div>
                <div class="tab-pane fade " id="newleveleducation" role="tabpanel" aria-labelledby="tab-tr-edu">
                  <RegisterLevelEducation addNewlevelEducation={addNewlevelEducation}/>
                </div>
                <div class="tab-pane fade show active" id="actualeducation" role="tabpanel" aria-labelledby="tab-o-edu">

                  <Formik
                    initialValues={{
                      name: "",
                      educational_institution: "",
                      starting_date: "",
                      finishing_date: "",
                      level_study: "",
                      inprogress: "",
                    }}
                    validate={(values) => {
                      const errors = {};
                      const pattern = new RegExp(
                        "^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$",
                        "i"
                      );

                      if (!values.name) errors.name = t("fr");
                      else if (!pattern.test(values.name))
                        errors.name = t("ot");

                      return errors;
                    }}
                    validationSchema={Yup.object().shape({
                      educational_institution:
                        Yup.string().required(t("fr")),
                      starting_date: Yup.string().required(t("fr")),
                      finishing_date: Yup.string().required(t("fr")),
                      level_study: Yup.string().required(t("fr")),
                    })}

                    onSubmit={(val, { resetForm }) => {
                      resetForm();
                      closeEducation();
                      addEducation(val);
                    }}
                    render={({ errors, status, touched }) => (
                      <Form class="container mt-3">
                        <div class="form-group mb-4">
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            class="form-control imputText"
                            placeholder={t("lbl57")}
                          />

                          <ErrorMessage
                            name="name"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>

                        <div class="form-group mb-4">
                          <Field
                            as="select"
                            id="educational_institution"
                            name="educational_institution"
                            className="form-select fondoamarillo imputText"
                          >
                            <option className="fondo fw-bolder" value="" selected>
                              {t("lbl40")}
                            </option>

                            {educacionInstitucion.map((i) => {
                              return (
                                <option className="fondo fw-bolder" value={i.id}>
                                  {i.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="educational_institution"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>

                        <div class="form-group mb-4">
                          <label htmlFor="starting_date">{t("lbl59")}</label>
                          <Field
                            id="starting_date"
                            name="starting_date"
                            type="date"
                            class="form-control imputText"
                          />
                          {/* <DatePicker 
                            selected={starting_date}
                            dateFormat="Y-M-d"
                            className="form-control"
                            id="starting_date"
                            name="starting_date"
                            onChange={(e) => {
                              setstarting_date(e)
                            }}
                          /> */}

                          <ErrorMessage
                            name="starting_date"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>

                        <div class="form-group mb-4">
                          <label htmlFor="finishing_date">{t("lbl60")}</label>
                          <Field
                            id="finishing_date"
                            name="finishing_date"
                            type="date"
                            class="form-control imputText"
                          />
                          {/* <DatePicker 
                            selected={finishing_date}
                            dateFormat="Y-M-d"
                            className="form-control"
                            id="finishing_date"
                            name="finishing_date"
                            onChange={(e) => {
                              setfinishing_date(e)
                            }}
                          /> */}

                          <ErrorMessage
                            name="finishing_date"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>

                        <div class="form-group mb-4">
                          <Field
                            as="select"
                            id="level_study"
                            name="level_study"
                            className="form-select fondoamarillo imputText"
                          >
                            <option className="fondo fw-bolder" value="" selected>
                              {t("lbl61")}
                            </option>

                            {levelStudy.map((i) => {
                              return (
                                <option className="fondo fw-bolder" value={i.id}>
                                  {
                                    props.lng == 'en' ?
                                      i.description  :
                                    props.lng == 'es' ?
                                      i.description_es  :
                                    props.lng == 'fr' ?
                                      i.description_fr  : ""
                                  }
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="level_study"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>

                        <div class="form-group mb-4">
                          <div class=" left">
                            <Field
                              id="inprogress"
                              type="checkbox"
                              name="inprogress"
                              className={
                                "form-check-input " +
                                (errors.acceptTerms && touched.acceptTerms
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <label
                              class="color-fuente-gris textbold mx-2"
                              for="inprogress"
                            >
                              {t("lbl43")}
                            </label>

                            <ErrorMessage
                              name="inprogress"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>
                        </div>

                        <div class="divider d-flex align-items-center my-2">
                          <button
                            type="submit"
                            class="btn btn-warning  btn-block  buttons "
                          >
                            {t("lbl33")}
                          </button>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





         {/* modal add Experience */}

         <div
        class="modal fade"
        id="modalExperience"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title txtSmallerGrisModal "
                id="staticBackdropLabel"
              >
                {t("lbl44")}
              </h5>

              <button
                id="clocedModalExperience"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="tab-o-xp" data-bs-toggle="tab" data-bs-target="#actualxpcomp" type="button" role="tab" aria-controls="actualxpcomp" aria-selected="true">{t("ttl8")}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link " id="tab-t-xp" data-bs-toggle="tab" data-bs-target="#newxpComp" type="button" role="tab" aria-controls="newxpComp" aria-selected="false">{t("ttl9")}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link " id="tab-tr-xp" data-bs-toggle="tab" data-bs-target="#newPosition" type="button" role="tab" aria-controls="newPosition" aria-selected="false">{t("ttl10")}</button>
                </li>
              </ul>

              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade " id="newxpComp" role="tabpanel" aria-labelledby="tab-t-xp">
                  <RegisterXpCompany addNewXpCompany={addNewXpCompany}/>
                </div>
                <div class="tab-pane fade " id="newPosition" role="tabpanel" aria-labelledby="tab-tr-xp">
                  <RegisterPosition addNewPosition={addNewPosition}/>
                </div>
                <div class="tab-pane fade show active" id="actualxpcomp" role="tabpanel" aria-labelledby="tab-o-xp">

                  <Formik
                    initialValues={{
                      position: "",
                      company: "",
                      duration_time: "",
                      unit_measurement: "",
                      initial_year: "",
                      functions: "",
                      goals: ""
                    }}
                
                    validationSchema={Yup.object().shape({
                      position:
                        Yup.string().required(t("fr")),
                      company:
                        Yup.string().required(t("fr")),

                      duration_time: Yup.number()
                        .typeError(t("onum"))
                        .required(t("fr")),

                      unit_measurement: Yup.string().required(t("fr")),
                      
                      initial_year: Yup.string().required(t("fr")),
                      functions: Yup.string().required(t("fr")),
                      goals: Yup.string().required(t("fr")),
                    
                    })}

                    validate = {(values) => {
                      const errors = {};
                      if (values.duration_time < 1) errors.duration_time = t("fr");
                      return errors;
                    }}

                    onSubmit={(val, { resetForm }) => {
                      addExperience(val);
                      resetForm();
                      closeExperience();
                    }}
                    render={({ errors, status, touched }) => (

                      <Form class="container">
                            <div class="form-group mb-4">
                              <Field
                                as="select"
                                id="position"
                                name="position"
                                className="form-select fondoamarillo imputText"
                                
                              >
                                <option
                                  className="fondo fw-bolder"
                                  value=""
                                  selected
                                >
                                  {t("lbl45")}
                                </option>

                                {positions.map((i) => {
                                  return (
                                    <option
                                      className="fondo fw-bolder"
                                      value={i.id}
                                    >
                                    {
                                      props.lng == 'en' ?
                                        i.name :
                                      props.lng == 'es' ?
                                        i.name_es :
                                      props.lng == 'fr' ?
                                        i.name_fr : ""
                                    }
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="position"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>

                            <div class="form-group mb-4">


                              <Field
                                id="duration_time"
                                name="duration_time"
                                type="number"
                                class="form-control imputText"
                                placeholder={t("lbl41")}
                              />

                              <ErrorMessage
                                name="duration_time"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>

                            <div class="form-group mb-4">
                              <Field
                                as="select"
                                id="unit_measurement"
                                name="unit_measurement"
                                className="form-select fondoamarillo imputText"
                                
                              >
                                <option
                                  className="fondo fw-bolder"
                                  value=""
                                  selected
                                >
                                  {t("lbl42")}
                                </option>

                                {unidad.map((i) => {
                                  return (
                                    <option
                                      className="fondo fw-bolder"
                                      value={i.id}
                                    >
                                    {
                                      props.lng == 'en' ?
                                        i.name :
                                      props.lng == 'es' ?
                                        i.name_es :
                                      props.lng == 'fr' ?
                                        i.name_fr : ""
                                    }
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="unit_measurement"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>

                            <div class="form-group mb-4">
                              <Field
                                as="select"
                                id="company"
                                name="company"
                                className="form-select fondoamarillo imputText"
                                
                              >
                                <option
                                  className="fondo fw-bolder"
                                  value=""
                                  selected
                                >
                                  {t("lbl46")}
                                </option>

                                {companyWork.map((i) => {
                                  return (
                                    <option
                                      className="fondo fw-bolder"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="company"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>

                            <div class="form-group mb-4">
                              <div class="color-fuente-gris textbold">
                                {" "}
                                {t("lbl47")} &nbsp;
                              </div>
                              <Field
                                id="initial_year"
                                name="initial_year"
                                type="date"
                                class="form-control imputText"
                                placeholder={t("lbl47")}
                              />

                              <ErrorMessage
                                name="initial_year"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>

                            <div class="form-group mb-4">
                              <Field
                                id="functions"
                                name="functions"
                                type="textarea"
                                class="form-control rounded-0 textArea"
                                component="textarea"
                                aria-describedby="emailHelp"
                                placeholder={t("lbl48")}
                                rows="3"
                              />

                              <ErrorMessage
                                name="functions"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>

                            <div class="form-group mb-4">
                              <Field
                                id="goals"
                                name="goals"
                                type="textarea"
                                class="form-control rounded-0 textArea"
                                component="textarea"
                                aria-describedby="emailHelp"
                                placeholder={t("lbl49")}
                                rows="3"
                              />

                              <ErrorMessage
                                name="goals"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>

                        <div class="divider d-flex align-items-center my-2">
                          <button
                            type="submit"
                            class="btn btn-warning  btn-block  buttons "
                          >
                            {t("lbl33")}
                          </button>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    );
}

export default AspiranteApplicant;
