import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Swal from "sweetalert2";
import UserProfile from "../../Login/UserProfile";
import HomeCompany  from "../Pages/HomeCompany";
import Notifications from "../../../src/components/Notifications.js"
import Comments from "../../../src/components/Comments"

import homeimg from "../../img/housedoorwhite.png";
import logoico from "../../img/formhrh.png";
// import logoico from "../../img/logohrhshort.png";
import onoff from "../../img/onoff.png";
import espaniol from "../../img/espaniaflag.png";
import ingles from "../../img/canadaflag.png";
import frances from "../../img/franciaflag.png";
import campana from "../../img/campana.png";
import sendimg from "../../img/send.png";
import userimg from "../../img/user.png";
import userchat from "../../img/userchat.png";
import cloudtext from "../../img/cloudtext.png";

function HeaderCompany(props) {

  const [t, i18n] = useTranslation("headercomp");

  const [user, setuser] = useState([]);
  const [chekDespliega, setchekDespliega] = useState(false);
  const [chekDespliegaMenu, setchekDespliegaMenu] = useState(false);
  const [opc, setopc] = useState("1");
  const [opcText, setopcText] = useState("Job offers");
  const [idreg, setidreg] = useState(0);
  const [Cantn, setCantn] = useState(0);

  //   hederClick = hederClick.bind(this);
  const [ chapln, setchapln ] = useState(localStorage.getItem('lngselect'));

  const [ idEmpresa, setidEmpresa ] = useState(0);
  const [ comentarios, setcomentarios ] = useState([]);

  const languages = {
    1 : {
      lang: "en",
      icon: ingles
    },
    2 : {
      lang: "es",
      icon: espaniol
    },
    3 : {
      lang: "fr",
      icon: frances
    }
  }

  const changeLanguage = async (opc) => {
    let ln = languages[opc];
    localStorage.setItem('lngselect', ln.lang);
    localStorage.setItem('lngiconselect', ln.icon);
    setchapln(ln.lang)
    i18n.changeLanguage(localStorage.getItem('lngselect'));
  };

  const changeOpc = async ({ opc = 1, data }) => {
    setidreg(data);
    hederClick(opc);
  };

  const hederClick = async (item) => {
    setopc(item);
  };

  const cantNotifications = (cant) => {
    setCantn(cant)
  }


  const salir = async () => {
    Swal.fire({
      title: t("msg1"),
      text: t("msg2"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ok"),
      cancelButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        props.history.push("/");
      }
    });
  };

  const listChat = async () => {
    setcomentarios([])
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/commentcompany/`+idEmpresa,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setcomentarios(res.data.data.comment_company)
      });
  };

  const send = async () => {
    if (document.getElementById("mensaje").value != "") {
      var data = {
        comment: document.getElementById("mensaje").value,
        company: 1,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/comment_company`, data, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            listChat();
          }
        })
        .catch((error) => console.log(error));
        document.getElementById("mensaje").value=""
    }
  };

    const getProfile  = async () => {
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/companies/profile`, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
          const data = res.data.data
          setidEmpresa(data.id)
        });
    };

  useEffect(() => {
    setuser(JSON.parse(localStorage.getItem('user')));
    UserProfile.setToken(localStorage.getItem('token'));
    if (!UserProfile.getToken()) {
      props.history.push("/login");
    }
    if (localStorage.getItem('lngiconselect') === null) {
      changeLanguage(1)
    }
    getProfile()
  }, []);

  return (
    <>
      <div class="dropdownn floatcomments">
        <button
          class="btn btn-lightModal btnfloatcomments"
          type="button"
          id="dropdownMenuButton2"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={()=>{listChat()}}
        >
          <span>
            <img
                className="img-fluid "
                width="30px"
                height="30px"
                src={cloudtext}
            />
          </span>
        </button>

        <div
          class="dropdown-menu fondoAzulModal"
          aria-labelledby="themes"
        >
          <div class="container">
            <div class="form-group mb-1 text-center ">
              <img
                className=" mt-2 img-fluid"
                width="20px"
                height="20px"
                src={userimg}
              />
            </div>
            <div class="form-group mb-2 text-center ">
              {t("lbl2")}
            </div>
          </div>

          <div className="linea lineamovil"></div>
          <div class=" fondoBlancoModal fondoBlancoModalMovil" >
            <div className="anyClass anyClassMovil" id="topabajo">
              {comentarios.map((i) => {
                if (i.type != 3) {
                  return (
                    <>
                      <div class="form-group mb-2 text-left mt-2 ">
                        <div class="row  my-1 ">
                          <div class="col-2 col-sm-2 col-md-2 ">
                            <img
                              className=" mt-2 img-fluid ingMpvilchat"
                              width="50px"
                              height="50px"
                              src={userchat}
                            />
                          </div>

                          <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                            <div className="txtSmallGris">
                            {i.name}
                            </div>

                            <p className="text-left"> {i.comment} </p>
                            <div class="txtSmallGris"> {i.created_at} </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                } else {
                  return (
                    <>
                      <div class="form-group mb-2  mt-2 ">
                        <div class="row  my-1 justify-content-end">
                          <div class=" col-5 col-sm-5 col-md-5 text-end mt-1 globo">
                            <div className="mt-2 txtSmallGris">
                            {i.compname}
                            </div>

                            <p className="">
                            {i.comment}
                            </p>
                            <div class="txtSmallGris"> {i.created_at} </div>
                          </div>

                          <div class="col-2 col-sm-2 col-md-2">
                            <img
                              className="mt-2 img-fluid ingMpvilchat"
                              width="50px"
                              height="50px"
                              src={userchat}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </div>

          <div class="container">
            <div class="row  my-1 ">
              <div class="col-10 ">
                <input
                  id="mensaje"
                  type="text"
                  name="mensaje"
                  className="mt-1 form-control imputTextModal"
                  aria-describedby="emailHelp"
                  placeholder={t("lbl3")}
                />
              </div>

              <div class="col-2 text-left ">
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    send();
                  }}
                >
                  <img
                    className=" mt-2 img-fluid"
                    width="20px"
                    height="20px"
                    src={sendimg}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header class="header sticky-top">
        <div class="container overflow-visible">
          <div class="row">
            <div class="col-md-3 col-sm-12 mt-4 pb-3">
              <a class="navbar-brand mt-2" href="#">
                <img
                  src={logoico}
                  alt=""
                  width="130"
                  class="img-fluid mx-2"
                />
              </a>
            </div>

            <div class="col-md-9 col-sm-12 mt-4">
              <div class="row text-end">
                <div class="col">
                  <a class="nav-link  fuente"
                    onClick={() => {
                      hederClick("3");
                    } }
                  >
                    {user.name}
                  </a>
                </div>
                <div class="col-1 mx-4">
                  <div class="dropdown dropstart">
                    {
                      Cantn > 0 ?
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {Cantn}
                          <span class="visually-hidden">unread messages</span>
                        </span>
                      : ""
                    }
                      <span
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        class="position-relative"
                      >
                        <img
                          className=""
                          width="20px"
                          height="20px"
                          src={campana}
                        />
                        {
                          Cantn > 0 ?
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                              {Cantn}
                              <span class="visually-hidden">unread messages</span>
                            </span>
                          : ""
                        }
                      </span>
                
                    <div
                      class="dropdown-menu fondoAzulModal"
                      aria-labelledby="themes"
                    >
                      <div className="linea lineamovil"></div>
                      <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                        <Notifications token={localStorage.getItem('token')} changeOpc={changeOpc} opcnotif={4} cantNotifications={cantNotifications} history={props.history} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                  <div class="container">
                    <button
                      class="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span class="navbar-toggler-icon"></span>
                    </button>
                  </div>

                  <div
                    class="navbar-collapse collapse"
                    id="navbarSupportedContent"
                  >
                    <ul class="navbar-nav  align-items-right">
                      <li class="nav-item">
                        <a
                          class={opc === "1"
                            ? " mx-3 active fuente  barra"
                            : " mx-3  fuente  barra"}
                          aria-current="page"
                          href="javascript:void(0)"
                          onClick={() => {
                            hederClick("1");
                          } }
                        >
                          <img src={homeimg} alt="" width="20" height="20"  class="text-white" />
                          {/* {t("lbl1")} */}
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          href="javascript:void(0)"
                          class={opc === "2"
                            ? "   nav-link active fuente mx-3"
                            : "   nav-link  fuente mx-3"}
                          onClick={() => {
                            hederClick("2");
                          } }
                        >
                          {t("lbl2")}
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          class={opc === "4"
                            ? "   nav-link active  fuente mx-3"
                            : "   nav-link  fuente mx-3"}
                          href="javascript:void(0)"
                          onClick={() => {
                            hederClick("3");
                          } }
                        >
                          {t("lbl3")}
                        </a>
                      </li>

                      <li class="nav-item dropdown">
                        <div class="text-end fuente">
                          <a class="btn text-white text-white dropdown-toggle" href="#" role="button" id="dropdownLanguage" data-bs-toggle="dropdown" aria-expanded="false">
                            {/* {localStorage.getItem('lngselect')} */}
                            <img
                              width="25"
                              height="17"
                              class="rounded-circle mx-3"
                              src={localStorage.getItem('lngiconselect')}
                            />
                          </a>

                          <ul class="dropdown-menu dropdown-menu-cus" aria-labelledby="dropdownLanguage">
                            <li 
                              onClick={() => {
                                changeLanguage(1);
                              }}>
                                <a class="dropdown-item">
                                      <img
                                        width="25"
                                        height="17"
                                        class="rounded-circle mx-3"
                                        src={ingles}
                                      />
                                    </a>
                                {/* <a class="dropdown-item">{t("en")}</a> */}
                            </li>
                            <li 
                              onClick={() => {
                                changeLanguage(2);
                              }}>
                                <a class="dropdown-item">
                                      <img
                                        width="25"
                                        height="17"
                                        class="rounded-circle mx-3"
                                        src={espaniol}
                                      />
                                    </a>
                                {/* <a class="dropdown-item">{t("es")}</a> */}
                            </li>
                            <li 
                              onClick={() => {
                                changeLanguage(3);
                              }}>
                                <a class="dropdown-item">
                                      <img
                                        width="25"
                                        height="17"
                                        class="rounded-circle mx-3"
                                        src={frances}
                                      />
                                    </a>
                                {/* <a class="dropdown-item">{t("fr")}</a> */}
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li class="nav-item ">
                        <a
                          class={opc === "9"
                            ? "   nav-link active  fuente mx-3"
                            : "   nav-link  fuente mx-3"}
                          href="javascript:void(0)"
                          onClick={() => {
                            salir();
                          } }
                        >
                          <img src={onoff} width="19px" height="19px" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            
          </div>
        </div>
      </header>
      <HomeCompany opc={opc} changeOpc={changeOpc} dataxtr={idreg} history={props.history} lng={chapln} />
    </>
  );
}

export default  HeaderCompany

