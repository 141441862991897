import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import basura from "../../img/Basura.png";
import editar from "../../img/edita.png";


const LanguagesList = (props) => {
    const [t, i18n] = useTranslation("dataadmin")

    return (
        <>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col"> {t("tbl4")} </th>
                        <th scope="col">{t("tbl4")} - {t("es")} </th>
                        <th scope="col">{t("tbl4")} - {t("frc")} </th>
                        <th scope="col"> {t("tbl13")} </th>
                        {/* <th scope="col">{t("tbl3")}</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        props.list.map((lang) => (
                            <tr>
                                <th scope="row"> {lang.id} </th>
                                <td> {lang.name} </td>
                                <td> {lang.name_es} </td>
                                <td> {lang.name_fr} </td>
                                <td> 
                                    <div class="row">
                                        <div class="col-2">
                                            <div class="form-check form-switch">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id={
                                                    lang.id
                                                  }
                                                  onClick={() => {
                                                    props.handleSwitchChange(
                                                      lang
                                                        .id,
                                                      lang
                                                        .status,
                                                      lang
                                                        .name
                                                    );
                                                  }}
                                                  checked={
                                                    lang
                                                      .status == 1
                                                      ? true
                                                      : false
                                                  }
                                                />
                                            </div>
                                        </div>

                                        <div class="col-2">
                                            <a
                                                title={t("ttl1")}
                                                href="javascript:void(0)"
                                                onClick={() =>
                                                    props.deleter(
                                                    lang.id
                                                  )
                                                }
                                              >
                                                <img
                                                  width="16"
                                                  height="20"
                                                  class="icoOpciones"
                                                  src={basura}
                                                />
                                            </a>
                                        </div>

                                        <div class="col-2">
                                          {" "}
                                            <a
                                                href="javascript:void(0)"
                                                title={t("lbl2")}
                                                onClick={() =>
                                                    props.updateForm(
                                                    lang,
                                                    lang.status
                                                  )
                                                }
                                              >
                                                {"   "}
                                                <img
                                                  width="16"
                                                  height="20"
                                                  class="icoOpciones mx-3"
                                                  src={editar}
                                                />{" "}
                                            </a>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        ))
                    }
                    
                </tbody>
            </table>
        </div>
        </>
    )
}

export default LanguagesList;