import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import logoico from "../../img/formhrh.png";
// import logoico from "../../img/logohrhshort.png";
import onoff from "../../img/onoff.png";

import Swal from "sweetalert2";
import UserProfile from "../../Login/UserProfile";

import homeimg from "../../img/housedoorwhite.png";
import espaniol from "../../img/espaniaflag.png";
import ingles from "../../img/canadaflag.png";
import frances from "../../img/franciaflag.png";
import campana from "../../img/campana.png";

import HomeAplicant  from "../Pages/HomeAplicant";
import Notifications from "../../../src/components/Notifications.js"
import Comments from "../../../src/components/Comments"

const HeaderAplicant = (props) => {

  const [t, i18n] = useTranslation("headerappl")

  const [ user, setuser ] = useState([])
  const [ opc, setopc ] = useState("1");
  const [idreg, setidreg] = useState(0);
  const [Cantn, setCantn] = useState(0);

  const [ chapln, setchapln ] = useState(localStorage.getItem('lngselect'));

  const languages = {
    1 : {
      lang: "en",
      icon: ingles
    },
    2 : {
      lang: "es",
      icon: espaniol
    },
    3 : {
      lang: "fr",
      icon: frances
    }
  }

  const changeOpc = async ({ opcc = 1, data }) => {
    setidreg(data);
    hederClick(opcc);
  };

  const hederClick = async (item) => {
    setopc(item);
  };

  const changeLanguage = async (opcs) => {
    let ln = languages[opcs]
    localStorage.setItem('lngselect', ln.lang)
    localStorage.setItem('lngiconselect', ln.icon)
    setchapln(ln.lang)
    i18n.changeLanguage(localStorage.getItem('lngselect'))
  }

  const salir = async () => {
    Swal.fire({
      title: t("txt1"),
      text: t("txt2"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ok"),
      cancelButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        props.history.push("/");
      }
    });
  }

  const cantNotifications = (cant) => {
    setCantn(cant)
  }


  useEffect(() => {
    setuser(JSON.parse(localStorage.getItem('user')))
    UserProfile.setToken(localStorage.getItem('token'));
    if(!UserProfile.getToken()){
        props.history.push("/login");
    }
    if (localStorage.getItem('lngiconselect') === null) {
      changeLanguage(1)
    }
  }, [])

      return (
        <>
          <header class="header sticky-top">
            <Comments opc={opc} history={props.history} lng={chapln} />
            <div class="container overflow-visible">
              <div class="row">
                <div class="col-md-3 col-sm-12 mt-4 pb-3">
                  <a class="navbar-brand mt-2" href="#">
                    <img
                      src={logoico}
                      alt=""
                      width="130"
                      class="img-fluid mx-2"
                    />
                  </a>
                </div>

                <div class="col-md-9 col-sm-12 mt-4">
                  <div class="row text-end">
                    <div class="col">
                      <a class="nav-link  fuente"
                        onClick={() => {
                          hederClick("3");
                        } }
                      >
                        {user.name}
                      </a>
                    </div>
                    <div class="col-1 mx-4">
                      <div class="dropdown dropstart">
                          <span
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            class="position-relative"
                          >
                            <img
                              className=""
                              width="20px"
                              height="20px"
                              src={campana}
                            />
                            {
                              Cantn > 0 ?
                                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                  {Cantn}
                                  <span class="visually-hidden">unread messages</span>
                                </span>
                              : ""
                            }
                          </span>
                    
                        <div
                          class="dropdown-menu fondoAzulModal"
                          aria-labelledby="themes"
                        >
                          <div className="linea lineamovil"></div>
                          <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                            <Notifications history={props.history} token={localStorage.getItem('token')} changeOpc={changeOpc} opcnotif={4} cantNotifications={cantNotifications} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                      <div class="container">
                        <button
                          class="navbar-toggler"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                        >
                          <span class="navbar-toggler-icon"></span>
                        </button>
                      </div>
                      <div
                        class="navbar-collapse collapse"
                        id="navbarSupportedContent"
                      >
                        <ul class="navbar-nav  align-items-right">
                          <li class="nav-item navitemwidth">
                            <a
                              class={
                                opc === "1"
                                  ? " mx-3 active fuente  barra"
                                  : " mx-3  fuente  barra"
                              }
                              aria-current="page"
                              href="javascript:void(0)"
                              onClick={() => {
                                hederClick("1");
                              }}
                            >
                              <img src={homeimg} alt="" width="20" height="20"  class="text-white" />
                              {/* {t("lbl3")} */}
                            </a>
                          </li>
                          <li class="nav-item navitemwidth">
                            <a
                              href="javascript:void(0)"
                              class={
                                opc === "2"
                                  ? "   nav-link active fuente mx-3"
                                  : "   nav-link  fuente mx-3"
                              }
                              onClick={() => {
                                hederClick("2");
                              }}
                            >
                              {t("lbl6")}
                            </a>
                          </li>
                          <li class="nav-item navitemwidth">
                            <a
                              href="javascript:void(0)"
                              class={
                                opc === "3"
                                  ? "   nav-link active fuente mx-3"
                                  : "   nav-link  fuente mx-3"
                              }
                              onClick={() => {
                                hederClick("3");
                              }}
                            >
                              {t("lbl7")}
                            </a>
                          </li>
                          <li class="nav-item navitemwidth">
                            <a
                              href="javascript:void(0)"
                              class={
                                opc === "21"
                                  ? "   nav-link active fuente mx-3"
                                  : "   nav-link  fuente mx-3"
                              }
                              onClick={() => {
                                hederClick("21");
                              }}
                            >
                              {t("lbl4")}
                            </a>
                          </li>

                          <li class="nav-item dropdown">
                            <div class="text-end  fuente">
                              <a class="btn text-white text-white dropdown-toggle" href="#" role="button" id="dropdownLanguage" data-bs-toggle="dropdown" aria-expanded="false">
                                <img
                                  width="25"
                                  height="17"
                                  class="rounded-circle mx-3"
                                  src={localStorage.getItem('lngiconselect')}
                                />
                              </a>

                              <ul class="dropdown-menu dropdown-menu-cus" aria-labelledby="dropdownLanguage">
                                <li 
                                  onClick={() => {
                                    changeLanguage(1);
                                  }}>
                                    <a class="dropdown-item">
                                      <img
                                        width="25"
                                        height="17"
                                        class="rounded-circle mx-3"
                                        src={ingles}
                                      />
                                    </a>
                                    {/* <a class="dropdown-item">{t("en")}</a> */}
                                </li>
                                <li 
                                  onClick={() => {
                                    changeLanguage(2);
                                  }}>
                                    <a class="dropdown-item">
                                      <img
                                        width="25"
                                        height="17"
                                        class="rounded-circle mx-3"
                                        src={espaniol}
                                      />
                                    </a>
                                    {/* <a class="dropdown-item">{t("es")}</a> */}
                                </li>
                                <li 
                                  onClick={() => {
                                    changeLanguage(3);
                                  }}>
                                    <a class="dropdown-item">
                                      <img
                                        width="25"
                                        height="17"
                                        class="rounded-circle mx-3"
                                        src={frances}
                                      />
                                    </a>
                                    {/* <a class="dropdown-item">{t("fr")}</a> */}
                                </li>
                              </ul>

                            </div>
                          </li>
                          
                          <li class="nav-item ">
                            <a
                              class={
                                opc === "9"
                                  ? "   nav-link active  fuente mx-3"
                                  : "   nav-link  fuente mx-3"
                              }
                              href="javascript:void(0)"
                              onClick={() => {
                                salir();
                              }}
                            >
                              <img src={onoff} width="19px"  height="19px"/>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                
              </div>
            </div>
          </header>
        <HomeAplicant opc={opc} history={props.history} lng={chapln} />
      </>
    );
}

export default  HeaderAplicant

