import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";

import axios from "axios";
import basura from "../../img/Basura.png";
import editar from "../../img/edita.png";
import campana from "../../img/campana.png";

import Swal from "sweetalert2";

import listplas from "../../img/listplas.png";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import ReactPaginate from "react-paginate";


const ServiciosAdmin = (props) => {

const [t, i18n] = useTranslation("serviceadm")
// i18n.changeLanguage("")

  const [ modalEditar, setmodalEditar ] = useState(false);
  const [ estado, setestado ] = useState(true);
  const [ listServices, setlistServices ] = useState([]);
  
  
  const [ form, setform ] = useState({
                                    id: '',
                                    name: '',
                                    description: '',
                                    price: '',
                                    visible: 0,
                                  });
  

  const [ id, setid ] = useState(0);
  
  const [ pageNex, setpageNex ] = useState(1);
  const [ pageCount, setpageCount ] = useState(1);
  
  const [ text, settext ] = useState("");
  const [ selectedPage, setselectedPage ] = useState("");
  
  const [ visible, setVisible ] = useState(true);
 

  const handleSwitchChange = async (value) => {
    if(value.visible==1){
      const data = {
        name: value.name,
        description: value.description,
        price: value.price,
        visible: 2,
    
      };
      axios
    .put(`${process.env.REACT_APP_BASE_URL}/api/services/${value.id}`,data)

    .then((res) => {
      list();
    })
    .catch((error) => console.log(error));

    }else{
      const data = {
        name: value.name,
        description: value.description,
        price: value.price,
        visible: 1,
      };

      axios
    .put(`${process.env.REACT_APP_BASE_URL}/api/services/${value.id}`,data)

    .then((res) => {
      list();
    })
    .catch((error) => console.log(error));

    }
  };


  const add = async (value) => {
    const data = {
      name: value.name,
      description: value.description,
      price: value.price,
      visible: value.visible,
    };
    axios
    .post(`${process.env.REACT_APP_BASE_URL}/api/services`, data, {      
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`, 
      }
    })
    .then((res) => {
      console.log(res);
      list();
    })
    .catch((error) => console.log(error));
  };


  const resetList  = async () => {
    var search = {}
    settext(0)
    list(search)
  }
  
  const handleClickFilter = async () => {
    var search = {}
    list(search)
  }
  
  const handlePageClick = (val) => {
    setselectedPage(val.selected+1)
    setpageNex(val.selected)
  }

  useEffect(() => {
    handleClickFilter()
  }, [selectedPage])
  

  const list = async () => {
    setlistServices([])
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/services`,
      {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
        params:{
            page: selectedPage
        }
      })
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setlistServices(res.data.data.services)
        setpageCount(res.data.data.pages)
      })
      .catch((error) => console.log(error));
  };

  const update = (value) => {
    setform(value)
    document.getElementById("updateModal").click()
  };


  const updateForm  = async (value) => {
    document.getElementById("updateModal").click()
    const data = {
      name: value.name,
      description: value.description,
      price: value.price,
      visible: value.visible,
    };
    axios
    .put(`${process.env.REACT_APP_BASE_URL}/api/services/${value.id}`,data , {
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      },
    })
    .then((res) => {
      list();
    })
    .catch((error) => console.log(error));
  };


  const deleter = async (id) => {
    Swal.fire({
      title: "Sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ok"),
      cancelButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/api/services/${id}`, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            list();
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const closedmodal = async () => {
    document.getElementById("clocedModal").click()
  };

  useEffect(() => {
    if(navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
    }else{
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

  let paginationElement;
    if (pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Sig"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
          onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
          forcePage={pageNex}
          containerClassName={"pagination justify-content-center"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
            <div className="container my-2">
              <div className="row  my-2 p-2">
                <div className="col-6 col-sm-6 col-md-6 text-center">
                  <div class="row txtlargeAzul">


                    <button
                      type="button"
                      class="btn btn-warning  btn-block  buttonLarge "
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      {" "}
                      <span>
                        <img
                          className="img-fluid"
                          width="15px"
                          height="15px"
                          src={listplas}
                        />{" "}
                      </span>{" "}
                      {t("lbl1")}
                    </button>





                    <button

                      id="updateModal"
                      type="button"
                      class="btn btn-warning  btn-block  buttonLarge invisible "
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdate"
                    >
                      {" "}
                      <span>
                        <img
                          className="img-fluid"
                          width="15px"
                          height="15px"
                          src={listplas}
                        />{" "}
                      </span>{" "}
                     {t("lbl2")}
                    </button>

                  </div>
                </div>

                {/* <div className="col-6 col-sm-6 col-md-6 fondod">
                  <div class="alingDerecha">
                    <img
                      className="img-fluid"
                      width="20px"
                      height="20px"
                      src={campana}
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <div className="my-5">
              <div className="container my-2 ocultar ">
                <div class="row  my-1 ">
                  <div class="col-3 text-center ">
                    <div className="fondo funeteblanca">{t("tbl1")}</div>
                  </div>

                  <div class="col-4 text-center  ">
                    <div className="fondo funeteblanca">{t("tbl2")}</div>
                  </div>

                  <div class="col-2 text-center  ">
                    <div className="fondo funeteblanca">{t("tbl3")}</div>
                  </div>

                  <div class="col-3 text-center  ">
                    <div className="fondo funeteblanca">{t("tbl4")}</div>
                  </div>
                </div>
              </div>

              <div className="container my-2 mostrar ">
                <div class="row  my-1 ">
                  <div class="col-12 text-center ">
                    <div className="fondo funeteblanca">{t("tbl5")}</div>
                  </div>
                </div>
              </div>

              

              {listServices.length > 0 ? (
                <>
                  <div className="container my-2">
                    
                    {Object.keys(listServices).map((i) => {
                      return (
                        <div className="accordion-item card sombra my-2">
                          <div className="accordion-header" id="headingOne">
                            <div className="row  my-2 p-2">
                              <div className="col-12 col-sm-12 col-md-3 text-center">
                                <div class="row txtlargeAzul">
                                  <div class="row txtSmallAzul mx-3">
                                    {listServices[i].name}
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-sm-12 col-md-4 text-center">
                                <div class="row txtlargeAzul">
                                  <div class="row txtSmallAzul mx-3">
                                    {listServices[i].description}
                                  </div>
                                </div>
                              </div>

                              <div className="col-8 col-sm-8 col-md-2 text-center">
                                <div class="row txtlargeAzul">
                                  <div class="row txtSmallAzul mx-3">
                                    {listServices[i].price}
                                  </div>
                                </div>
                              </div>

                              <div className="col-4 col-sm-4 col-md-3 ">

                                 <div class="row justify-content-center align-items-center ">
                                 {
                                   listServices[i].id != 1 && listServices[i].id != 2 ? 
                                      <div class="col-1 col-sm-1 col-md-1">
                                        {" "}
                                        <a
                                          href="javascript:void(0)"
                                          title="Eliminar"
                                          onClick={() =>
                                            deleter(
                                              listServices[i].id
                                            )
                                          }
                                        >
                                          <img
                                            width="16"
                                            height="20"
                                            class="icoOpciones"
                                            src={basura}
                                          />
                                        </a>
                                      </div> 
                                      :
                                      ""
                                  }

                                  <div class="col-1 col-sm-1 col-md-1">
                                    {" "}
                                    <a
                                      href="javascript:void(0)"
                                      title="Modificar"
                                      onClick={() => update(listServices[i])}
                                    >
                                      {"  "}
                                      <img
                                        width="16"
                                        height="20"
                                        class="icoOpciones mx-3"
                                        src={editar}
                                      />{" "}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>


                  {  
               <div>{paginationElement}</div>
            }
                </>
              ) : (
                <>
                  <div className="my-3">
                    <div className="container my-5">
                      <div class="row  my-5 ">
                        <div class="col-12 text-center ">
                          <div className="txtnoRegister">...</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* modal add  */}

        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title txtSmallerGrisModal " id="staticBackdropLabel">
                  {t("ttl1")}
                </h5>
                <button
                  id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label={t("lbl3")}
                ></button>
              </div>
              <div class="modal-body">


                
                <Formik
                  initialValues={{
                    name: "",
                    description: "",
                    price: "",
                    visible: 2,
                  }}



                  validate={(values) => {   
                    const pattern = new RegExp('^[A-Z ]+$', 'i')

                    const errors = {};
                   
                    if (!values.name) 
                      errors.name = t("fr");     

                    // else if (!pattern.test(values.name))               
                      // errors.name = "Solo texto";

                    if (!values.description) 
                      errors.description = t("fr");     

                    // else if (!pattern.test(values.description))               
                      // errors.description = "Solo texto";

                    return errors;           
                 
                    }  
                  }

                  validationSchema={Yup.object().shape({
                    price: Yup.number().typeError("Campo númerico").min(1).positive("mayor a 1").required("Campo requerido"),
                  })}

                  onSubmit={(val, { resetForm }) => {
                    resetForm()
                    closedmodal()
                    add(val)
                  }}

                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl4")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>
                      <div className="form-group mb-4">
                        <Field
                          id="description"
                          type="text"
                          name="description"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl5")}
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div className="form-group mb-4">
                        <Field
                          id="price"
                          type="numeric"
                          name="price"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl7")}
                        />
                        <ErrorMessage
                          name="price"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl6")}
                        </button>
                      </div>
                    </Form>

                  )}
                />
              </div>
             
            </div>
          </div>
        </div>

{/* modal update  */}

      <div
          class="modal fade"
          id="staticBackdropUpdate"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title txtSmallerGrisModal" id="staticBackdropLabel">
                  {t("ttl6")}
                </h5>
                <button
                  id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label={t("lbl3")}
                ></button>
              </div>
              <div class="modal-body">

                <Formik

                  initialValues={{
                    id: form.id,
                    price: form.price,
                    name: form.name,
                    description: form.description,
                    visible: form.visible,
                  }}

                  validationSchema={Yup.object().shape({
                    price: Yup.number().typeError(t("fr")).min(1).positive("mayor a 1").required(t("fr")),
                  })}

                  onSubmit={(val, { resetForm }) => {
                    resetForm()
                    closedmodal()
                    updateForm(val)
                  }}

                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field

                          id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl4")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div className="form-group mb-4">
                        <Field
                          id="description"
                          type="text"
                          name="description"
                          className="form-control imputText"
                          placeholder={t("lbl5")}
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div> 

                      <div className="form-group mb-4">
                        <Field
                          id="price"
                          type="numeric"
                          name="price"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl7")}
                        />
                        <ErrorMessage
                          name="price"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-check mb-4">
                      <Field
                          as="select"
                          id="visible"
                          name="visible"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl8")}
                        >
                          <option className="fondo fw-bolder" value="1" selected>
                            {t("lbl9")}
                          </option>
                          <option className="fondo fw-bolder" value="2" selected>
                            {t("lbl10")}
                          </option>
                        </Field>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl11")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
           
            </div>
          </div>
        </div>
      </>
    );
}

export default ServiciosAdmin;
