import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
    // import withFirebaseAuth from "react-with-firebase-auth";
// // import firebase from "firebase/app";
// import "firebase/auth";
// // import firebaseConfig from "../Login/firebaseConfig";
import axios from "axios";
import "../Login/login.css";

import Swal from "sweetalert2";

import icoGoogle from "../img/google_ico.png";
import linkedin from "../img/linkedinicon.png"
import geojobscanada from "../img/formhrh.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// // const firebaseApp = !firebase.apps.length
  // // ? firebase.initializeApp(firebaseConfig)
  // : firebase.app();


const Register = (props) => {

const [t, i18n] = useTranslation("login")
  
  const [ chekContras, setchekContras ] = useState(false)
  const [ typeUser, settypeUser ] = useState([])
  const [ idusertemp, setidusertemp ] = useState("");
  const [ tokentemp, settokentemp ] = useState("");
  const [ user, setuser ] = useState({
    name : "",
    email : ""
  })

  const registerGoogle = (nombre, correo, id, foto) => {
    const usertemp = { id: id, name: nombre, email: correo, avatar: foto };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/social/google/register`,
        usertemp
      )
      .then((res) => {
        if (res.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "Oops...",
            text: t("msg1"),
          });
          props.history.push("/");
        } else {
          Swal.fire({
            icon: "success",
            title: "Oops...",
            text: t("msg2"),
          });
        }
      });
  };

  const retornarLogin = () => {
    props.history.push("/login");
  };


  const seePassword = async () => {
    if (chekContras != false) {
      setchekContras(false)
    } else {
      setchekContras(true)
    }
  };

  const registro = async (val) => {
 
          const usertemp = {
            name: val.name,
            email: val.email,
            password: val.password2,
            type:val.type,
            lng: localStorage.getItem('lngselect'),
          };
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/api/auth/register`, usertemp)
            .then((res) => {
              if (res.data.status == true) {
                loadSessionData(res)
                // localStorage.setItem('token', res.data.data.token);
                // localStorage.setItem('id', res.data.data.user.id);
                // localStorage.setItem('user', JSON.stringify(usertemp));
                  // switch (res.data.data.user.type) {
                  //   case "1":
                  //     props.history.push("/admin")
                  //   break;

                  //   case "2":
                  //     axios
                  //     .get(`${process.env.REACT_APP_BASE_URL}/api/has_profile_applicant`,
                  //     {      
                  //       headers: {
                  //         Authorization: `Bearer  ${res.data.data.token}`,
                  //       }
                  //     })
                  //     .then((res) => {
                  //       if(res.data.status===true){
                  //         props.history.push("/applicant")
                  //       }else{
                  //         // props.history.push("/registerapplicant")
                  //         props.history.push("/applicant")
                  //       }
                  //     });
                  //   break;

                  //   case "3":
                  //     axios
                  //     .get(`${process.env.REACT_APP_BASE_URL}/api/has_companies`,
                  //     {      
                  //       headers: {
                  //         Authorization: `Bearer  ${res.data.data.token}`,
                  //       }
                  //     })
                  //     .then((res) => {
                  //       if(res.data.status===true){
                  //         props.history.push("/company")
                  //       }else{
                  //         props.history.push("/registercompany")
                  //       }
                  //     });
                  //   break;
                  // }
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res.data.error.map((msg)=>msg)
                  // text: t("msg3"),
                });
              }
              document.getElementById("salirRedes").click();
            }).catch(error => {
              console.log("error "+error)
            });
  };

  const loadSessionData = (res) => {
    if ( !((res.data.data.user.type ? res.data.data.user.type.id : 0) > 0) || res.data.data.user.type.id == 4 ) {
      document.getElementById("btnmdlchangeusertype").click()
      settokentemp(res.data.data.token)
      setidusertemp(res.data.data.user.id)
    }else{
      switch (res.data.data.user.type.id) {
        case 1:
          localStorage.setItem('token',res.data.data.token);
          localStorage.setItem('id',res.data.data.user.id);
          localStorage.setItem('user',JSON.stringify(res.data.data.user));
          props.history.push("/admin")
        break;

        case 2:
          localStorage.setItem('token',res.data.data.token);
          localStorage.setItem('id',res.data.data.user.id);
          localStorage.setItem('user',JSON.stringify(res.data.data.user));
          axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/has_profile_applicant`,{      
            headers: {
              Authorization: `Bearer  ${res.data.data.token}`,
            }
          })
          .then((res) => {
            if(res.data.status===true){
              props.history.push("/applicant")
            }else{
              props.history.push("/applicant")
            }
          });
        break;

        case 3:
        localStorage.setItem('user',JSON.stringify(res.data.data.user));
        localStorage.setItem('token',res.data.data.token);
        localStorage.setItem('id',res.data.data.user.id);
          axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/has_companies`, {      
            headers: {
              Authorization: `Bearer  ${res.data.data.token}`,
            }
          })
          .then((res) => {
            if(res.data.status===true){
              props.history.push("/company")
            }else{
              props.history.push("/registercompany")
            }
          });
        break;
      }
    }
  } 

  const changeTypeUser = (type) => {
    const valuesupdate = {
      userid: idusertemp,
      type: type,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/changetypeuser`,
        valuesupdate, {
           headers: {
             Authorization: `Bearer  ${tokentemp}`,
           }
         }
      )
      .then((res) => {
        document.getElementById("closemodal").click()
        loadSessionData(res)
      })
      .catch((error) => console.log(error));
  }

  const getTypeUSer = () => {
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/user_type`)
    .then((res) => {
      settypeUser(res.data.data.user_type)
    })
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    document.getElementById("salirRedes").click();
    getTypeUSer()
  }, [])

    const {
      usertemp,
      signOut,
      signInWithGoogle,
      signInWithLinkedin,
    } = props;

    // const auth = firebase.auth();

    return (
      <>
        <div>
          <section class="">
            <div class="container py-5 h-100">
              <div class="row d-flex  justify-content-center h-100">
                <div class="col-md-12 col-lg-5 col-xl-5 offset-xl-1">
                  {usertemp ? (
                    <p>
                      {registerGoogle(
                        usertemp.displayName,
                        usertemp.email,
                        usertemp.uid,
                        usertemp.photoURL,
                        "google"
                      )}
                    </p>
                  ) : (
                    <p></p>
                  )}

                  <div class="square xyz-in" xyz="fade up big">
              
                      <div class="form-group  row justify-content-center mb-4" onClick={()=>props.history.push("/")}>
                        <img src={geojobscanada} class="img-responsive  imgform cursorpointer" />
                      </div>
                      <Formik
                      initialValues={{
                        name: user.name,
                        email: user.email,
                        type: localStorage.getItem('userrol') ? localStorage.getItem('userrol') : "4",
                        password: "",
                        password2: "",
                        conditions: ""
                      }}
                      validationSchema={Yup.object().shape({
                        conditions: Yup.string().required(t("fr")),
                        name: Yup.string().required(t("fr")),
                        email: Yup.string().required(t("fr")).email(t("inmai")), 
                        type: Yup.string(),
                        password: Yup.string().required(t("fr")),
                        password2: Yup.string().required(t("fr")).oneOf([Yup.ref("password"), null], t("passnoniq")), 
                      })}

                      onSubmit={(val, { resetForm }) => {
                        // resetForm();
                            registro(val)
                      }}

                      enableReinitialize
                      render={({ errors, status, touched }) => (
                        <Form class="container">
                    <div class="form-group mb-4">
                     <Field
                       id="name"
                       name="name"
                       type="text"
                       class="form-control imputText"
                       placeholder={t("lbl1")}
                     />

                      <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                   </div>

                   <div class="form-group mb-4">
                     <Field
                       id="email"
                       name="email"
                       type="email"
                       class="form-control imputText"
                       placeholder={t("lbl2")}
                   
                     />

                      <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                   </div>
                   <div class="form-group mb-4 d-none">
                        <Field
                          as="select"
                          id="type"
                          name="type"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl3")}
                        >
                          <option className="fondo fw-bolder" value="4" selected>
                            {t("lbl4")}
                          </option>

                          {typeUser.map((i) => {

                            if(i.id!=1){

                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.description}
                              </option>
                            );
                            }
                          })}
                        </Field>

                        <ErrorMessage
                          name="type"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>
                   <div class="form-group mb-4">


                     <Field
                       id="password"
                       name="password"
                       type={chekContras ? "text" : "password"}
                       class="form-control imputText"
                       placeholder={t("lbl5")}
      
                     />
                      <ErrorMessage
                          name="password"
                          component="div"
                          className="msgerror mx-2"
                        />
                   </div>

                   <div class="form-group mb-4">
                     <Field
                       id="password2"
                       name="password2"
                       type={chekContras ? "text" : "password"}
                       class="form-control imputText"
                       placeholder={t("lbl5")}
      
                     />
                      <ErrorMessage
                          name="password2"
                          component="div"
                          className="msgerror mx-2"
                        />
                   </div>

                  <div class="d-flex   mb-4">
                    <div class=" left">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="form1Example4"
                        onClick={() => seePassword()}
                      />
                      <label
                        class="leftLabel  form-check-label inputCheckbox textmovil"
                        for="form1Example4"
                      >
                      {t("lbl6")}
                      </label>
                    </div>
                  </div>

                  <div class="d-flex   mb-4">
                    <div class=" left">
                        <Field
                          id="conditions"
                          name="conditions"
                          class="form-check-input"
                          type="checkbox"
                          placeholder={t("lbl21")}
                        />

                        <label
                          class="color-fuente-gris textbold mx-2"
                          for="form1Example4"
                        >
                          {" "}
                          {t("lbl21")} {" "}
                        </label>

                        <ErrorMessage
                          name="conditions"
                          component="div"
                          className="msgerror mx-2"
                        />
                      <a class="fs-6" target="_blank" onClick={()=>window.open(process.env.REACT_APP_DOC_TRAT_DATA, '_blank')} > {t("lbl22")} </a>
                    </div>
                  </div>

                   

                   <div class="divider d-flex align-items-center my-2">
                   <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl7")}
                        </button>
                   </div>
                        </Form>
                       )}
                      />

                      <div class="divider d-flex align-items-center my-2">
                        <div class="color-fuente-gris textbold">
                          {t("ttl1")}
                        </div>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          class="btn btn-warning buttons widtauto"
                          href="#"
                          onClick={() => retornarLogin()}
                        >
                          {t("ttl2")}
                        </button>

                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <p class="text-center fw-bold mx-3 mb-0 text-muted my-2">
                          {t("ttl3")}
                        </p>
                      </div>

                      <div class="divider d-flex align-items-center my-1">
                        <a href={process.env.REACT_APP_BASE_URL+"/auth/google-redirect"} >
                          <div class="boton">
                            <img
                              class="img-responsive iconoRedes"
                              src={icoGoogle}
                            ></img>
                          </div>
                        </a>

                        {/* <a href="#" onClick={signInWithFacebook}>
                          <div class="botonfacebook">
                            <img
                              class="img-responsive iconoRedes"
                              src={svgFacebook}
                            />
                          </div>
                        </a> */}

                        <a href={process.env.REACT_APP_URLENCODE_LOGIN_LINKEDIN} >
                          <div class="botonfacebook">
                            <img
                              class="img-responsive iconoRedes"
                              src={linkedin}
                            />
                          </div>
                        </a>
                      </div>

                  </div>
                </div>
              </div>
            </div>
          </section>

          <button class="invisible" id="salirRedes" onClick={signOut}>
            {t("ttl4")}
          </button>
        </div>

        <button type="button" id="btnmdlchangeusertype" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#mdlchangeusertype"></button>
        <div class="modal fade" id="mdlchangeusertype" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="mdlchangeusertypeLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="mdlchangeusertypeLabel">{t("txt1")}</h5>
                <button id="closemodal" type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="row justify-content-center text-center">
                    <div class="col">
                      <button type="button" class="btn btn-info" value="2" onClick={(e)=>changeTypeUser(e.target.value)}> {t("lbl23")} </button>
                    </div>
                    <div class="col">
                      <p class=""> {t("lbl25")} </p>
                    </div>
                    <div class="col">
                      <button type="button" class="btn btn-success" value="3" onClick={(e)=>changeTypeUser(e.target.value)}> {t("lbl24")} </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Understood</button>
              </div> */}
            </div>
          </div>
        </div>
      </>
    )
}

// const firebaseAppAuth = firebaseApp.auth();

// const providers = {
//   googleProvider: new firebase.auth.GoogleAuthProvider(),
//   facebookProvider: new firebase.auth.FacebookAuthProvider(),
// };

// export default withFirebaseAuth({
//   providers,
//   firebaseAppAuth,
// })(Register);

export default Register