import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";

import Swal from "sweetalert2";

import axios from "axios";

import verimg from "../../img/ver.png";
// import userchat from "../../img/userchat.png";
// import listicon from "../../img/list.png";
// import ReactPaginate from "react-paginate";

const RecentApplicants = (props) => {

const [t, i18n] = useTranslation("recentapps")
  
  const [ applicants, setapplicants ] = useState([]);

  //get data X applicant
  const seeApplDetails = async ({id}) => {
    props.changeOpc({opc:"21", data:id})
  }

  const list = async () => {
    setapplicants([])
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/applicants`, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
          params:{
            page: 1
        }}
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setapplicants( res.data.data.applicants )
      })
      .catch((error) => console.log(error));
  };

  
  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

    return (
      <>
        <div class="card">
            <h5 class="card-header">{t('ttl6')}</h5>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">{t('ttl1')}</th>
                            <th scope="col">{t('ttl2')}</th>
                            <th scope="col">{t('ttl3')}</th>
                            <th scope="col">{t('ttl4')}</th>
                            <th scope="col">{t('ttl5')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {   
                          applicants.length > 0 ?
                            applicants.map((aplicant)=>(
                                <tr>
                                    <td> {aplicant.created_at.split('T')[0]} </td>
                                    <td>{aplicant.first_name} {aplicant.first_lastname}</td>
                                    <td>{aplicant.title}</td>
                                    <td>{aplicant.department.name}</td>
                                    {/* <td>{aplicant.department_nacionality.name}</td> */}
                                    <td>
                                        <div class="col-5 col-sm-5 col-md-3">
                                            {" "}
                                            <a
                                                href="javascript:void(0)"
                                                title={t('lbl1')}
                                                onClick={() => {
                                                seeApplDetails({
                                                    id: aplicant.id
                                                });
                                                }}
                                            >
                                                <img
                                                width="30"
                                                height="30"
                                                class=" mx-3"
                                                src={verimg}
                                                />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            ))
                          : ""
                        }
                    </tbody>
                </table>
            </div>
        </div>
      </>
    );
}
export default RecentApplicants;
