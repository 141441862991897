import React, { Component,useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import UserProfile from "../Login/UserProfile";
import Swal from "sweetalert2";

function PasswordRecoveryform(props){

  const [t, i18n] = useTranslation("login")

    const {
        handleSubmit,
        isSubmitting,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        resetForm,
      } = props;

    const sendCode = (values) => {
        values.lng = localStorage.getItem('lngselect')
          axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/forgot_password`,values)
          .then(res => {
      
            if(res.data.status==true){
              Swal.fire({
                  icon: "success",
                  title: "...",
                  text: res.data.data.message,
                });
  
                UserProfile.setEmail(values.email);
                props.history.push('/changepassword')
            }else{
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: t("msg7") ,
              });
            }
          })
      }

    return (
        <>
          <Formik
            initialValues={{
              email: "",
            }}
        
            validationSchema={Yup.object().shape({
              email: Yup.string().required(t("fr"))
            })}

            validate = {(values) => {
              const errors = {};
              if (!values.email) {
                  errors.email = t("msg6");
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = t("msg6");
                }
              return errors;
            }}

            onSubmit={(val, { resetForm }) => {
              val.status = 2
              sendCode(val, resetForm);
            }}
          >
              {/* render={({ errors, status, touched }) => ( */}
              {({handleSubmit}) => ( 
                <Form class="container" onSubmit={handleSubmit}>
                  <div class="form-group mb-4">
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      class="form-control imputText"
                      aria-describedby="emailHelp"
                      placeholder={t("lbl11")}
                    />

                      <ErrorMessage name="email">
                          {(message) => <div class="msgerror">{message}</div>}
                      </ErrorMessage>
                  </div>

                  <div class="divider d-flex align-items-center my-2">
                    <button
                        class="btn btn-warning  btn-block  buttons mx-2 "
                        disabled={isSubmitting}
                        onClick={()=>props.history.push('/changepassword')}
                    >
                    {t("lbl12")}
                    </button>

                    <button
                        type="submit"
                        class="btn btn-warning  btn-block  buttons mx-2 "
                        disabled={isSubmitting}
                    >
                    {t("lbl13")}
                    </button>
                  </div>
              </Form>
            )}
          </Formik>
        </>
    );
}

export default PasswordRecoveryform
// export default withFormik({

//     mapPropsToValues(props) {
//        return {
//           email: "",
//         };
//       },


//     validate(values) {
//         const errors = {};
//         if (!values.email) {
//             errors.email = t("msg6");
//           } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
//             errors.email = t("msg6");
//           }
//         return errors;
//     },

//     handleSubmit(values,  { props, setSubmitting, setStatus }) {
//       values.lng = localStorage.getItem('lngselect')
//         axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/forgot_password`,values)
//         .then(res => {
    
//           if(res.data.status==true){
//             Swal.fire({
//                 icon: "siccess",
//                 title: "Oops...",
//                 text: res.data.data.message,
//               });

//               UserProfile.setEmail(values.email);
//               props.history.push('/changepassword')
//           }else{
//             Swal.fire({
//               icon: "error",
//               title: "Oops...",
//               text: t("msg7") ,
//             });
//           }
//         })
//     },

// })(PasswordRecoveryform);