import React, { Component, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { withFormik, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";

import UserProfile from "../Login/UserProfile";

function ChangePasswordform(props) {
  
  const [t, i18n] = useTranslation("login")

  const [estado, setEstado] = useState(false);

  const {
    handleSubmit,
    isSubmitting,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
  } = props;

  function changeType(){
    if(estado==false){
      document.getElementById("password").type="text"
      document.getElementById("passwordre").type="text"
      setEstado(true)
    }else{
      document.getElementById("password").type="password"
      document.getElementById("passwordre").type="password"
      setEstado(false)
    }
  }

  return (
    <>
      <form  onSubmit={handleSubmit}>
        <div class="form-group mb-4">
          <Field
            id="email"
            name="email"
            type="text"
            class="form-control imputText"
            aria-describedby="emailHelp"
            placeholder={t("lbl15")}
          />


            <ErrorMessage name="email">
            {(message) => <div class="msgerror">{message}</div>}
          </ErrorMessage>

        </div>

        <div class="form-group mb-4">
          <Field
            id="code"
            name="code"
            type="text"
            class="form-control imputText"
            aria-describedby="emailHelp"
            placeholder={t("lbl16")}
          />

        <ErrorMessage name="code">
            {(message) => <div class="msgerror">{message}</div>}
          </ErrorMessage>
        </div>

        <div class="form-group mb-4">
          <Field
            id="password"
            name="password"
            type="password"
            class="form-control imputText"
            aria-describedby="emailHelp"
            placeholder={t("lbl17")}
          />

           <ErrorMessage name="password">
            {(message) => <div class="msgerror">{message}</div>}
           </ErrorMessage>


        </div>

        <div class="form-group mb-4">
          <Field
            id="passwordre"
            name="passwordre"
            type="password"
            class="form-control imputText"
            aria-describedby="emailHelp"
            placeholder={t("lbl18")}
          />

           <ErrorMessage name="passwordre">
            {(message) => <div class="msgerror">{message}</div>}
           </ErrorMessage>
        </div>

        <div class="d-flex   mb-4">
          <div class=" left">
            <input
              id="checTerminos"
              class="form-check-input "
              type="checkbox"
              value=""
              id="form1Example4"
              onChange={changeType}
            />
            <label
              class="leftLabel  form-check-label inputCheckbox textmovil"
              for="form1Example4"
            >
              {t("lbl19")}
            </label>
          </div>
        </div>

        <div class="divider d-flex align-items-center my-2">
          <button
            type="submit"
            class="btn btn-warning  btn-block  buttons "
          >
            {t("lbl20")}
          </button>
        </div>
      </form>
    </>
  );
}

export default 

withFormik({
    mapPropsToValues(props) {
       return {
          email: "",
          code: "",
          password: "",
          passwordre: "",
        };
      },

    validate(values) {
        const errors = {};
        if (!values.email) {
            errors.email = "Required field";
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid Email";
          }
          if (!values.code) {
            errors.code = "Required field";
        }
        if (!values.password) {
            errors.password = "Required field";
        }
        if (!values.passwordre) {
            errors.passwordre = "Required field";
        }else{
          if(values.passwordre!=values.password){
            errors.passwordre = "Passwords do not match";
          }
        }
        return errors;
    },

    handleSubmit(values,  { props, setSubmitting, setStatus }) {
       const user = {
        email: values.email,
        code: values.code,
        password: values.passwordre,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/auth/change_password`, user)
        .then((res) => {
          if (res.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: res.data.data.message,
            });
            props.history.push('/login')
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.error[0],
            });
          }
        });
    },


})(ChangePasswordform);
