import React, { useState, useEffect } from "react";

import Inicio  from "./inicioApplicant";
import Aspirante from "./aspiranteApplicant"
import MyOffers from "./myOffers"
import EmpresaApplicant from "./empresaApplicant"
import NotificationList from "../../components/NotificationList"

const HomeAplicant = (props) => {
    
    const renderpage = ({opc, data, type}) => {
        if (opc == 1) return <Inicio history={props.history} lng={props.lng} /> 
        if (opc == 21) return <Aspirante history={props.history} lng={props.lng} /> 
        if (opc == 2) return <MyOffers history={props.history} lng={props.lng} /> 
        if (opc == 3) return <EmpresaApplicant history={props.history} lng={props.lng} /> 
        if (opc == 4) return <NotificationList changeOpc={props.changeOpc} history={props.history} type={type} />
    }

    return (
        <>
        <div>
            {
                renderpage({opc: props.opc})
            }
        </div>
        </>
    );
}

export default HomeAplicant