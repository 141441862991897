import React, { Component, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";
import Swal from "sweetalert2";
import axios from "axios";
// import { useMercadopago } from 'react-sdk-mercadopago';

import { Formik, Field, Form, ErrorMessage } from "formik";
import DatePicker from "react-datepicker"
import Table from 'react-bootstrap/Table'

import * as Yup from "yup";

import ver from "../../img/ver.png";
import ReactPaginate from "react-paginate";
import userchat from "../../img/userchat.png";
import listplas from "../../img/listplas.png";
import sendicon from "../../img/send.png";
import usericon from "../../img/user.png";
import buser from "../../img/blackuser.png";
import editar from "../../img/edita.png";
import searchStaff from "../../img/searchStaff.png";

import "react-datepicker/dist/react-datepicker.css";

import { components } from "react-select";
import Select from "react-select";

import RegisterPosition from "../../components/RegisterPosition"
import RegisterSalaryRng from "../../components/RegisterSalaryRng"
import RegisterSchedule from "../../components/RegisterSchedule"
import RegisterSkill from "../../components/RegisterSkill"



const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",

    padding: 20,
  }),
  control: () => ({
    width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = 'isDisabled' ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          class="form-check-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const OfertasCompany = (props) => {

  const [t, i18n] = useTranslation("offercomp")

  // const [ limitdescr, setlimitdescr ] = useState(200);

  const [ ofertas, setofertas ] = useState([]);
  const [ jobofert, setjobofert ] = useState([]);
  const [ idioma, setidioma ] = useState([]);

  const [ datos, setdatos ] = useState([]);

  const [ countryselect,  setcountryselect,] = useState(0);
  const [ departmentselect, setdepartmentselect ] = useState(0);
  const [ cityselect, setcityselect ] = useState(0);

  const [ country, setcountry ] = useState(0); 
  const [ deparmet, setdeparmet ] = useState(0);
  

  
  const [ countryName,  setcountryName,] = useState("");
  const [ deparmetName, setdeparmetName ] = useState("");
  const [ estadoName, setestadoName ] = useState("");

  
  const [ status, setstatus ] = useState(0);
  const [ user, setuser ] = useState(0);

  
  const [ pais, setpais ] = useState([]);
  const [ departamento, setdepartamento ] = useState([]);
  const [ department, setdepartment ] = useState([]);
  const [ cities, setcities ] = useState([]);
  const [ usuario, setusuario ] = useState([]);
  const [ errors, seterrors ] = useState([]);

    //mods
  const [ applicantspost, setapplicantspost ] = useState([]);

    // selects
  const [ empresa, setempresa ] = useState([]);
  const [ actividad, setactividad ] = useState([]);
  const [ cargo, setcargo ] = useState([]);
  const [ salaries, setsalaries ] = useState([]);
  // const [ unitmeasure, setunitmeasure ] = useState([]);
  const [ xpreq, setxpreq ] = useState([]);
  const [ schedules, setschedules ] = useState([]); 
  const [ filstatus, setfilstatus ] = useState([]); 
  
  const [ enddateoffer, setenddateoffer ] = useState(new Date());
  const [ endselectiondateoffer, setendselectiondateoffer ] = useState(new Date());
  
  const [ activities, setactivities ] = useState([]);
  const [ languages, setlanguages ] = useState([]);

  
  const [ optionSelected, setoptionSelected, ] = useState(null);
  const [ optionLanguageSelected, setoptionLanguageSelected ] = useState(null);

    /*paginacion*/
  
  // const [ offset, setoffset ] = useState(0);
  // const [ currentPage, setcurrentPage ] = useState(0);
  // const [ pageCount, setpageCount ] = useState(0);
  
  // const [perPage, setperPage] = useState(10);
  const [ selectedPage, setselectedPage ] = useState(1);
  const [ pageCount, setpageCount ] = useState(0);
  const [ pageNex, setpageNex ] = useState()
  const [ offset, setoffset ] = useState(0);
  const [ perPage, setperPage] = useState(10);
  
  const [ elements, setelements ] = useState([]);
  const [ coment, setcoment ] = useState([]);

  const [ identificador, setidentificador] = useState("inicio");
  
  const [ pagePais, setpagePais ] = useState(1);
  const [ pageDepartamento, setpageDepartamento ] = useState(1);
  const [ pageEmpresa, setpageEmpresa ] = useState(1);
  const [ pageActividad, setpageActividad ] = useState(1);
  const [ pageCargo, setpageCargo ] = useState(1);
  const [ pageEstado, setpageEstado ] = useState(1);

  const [ targetmodel, settargetmodel ] = useState([]);
  const [ comentarios, setcomentarios ] = useState([]);

  
  const [ idoffert, setidoffert ] = useState(0);

  
  const [ oitem, setoitem ] = useState(1);

    // datos para actualizar la oferta
  const [ titleoffer, settitleoffer ] = useState("")
  const [ positionoffer, setpositionoffer ] = useState("")
  const [ salaryoffer, setsalaryoffer ] = useState("")
  const [ scheduleoffer, setscheduleoffer ] = useState("")
  const [ durationtimeoffer, setdurationtimeoffer ] = useState("")
  // const [ unitmeasureoffer, setunitmeasureoffer ] = useState("")
  const [ xpreqoffer, setxpreqoffer ] = useState("")
  const [ postalcodeoffer, setpostalcodeoffer ] = useState("")
  const [ vacancynumberoffer, setvacancynumberoffer ] = useState("")
  const [ descriptionoffer, setdescriptionoffer ] = useState("")
    
  const [ countryoffer, setcountryoffer ] = useState("")
  const [ departmenoffer, setdepartmenoffer ] = useState("")
  const [ cityoffer, setcityoffer ] = useState("")

  const [ idEmpresa, setidEmpresa ] = useState("")
  
    // paid aplicants
  const [ listServices, setlistServices ] = useState([])
  const [ applicant_offers, setapplicant_offers ] = useState([])
  const [ amounttopay, setamounttopay ] = useState(0)
  const [ preferenceId, setpreferenceId] = useState()

  //selects filter
  const [ citiesfil, setcitiesfil ] = useState([]);
  const [ deparmentsfil, setdeparmentsfil ] = useState([]);
  
    //         // filters
  const [ filtxtsearch, setfiltxtsearch ] = useState("");
  const [ filcountry, setfilcountry ] = useState("");
  const [ fildepartment, setfildepartment ] = useState("");
  const [ filcity, setfilcity ] = useState("");
  const [ filactivity, setfilactivity ] = useState("");
  const [ filposition, setfilposition ] = useState("");
  const [ filsalary, setfilsalary ] = useState("");
  const [ filschedule, setfilschedule ] = useState("");

  const addNewPosition = (data) => {
    setcargo([...cargo, data])
  }
  
  const addNewSalary = (data) => {
    setsalaries([...salaries, data])
  }

  const addNewSchedule = (data) => {
    setschedules([...schedules, data])
  }

  const addNewSkill = (data) => {
    setactivities([...activities, data])
  }
  
  
  
const resetList  = async () => {
        var search = {}
        setfiltxtsearch("")
        setfilactivity(0)
        setfilposition(0)
        setfilcountry(0)
        setfildepartment(0)
        setfilcity(0)
        setfilschedule(0)
        setfilsalary(0)
        setfilstatus(0)
        list(search)
    }

    const handleClickFilter = async () => {
        var search = {}
        if (filtxtsearch.length > 0) search.query = filtxtsearch
        if (filactivity > 0) search.activity = filactivity 
        if (filposition > 0) search.position = filposition 
        if (filcountry > 0) search.country = filcountry 
        if (fildepartment > 0) search.department = fildepartment 
        if (filcity > 0) search.city = filcity 
        if (filschedule > 0) search.schedule = filschedule 
        if (filsalary > 0) search.salary = filsalary 
        if (filstatus > 0) search.salary = filstatus 
        list(search)
    }

    const handlePageClick = (val) => {
        setselectedPage(val.selected+1)
        setpageNex(val.selected)
    }

 useEffect(() => {
  handleClickFilter()
}, [filtxtsearch, selectedPage, filcountry, fildepartment, filcity, filactivity, filposition, filsalary, filschedule,])


  const send = async () => {
    if (document.getElementById("mensaje").value != "") {
      var data = {
        comment: document.getElementById("mensaje").value,
        offer: idoffert,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/comment_offer`, data, {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            listChat(idoffert);
          }
        })
        .catch((error) => console.log(error));
        document.getElementById("mensaje").value=""
    }
  }


  const listChat = async (id) => {
    setidoffert(id)
    setcomentarios([])
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/commentoffer/${id}`,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => {
          if(res.data.status==true){
            setcomentarios(res.data.data.comment_offer);
          }
      });
  }

  const getUser = async () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/user_type`,
    {      
      headers: {
        Authorization: `Bearer  ${localStorage.getItem('token')}`,
      }
    }
    ).then((res) => {
      setusuario(res.data.data.user_type);
    });
  }

  const getCountry = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`,
      {      
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setpais(res.data.data.country );
      });
  }

  const getDeparment = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/department`, 
      {      
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      }
      )
      .then((res) => {
        setdepartamento(res.data.data.department);
      });
  }
  
  const getCities = async (val) => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/municipality`, 
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => {
        setcities(res.data.data.city)
      });
  };

  const handleChange = (selected) => {
    setoptionSelected(selected);
  }

  const handleChangeLanguage = (selected) => {
    setoptionLanguageSelected(selected);
  }

  const getActivity = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/activities`,
      {      
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setactivities(res.data.data.activities);
      });
  }

  const getLanguages = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/languages`,
      {      
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setlanguages(res.data.data.languages );
      });
  }

  const getCargo = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, 
      {      
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setcargo(res.data.data.positions );
      });
  }

  const getsalaries = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/salary_ranges`, 
      {      
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setsalaries(res.data.data.salary_ranges );
      });
  }

  
  // const getunitmeasure = async () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/unit_measurement`, 
  //     {      
  //       headers: {
  //         Authorization: `Bearer  ${localStorage.getItem('token')}`,
  //       }
  //     })
  //     .then((res) => {
  //       setunitmeasure(res.data.data.unit_measurement );
  //     });
  // }

  const getexperiencereq = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/experience_required`)
      .then((res) => {
        setxpreq(res.data.data.experience_required );
      });
  }

  const getworkinghours = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/work_hours`,
      {      
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setschedules(res.data.data.work_hours );
      });
  }

  const getDeparmentByCountry = ({id, fil}) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        })
      .then((res) => {
        if (fil) {
          setdeparmentsfil(res.data.data.department)
        }else{
          setdepartamento(res.data.data.department);
        }
        setcountryselect(id);
      });
  }

  const getCityByDepartment = ({id, fil}) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/cities/by_department/${id}`,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => {
        if (fil) {
          setcitiesfil(res.data.data.city);
        }else{
          setcities(res.data.data.city);
        }
        setdepartmentselect(id);
      });
  }


  const updateForm = async (value, countryf, deparmentf, statusf, userf) => {
    getCountry();
    getDeparment();
    getUser();

    setdatos([]);
    setcountry(0)
    setdepartment(0)
    setstatus(0)
    setuser(0)

    setdatos({ ...datos, datos: value });
    setcountry({ ...country, country: countryf });
    setdeparmet({ ...deparmet, deparmet: deparmentf });
    setstatus({ ...status, status: statusf });
    setuser({ ...user, user: userf });

    document.getElementById("openModalUpdate").click();
  }

  const renderList = () => {
    return activities.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  }

  const renderListActividad = () => {
    if (!optionSelected) {
      return
    }
    return optionSelected.map((data) => ({
      activity: data.value,
    }));
  }

  const renderListLanguages = () => {
    return languages.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  }

  const list = (search) => {
    setofertas([])
    let newsearch = {...search, page: selectedPage}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/job_offers`,{
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        },
        params: newsearch
      })
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setofertas(res.data.data.job_offers)
        setoitem(0)
        setpageCount(res.data.data.pages)
      })
      .catch((error) => console.log(error));
  }


  const editoffert = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/job_offers/${id}`,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          }
      })
      .then((res) => {
        const offer = res.data.data
        
        setidoffert( offer.id)
        settitleoffer( offer.title)
        setpositionoffer( offer.position ? offer.position.id : 0)
        setsalaryoffer( offer.salary ? offer.salary.id : 0)
        setscheduleoffer( offer.schedule ? offer.schedule.id : 0)
        // setdurationtimeoffer( offer.duration_time)
        // setunitmeasureoffer( offer.unit_measurement ? offer.unit_measurement.id : 0)
        setxpreqoffer( offer.experience_required ? offer.experience_required.id : 0)
        setpostalcodeoffer( offer.postal_code)
        setvacancynumberoffer( offer.vacancy_numbers)
        setdescriptionoffer( offer.description)
        
        getDeparmentByCountry({id: offer.country ? offer.country.id : 0})
        getCityByDepartment({id: offer.department ? offer.department.id : 0})

        setcountryoffer( offer.country ? offer.country.id : 0)
        setdepartmenoffer( offer.department ? offer.department.id : 0)
        setcityoffer( offer.city ? offer.city.id : 0)

        setenddateoffer( new Date(offer.end_offer))
        setendselectiondateoffer( new Date(offer.end_selection))

        let opactsel = []
        for (const {activity:{id, name}} of offer.activities_offer) {
          opactsel.push({"value":id, "label":name})
        }
        
        let oplansel = []
        for (const {language:{id, name}} of offer.language_offers) {
          oplansel.push({"value":id, "label":name})
        }

        setoptionSelected(opactsel)
        setoptionLanguageSelected(oplansel)
      })
      .catch((error) => console.log(error));
  }

  const saveEditOffer = ({val, resetForm}) => {
    let id = idoffert
    let acts = [];
    let lengs = []
    for (const {value: id} of optionSelected) {
      acts.push({"activity":id})
    }
    for (const {value: id} of optionLanguageSelected) {
      lengs.push({"language":id})
    }

    const values = {
            "title": val.titleoffer,
            "description": val.descriptionoffer,
            "position": val.positionoffer,
            // "duration_time": val.durationtimeoffer,
            // "unit_measurement": val.unitmeasureoffer,
            "experience_required": val.xpreqoffer,
            "salary": val.salaryoffer,
            "schedule": val.scheduleoffer,
            "end_offer": val.enddateoffer,
            "end_selection": val.endselectiondateoffer,
            "country": countryselect,
            "department": val.departmenoffer,
            "city": val.cityoffer,
            "postal_code": val.postalcodeoffer,
            "vacancy_numbers": val.vacancynumberoffer,
            "activity": acts,
            "languages": lengs,
          }
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/job_offers/${id}`,
        values,
        {      
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`, 
          }
      })
      .then((res) => {
        if (res.data.status == false) {
          seterrors(res.data.error)
        }
        if (res.data.status == true) {
          list();
          seterrors([])
          resetForm();
          Swal.fire({
            icon: "success",
            title: t("msg1"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
        }
      })
      .catch((error) => console.log(error));
  }

  const saveOffer = ({val, resetForm}) => {
    let acts = [];
    let lengs = []
    for (const {value: id} of optionSelected) {
      acts.push({"activity":id})
    }
    for (const {value: id} of optionLanguageSelected) {
      lengs.push({"language":id})
    }

    const values = {
            "title": val.titleoffer,
            "description": val.descriptionoffer,
            "position": val.positionoffer,
            "experience_required": val.xpreqoffer,
            "salary": val.salaryoffer,
            "schedule": val.scheduleoffer,
            "end_offer": val.enddateoffer,
            "end_selection": val.endselectiondateoffer,
            "country": countryselect,
            "department": val.departmenoffer,
            "city": val.cityoffer,
            "postal_code": val.postalcodeoffer,
            "vacancy_numbers": val.vacancynumberoffer,
            "activity": acts,
            "languages": lengs,
          };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/job_offers`,
        values,
        {      
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`, 
          }
      })
      .then((res) => {
        if (res.data.status == false) {
          seterrors(res.data.error)
        }
        if (res.data.status == true) {
          list();
          seterrors([])
          resetForm();
          Swal.fire({
            icon: "success",
            title: t("msg1"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
        }
      })
      .catch((error) => console.log(error));
  }

  const listApplicantpost = ({id}) =>{
    axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/applicant_offers?offer=${id}`,
      {      
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`, 
        },
          params:{
            page: 1
          }
        })
        .then((res) => {
        setapplicantspost(res.data.data.applicant_offers)
      })
      .catch((error) => console.log(error));
  }

  // get services
  const getServices = async () => {
    setlistServices([])
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/services`,
      {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        },
        params:{
            page: pageNex
        }
      })
      .then((res) => {
        setlistServices(res.data.data.services)
      })
      .catch((error) => console.log(error));
  };

  // Sum value to pay form applicants
  const sumpay = ({applicant, offer, company}) =>{
    var serbuydata = listServices.find( item => item.id == 1 ) // id 1, service applicant information
    var delet = applicant_offers.findIndex(item => item.applicant == applicant)

    var temp = applicant_offers
    if (delet >= 0) {
      temp.splice(delet, 1)
    }else{
      temp.push({
        "applicant" : applicant,
        "offer" : offer,
      })
    }
    var sum = parseFloat(temp.length) == 0 ? 0 : parseFloat(temp.length) * parseFloat(serbuydata.price)
    setapplicant_offers(temp)
    setamounttopay(sum)
  }

  const buyapplicants = async () => {
    var data = {applicant_offers}

    axios
    .post(`${process.env.REACT_APP_BASE_URL}/api/applicant_pays`, data, {
      headers: {
        Authorization: `Bearer  ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.error[0],
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        window.open(res.data.data.paypal_url)
      })
      .catch((error) => console.log(error));
  }

  const lookingStaff = (id) => {
    const service = listServices.find(ser => ser.id === 2)
    Swal.fire({
      title: t("msg3"),
      showDenyButton: true,
      confirmButtonText: t("tbl21") + " $ " + (service ? service.price : ""),
      denyButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          offer: id,
          applicant: 0
        }
        axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/company_services/search_staff_buy`, data, {
        headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.error[0],
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        window.open(res.data.data.paypal_url)
        })
        .catch((error) => console.log(error));
      }
    })
    
  }

  
  useEffect(() => {
    UserProfile.setToken(localStorage.getItem('token'));
    list()
    getCountry();
    getActivity();
    getLanguages();
    getCargo();
    getsalaries();
    // getunitmeasure();
    getexperiencereq();
    getworkinghours();
    getServices();
  }, []);
  
  let paginationElement
  {
    if(pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Sig"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
          onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
          forcePage={pageNex}
          containerClassName={"pagination justify-content-center"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      ) 
    }
  }
  
  return (
    <>
      <div class="item-group" xyz="fade">
        <div class="square xyz-in">
          <div class="row  d-flex  h-100  filtrosfondoxz">
            <div class="col-12 col-sm-12 col-md-2 filtrosopcioes  ">
              <div class=" row filtrosfondo">
                <div class="filtros mx-2 my-4">{t("lbl1")}</div>
              </div>

              <div class="row my-3">
                <div class="form-group mb-4 col-md-12">
                  <input
                    type="text"
                    className="form-control imputTextSelect mx-1"
                    aria-describedby="emailHelp"
                    placeholder={t("lbl2")}
                    onChange={(e)=>setfiltxtsearch(e.target.value)}
                  />
                </div>

                <hr />
              </div>

              <div class="row my-3">
                        <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#countr"}
                        aria-expanded="false"
                        aria-controls={"countr"}
                        >
                        {t("lbl3")}
                        </a>

                        <ul className="collapse mx-3" id={"countr"}>
                            <li class="mt-2">
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="filcountry"
                                        name="filcountry"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        onChange={(e) => (setfilcountry(e.target.value), getDeparmentByCountry({id: e.target.value, fil: 1}))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl3")}
                                        </option>

                                        {pais.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {i.name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="fildepartment"
                                        name="fildepartment"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        onChange={(e) => (setfildepartment(e.target.value), getCityByDepartment({id: e.target.value, fil: 1}))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("tbl12")}
                                        </option>

                                        {deparmentsfil.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {i.name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="filcity"
                                        name="filcity"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        onChange={(e) => (setfilcity(e.target.value))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("tbl30")}
                                        </option>

                                        {citiesfil.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {i.name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                            </li>
                        </ul>
                        <hr />
                    </div>

              <div class="row my-3">
                <a
                  href="#"
                  className="mx-2  items-center txtlargeAzul"
                  data-bs-toggle="collapse"
                  data-bs-target={"#activdad"}
                  aria-expanded="false"
                  aria-controls={"activdad"}
                >
                  {t("lbl6")}
                </a>

                <ul className="collapse mx-3" id={"activdad"}>
                  <li class="mt-2">
                    <div class="form-group mb-4 col-md-11">
                      <select
                        id="activdad"
                        name="activdad"
                        className="form-select fondoamarillo imputTextSelect"
                        aria-label="Default select example"
                        onChange={(e) =>
                          setfilactivity(e)
                        }
                      >
                        <option className="fondo fw-bolder" value="" selected>
                          {t("lbl6")}
                        </option>

                        {cargo.map((i) => {
                          return (
                            <option className="fondo fw-bolder" value={i.id}>
                              {
                                        props.lng == 'en' ?
                                          i.name :
                                        props.lng == 'es' ?
                                          i.name_es :
                                        props.lng == 'fr' ?
                                          i.name_fr : ""
                                      }
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </li>
                </ul>

                <hr />
              </div>

              {/* <div class="row my-3">
                <a
                  href="#"
                  className="mx-2  items-center txtlargeAzul"
                  data-bs-toggle="collapse"
                  data-bs-target={"#cargo"}
                  aria-expanded="false"
                  aria-controls={"cargo"}
                >
                  {t("lbl6")}
                </a>

                <ul className="collapse mx-3" id={"cargo"}>
                  <li class="mt-2">
                    <div class="form-group mb-4 col-md-11">
                      <select
                        id="cargo"
                        name="cargo"
                        className="form-select fondoamarillo imputTextSelect"
                        aria-label="Default select example"
                        onChange={(e) => setfilposition(e)}
                      >
                        <option className="fondo fw-bolder" value="" selected>
                          {t("lbl6")}
                        </option>

                        {cargo.map((i) => {
                          return (
                            <option className="fondo fw-bolder" value={i.id}>
                              {i.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </li>
                </ul>

                <hr />
              </div> */}

              <div class="row my-3">
                <a
                  href="#"
                  className="mx-2  items-center txtlargeAzul"
                  data-bs-toggle="collapse"
                  data-bs-target={"#estado"}
                  aria-expanded="false"
                  aria-controls={"estado"}
                >
                  {t("lbl7")}
                </a>

                <ul className="collapse mx-3" id={"estado"}>
                  <li class="mt-2">
                    <div class="form-group mb-4 col-md-11">
                      <select
                        id="statusfi"
                        name="statusfi"
                        className="form-select fondoamarillo imputTextSelect"
                        aria-label="Default select example"
                        onChange={(e) => setfilstatus(e)}
                      >
                        <option className="fondo fw-bolder" value="" selected>
                          {t("lbl8")}
                        </option>
                        <option className="fondo fw-bolder" value="todos">
                          {t("lbl9")}
                        </option>
                        <option className="fondo fw-bolder" value="1">
                          {t("lbl10")}
                        </option>
                        <option className="fondo fw-bolder" value="2">
                          {t("lbl11")}
                        </option>
                      </select>
                    </div>
                  </li>
                </ul>

                <hr />
              </div>

              <div class="row my-3">
                <a
                  href="#"
                  className="mx-2  items-center txtlargeAzul"
                  data-bs-toggle="collapse"
                  data-bs-target={"#reset"}
                  aria-expanded="false"
                  aria-controls={"reset"}
                  onClick={() => resetList()}
                >
                  {t("lbl12")}
                </a>

                <hr />
              </div>
            {/* END FILTER */}
            </div>

            <div class="col-12 col-sm-12 col-md-10 center-block container">
              {/* tabla de bits */}
                <div class="container">
                  <div class="row">
                    <div class="col mt-2">
                      <button
                        className="btn fondo buttonsWarning"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#mdlformnewoffer"
                        onClick={()=>{
                          setoptionSelected([])
                          setoptionLanguageSelected([])
                        }}
                      >
                        {t("lbl13")}
                      </button>
                    </div>
                  </div>
                </div>
                
              {/* <div className="my-2"> */}
                {/* <div className="container my-2 ocultar ">
                  <div class="row ">
                    {/* <div class="col text-center fondo">
                      <div className="funeteblanca">#</div>
                    </div> * /}

                    <div class="col text-center fondo">
                      <div className="funeteblanca">{t("tbl1")}</div>
                    </div>

                    <div class="col text-center fondo">
                      <div className="funeteblanca">{t("tbl2")}</div>
                    </div>

                    <div class="col text-center  fondo">
                      <div className="funeteblanca">{t("tbl3")}</div>
                    </div>

                    <div class="col text-center  fondo">
                      <div className="funeteblanca">{t("tbl4")}</div>
                    </div>

                    <div class="col text-center  fondo">
                      <div className="funeteblanca">{t("tbl5")}</div>
                    </div>

                    <div class="col text-center  fondo">
                      <div className="funeteblanca">{t("tbl6")}</div>
                    </div>

                    <div class="col text-center  fondo">
                      <div className="funeteblanca">{t("tbl7")}</div>
                    </div>
                  </div>
                </div> */}

                {ofertas.length > 0 && (
                  <div>
                    <div> 
                      {
                        // ofertas
                        //   .slice(offset, offset + perPage),
                          ofertas.map((oferta, i) => {
                            return (
                              <>
                              <div className=" my-2 px-3" key={oferta.id}>
                                <div className="accordion-item card sombra my-2">
                                  <div className="accordion-header" id="headingOne">
                                    <div className="row  my-2 p-2">
                                      {/* <div className="col text-center text-movil">
                                          <br />
                                          <div class="text-center txtSmallAzul ">{oferta.id}</div>
                                      </div> */}
                    
                                      <div className="col text-center">
                                          <div class="text-center txtSmallerGris">{t("tbl1")} : </div>
                                          <div class="text-center txtSmallAzul">{oferta.status.description}</div>
                                      </div>
                    
                                      <div className="col text-center">
                                          <div class="text-center txtSmallerGris">{t("tbl2")} : </div>
                                          <div class="text-center txtSmallAzul">{oferta.title}</div>
                                      </div>
                    
                                      <div className="col text-center">
                                          <div class="text-center txtSmallerGris">{t("tbl3")} : </div>
                                          <div class="text-center txtSmallAzul">
                                            {/* {oferta.position.name} */}
                                            {
                                              props.lng == 'en' ?
                                                oferta.position.name :
                                              props.lng == 'es' ?
                                                oferta.position.name_es :
                                              props.lng == 'fr' ?
                                                oferta.position.name_fr : ""
                                            }
                                          </div>
                                      </div>
                    
                                      <div className="col text-center">
                                          <div class="text-center txtSmallerGris">{t("tbl4")} : </div>
                                          <div class="text-center txtSmallAzul">
                                            {oferta.salary.name}
                                          </div>
                                      </div>
                    
                                      <div className="col text-center">
                                          <div class="text-center txtSmallerGris">{t("tbl5")} : </div>
                                          <div class="text-center txtSmallAzul">
                                            {oferta.end_offer}
                                          </div>
                                      </div>
                                      
                    
                                      <div className="col text-center">
                                          <div class="text-center txtSmallerGris">{t("tbl6")} : </div>
                                          <div class="text-center txtSmallAzul">
                                            {oferta.vacancy_numbers}
                                          </div>
                                      </div>
                    
                                      <div className="col text-center">
                                        <div className="row">
                                          {/* <div class="text-center txtSmallerGris">{t("tbl7")} : </div> */}
                                          <div className="row">
                                            <div class="text-center txtSmallAzul">
                                              <a
                                                href="javascript:void(0)"
                                                title={t("lbl36")}
                                                data-bs-toggle="collapse"
                                                data-bs-target={"#c" + oferta.id}
                                                aria-expanded="false"
                                                aria-controls={"c" + oferta.id}
                                              >
                                                <img
                                                  width="30"
                                                  height="30"
                                                  class="icoOpciones mx-3"
                                                  src={ver}
                                                />{" "}
                                              </a>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div class="text-center txtSmallAzul">
                                              <a
                                                href="javascript:void(0)"
                                                title={t("lbl14")}
                                                class="position-relative"
                                                data-bs-toggle="modal"
                                                data-bs-target="#mdllispostapplicant"
                                                onClick={listApplicantpost.bind(this, {id: oferta.id})}
                                              >
                                                <img
                                                  width="25"
                                                  height="25"
                                                  class="icoOpciones mx-3 mt-1"
                                                  src={buser}
                                                />{" "}
                                                {oferta.postulates < 1 ? "" : 
                                                
                                                <span class="position-absolute top-0 translate-middle badge rounded-pill bg-danger">
                                                  {oferta.postulates > 99 ? "99+" : oferta.postulates}
                                                </span>
                                                
                                                }
                                              </a>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div class="text-center txtSmallAzul">
                                              <a
                                                href="javascript:void(0)"
                                                title={t("lbl15")}
                                                class="position-relative"
                                                // class={ oferta.status.id != 2 ? 'position-relative d-none' : 'position-relative' }
                                                data-bs-toggle="modal"
                                                data-bs-target="#mdlformeditoffer"
                                                onClick={editoffert.bind(this, oferta.id)}
                                              >
                                                <img
                                                  width="20"
                                                  height="20"
                                                  class="icoOpciones mx-3 mt-1"
                                                  src={editar}
                                                />
                                              </a>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div class="text-center txtSmallAzul">
                                              <a
                                                href="javascript:void(0)"
                                                title={t("lbl37")}
                                                class="position-relative"
                                                onClick={()=>lookingStaff(oferta.id)}
                                              >
                                                <img
                                                  width="20"
                                                  height="20"
                                                  class="icoOpciones mx-3 mt-1"
                                                  src={searchStaff}
                                                />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>

                                  {/* collapse */}
                                  <div className="collapse" id={"c" + oferta.id}>
                                    <div className="card card-body txtSmallerGris ">
                                      <div className="row">
                                        <div className=" col-md-6 ">
                                          {/* <div class="txtSmallAzul">{t("tbl8")}</div> */}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl9")}</div>
                                          {oferta.position ? oferta.position.name : ""}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl10")}</div>
                    
                                          {oferta.salary ? oferta.salary.name : ""}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl11")}</div>
                                          {oferta.experience_required ? oferta.experience_required.name : ""}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl12")}</div>
                                          {oferta.department ? oferta.department.name : ""}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl13")}</div>
                                          {oferta.vacancy_numbers}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl14")}</div>
                                          {oferta.description}
                                        </div>
                    
                                        <div className=" col-md-6 ">
                                          <div class="txtSmallAzul">{t("tbl15")}</div>
                    
                                          {oferta.language_offers.map((i) => {
                                            return <>{i.language.name + " - "}</>;
                                          })}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl16")}</div>
                    
                                          {oferta.schedule ? oferta.schedule.name : ""}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl17")}</div>
                                          {oferta.country ? oferta.country.name : ""}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl18")}</div>
                    
                                          {oferta.postal_code}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl19")}</div>
                                          {oferta.status ? oferta.status.description : ""}
                    
                                          <div class="txtSmallAzul mt-1">{t("tbl20")}</div>
                                          {oferta.activities_offer.map((i) => {
                                            return <>{i.activity ? i.activity.name + " - " : ""}</>;
                                          })}
                    
                                          <div class="row mt-2">
                                            <div class="dropdownn">
                                              <button
                                                class="btn btn-lightModal buttonSmallAzulModal"
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#modalOfetas"
                                                onClick={listChat.bind(this, oferta.id)}
                                              >
                                                <span>
                                                  <img
                                                    className="img-fluid "
                                                    width="12px"
                                                    height="12px"
                                                    src={listplas}
                                                  />
                                                </span>
                                                {t("lbl16")}
                                              </button>
                                            </div>
                                          </div>
                    
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              
                              </>
                            )
                          })
                      }
                    </div>
                    <div>{paginationElement}</div>
                  </div>
                )}
                
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      

      {/* modal form new job offer */}
      <div class="modal fade" id="mdlformnewoffer" tabindex="-1" aria-labelledby="mdlformnewofferLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="mdlformnewoffer">{t("ttl1")}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {errors.map((i) => {
                return (
                  <div class="alert alert-danger" role="alert">
                    {i}
                  </div>
                );
              })}

              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="tab-o" data-bs-toggle="tab" data-bs-target="#newJobOffer" type="button" role="tab" aria-controls="newJobOffer" aria-selected="true">{t("ttl1")}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link " id="tab-t" data-bs-toggle="tab" data-bs-target="#newPosition" type="button" role="tab" aria-controls="newPosition" aria-selected="false">{t("ttl6")}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link " id="tab-f" data-bs-toggle="tab" data-bs-target="#newSkill" type="button" role="tab" aria-controls="newSkill" aria-selected="false">{t("ttl9")}</button>
                </li>
                {/* <li class="nav-item" role="presentation">
                  <button class="nav-link " id="tab-f" data-bs-toggle="tab" data-bs-target="#newSalary" type="button" role="tab" aria-controls="newSalary" aria-selected="false">{t("ttl7")}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link " id="tab-fv" data-bs-toggle="tab" data-bs-target="#newSchedule" type="button" role="tab" aria-controls="newSchedule" aria-selected="false">{t("ttl8")}</button>
                </li> */}
              </ul>

              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade " id="newPosition" role="tabpanel" aria-labelledby="tab-t">
                  <RegisterPosition addNewPosition={addNewPosition}/>
                </div>
                <div class="tab-pane fade " id="newSalary" role="tabpanel" aria-labelledby="tab-f">
                  <RegisterSalaryRng addNewSalary={addNewSalary}/>
                </div>
                <div class="tab-pane fade " id="newSchedule" role="tabpanel" aria-labelledby="tab-fv">
                  <RegisterSchedule addNewSchedule={addNewSchedule}/>
                </div>
                <div class="tab-pane fade " id="newSkill" role="tabpanel" aria-labelledby="tab-fv">
                  <RegisterSkill addNewSkill={addNewSkill}/>
                </div>
                
                <div class="tab-pane fade show active" id="newJobOffer" role="tabpanel" aria-labelledby="tab-o">

                  <Formik
                    initialValues={{
                      titleoffer: "",
                      positionoffer: "",
                      salaryoffer: "",
                      scheduleoffer: "",
                      durationtimeoffer: "",
                      // unitmeasureoffer: "",
                      xpreqoffer: "",
                      postalcodeoffer: "",
                      vacancynumberoffer: "",
                      descriptionoffer: "",
                      
                      actividadoffer: renderListActividad(),
                      countryoffer: "",
                      departmenoffer: "",
                      cityoffer: "",
                      enddateoffer: new Date(),
                      endselectiondateoffer: new Date(),
                    }}
                    

                    validationSchema={Yup.object().shape({
                      titleoffer: Yup.string().required(t("fr")),
                      positionoffer: Yup.string().required(t("fr")),
                      salaryoffer: Yup.string().required(t("fr")),
                      scheduleoffer: Yup.string().required(t("fr")),
                      // durationtimeoffer: Yup.number().required(t("fr")).positive().integer(),
                      // unitmeasureoffer: Yup.string().required(t("fr")),
                      xpreqoffer: Yup.string().required(t("fr")),
                      postalcodeoffer: Yup.string().required(t("fr")),
                      vacancynumberoffer: Yup.number().required(t("fr")).positive().integer(),
                      descriptionoffer: Yup.string().required(t("fr")),

                      countryoffer: Yup.number(),
                      departmenoffer: Yup.number().required(t("fr")),
                      enddateoffer: Yup.date(),
                      endselectiondateoffer: Yup.date(),

                    })}

                    validate = {(values) => {
                      if (values.countryoffer != countryselect && values.countryoffer > 0) getDeparmentByCountry({id:values.countryoffer})
                      if (values.departmenoffer != departmentselect && values.departmenoffer > 0) getCityByDepartment({id:values.departmenoffer})
                      // setlimitdescr(200 - values.reason.length)
                    }}

                    onSubmit={(val, { resetForm }) => {
                        saveOffer({val, resetForm})
                    }}
                    // enableReinitialize
                  >
                    {({handleSubmit}) => ( 
                      <Form class="container" onSubmit={handleSubmit}>
                        <div class="row">
                          <div class="col">
                            <div class="form-group mt-2">
                              <label for="titleoffer" class="form-label">{t("lbl17")}</label>
                              <Field
                                id="titleoffer"
                                name="titleoffer"
                                type="text"
                                class="form-control imputText"
                                placeholder={t("lbl17")}
                              />

                              <ErrorMessage
                                name="titleoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6">
                            <div class="form-group mt-2">
                              <label for="positionoffer" class="form-label">{t("lbl18")}</label>
                              <Field
                                as="select"
                                id="positionoffer"
                                name="positionoffer"
                                className="form-select fondoamarillo imputText"
                              >
                                <option
                                  className="fondo fw-bolder"
                                  value=""
                                  selected
                                >
                                  {t("lbl18")}
                                </option>

                                {cargo.map((i) => {
                                  return (
                                    <option
                                      className="fondo fw-bolder"
                                      value={i.id}
                                    >
                                      {/* {
                                        props.lng == 'en' ?
                                          i.name :
                                        props.lng == 'es' ?
                                          i.name_es :
                                        props.lng == 'fr' ?
                                          i.name_fr : ""
                                      } */}
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="positionoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form-group mt-2">
                              <label for="salaryoffer" class="form-label">{t("lbl19")}</label>
                              <Field
                                as="select"
                                id="salaryoffer"
                                name="salaryoffer"
                                className="form-select fondoamarillo imputText"
                              >
                                <option
                                  className="fondo fw-bolder"
                                  value=""
                                  selected
                                >
                                  {t("lbl19")}
                                </option>

                                {salaries.map((i) => {
                                  return (
                                    <option
                                      className="fondo fw-bolder"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="salaryoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-6">
                            <div class="form-group ">
                              <label for="scheduleoffer" class="form-label">{t("lbl20")}</label>
                              <Field
                                as="select"
                                id="scheduleoffer"
                                name="scheduleoffer"
                                className="form-select fondoamarillo imputText"
                              >
                                <option
                                  className="fondo fw-bolder"
                                  value=""
                                  selected
                                >
                                  {t("lbl20")}
                                </option>

                                {schedules.map((i) => {
                                  return (
                                    <option
                                      className="fondo fw-bolder"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="scheduleoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form-group mt-2">
                              <label for="xpreqoffer" class="form-label">{t("lbl21")}</label>
                              <Field
                                as="select"
                                id="xpreqoffer"
                                name="xpreqoffer"
                                className="form-select fondoamarillo imputText"
                              >
                                <option
                                  className="fondo fw-bolder"
                                  value=""
                                  selected
                                >
                                  {t("lbl21")}
                                </option>

                                {xpreq.map((i) => {
                                  return (
                                    <option
                                      className="fondo fw-bolder"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="xpreqoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-6">
                            <div class="form-group">
                              <label for="countryoffer" class="form-label">{t("lbl23")}</label>
                              <Field
                                as="select"
                                id="countryoffer"
                                name="countryoffer"
                                className="form-select fondoamarillo imputText"
                              >
                                <option
                                  className="fondo fw-bolder"
                                  value=""
                                >
                                  {t("lbl23")}
                                </option>

                                {pais.map((i) => {
                                  return (
                                    <option
                                      className="fondo fw-bolder"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="countryoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label for="departmenoffer" class="form-label">{t("lbl24")}</label>
                              <Field as="select"
                                id="departmenoffer"
                                name="departmenoffer"
                                className="form-select fondoamarillo imputText"
                                aria-label="Default select example"
                              >
                                <option
                                  className="fondo fw-bolder"
                                  value=""
                                  selected
                                >
                                  {t("lbl24")}
                                </option>

                                {departamento.map((i) => {
                                  return (
                                    <option
                                      className="fondo fw-bolder"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="departmenoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label for="cityoffer" class="form-label">{t("tbl29")}</label>
                              <Field as="select"
                                id="cityoffer"
                                name="cityoffer"
                                className="form-select fondoamarillo imputText"
                                aria-label="Default select example"
                              >
                                <option
                                  className="fondo fw-bolder"
                                  value=""
                                  selected
                                >
                                  {t("tbl29")}
                                </option>

                                {cities.map((i) => {
                                  return (
                                    <option
                                      className="fondo fw-bolder"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="cityoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>
                        </div>
                        


                        <div class="row mt-2">
                          <div class="col-6">
                            <div class="form-group">
                              <label for="postalcodeoffer" class="form-label">{t("lbl25")}</label>
                              <Field
                                id="postalcodeoffer"
                                name="postalcodeoffer"
                                type="text"
                                class="form-control imputText"
                                placeholder={t("lbl25")}
                              />

                              <ErrorMessage
                                name="postalcodeoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form-group">
                              <label for="vacancynumberoffer" class="form-label">{t("lbl26")}</label>
                              <Field
                                id="vacancynumberoffer"
                                name="vacancynumberoffer"
                                type="number"
                                class="form-control imputText"
                                placeholder={t("lbl26")}
                              />

                              <ErrorMessage
                                name="vacancynumberoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>
                        </div>



                        <div class="row">
                          <div class="col-6">
                            <div class="form-group mt-2">
                              <label htmlFor="enddateoffer">{t("lbl27")}</label>
                              <DatePicker 
                                selected={enddateoffer}
                                dateFormat="Y-M-d"
                                className="form-control"
                                id="enddateoffer"
                                name="enddateoffer"
                                onChange={(e) => {
                                  setenddateoffer(e)
                                }}
                              />

                              <ErrorMessage
                                name="enddateoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form-group mt-2">
                              <label htmlFor="endselectiondateoffer">{t("lbl28")}</label>
                              <DatePicker 
                                selected={endselectiondateoffer}
                                dateFormat="Y-M-d"
                                className="form-control"
                                id="endselectiondateoffer"
                                name="endselectiondateoffer"
                                onChange={(e) => {
                                  setendselectiondateoffer(e)
                                }}
                              />

                              <ErrorMessage
                                name="endselectiondateoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>
                        </div>

                        

                        <div class="row mt-2">
                          <div class="col">
                            <div class="form-group mb-4">
                              <label for="actividadoffer" class="form-label">{t("lbl29")}</label>
                              <Select
                                name="actividadoffer"
                                placeholder={
                                  <div className="fondoamarillo">
                                    {t("lbl29")}
                                  </div>
                                }
                                options={renderList()}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                  Option,
                                }}
                                onChange={handleChange}
                                allowSelectAll={true}
                                value={optionSelected}
                              />

                                  <ErrorMessage
                                    name="actividadoffer"
                                    component="div"
                                    className="msgerror mx-2"
                                  />
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group mb-4">
                              <label for="languagesoffer" class="form-label">{t("lbl30")}</label>
                              <Select
                                name="languagesoffer"
                                placeholder={
                                  <div className="fondoamarillo">
                                    {t("lbl30")}
                                  </div>
                                }
                                options={renderListLanguages()}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                  Option,
                                }}
                                onChange={handleChangeLanguage}
                                allowSelectAll={true}
                                value={optionLanguageSelected}
                              />

                                  <ErrorMessage
                                    name="languagesoffer"
                                    component="div"
                                    className="msgerror mx-2"
                                  />
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col">
                            <div class="form-group mt-2">
                              <label for="descriptionoffer" class="form-label">{t("lbl31")}</label>
                              <Field
                                id="descriptionoffer"
                                name="descriptionoffer"
                                component="textarea"
                                class="form-control imputText"
                                placeholder={t("lbl31")}
                                // maxlength="200"
                              />

                              <ErrorMessage
                                name="descriptionoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                              {/* <label for="description" class="form-label">{limitdescr}</label> */}
                            </div>
                          </div>
                        </div>
                        

                        <div class="row">
                          <div class="divider d-flex align-items-center my-2">
                            <button
                              type="submit"
                              class="btn btn-warning  btn-block  buttons "
                            >
                              {t("lbl32")}
                            </button>
                          </div>
                        </div>

                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl33")}</button>
            </div>
          </div>
        </div>
      </div>
      {/* end modal form new job offer */}

      {/* modal form edit job offer */}
      <div class="modal fade" id="mdlformeditoffer" tabindex="-1" aria-labelledby="mdlformeditofferLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="mdlformeditoffer">{t("ttl2")} {titleoffer}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {errors.map((i) => {
                return (
                  <div class="alert alert-danger" role="alert">
                    {i}
                  </div>
                );
              })}
              <Formik

                initialValues={{
                  titleoffer: titleoffer,
                  positionoffer: positionoffer,
                  salaryoffer: salaryoffer,
                  scheduleoffer: scheduleoffer,
                  // durationtimeoffer: durationtimeoffer,
                  // unitmeasureoffer: unitmeasureoffer,
                  xpreqoffer: xpreqoffer,
                  postalcodeoffer: postalcodeoffer,
                  vacancynumberoffer: vacancynumberoffer,
                  descriptionoffer: descriptionoffer,
                  
                  actividadoffer: renderListActividad(),
                  countryoffer: countryoffer,
                  departmenoffer: departmenoffer,
                  cityoffer: cityoffer,
                  enddateoffer: enddateoffer,
                  endselectiondateoffer: endselectiondateoffer,
                }}
                

                validationSchema={Yup.object().shape({
                  titleoffer: Yup.string().required(t("fr")),
                  descriptionoffer: Yup.string().required(t("fr")),
                  postalcodeoffer: Yup.string().required(t("fr")),
                  vacancynumberoffer: Yup.number().required(t("fr")).positive().integer(),
                  
                  positionoffer: Yup.string().required(t("fr")),
                  // durationtimeoffer: Yup.number().required(t("fr")).positive().integer(),
                  // unitmeasureoffer: Yup.string().required(t("fr")),
                  xpreqoffer: Yup.string().required(t("fr")),
                  salaryoffer: Yup.string().required(t("fr")),
                  scheduleoffer: Yup.string().required(t("fr")),

                  countryoffer: Yup.number(),
                  departmenoffer: Yup.number().required(t("fr")),
                  enddateoffer: Yup.date(),
                  endselectiondateoffer: Yup.date(),

                })}

                validate = {(values) => {
                  const errors = {};
                  if (values.countryoffer != countryselect && values.countryoffer > 0) getDeparmentByCountry({id:values.countryoffer})
                  if (values.departmenoffer != departmentselect && values.departmenoffer > 0) getCityByDepartment({id:values.departmenoffer})
                  // if (values.cityoffer != cityselect && values.cityoffer > 0) getCityByDepartment({id:values.departmenoffer})
                  if (values.positionoffer < 1) {errors.positionoffer = t("fr")}
                  // if (values.unitmeasureoffer < 1) {errors.unitmeasureoffer = t("fr")}
                  if (values.xpreqoffer < 1) {errors.xpreqoffer = t("fr")}
                  if (values.salaryoffer < 1) {errors.salaryoffer = t("fr")}
                  if (values.scheduleoffer < 1) {errors.scheduleoffer = t("fr")}
                  return errors;
                }}

                onSubmit={(val, { resetForm }) => {
                    saveEditOffer({val, resetForm})
                }}
                enableReinitialize
              >
                {({handleSubmit}) => ( 
                  <Form class="container" onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="col">
                        <div class="form-group mt-2">
                          <label for="titleoffer" class="form-label">{t("lbl17")}</label>
                          <Field
                            id="titleoffer"
                            name="titleoffer"
                            type="text"
                            class="form-control imputText"
                            placeholder={t("lbl17")}
                          />

                          <ErrorMessage
                            name="titleoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="form-group mt-2">
                          <label for="positionoffer" class="form-label">{t("lbl18")}</label>
                          <Field
                            as="select"
                            id="positionoffer"
                            name="positionoffer"
                            className="form-select fondoamarillo imputText"
                          >
                            <option
                              className="fondo fw-bolder"
                              value=""
                              selected
                            >
                              {t("lbl18")}
                            </option>

                            {cargo.map((i) => {
                              return (
                                <option
                                  className="fondo fw-bolder"
                                  value={i.id}
                                >
                                  {i.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="positionoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group mt-2">
                          <label for="salaryoffer" class="form-label">{t("lbl19")}</label>
                          <Field
                            as="select"
                            id="salaryoffer"
                            name="salaryoffer"
                            className="form-select fondoamarillo imputText"
                          >
                            <option
                              className="fondo fw-bolder"
                              value=""
                              selected
                            >
                              {t("lbl19")}
                            </option>

                            {salaries.map((i) => {
                              return (
                                <option
                                  className="fondo fw-bolder"
                                  value={i.id}
                                >
                                  {i.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="salaryoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-6">
                        <div class="form-group ">
                          <label for="scheduleoffer" class="form-label">{t("lbl20")}</label>
                          <Field
                            as="select"
                            id="scheduleoffer"
                            name="scheduleoffer"
                            className="form-select fondoamarillo imputText"
                          >
                            <option
                              className="fondo fw-bolder"
                              value=""
                              selected
                            >
                              {t("lbl20")}
                            </option>

                            {schedules.map((i) => {
                              return (
                                <option
                                  className="fondo fw-bolder"
                                  value={i.id}
                                >
                                  {i.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="scheduleoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group mt-2">
                          <label for="xpreqoffer" class="form-label">{t("lbl22")}</label>
                          <Field
                            as="select"
                            id="xpreqoffer"
                            name="xpreqoffer"
                            className="form-select fondoamarillo imputText"
                          >
                            <option
                              className="fondo fw-bolder"
                              value=""
                              selected
                            >
                              {t("lbl22")}
                            </option>

                            {xpreq.map((i) => {
                              return (
                                <option
                                  className="fondo fw-bolder"
                                  value={i.id}
                                >
                                  {i.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="xpreqoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>
                    </div>


                    <div class="row mt-2">
                      <div class="col-6">
                        <div class="form-group">
                          <label for="countryoffer" class="form-label">{t("lbl23")}</label>
                          <Field
                            as="select"
                            id="countryoffer"
                            name="countryoffer"
                            className="form-select fondoamarillo imputText"
                          >
                            <option
                              className="fondo fw-bolder"
                              value=""
                            >
                              {t("lbl23")}
                            </option>

                            {pais.map((i) => {
                              return (
                                <option
                                  className="fondo fw-bolder"
                                  value={i.id}
                                >
                                  {i.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="countryoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label for="departmenoffer" class="form-label">{t("lbl24")}</label>
                          <Field as="select"
                            id="departmenoffer"
                            name="departmenoffer"
                            className="form-select fondoamarillo imputText"
                            aria-label="Default select example"
                          >
                            <option
                              className="fondo fw-bolder"
                              value=""
                              selected
                            >
                              {t("lbl24")}
                            </option>

                            {departamento.map((i) => {
                              return (
                                <option
                                  className="fondo fw-bolder"
                                  value={i.id}
                                >
                                  {i.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="departmenoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label for="cityoffer" class="form-label">{t("tbl29")}</label>
                          <Field as="select"
                            id="cityoffer"
                            name="cityoffer"
                            className="form-select fondoamarillo imputText"
                            aria-label="Default select example"
                          >
                            <option
                              className="fondo fw-bolder"
                              value=""
                              selected
                            >
                              {t("tbl29")}
                            </option>

                            {cities.map((i) => {
                              return (
                                <option
                                  className="fondo fw-bolder"
                                  value={i.id}
                                >
                                  {i.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="cityoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>
                    </div>


                    <div class="row mt-2">
                      <div class="col-6">
                        <div class="form-group">
                          <label for="postalcodeoffer" class="form-label">{t("lbl25")}</label>
                          <Field
                            id="postalcodeoffer"
                            name="postalcodeoffer"
                            type="text"
                            class="form-control imputText"
                            placeholder={t("lbl25")}
                          />

                          <ErrorMessage
                            name="postalcodeoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group">
                          <label for="vacancynumberoffer" class="form-label">{t("lbl26")}</label>
                          <Field
                            id="vacancynumberoffer"
                            name="vacancynumberoffer"
                            type="number"
                            class="form-control imputText"
                            placeholder={t("lbl26")}
                          />

                          <ErrorMessage
                            name="vacancynumberoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>
                    </div>



                    <div class="row">
                      <div class="col-6">
                        <div class="form-group mt-2">
                          <label htmlFor="enddateoffer">{t("lbl27")}</label>
                          <DatePicker 
                            selected={enddateoffer}
                            dateFormat="Y-M-d"
                            className="form-control"
                            id="enddateoffer"
                            name="enddateoffer"
                            onChange={(e) => {
                              setenddateoffer(e)
                            }}
                          />

                          <ErrorMessage
                            name="enddateoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group mt-2">
                          <label htmlFor="endselectiondateoffer">{t("lbl28")}</label>
                          <DatePicker 
                            selected={endselectiondateoffer}
                            dateFormat="Y-M-d"
                            className="form-control"
                            id="endselectiondateoffer"
                            name="endselectiondateoffer"
                            onChange={(e) => {
                              setendselectiondateoffer(e)
                            }}
                          />

                          <ErrorMessage
                            name="endselectiondateoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>
                    </div>

                    

                    <div class="row mt-2">
                      <div class="col">
                        <div class="form-group mb-4">
                          <label for="actividadoffer" class="form-label">{t("lbl29")}</label>
                          <Select
                            name="actividadoffer"
                            placeholder={
                              <div className="fondoamarillo">
                                {t("lbl29")}
                              </div>
                            }
                            options={renderList()}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                            }}
                            onChange={handleChange}
                            allowSelectAll={true}
                            value={optionSelected}
                          />

                              <ErrorMessage
                                name="actividadoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group mb-4">
                          <label for="languagesoffer" class="form-label">{t("lbl30")}</label>
                          <Select
                            name="languagesoffer"
                            placeholder={
                              <div className="fondoamarillo">
                                {t("lbl30")}
                              </div>
                            }
                            options={renderListLanguages()}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                            }}
                            onChange={handleChangeLanguage}
                            allowSelectAll={true}
                            value={optionLanguageSelected}
                          />

                              <ErrorMessage
                                name="languagesoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="form-group mt-2">
                          <label for="descriptionoffer" class="form-label">{t("lbl31")}</label>
                          <Field
                            id="descriptionoffer"
                            name="descriptionoffer"
                            component="textarea"
                            class="form-control imputText"
                            placeholder={t("lbl31")}
                          />

                          <ErrorMessage
                            name="descriptionoffer"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>
                      </div>
                    </div>
                    

                    <div class="row">
                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl32")}
                        </button>
                      </div>
                    </div>

                  </Form>
                )}
              </Formik>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl33")}</button>
            </div>
          </div>
        </div>
      </div>
      {/* end modal form edit job offer */}

      {/* modal list postulated applicants */}
      <div class="modal fade" id="mdllispostapplicant" tabindex="-1" aria-labelledby="mdllispostapplicantLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="mdllispostapplicantLabel">{t("ttl3")}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <h5> {t("ttl10")} $ {amounttopay}</h5>
                  </div>
                  <div class="col">
                    <button
                      type="button"
                      class={amounttopay <= 0 ? "btn btn-warning  btn-block  buttons mb-3 d-none" : "btn btn-warning  btn-block  buttons mb-3"}
                      onClick={buyapplicants.bind()}
                    >
                      {t("lbl34")}
                    </button>
                  </div>
                  <div class="col">
                    <div class={amounttopay <= 0 ? "btncheckout mb-3 d-none" : "btncheckout mb-3"}/>
                  </div>
                  
                </div>
                <div class="row">
                  <div class="col table-responsive">
                    <Table striped bordered size="sm">
                      <thead>
                        <tr>
                          <th className="fondoi funeteblanca">{t("tbl21")} </th>
                          <th className="fondoi funeteblanca">{t("tbl22")} </th>
                          <th className="fondoi funeteblanca">{t("tbl2")} </th>
                          {/* <th className="fondoi funeteblanca">DOCUMENT TYPE </th> */}
                          {/* <th className="fondoi funeteblanca">IDENTIFICATION </th> */}
                          {/* <th className="fondoi funeteblanca">EMAIL</th> */}
                          <th className="fondoi funeteblanca">{t("tbl23")} </th>
                          <th className="fondoi funeteblanca">{t("tbl24")} </th>
                          <th className="fondoi funeteblanca">{t("tbl25")}</th>
                          <th className="fondoi funeteblanca">{t("tbl26")}</th>
                          <th className="fondoi funeteblanca">{t("tbl27")}</th>
                          <th className="fondoi funeteblanca">{t("tbl28")}</th>
                          {/* <th className="fondoi funeteblanca">OPTIONS</th> */}
                        </tr>
                      </thead>
                      <tbody>
                      {
                        applicantspost.map((applicant) => {
                          return (
                            <>
                              <tr>
                                <td> 
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" 
                                      onClick={sumpay.bind(this, {
                                                                      applicant: applicant.applicant.id, 
                                                                      offer: applicant.offer.id, 
                                                                      company: applicant.offer.company.id
                                                                    })}
                                    />
                                  </div>
                                </td>
                                <td>{applicant.applicant.first_name ? applicant.applicant.first_name : ""}</td>
                                <td>{applicant.applicant.title ? applicant.applicant.title : ""}</td>
                                <td>
                                  {applicant.applicant.studies.map((studi) => {
                                    return(
                                      <>
                                        <div class="row">
                                          <div class="col">
                                            ({studi.name} - {studi.level_study.description} - {studi.duration_time} - {studi.unit_measurement.name})
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })}
                                </td>
                                <td>
                                {applicant.applicant.experiences.map((exp) => {
                                    return(
                                      <>
                                        <div class="row">
                                          <div class="col">
                                            ({exp.position.name} - {exp.duration_time} - {exp.unit_measurement.name} - {exp.company.name})
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })}
                                </td>
                                <td>
                                {applicant.applicant.languages.map((exp) => {
                                    return(
                                      <>
                                        <div class="row">
                                          <div class="col">
                                            ({exp.language.name} - {exp.level.description})
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })}
                                </td>
                                <td>{applicant.applicant.country_location ? applicant.applicant.country_location.name : ""}</td>
                                <td>{applicant.applicant.civil_status ? applicant.applicant.civil_status.description : ""}</td>
                                <td>{applicant.applicant.gender ? applicant.applicant.gender.name : ""}</td>
                                {/* <td>
                                  <div class="col-5 col-sm-5 col-md-3">
                                    {" "}
                                    <a
                                      href="javascript:void(0)"
                                      title="Ver Curriculum"
                                      onClick={() => {
                                        seeApplDetails({
                                          id: applicant.applicant.id
                                        });
                                      }}
                                    >
                                      {"   "}
                                      <img
                                        width="30"
                                        height="30"
                                        class=" mx-3"
                                        src={ver}
                                      />{" "}
                                    </a>
                                  </div>
                                </td> */}
                              </tr> 
                            </>
                          );
                        })
                      }
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl33")}</button>
            </div>
          </div>
        </div>
      </div>
      {/* end modal list postulated applicants */}


      {/* Modal comments */}
      <div
        class="modal fade"
        id="modalOfetas"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          .
          <div class="modal-content ">
            <div class="modal-header modalheader">
              <div class="container">
                <div class="form-group mb-1 text-center ">
                  <img
                    className=" mt-2 img-fluid"
                    width="25px"
                    height="25px"
                    src={usericon}
                  />
                </div>
                <div class="form-group mb-2 text-center colormodal">
                  {t("ttl5")}
                </div>
              </div>

              <button
                id="clocedModal"
                type="button"
                class="btn text-white colormodal"
                data-bs-dismiss="modal"
                aria-label="Close"

              >X</button>
            </div>

            <div className="linea lineamovil"></div>
            <div class="modal-body">
              <div className="anyClass anyClassMovil">
                {comentarios.map((i) => {
                  if (i.type == 1) {
                    return (
                      <>
                        <div class="form-group mb-2 text-left mt-2 ">
                          <div class="row  my-1 ">
                            <div class="col-2 col-sm-2 col-md-2 ">
                              <img
                                className=" mt-2 img-fluid ingMpvilchat"
                                width="40px"
                                height="40px"
                                src={userchat}
                              />
                            </div>

                            <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                              <div className="txtSmallGris">
                              {i.name}
                              </div>

                              <p className="text-left"> {i.comment} </p>
                              <div class="txtSmallGris"> {i.created_at} </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div class="form-group mb-2  mt-2 ">
                          <div class="row  my-1 justify-content-end">
                            <div class=" col-5 col-sm-5 col-md-5 text-end mt-1 globo">
                              <div className="mt-2 txtSmallGris">
                              {i.name}
                              </div>

                              <p className="">
                              {i.comment}
                              </p>
                              <div class="txtSmallGris"> {i.created_at} </div>
                            </div>

                            <div class="col-2 col-sm-2 col-md-2 ">
                              <img
                                className=" mt-2 img-fluid ingMpvilchat"
                                width="40px"
                                height="40px"
                                src={userchat}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </div>

            <div class="modal-footer modalheader ">
              <div class="container">
                <div class="row  my-1 ">
                  <div class="col-10 ">
                    <input
                      id="mensaje"
                      type="text"
                      name="mensaje"
                      className="mt-1 form-control imputTextModal"
                      aria-describedby="emailHelp"
                      placeholder={t("lbl35")}
                    />
                  </div>

                  <div class="col-2 text-left ">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        send();
                      }}
                    >
                      <img
                        className=" mt-2 img-fluid"
                        width="20px"
                        height="20px"
                        src={sendicon}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>   
      {/* end modal comments */}
    </>
  )

}

export default OfertasCompany
