import React, { Component } from "react";

import RecentApplicants from "../components/RecentApplicants"
import RecentCompanies from "../components/RecentCompanies"
import DemandReport from "../components/DemandReport"
import AppliSkillsReport from "../components/AppliSkillsReport"


const DashboardAdmin = (props) => {

    return (
        <>
            <div class="container">
                <div class="row text-center mt-2">
                    <div class="col-6" data-bs-spy="scroll">
                        <RecentApplicants changeOpc={props.changeOpc} history={props.history} />
                    </div>
                    <div class="col-6" data-bs-spy="scroll">
                        <RecentCompanies changeOpc={props.changeOpc} history={props.history} />
                    </div>
                </div>
                <div class="row text-center mt-2">
                    <div class="col-6" data-bs-spy="scroll">
                        <DemandReport changeOpc={props.changeOpc} history={props.history} />
                    </div>
                    <div class="col-6" data-bs-spy="scroll">
                        <AppliSkillsReport changeOpc={props.changeOpc} history={props.history} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardAdmin;