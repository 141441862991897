import React, { Component } from "react";

import axios from "axios";

import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

class PrintApplicantFormat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applicant: [],
      id_type: [],
      countr: [],
      depart: [],
      applicantTem: [],
      countr_loc: [],
      depart_loc: [],
      languages_applicant: [],
      social_networks: [],
      studies: [],
      experiences: [],

      status: 0,
      errors: [],

      comentarios: [],
    };
  }

  getApplicant = async (id) => {
    await axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/applicants/${id}`)
    .then((res) => {
        console.log(res);
        if (!res.data.status) {
          this.setState({ errors: res.data.error });
          return
        }
        this.setState({ applicant: res.data.data });
        this.setState({ id_type: this.state.applicant.identification_type.description });
        this.setState({ countr: this.state.applicant.country.name });
        this.setState({ depart: this.state.applicant.department.name });
        this.setState({ countr_loc: this.state.applicant.country_location.name });
        this.setState({ depart_loc: this.state.applicant.department_location.name });
        this.setState({ status: this.state.applicant.status });
        // listas
        this.setState({ languages_applicant: this.state.applicant.languages_applicant });
        this.setState({ social_networks: this.state.applicant.applicant_social_networks });
        this.setState({ studies: this.state.applicant.studies });
        this.setState({ experiences: this.state.applicant.experiences });
        
      })
      .catch((error) => console.log(error));
  };

  async componentWillMount() {
    if (navigator.onLine) {
      await this.getApplicant(1);
      // await this.listChat()
    } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Sin conexión a internet",
    //     confirmButtonColor: "#071b30",
    //     confirmButtonText: "Aceptar",
    //   });
    }
  }

  render() {
    // select percent level languages
    var level = {
      1 : "17",
      2 : "33",
      3 : "50",
      4 : "67",
      5 : "85",
      6 : "100",
    }
    var varcolor = {
      1 : "Secondary",
      2 : "danger",
      3 : "warning",
      4 : "info",
      5 : "success",
      6 : "success",
    }
    var varcolord = {
        1 : "bg-danger",
        2 : "bg-danger",
        3 : "bg-warning",
        4 : "bg-info",
        5 : "bg-success",
        6 : "bg-success",
      }
    return (
      <>
        <Image src={this.state.applicant.img_perfil || 'https://www.focusedu.org/wp-content/uploads/2018/12/circled-user-male-skin-type-1-2.png'} roundedCircle fluid/>
      </>
    );
  }
}
export default PrintApplicantFormat;
