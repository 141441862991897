import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import withFirebaseAuth from "react-with-firebase-auth";
// import firebase from "firebase/app";
// import "firebase/auth";
// import firebaseConfig from "./firebaseConfig";
// import MicrosoftLogin from "react-microsoft-login";
import axios from "axios";
import Swal from "sweetalert2";
// import { Redirect, BrowserRouter, Switch } from "react-router-dom";
import UserProfile from "./UserProfile";
// import Header from "../Header/Header";

import svgFacebook from "../img/facebook.svg";
import icoGoogle from "../img/google_ico.png";
// import imgform from "../img/imgfroms.png";
import geojobscanada from "../img/formhrh.png";

// import Loginform from "../Login/Loginform"

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// const firebaseApp = !firebase.apps.length
//   ? firebase.initializeApp(firebaseConfig)
//   : firebase.app();

const Login = (props) => {
  const [t, i18n] = useTranslation("login")

  const [ chekContras, setchekContras ] = useState(false);
  const [ chekTerminos, setchekTerminos ] = useState(false);
  const [ login, setlogin ] = useState(false);
  
  const [ form, setform ] = useState({
                                      nombres: "",
                                      correo: "",
                                      contra1: "",
                                      contra2: "",
                                    });

  const [ user, setuser ] = useState([]);
  
  // const [ loginGoogle, setloginGoogle ] = useState([]);
  // const [ signInWithGoogle, setsignInWithGoogle ] = useState([]);
  // const [ signInWithFacebook, setsignInWithFacebook ] = useState([]);
  // const [ signOut, setsignOut ] = useState([]);

  const {
    // user,
    // signOut,
    signInWithGoogle,
    signInWithFacebook,
    // createUserWithEmailAndPassword,
    // signInWithEmailAndPassword,
    // signInWithTwitter,
    // signInWithGithub,
  } = props;

  // state = {
  //   chekContras: false,
  //   chekTerminos: false,
  //   login: false,

  //   form: {
  //     nombres: "",
  //     correo: "",
  //     contra1: "",
  //     contra2: "",
  //   },

  //   user: {
  //     id: "",
  //   },

  //   login: {
  //     correo: "",
  //     contrasena: "",
  //   },
  // }
  // const [ login, setlogin ] = useState({id:""});

  const iniciaSesionGoogle = (nombre, correo, id) => {
    const user = { id: id, name: nombre, email: correo }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/auth/social/google/login`, user)
      .then((res) => {
        console.log(res);
        if (res.data.status == true) {
          UserProfile.setToken(res.data.data.token);
          props.history.push("/home");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "to Log in",
          });
        }
      });
  }

  const check = async () => {
    var contra1 = document.getElementById("txtContra");
    var contra2 = document.getElementById("txtContra2");

    if (chekContras != false) {
      setchekContras(false)
    } else {
      setchekContras(true)
    }
  }

  const checkTerminos = async () => {
    if (chekTerminos != false) {
      setchekTerminos(false)
    } else {
      setchekTerminos(true)
    }
  }

  const crearCuenta = async () => {
    props.history.push("/register");
  }
  
  const iniciarSesion = async (value) => {
    const user = {
      email: value.email,
      password: value.password,
    }
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/auth/login`, user)
        .then((res) => {
          if (res.data.status == true) {
            if (res.data.data.user.status.id == 2) {
              activeAcount(res)
            }else{
              loadSessionData(res)
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "could not login",
            });
          }
        })

        .catch((error) => {
          console.log(error.response);
        });
  }

  const loadSessionData = (res) => {
    switch (res.data.data.user.type.id) {
      case 1:
        localStorage.setItem('token',res.data.data.token);
        localStorage.setItem('id',res.data.data.user.id);
        localStorage.setItem('user',JSON.stringify(res.data.data.user));
        props.history.push("/admin")
      break;

      case 2:
        localStorage.setItem('token',res.data.data.token);
        localStorage.setItem('id',res.data.data.user.id);
        localStorage.setItem('user',JSON.stringify(res.data.data.user));
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/has_profile_applicant`,
        
        {      
          headers: {
            Authorization: `Bearer  ${res.data.data.token}`,
          }
    
        }
        
        )
        .then((res) => {
          if(res.data.status===true){
            props.history.push("/applicant")
          }else{
            props.history.push("/registerapplicant")
          }
        });
      break;

      case 3:
      localStorage.setItem('user',JSON.stringify(res.data.data.user));
      localStorage.setItem('token',res.data.data.token);
      localStorage.setItem('id',res.data.data.user.id);
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/has_companies`,
        
        {      
          headers: {
            Authorization: `Bearer  ${res.data.data.token}`,
          }
    
        }
        
        )
        .then((res) => {
          if(res.data.status===true){
            props.history.push("/company")
          }else{
            props.history.push("/registercompany")
          }
        });
      break;
    }
  } 

  const activeAcount = (res) => {
    Swal.fire({
      title: t("lbl14"),
      text: t("lbl15"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t("lbl16")
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus(res)
        loadSessionData(res)
      }
    })
  }

  const updateStatus = (user) => {
    const valuesupdate = {
      name: user.data.data.user.name,
      email: user.data.data.user.email,
      type: 2,
      status: 1
    }
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/users/${user.data.data.user.id}`,
        valuesupdate
      )
      .then(() => {
        Swal.fire({
          icon: "success",
          title: t('msg6'),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      })
      .catch((error) => console.log(error));
  }

  const handleChangeLogin = (e) => {
    setlogin({ ...login, [e.target.name]: e.target.value, })
  }

  // componentDidMount() {
  // useEffect(() => {
    // document.getElementById("salirRedes").click();
  // }, [])
  // }

  
    // const auth = firebase.auth()

    return (
      <div>
        <section class="vh-90">
          <div class="container h-100">
            <div class="row d-flex align-items-center justify-content-center h-100">
              <div class="col-md-12 col-lg-12 col-xl-12">

                <div class="square xyz-in" xyz="fade up big">

                  <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().email("Invalid email").required("Field is required"),
                    password: Yup.string().required("Field is required"),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    iniciarSesion(val)
                  }}
                  render={({ errors, status, touched }) => (
                   <Form>
                    <div class="form-group mb-4">

                      <Field
                        id="email"
                        name="email"
                        type="email"
                        class="form-control imputText"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                      />


                        <ErrorMessage
                          name="email"
                          component="div"
                          className="msgerror mx-2"
                        />

                    </div>

                    <div class="form-group mb-4">

                      <Field
                        id="password"
                        name="password"
                        type={chekContras ? "text" : "password"}
                        class="form-control imputText"
                        aria-describedby="emailHelp"
                        placeholder="Password"

                      />

                        <ErrorMessage
                          name="password"
                          component="div"
                          className="msgerror mx-2"
                        />
                    </div>

                    <div class="d-flex   mb-4">
                      <div class=" left">
                        <input
                          id="checTerminos"
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="form1Example4"
                          onClick={() => check()}
                        />
                        <label
                          class="leftLabel  form-check-label inputCheckbox"
                          for="form1Example4"
                        >
                          See password
                        </label>
                      </div>
                    </div>


                    <div class="row align-items-center"> 
                      <div class="col mt-1">
                          <button
                            type="submit"
                            class="btn btn-warning  btn-block  buttons "
                          >
                            Log in
                          </button>
                      </div>
                      <div class="col ">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                          onClick={() => crearCuenta()}
                          >
                            Register
                        </button>
                      </div>
                    </div>
                  </Form>
                  )}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  
}

// const firebaseAppAuth = firebaseApp.auth();

// const providers = {
//   googleProvider: new firebase.auth.GoogleAuthProvider(),
//   facebookProvider: new firebase.auth.FacebookAuthProvider(),
// }

// export default withFirebaseAuth({
//   providers,
//   firebaseAppAuth,
// })(Login);


export default  Login