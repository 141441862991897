import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import UserProfile from "../Login/UserProfile";
import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";


const RegisterActivity = (props) => {

    const [t, i18n] = useTranslation("formcmnps")
    const [ loadd, setloadd ] = useState(0)

    const saveActivity = (values,resetForm) =>{
        setloadd(1)
        axios
        .post(
            `${process.env.REACT_APP_BASE_URL}/api/attrs/activities`, values,
            {      
            headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`, 
                }
            }
        )
        .then((res) => {
            setloadd(0)
            resetForm()
            props.addNewActivity(res.data.data)
        })
        .catch((error) => console.log(error));
    }


    return (
        <>
            <div>
                <Formik
                  initialValues={{
                    id: "",
                    name: ""
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");
                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}

                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(t("fr"))
                  })}

                  onSubmit={(val, { resetForm }) => {
                    val.status = 2
                    saveActivity(val, resetForm);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container mt-3">
                      <div class="form-group mb-4">
                        <label for="name" class="form-label">{t("lbl1")}</label>
                        <Field
                          id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl1")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      {
                        loadd == 0 ?
                        <div class="divider d-flex align-items-center my-2">
                            <button
                            type="submit"
                            class="btn btn-warning  btn-block  buttons "
                            >
                            {t("lbl2")}
                            </button>
                        </div>
                        : 
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                      }
                    </Form>
                  )}
                />
            </div>
        </>
    );
}

export default RegisterActivity;