import React, { Component } from "react";

import DatosAdmin from "./DatosAdmin"
import ServiciosAdmin from "./ServiciosAdmin"
import UsersAdmin from "./UsersAdmin"
import EmpresasAdmin from "./EmpresasAdmin"
import OfertasLaboralesAdmin from "./OfertasLaboralesAdmin"
import ApplicantsAdmin from "./ApplicantAdmin"
import DetailsApplicantsAdmin from "./DetailsApplicantsAdmin"
import DashboardAdmin from "./DashboardAdmin"
import SalesAdmin from "./SalesAdmin"
import NotificationList from "../../components/NotificationList"


const HomeAdmin = (props) => {

    // #####################################
    const renderpage = ({opc, data, type}) => {
        if (opc ==  21) return <DetailsApplicantsAdmin changeOpc={props.changeOpc} dataxtr={data} history={props.history} />
        if (opc ==  6) return <DatosAdmin history={props.history} />
        if (opc ==  7) return <ServiciosAdmin history={props.history} />
        if (opc ==  8) return <UsersAdmin history={props.history} />
        if (opc ==  3) return <EmpresasAdmin history={props.history} changeOpc={props.changeOpc} dataxtr={data} timeseg={props.timeseg} />
        if (opc ==  9) return <OfertasLaboralesAdmin history={props.history} changeOpc={props.changeOpc} dataxtr={data} timeseg={props.timeseg} />
        if (opc ==  2) return <ApplicantsAdmin changeOpc={props.changeOpc} history={props.history} />
        if (opc ==  1) return <DashboardAdmin changeOpc={props.changeOpc} history={props.history} />
        if (opc ==  4) return <SalesAdmin changeOpc={props.changeOpc} history={props.history} dataxtr={data} type={type} timeseg={props.timeseg} />
        if (opc ==  10) return <NotificationList changeOpc={props.changeOpc} history={props.history} dataxtr={data} type={type} timeseg={props.timeseg} />
    }

    return (
        <>
            <div>
                {
                    renderpage({opc: props.opc, data: props.dataxtr, type: props.type})
                }
            </div>
        </>
    )
}

export default HomeAdmin;