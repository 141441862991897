import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import axios from "axios";
import ReactPDF from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';

import userchat from "../../img/userchat.png";
import listplas from "../../img/listplas.png";
import imgmodal from "../../img/imgmodal.png";
import sendimg from "../../img/send.png";
import userimg from "../../img/user.png";

import { Card, Container, Alert, Image, ProgressBar } from "react-bootstrap";

import PrintApplicant from "./CompanyPrint/PrintApplicant";
import UserProfile from "../../Login/UserProfile";


const DetailsApplicantsCompany = (props) => {

const [t, i18n] = useTranslation("detailapplcomp")
// i18n.changeLanguage("")

  const [ applicant, setapplicant ] = useState([]);
  const [ id_type, setid_type ] = useState([]);
  const [ countr, setcountr ] = useState([]);
  const [ depart, setdepart ] = useState([]);
  const [ countr_loc, setcountr_loc ] = useState([]);
  const [ depart_loc, setdepart_loc ] = useState([]);
  const [ languages_applicant, setlanguages_applicant ] = useState([]);
  const [ social_networks, setsocial_networks ] = useState([]);
  const [ studies, setstudies ] = useState([]);
  const [ experiences, setexperiences ] = useState([]);
  const [ applicant_activities, setapplicant_activities ] = useState([]);
  
  const [ status, setstatus ] = useState(0);
  const [ errorss, seterrorss ] = useState(0);
  const [ comentarios, setcomentarios ] = useState([]);
  
  
  const [ messagechat, setmessagechat ] = useState("");

  const [ selectdate, setselectdate ] = useState(new Date());
  const [ linkmeeting, setlinkmeeting ] = useState("");
  



  const getApplicant = async (id) => {
    await axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/applicants/${id}`,{
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      }})
    .then(async (res) => {
      if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        if (!res.data.status) {
          seterrorss(res.data.error)
          return
        }
        setapplicant(res.data.data)
        setid_type(res.data.data.identification_type.description)
        setcountr(res.data.data.country.name)
        setdepart(res.data.data.department.name)
        setcountr_loc(res.data.data.country_location.name)
        setdepart_loc(res.data.data.department_location.name)
        setstatus(res.data.data.status)
        // listas
        setlanguages_applicant(res.data.data.languages_applicant)
        setsocial_networks(res.data.data.applicant_social_networks)
        setstudies(res.data.data.studies)
        setexperiences(res.data.data.experiences)
        setapplicant_activities(res.data.data.applicant_activities)
        
      })
      .catch((error) => console.log(error));
  };


  // print curriculum
  const printCurriculum = (applicant) => {
    return ReactDOM.render(
      <PDFViewer style={{width:"100%", height:"100vh"}}>
        <PrintApplicant applicant={applicant.applicant}/>
      </PDFViewer>
    , document.getElementById('printing'))
  }

  const listChat = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/commentcompanyapplicant/${applicant.id}`,{
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcomentarios(res.data.data.comment_company_applicant)
      });
  }

  const send = () => {
    if (messagechat != "") {
      var data = {
        comment: messagechat,
        to: props.dataxtr,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/comment_company_applicant`, data, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            listChat();
          }
        })
        .catch((error) => console.log(error));
        setmessagechat("")
    }
  };

  const sendautomsg = () => {
    var message = t("msg3") + " " + selectdate + " " + t("msg4") + "-- " + linkmeeting
    sendmsgauto(message)
  }

  const sendmsgauto = (msg) => {
    if (msg != "") {
      var data = {
        comment: msg,
        to: props.dataxtr,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/comment_company_applicant`, data, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            listChat();
          }
        })
        .catch((error) => console.log(error));
        setmessagechat("")
    }
  };

  const videotest = async () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/schedulemeet`, [], {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => console.log(error));
  }

  useEffect(async () => {
    if (navigator.onLine) {
      await getApplicant(props.dataxtr);
      // await listChat()
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

    // select percent level languages
    var level = {
      1 : "17",
      2 : "33",
      3 : "50",
      4 : "67",
      5 : "85",
      6 : "100",
    }
    var levellabel = {
      1 : "A1",
      2 : "A2",
      3 : "B1",
      4 : "B2",
      5 : "C1",
      6 : "C2",
    }
    var varcolor = {
      1 : "Secondary",
      2 : "danger",
      3 : "warning",
      4 : "info",
      5 : "success",
      6 : "success",
    }
    return (
      <>
        <Container>
          {
            errorss ? 
              errorss.map((variant, idx) => (
                <Alert key={idx} variant="warning">
                  {variant}
                </Alert>
              )) : ""
          }
          
          <Card>
            <Card.Body>
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-2 col-sm-12">
                    <Image src={typeof applicant.img_perfil === 'string' ? applicant.img_perfil : userchat } roundedCircle fluid/>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <div class="row mt-4">
                      <h5 class="fw-bold col">{t("ttl1")}</h5>
                      
                      <div class="col text-end">
                        <div class="text-left ">
                          <div className="mb-4">
                            <div className="container ">
                              <div class="row  my-1 ">
                                <div class="col-11 text-left txtSmallAzulModal ">
                                  <div class="dropdownn">
                                    <button
                                      class="btn btn-lightModal buttonSmallAzulModal"
                                      type="button"
                                      id="dropdownMenuButton2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      data-bs-auto-close="inside"
                                      onClick={()=>(listChat())}
                                    >
                                      {" "}
                                      <span>
                                        <img
                                          className="img-fluid "
                                          width="12px"
                                          height="12px"
                                          src={listplas}
                                        />{" "}
                                      </span>{" "}
                                      {t("lbl1")}
                                    </button>
                                    <div
                                      class="dropdown-menu fondoAzulModal"
                                      aria-labelledby="dropdownMenuClickableOutside"
                                    >
                                      <div class="container">
                                        <div class="form-group mb-1 text-center ">
                                          <img
                                            className=" mt-2 img-fluid"
                                            width="20px"
                                            height="20px"
                                            src={userimg}
                                          />
                                        </div>
                                        <div class="form-group mb-2 text-center ">
                                          {t("lbl2")}
                                        </div>
                                      </div>
                                      <div className="linea lineamovil"></div>
                                      <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                                        <div className="anyClass anyClassMovil" id="topabajo">
                                          {comentarios.map((i) => {
                                            if (i.type == 2) {
                                            return (
                                                <>
                                                <div class="form-group mb-2 text-left mt-2 " key={i.id}>
                                                    <div class="row  my-1 ">
                                                    <div class="col-2 col-sm-2 col-md-2 ">
                                                        <img
                                                        className=" mt-2 img-fluid ingMpvilchat"
                                                        width="50px"
                                                        height="50px"
                                                        src={userchat}
                                                        />
                                                    </div>

                                                    <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                                                        <div className="txtSmallGris">
                                                        {i.name}
                                                        </div>

                                                        <p className="text-left">
                                                          { i.comment.split('--')[0] } 
                                                        </p>
                                                        <a target="_blank" class="d-block"  href={ (i.comment.split('--')[1] ? i.comment.split('--')[1] : "") }> {i.comment.split('--')[1] ? i.comment.split('--')[1] : ""} </a>
                                                        <div class="txtSmallGris"> {i.created_at} </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </>
                                            );
                                            } else {
                                            return (
                                                <>
                                                <div class="form-group mb-2  mt-2 ">
                                                    <div class="row  my-1   justify-content-end    ">
                                                    <div class=" col-5 col-sm-5 col-md-5 text-end mt-1 globo">
                                                        <div className="mt-2 txtSmallGris">
                                                        {i.name}
                                                        </div>

                                                        <p className="">
                                                          { i.comment.split('--')[0] } 
                                                        </p>
                                                        <a target="_blank" class="d-block" href={ (i.comment.split('--')[1] ? i.comment.split('--')[1] : "") }> {i.comment.split('--')[1] ? i.comment.split('--')[1] : ""} </a>
                                                        <div class="txtSmallGris"> {i.created_at} </div>
                                                    </div>

                                                    <div class="col-2 col-sm-2 col-md-2 ">
                                                        <img
                                                        className=" mt-2 img-fluid ingMpvilchat"
                                                        width="50px"
                                                        height="50px"
                                                        src={userchat}
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                </>
                                            );
                                            }
                                        })}
                                        </div>
                                      </div>

                                      <div class="container">
                                        <div class="row  my-1 ">
                                          <div class="col-9 ">
                                            <input
                                              id="mensaje"
                                              type="text"
                                              name="mensaje"
                                              className="mt-1 form-control imputTextModal"
                                              aria-describedby="emailHelp"
                                              placeholder={t("lbl3")}
                                              value={messagechat}
                                              onChange={(e)=>setmessagechat(e.target.value)}
                                            />
                                          </div>

                                          <div class="col-2 text-center ">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                send();
                                              }}
                                            >
                                              <img
                                                className=" mt-2"
                                                width="25px"
                                                height="25px"
                                                src={sendimg}
                                              />
                                            </a>
                                          </div>

                                          <div class="col-1 text-center " data-bs-toggle="tooltip" data-bs-placement="top" title={t("lbl8")}>
                                            <a href="javascript:void(0)" class="text-light"
                                              // onClick={()=>videotest()}
                                              data-bs-toggle="modal" data-bs-target="#mdlmeeting"
                                            >
                                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-people-fill mt-2" viewBox="0 0 16 16">
                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("tbl1")}</div>
                              <div class="col-6 text-center">{applicant.first_name}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("tbl2")}</div>
                              <div class="col-6 text-center">{applicant.second_name}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("tbl3")}</div>
                              <div class="col-6 text-center">{applicant.first_lastname}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("tbl4")}</div>
                              <div class="col-6 text-center">{applicant.second_lastname}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("tbl5")}</div>
                              <div class="col-6 text-center">{id_type}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("tbl10")}</div>
                              <div class="col-6 text-center">{applicant.gender ? applicant.gender.name : "" }</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("tbl6")}</div>
                              <div class="col-6 text-center">{applicant.identification}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("tbl7")}</div>
                              <div class="col-6 text-center">{countr}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("tbl8")}</div>
                              <div class="col-6 text-center">{depart}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("tbl9")}</div>
                              <div class="col-6 text-center">{applicant.email}</div>
                            </div>
                          </li>
                      </ul>
                    </div>
                    
                    <div class="row mt-5">
                      <h5 class="fw-bold">{t("ttl2")}</h5>
                      {languages_applicant.map((i) => {
                        return <>
                          <div key={i.language.name} class="row mt-5">
                            <div class="col-3">
                             { i.language.name }
                            </div>
                            <div class="col-9">
                            <ProgressBar now={level[i.level.id]} label={levellabel[i.level.id]} variant={varcolor[i.level.id]} />
                            </div>
                          </div>
                        </>;
                      })}

                    </div>

                    <div class="row mt-5">
                        <h5 class="fw-bold">{t("ttl3")}</h5>
                        {social_networks.map((i) => {
                          return <>
                            <div key={i.network} class="row mt-5">
                              <div class="col-3">
                                {i.network}
                              </div>
                              <div class="col-9">
                                <p>{i.nickname}</p>
                              </div>
                            </div>
                          </>;
                        })}
                      
                    </div>
                  </div>

                  {/* lado derecho */}
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <div class="row mt-5">
                      <h5 class="fw-bold">{t("ttl4")}</h5>
                      <div class="row">
                        <div class="col">
                          {applicant.description}
                        </div>
                      </div>
                      <div class="row">
                        <h5 class="fw-bold">{t("ttl5")}</h5>
                        <div class="col">
                          {depart_loc + " - " + countr_loc}
                        </div>
                      </div>
                    </div>

                    <div class="row mt-5">
                    <h5 class="fw-bold">{t("ttl9")}</h5>
                      <div class="col">
                        {
                          applicant_activities.map((item) => (
                              item.activity.name + " - " 
                            ))
                        }
                      </div>
                    </div>

                    <div class="row mt-5">
                      <h5 class="fw-bold">{t("ttl6")}</h5>
                      <div class="col">
                        <table class="table table-responsive ">
                          <tbody>
                            {studies.map((i) => {
                              return <>
                                    <tr key={i.name}>
                                      <td>{i.name}</td>
                                      <td>
                                        <p class="fw-bold" > {t("lbl5")} </p> {i.starting_date}
                                        <p class="fw-bold" > {t("lbl6")} </p> {i.finishing_date}
                                      </td>
                                      <td>{i.inprogress == 1 ? "completed" : ""}</td>
                                      <td>{i.educational_institution.name}</td>
                                      <td>{i.level_study.description}</td>
                                    </tr>
                              </>;
                            })}
                            
                          </tbody>
                        </table>
                      </div>

                    </div>

                    <div class="row mt-5">
                      <h5 class="fw-bold">{t("ttl7")}</h5>
                      <div class="col">
                        <table class="table table-responsive ">
                          <tbody>
                            {experiences.map((i) => {
                              return <>
                                    <tr key={i.position.name}>
                                      <td>{i.position.name}</td>
                                      <td>{i.duration_time + " - " + i.unit_measurement.name}</td>
                                      <td>{i.company.name}</td>
                                      <td>{i.initial_year}</td>
                                      <td></td>
                                    </tr>
                              </>;
                            })}
                            <tr>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row mt-5">
                      <h5 class="fw-bold">{t("ttl8")}</h5>
                      <div class="p-3">
                        <div class="ratio ratio-16x9">
                          <iframe src={applicant.video_presentation ? applicant.video_presentation : ""} title="YouTube video" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>

                    {/* <div class="row mt-5 justify-content-center">
                      <div class="col-auto">
                        <button type="submit" class="btn btn-warning  btn-block  buttons"
                        data-bs-toggle="modal" data-bs-target="#mdlprinting"
                        onClick={() => {
                          printCurriculum({
                            applicant: applicant
                          });
                        }}
                        >
                          To print
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
        {/* modal */}
        <div class="modal fade " id="mdlprinting"  aria-labelledby="mdlprintingLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-body">
                <div id="printing" style={{minHeight:"100vh"}}></div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl4")}</button>
              </div>
            </div>
          </div>
        </div>

        {/* modal schedule meeting */}
        <div class="modal fade " id="mdlmeeting"  aria-labelledby="mdlmeetingLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h3> {t("ttl10")} </h3>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">

                            <div class="form-group mb-4">
                                <label for="programdate" class="form-label"> {t("lbl7")} </label>
                                <input
                                    id="programdate"
                                    name="programdate"
                                    type="date"
                                    class="form-control imputText"
                                    placeholder="asda"
                                    value={selectdate}
                                    onChange={(e)=>setselectdate(e.target.value)}
                                />
                            </div>

                            <div class="form-group mb-4">
                                <label for="linkmeeting" class="form-label"> {t("msg2")} </label>
                                {/* <a target="_blank" href="https://meet.google.com/new"> {t("lbl9")} </a> */}
                                <input
                                    id="linkmeeting"
                                    name="linkmeeting"
                                    type="text"
                                    class="form-control imputText"
                                    placeholder="https://...com/"
                                    value={linkmeeting}
                                    onChange={(e)=>setlinkmeeting(e.target.value)}
                                />
                            </div>

                            <div class="form-group mb-4">
                                <button onClick={()=>sendautomsg()} type="button" class="btn btn-success" data-bs-dismiss="modal">{t("lbl8")}</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
              </div>
              {/* <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl4")}</button>
              </div> */}
            </div>
          </div>
        </div> 

      </>
    );
}
export default DetailsApplicantsCompany;
