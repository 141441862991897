import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import banderacanada from "../img/banderacanada.jpeg";
import bannercanada from "../img/bannercanada.png";
import statistics from "../img/statistics.png";


const Canada = (props) => {

  const [t, i18n] = useTranslation("canada");
// i18n.changeLanguage("")

    return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
          <div class="">
                <div class="row  d-flex align-items-center  h-100">
                  <img src={bannercanada} class="img-fluid" />
              </div>
            </div>

            <div class="row  d-flex align-items-center  h-100 my-4">
              <div class="col-12 col-sm-12 col-md-8 center-block ">
                <div class="letreroWeb mt-3  fs-1 px-5 ">
                  {t("xqcanada")}
                </div>

                <p class="txtPequenoAzul mt-5 px-5">
                  <ul class="vineta">
                    <li>
                      <span class="text fontmont">
                        {t("text1")}
                      </span>
                    </li>
                  </ul>
                </p>

                <p class="txtPequenoAzul mt-5 px-5">
                <ul class="vineta">
                    <li>
                    <span class="text fontmont">
                        {t("text2")}
                      </span>
                    </li>
                  </ul>
                </p>

                <p class="txtPequenoAzul mt-5 px-5">
                <ul class="vineta">
                    <li>
                    <span class="text fontmont">
                        {t("text3")}
                      </span>
                    </li>
                  </ul>
                </p>

                <p class="txtPequenoAzul mt-5 px-5">
                <ul class="vineta">
                    <li>
                    <span class="text fontmont">
                        {t("text4")}
                      </span>
                    </li>
                  </ul>
                </p>

                <p class="txtPequenoAzul mt-5 px-5">
                <ul class="vineta">
                    <li>
                    <span class="text fontmont">
                        {t("text5")}
                      </span>
                    </li>
                  </ul>
                </p>

                <div class="letreroCanada  fs-1 mt-5 px-5 ">
                  {t("text6")}
                </div>


                <p class="txtPequenoAzul mt-5 px-5">
                <ul class="vineta">
                    <li>
                    <span class="text fontmont">
                        {t("text7")} <a target="_blank" href="https://www.larepublica.co/globoeconomia/canada-recibira-mas-de-400000-migrantes-al-ano-la-cifra-mas-alta-en-el-ultimo-siglo-3085711">Visit</a>
                      </span>
                    </li>
                  </ul>
                </p>
              </div>

              <div class="col-12 col-sm-12 col-md-4 center-block person ">
                <img src={banderacanada} class="img-fluid" />
                <img src={statistics} class="img-fluid mt-5" />

              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default Canada;
