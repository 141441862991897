import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import verimg from "../img/ver.png";

const Notifications = (props) => {

    const [ notifications, setnotifications ] = useState([])

    const getNotifications = async () => {
        setnotifications([])
        await axios
        .get(
            `${process.env.REACT_APP_BASE_URL}/api/notifications`,
            {      
            headers: {
                Authorization: `Bearer  ${props.token}`,
                }
            }
        )
        .then((res) => {
            if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
            setnotifications(res.data.data.notifications.filter((itm)=> itm.visible == 1 || itm.visible == "1"))
            props.cantNotifications(res.data.data.total)
        })
        .catch((error) => console.log(error));
    }

    const changeState = (id) => {
        var data = {
            "user": localStorage.getItem('id'),
            "visible": 0
        }
        axios
        .put(
            `${process.env.REACT_APP_BASE_URL}/api/notifications/${id}`, data,
            {
            headers: {
                Authorization: `Bearer  ${props.token}`,
                }
            }
        )
        .then((res) => {
        })
        .catch((error) => console.log(error));
    }

    const redirect = (data) => {
        let type = data.type
        var now = new Date();
        // let rol = localStorage.getItem('userrol')
        if (data.iddata > 0) {
            if (type == 'company' || type == 'commentscompany') {
                props.changeOpc({opc: "3", data: data.iddata, time: now.getMilliseconds()})
            }
            if (type == 'applicants' || type == 'commentsapplicants') {
                props.changeOpc({opc: "21", data: data.iddata})
            }
            if (type == 'job_offers' || type == 'commentsjob_offers') {
                props.changeOpc({opc: "9", data: data.iddata, time: now.getMilliseconds()})
            }
            if (type == 'applicant_pays') {
                props.changeOpc({opc: "4", data: data.iddata, type: "applicants", time: now.getMilliseconds()})
            }
            if (type == 'company_services') {
                props.changeOpc({opc: "4", data: data.iddata, type: "service", time: now.getMilliseconds()})
            }
        }
    }
    
    useEffect(() => {
        getNotifications()
    }, [props.token])

    return (
        <>
            <a class="alingDerecha d-block" href="javascript:void(0)"
                onClick={()=>props.changeOpc({opc: props.opcnotif})}
                >
                <img
                    width="30"
                    height="30"
                    class="icoOpciones mx-3"
                    src={verimg}
                />
            </a>
            <div class="list-group">
                {
                    notifications.map((notif) => (
                        <>
                            <a aria-current="true" key={notif.id}
                                class={notif.visible == 1 ? "list-group-item list-group-item-action list-group-item-primary" : "list-group-item list-group-item-action"}
                                onMouseOver={changeState.bind(this, notif.id)}
                                onClick={()=>redirect({id: notif.id, type: notif.table, iddata: notif.idvalue})}
                            >
                                <div class="d-flex w-100 justify-content-between">
                                    {/* <h5 class="mb-1"></h5> */}
                                    <small>{notif.name} - {notif.description.substr(0, 25)} ...</small>
                                    <small>{ notif.created_at.split('T')[0] }</small>
                                </div>
                                {/* <p class="mb-1">Some placeholder content in a paragraph.</p> */}
                                {/* <small></small> */}
                            </a>
                        </>
                    ))
                }
            </div>
        </>
    );
}

export default Notifications;