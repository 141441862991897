import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

const PaymentCanceled = (props) => {

    const [t, i18n] = useTranslation("payment")

    // const [ notifications, setnotifications ] = useState([])

    useEffect(() => {
        console.log(props);
        Swal.fire({
            title: t("txt2"),
            confirmButtonText: t("lbl1")
          }).then(() => {
            console.log("Close tab ...");
          })
        setTimeout(() => {
          props.history.push("/company");
        }, 3000)
    }, [])

    return (
        <>
            
        </>
    );
}

export default PaymentCanceled;