import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import buscoempleo from "../img/buscoempleo.png";
import buscocandidato from "../img/buscocandidato.png";

class Portal extends Component {
  buscoEmpleo = async () => {
    this.props.history.push("/register");
  };

  buscoCandidato = async () => {
    this.props.history.push("/register");
  };

  render() {
    return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
        <div class="container">
          <div class="row  d-flex align-items-center  h-100">
            <div class="my-5">
              <div class="col-12 col-sm-12 col-md-12 center-block ">
                <div class="text-center letreroWeb  ">¿Qué estás buscando?</div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row  d-flex align-items-center justify-content-center h-100">
            <div class="col-12 col-sm-12 col-md-6 text-center ">
            <div class="text-center letreroWebsmall  ">Busco Candidatos</div>
              <div class="container ">
                <div class=" mt-5  contenAmarillo">
                    <div class="col-12 col-sm-12 col-md-12 text-center">
                      <img src={buscocandidato} class="img-fluid mt-5"/>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12  my-5 text-center">
                    <a
                class="btn btn-light  btn-block my-5  buttonsPortal mx-2"
                href="#"
                onClick={() => {
                  this.buscoEmpleo();
                }}
              >
                Publicar Oferta Laboral
              </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 text-center ">

            <div class="text-center letreroWebsmall  ">Busco Empleo</div>
              <div class="container ">
                <div class=" mt-5  contenAmarillo">
                    <div class="col-12 col-sm-12 col-md-12 text-center">
                      <img src={buscoempleo} class="img-fluid mt-5"/>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12  my-5 text-center">
                    <a
                    onClick={() => {
                      this.buscoCandidato();
                    }}
                    class="btn btn-light  btn-block my-5  buttonsPortal mx-2"
                  >
                    Busco Candidato
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </>
    );
  }
}

export default withRouter(Portal);
