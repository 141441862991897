import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HomeAdmin from "../Pages/HomeAdmin";
import Notifications from "../../../src/components/Notifications.js"

// import logoico from "../../img/logohrh.png";
// import logoico from "../../img/logohrhshort.png";
import homeimg from "../../img/housedoorwhite.png";
import logoico from "../../img/formhrh.png";
import onoff from "../../img/onoff.png";
import espaniol from "../../img/espaniaflag.png";
import ingles from "../../img/canadaflag.png";
import frances from "../../img/franciaflag.png";
import campana from "../../img/campana.png";

import Swal from "sweetalert2";
import UserProfile from "../../Login/UserProfile";

const HeaderAdmin = (props) => {

  const [t, i18n] = useTranslation("hedradm")
  // i18n.changeLanguage(localStorage.getItem('lngselect'))

  const [ user, setuser ] = useState([])
  const [ chekDespliegaMenu, setchekDespliegaMenu ] = useState(false);
  const [ opc, setopc ] = useState("1");
  const [ idreg, setidreg ] = useState(0);
  const [ type, settype ] = useState("service");
  const [ timeseg, settimeseg ] = useState("");
  const [ Cantn, setCantn ] = useState(0);

  const [ chapln, setchapln ] = useState(localStorage.getItem('lngselect'));

  const languages = {
    1 : {
      lang: "en",
      icon: ingles
    },
    2 : {
      lang: "es",
      icon: espaniol
    },
    3 : {
      lang: "fr",
      icon: frances
    }
  }

  const changeLanguage = async (opc) => {
    let ln = languages[opc]
    localStorage.setItem('lngselect', ln.lang)
    localStorage.setItem('lngiconselect', ln.icon)
    setchapln(ln.lang)
    i18n.changeLanguage(localStorage.getItem('lngselect'))
  }

  const salir = async () => {
    Swal.fire({
      title: t("msg1"),
      text: t("msg2"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ok"),
      cancelButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        props.history.push("/");
      }
    });
  }

  // para cambiar pantalla desde dentro 
  const changeOpc = async ({opc=1, data, type, time}) => {
    setidreg(data)
    settype(type)
    settimeseg(time)
    hederClick(opc)
  }

  const hederClick = async (item) => {
    setopc(item)
  };

  const cantNotifications = (cant) => {
    setCantn(cant)
  }

  useEffect(() => {
    setuser(JSON.parse(localStorage.getItem('user')))
    UserProfile.setToken(localStorage.getItem('token'));
    if(!UserProfile.getToken()){
        props.history.push("/login");
    }
    if (localStorage.getItem('lngiconselect') === null) {
      changeLanguage(1)
    }
  }, []);

    return (
      <>
        <header class="header sticky-top">
          <div class="container overflow-visible">
            <div class="row">
              <div class="col-md-3 col-sm-12 mt-4 pb-3">
                <a class="navbar-brand" href="#">
                  <img
                    src={logoico}
                    alt=""
                    width="130"
                    class="img-fluid mx-2"
                  />
                </a>
              </div>

              <div class="col-md-9 col-sm-12 mt-4">
                <div class="row">
                  <div class="col text-end">
                    <a class="nav-link fuente"
                      onClick={() => {
                        hederClick("2");
                      }}
                    >
                      {user.name}
                    </a>
                  </div>
                  <div class="col-1 mx-2">
                    <div class="dropdown dropstart">
                      
                        <span
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          class="position-relative"
                        >
                          <img
                            // className="img-fluid"
                            width="20px"
                            height="20px"
                            src={campana}
                          />
                          {
                            Cantn > 0 ?
                              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {Cantn}
                                <span class="visually-hidden">unread messages</span>
                              </span>
                            : ""
                          }
                        </span>
                  
                      <div
                        class="dropdown-menu fondoAzulModal"
                        aria-labelledby="themes"
                      >
                        <div className="linea lineamovil"></div>
                        <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                          <Notifications token={localStorage.getItem('token')} changeOpc={changeOpc} opcnotif={10} cantNotifications={cantNotifications} history={props.history} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div class="container-fluid">
                      <button
                        class="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span class="navbar-toggler-icon"></span>
                      </button>

                      <div
                        class="navbar-collapse collapse alingend"
                        id="navbarSupportedContent"
                      >
                        <ul class="navbar-nav">
                          <li class="nav-item">
                            <a
                              class={
                                opc === "1"
                                  ? " mx-3 active fuente  barra"
                                  : " mx-3  fuente  barra"
                              }
                              aria-current="page"
                              href="javascript:void(0)"
                              onClick={() => {
                                hederClick("1");
                              }}
                            >
                              <img src={homeimg} alt="" width="20" height="20"  class="text-white" />
                              {/* {t("home")} */}
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              href="javascript:void(0)"
                              class={
                                opc === "2"
                                  ? "   nav-link active fuente mx-3"
                                  : "   nav-link  fuente mx-3"
                              }
                              onClick={() => {
                                hederClick("2");
                              }}
                            >
                              {t("appl")}
                            </a>
                          </li>

                          <li class="nav-item dropdown">
                            <a
                              class={
                                chekDespliegaMenu && opc === "4"
                                  ? "nav-link dropdown-toggle show active fuente mx-3 active"
                                  : "nav-link dropdown-toggle  fuente mx-3"
                              }
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded={
                                chekDespliegaMenu ? "false" : "true"
                              }
                            
                            >
                              {t("companies")}
                            </a>
                            <ul
                              class={
                                chekDespliegaMenu
                                  ? "dropdown-menu show "
                                  : "dropdown-menu"
                              }
                              aria-labelledby="navbarDropdown"
                            >
                              <li class="m-0">
                                <a
                                  class="dropdown-item fuente-link align-items-center"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                      hederClick("3");
                                  }}
                                >
                                  {t("companies")}
                                </a>
                              </li>

                              <li>
                                <hr class="dropdown-divider" />
                              </li>

                              <li class="m-0">
                                <a
                                  class="dropdown-item fuente-link align-items-center"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                    hederClick("9");
                                  }}
                                >
                                  {t("joffers")}
                                </a>
                              </li>
                              <li>
                                <hr class="dropdown-divider" />
                              </li>
                            </ul>
                          </li>

                          <li class="nav-item">
                            <a
                              class={
                                opc === "4"
                                  ? "   nav-link active  fuente mx-3"
                                  : "   nav-link  fuente mx-3"
                              }
                              href="javascript:void(0)"
                              onClick={() => {
                                hederClick("4");
                              }}
                            >
                              {t("sales")}
                            </a>
                          </li>



                          <li class="nav-item dropdown">
                            <a
                              class={
                                chekDespliegaMenu && opc === "4"
                                  ? "nav-link dropdown-toggle show active fuente mx-3 active"
                                  : "nav-link dropdown-toggle  fuente mx-3"
                              }
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded={
                                chekDespliegaMenu ? "false" : "true"
                              }
                            
                            >
                              {t("config")}
                            </a>
                            <ul
                              class={
                                chekDespliegaMenu
                                  ? "dropdown-menu show mr-5 "
                                  : "dropdown-menu mr-5"
                              }
                              aria-labelledby="navbarDropdown"
                            >
                              <li>
                                <a
                                  class="dropdown-item fuente-link align-items-center"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                      hederClick("6");
                                  }}
                                >
                                  {t("data")}
                                </a>
                              </li>

                              <li>
                                <hr class="dropdown-divider" />
                              </li>

                              <li>
                                <a
                                  class="dropdown-item fuente-link align-items-center"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                      hederClick("7");
                                  }}
                                >
                                  {t("servi")}
                                </a>
                              </li>


                              <li>
                                <hr class="dropdown-divider" />
                              </li>

                              <li>
                                <a
                                  class="dropdown-item fuente-link align-items-center"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                      hederClick("8");
                                  }}
                                >
                                  {t("users")}
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li class="nav-item dropdown">
                            <div class="text-end fuente">
                              <a class="btn text-white text-white dropdown-toggle" href="#" role="button" id="dropdownLanguage" data-bs-toggle="dropdown" aria-expanded="false">
                                {/* {localStorage.getItem('lngselect')} */}
                                <img
                                  width="25"
                                  height="17"
                                  class="rounded-circle mx-3"
                                  src={localStorage.getItem('lngiconselect')}
                                />
                              </a>

                              <ul class="dropdown-menu dropdown-menu-cus" aria-labelledby="dropdownLanguage">
                                <li 
                                  onClick={() => {
                                    changeLanguage(1);
                                  }}>
                                    <a class="dropdown-item">
                                      <img
                                        width="25"
                                        height="17"
                                        class="rounded-circle mx-3"
                                        src={ingles}
                                      />
                                    </a>
                                    {/* <a class="dropdown-item">{t("en")}</a> */}
                                </li>
                                <li 
                                  onClick={() => {
                                    changeLanguage(2);
                                  }}>
                                    <a class="dropdown-item">
                                      <img
                                        width="25"
                                        height="17"
                                        class="rounded-circle mx-3"
                                        src={espaniol}
                                      />
                                    </a>
                                    {/* <a class="dropdown-item">{t("es")}</a> */}
                                </li>
                                <li 
                                  onClick={() => {
                                    changeLanguage(3);
                                  }}>
                                    <a class="dropdown-item">
                                      <img
                                        width="25"
                                        height="17"
                                        class="rounded-circle mx-3"
                                        src={frances}
                                      />
                                    </a>
                                    {/* <a class="dropdown-item">{t("fr")}</a> */}
                                </li>
                              </ul>

                            </div>
                          </li>

                          <li class="nav-item">
                            <a
                              class={
                                opc === "9"
                                  ? "   nav-link active  fuente mx-3"
                                  : "   nav-link  fuente mx-3"
                              }
                              href="javascript:void(0)"
                              onClick={() => {
                                salir();
                              }}
                            >
                              <img src={onoff} width="19px"  height="19px"/>
                            </a>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              
            </div>
          </div>
          
        </header>
        <HomeAdmin opc={opc} changeOpc={changeOpc} dataxtr={idreg} type={type} lng={chapln} history={props.history} timeseg={timeseg} />
      </>
    );
}

export default HeaderAdmin;
