import React, { Component } from "react";

import Ofertas  from "../Pages/OfertasCompany";
import Perfil from "../Pages/PerfilCompany"
import Candidatos from "../Pages/CandidatosCompany"
import DetailsApplicantsCompany from "../Pages/DetailsApplicantsCompany"
import NotificationList from "../../components/NotificationList"

const HomeCompany = (props) => {

    const renderpage = ({opc, data, type}) => {
        if (opc ==  2) return <Candidatos changeOpc={props.changeOpc} history={props.history} lng={localStorage.getItem('lngselect')} />
        if (opc ==  21) return <DetailsApplicantsCompany changeOpc={props.changeOpc} dataxtr={props.dataxtr} history={props.history} lng={localStorage.getItem('lngselect')} />
        if (opc ==  1) return <Ofertas history={props.history} lng={localStorage.getItem('lngselect')} />
        if (opc ==  3) return <Perfil history={props.history} lng={localStorage.getItem('lngselect')} />
        if (opc ==  4) return <NotificationList changeOpc={props.changeOpc} history={props.history} type={type} lng={localStorage.getItem('lngselect')} />
    }
        return (
            <>
            <div>
                {
                    renderpage(({opc: props.opc, data: props.dataxtr, type: props.type}))
                }
            </div>
            </>
        );
}

export default HomeCompany