import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";

import Swal from "sweetalert2";

import axios from "axios";

import verimg from "../../img/ver.png";
import imgmodal from "../../img/imgmodal.png";
// import listicon from "../../img/list.png";
// import ReactPaginate from "react-paginate";

const RecentCompanies = (props) => {

const [t, i18n] = useTranslation("recentscomps")
  
  const [ companies, setcompanies ] = useState([]);
  const [ company, setcompany ] = useState([]);

  const list = async () => {
    setcompanies([])
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/companies`, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
          params:{
            page: 1
        }}
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setcompanies( res.data.data.companies )
      })
      .catch((error) => console.log(error));
  };

  
  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

    return (
      <>
        <div class="card">
            <h5 class="card-header">{t('ttl6')}</h5>
            <div class="card-body table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">{t('ttl1')}</th>
                            <th scope="col">{t('ttl7')}</th>
                            <th scope="col">{t('ttl2')}</th>
                            <th scope="col">{t('ttl3')}</th>
                            <th scope="col">{t('ttl4')}</th>
                            <th scope="col">{t('ttl5')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {   
                          companies.map((comp)=>(
                              <tr>
                                  <td>{comp.created_at ? comp.created_at.split('T')[0] : "" }</td>
                                  <td>{comp.nit}</td>
                                  <td>{comp.name}</td>
                                  <td>{comp.department.name}</td>
                                  <td>{comp.phone_number}</td>
                                  <td>
                                      <div class="col-5 col-sm-5 col-md-3">
                                          {" "}
                                          <a
                                            data-bs-toggle="modal"
                                            data-bs-target="#mdlDetailCompany"
                                            href="javascript:void(0)"
                                            title={t('lbl1')}
                                            onClick={() => {
                                              setcompany(comp)
                                            }}
                                          >
                                              <img
                                              width="30"
                                              height="30"
                                              class=" mx-3"
                                              src={verimg}
                                              />
                                          </a>
                                      </div>
                                  </td>
                              </tr>
                          ))
                        }
                    </tbody>
                </table>
            </div>
        </div>


{/* company Details modal */}
        <div
          class="modal fade bd-example-modal-lg"
          id="mdlDetailCompany"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog  modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                ></h5>
                <button
                  id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                {
                  company.id > 0 ?
                  <div class="container-fluid  my-2 mb-5">
                    <div class="row ">
                      <div class="col-md-5 ocultarMovil">
                        <div class="text-center">
                          <br />
                          <br />
                          <img
                            className="img-fluid center-block "
                            width="50%"
                            height="50%"
                            src={company.logo ? company.logo : imgmodal}
                          />
                        </div>
                      </div>

                      <div class="col-md-7">
                        <div class="text-left ">
                          <div className="mb-2">
                            <div className="container ">
                              <div class="row  my-1 ">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("lbl2")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {company.nit}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="text-left ">
                          <div className="mb-2">
                            <div className="container ">
                              <div class="row  my-1 ">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("lbl3")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {company.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="text-left ">
                          <div className="mb-2">
                            <div className="container ">
                              <div class="row  my-1 ">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("lbl4")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {company.phone_number}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="text-left ">
                          <div className="mb-2">
                            <div className="container ">
                              <div class="row  my-1 ">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("lbl5")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {company.address}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="text-left ">
                          <div className="mb-2">
                            <div className="container ">
                              <div class="row  my-1 ">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("lbl6")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {company.country.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="text-left ">
                          <div className="mb-2">
                            <div className="container ">
                              <div class="row  my-1 ">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("lbl7")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {company.department.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="text-left ">
                          <div className="mb-2">
                            <div className="container ">
                              <div class="row  my-1  ">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("lbl8")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {company.postal_code}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="text-left ">
                          <div className="mb-2">
                            <div className="container ">
                              <div class="row  my-1 mb-5 ">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("lbl9")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {company.status.description}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container my-2  mb-2">
                      <div class="row  my-1 ">
                        <div class="col-12 text-left  txtSmallAzulModal">
                          {t("lbl10")}
                        </div>

                        <div class="col-12 text-left txtSmallGris">
                          {company.legal_representative.map((i) => {
                            return (
                              <>
                                {i.first_name} {i.last_name} - {i.email}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="container my-2  mb-5">
                      <div class="row  my-1 ">
                        <div class="col-12 text-left  txtSmallAzulModal">
                          {t("lbl11")}
                        </div>

                        <div class="col-12 text-left txtSmallGris">
                          {company.description}
                        </div>
                      </div>
                    </div>
                  </div>
                  : "" 
                }
              </div>
            </div>
          </div>
        </div>
        {/* modal details company  */}

      </>
    );
}
export default RecentCompanies;
