import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import SliderInfinite from "./SliderInfinite";

// import person from "../img/person.png";
// import buscoempleo from "../img/buscoempleo.png";
// import buscocandidato from "../img/buscocandidato.png";
// import hrhbi from "../img/hrhbi.png";
// import iconright from "../img/iconright.svg";
// import imghome from "../img/backgroundhome.png";
// import imgo from '../img/caruselhometwo.png'
// import imgt from '../img/caruselhomeone.png'
// import imgtr from '../img/caruselhometr.png'
// import imgf from '../img/caruselhomefour.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/homeComporApplicant.css';

// import PaymentCompleted from "../components/PaymentCompleted";
// import PaymentCanceled from "../components/PaymentCanceled";
// import PaymentFailed from "../components/PaymentFailed";

const HomeComporApp = (props) => {

  const [t, i18n] = useTranslation("home")

  // const [ paymentstatus, setpaymentstatus ] = useState("")

  // const rendermessage = ({opc}) => {
  //   if (opc == "approved") return <PaymentCompleted lng={props.lng} /> 
  //   if (opc == "failed") return <PaymentCanceled lng={props.lng} /> 
  //   if (opc == "failed") return <PaymentFailed lng={props.lng} />
  // }

  // useEffect(()=>{
  //   setpaymentstatus(props.match.params && props.match.params.status)
  // },[])

    return (
      <>
        {/* {rendermessage({opc : paymentstatus})} */}
        <div class="contentHomeCoraAp container-fluid">
          <div class="row justify-content-center text-center contentcenter">

            {/* <img class="imagen" src={imghome} /> */}
            <SliderInfinite selectimgs="homeimgs" />

            {/* <div id="carouselFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src={imgo} class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                  <img src={imgt} class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                  <img src={imgtr} class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                  <img src={imgf} class="d-block w-100" alt="..."/>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselFade" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselFade" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div> */}
            {/* <div class="col-md-6 py-5" >
              <div class="coverwhite" > </div>
            </div> */}
          </div>

          <div class="row contentFoteer py-3" >

          </div>

          <div class="row text-center position-relative conticons ">
            <div class="row mb-3" >
              <div class="col">
                <h1 class="boldtx fontbebas"> {t("ttl1")} </h1>
              </div>
            </div>
            <div class="row" >
              <div class="col-md-2" ></div>
              <div class="col-md-4 col-sm-12 col-lg-6d " >
                <button type="button" class="btn btn-white gthanlight btnhpage" onClick={()=>{
                    localStorage.setItem('userrol', '2');
                    props.history.push("register")
                  }} > <h5 class="gthanlight "> {t("btn1")} </h5>
                </button>
              </div>

              <div class="col-md-4 col-sm-12 col-lg-6d " >
                <button type="button" class="btn btn-white gthanlight btnhpage" onClick={()=>{
                    localStorage.setItem('userrol', '3');
                    props.changeHome(2)
                  }} > <h5 class="gthanlight "> {t("btn2")} </h5>
                </button>
              </div>
              <div class="col-md-2" ></div>

            </div>
            
          </div>
        </div>
      </>
    )
}

export default HomeComporApp;
