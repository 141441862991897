import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/SliderInfinite.css';

import himg1 from '../img/homeimg1.png'
import himg2 from '../img/homeimg2.jpg'
import himg3 from '../img/homeimg3.jpg'
import himg4 from '../img/homeimg4.jpeg'
import himg5 from '../img/homeimg5.jpg'
import himg6 from '../img/homeimg6.jpg'
import himg7 from '../img/homeimg7.jpg'
import himg8 from '../img/homeimg8.jpg'


const SliderInfinite = (props) => {
    const [t, i18n] = useTranslation("homecomp")

    const [logos, setlogos] = useState([])
    const [images, setimages] = useState([])

    useEffect(() => {
        // 
        setlogos([])
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/companies/logos`)
        .then((res) => {
            setlogos(res.data.data)
        })
        .catch((error) => console.log(error));

        setimages([
            himg2,
            himg3,
            himg1,
            himg4,
            himg5,
            himg6,
            himg7,
            himg8
        ])

    }, []);

    return (
      <>
        <div class="container">
            <div class="row align-items-center">
                <div class="container rounded">
                    {
                        props.selectimgs == 'homeimgs' ?
                        <div class="sliderimg">
                            <div class="logosimg">
                                {
                                    images.map((i)=>(
                                        <i><img src={i} /></i>
                                    ))
                                }
                                {
                                    images.map((i)=>(
                                        <i><img src={i} /></i>
                                    ))
                                }
                            </div>
                        </div>
                        : 
                        <>
                            <h1 class="text-center">{t("ttl10")}</h1>
                            <div class="slider">
                                <div class="logos">
                                    {
                                        logos.map((i)=>(
                                            <i><img src={i.logo} class="mx-3" /></i>
                                        ))
                                    }
                                    {
                                        logos.map((i)=>(
                                            <i><img src={i.logo} class="mx-3" /></i>
                                        ))
                                    }
                                </div>
                                {/* <div class="logos">
                                    
                                </div> */}
                            </div>
                        </>
                    }
                    
                </div>
            </div>
        </div>
      </>
    )
}

export default SliderInfinite;
