import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import UserProfile from "../Login/UserProfile";
import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";

const RegisterEducation = (props) => {

    const [t, i18n] = useTranslation("formcmnps")

    const [ loadd, setloadd ] = useState(0)
    const [ countryselect,  setcountryselect ] = useState(0);
    const [ country, setcountry ] = useState([]);
    const [ departments, setdepartments ] = useState([]);

    const saveInstEdu = (values,resetForm) =>{
        setloadd(1)
        axios
        .post(
            `${process.env.REACT_APP_BASE_URL}/api/attrs/educational_institutions`, values,
            {      
            headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`, 
                }
            }
        )
        .then((res) => {
            setloadd(0)
            resetForm()
            props.addNewEducation(res.data.data)
        })
        .catch((error) => console.log(error));
    }


    const getCountry = async () => {
        await axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            setcountry(res.data.data.country)
          });
    };


    const getDeparmentByCountry = ({id}) => {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`,
            {
              headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`,
              },
            })
          .then((res) => {
            setdepartments(res.data.data.department);
            setcountryselect(id);
          });
      }
    
    useEffect(() => {
        getCountry()
    }, [])

    return (
        <>
            <div>
                <Formik
                  initialValues={{
                        name: "",
                        country: "",
                        department: "",
                        // number_phone: "",
                  }}
              
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(t("fr")),
                    country: Yup.number().required(t("fr")),
                    department: Yup.number().required(t("fr")),
                    // number_phone: Yup.string().required(t("fr"))
                  })}

                  validate = {(values) => {
                    const errors = {};
                    if (values.country != countryselect && values.country > 0) getDeparmentByCountry({id:values.country})
                    return errors;
                  }}

                  onSubmit={(val, { resetForm }) => {
                    val.status = 2
                    saveInstEdu(val, resetForm);
                  }}
                >
                   {/* render={({ errors, status, touched }) => ( */}
                   {({handleSubmit}) => ( 
                    <Form class="container mt-3" onSubmit={handleSubmit}>
                        <div class="form-group mb-4">
                          <label for="name" class="form-label">{t("lbl3")}</label>
                            <Field
                            id="name"
                            type="text"
                            name="name"
                            className="form-control imputText"
                            placeholder={t("lbl3")}
                            />

                            <ErrorMessage
                            name="name"
                            component="div"
                            className="msgerror mx-2"
                            />
                        </div>

                        <div class="form-group mb-4">
                          <label for="country" class="form-label">{t("lbl4")}</label>
                            <Field
                            as="select"
                            id="country"
                            name="country"
                            className="form-select fondoamarillo imputText"
                            aria-label={t("lbl4")}
                            >
                            <option className="fondo fw-bolder" value="" selected>
                                {t("lbl4")}
                            </option>

                            {country.map((i) => {
                                return (
                                <option className="fondo fw-bolder" value={i.id}>
                                    {i.name}
                                </option>
                                );
                            })}
                            </Field>

                            <ErrorMessage
                            name="country"
                            component="div"
                            className="msgerror mx-2"
                            />
                        </div>

                        <div class="form-group mb-4">
                          <label for="department" class="form-label">{t("lbl5")}</label>
                            <Field
                            as="select"
                            id="department"
                            name="department"
                            className="form-select fondoamarillo imputText"
                            aria-label={t("lbl5")}
                            >
                            <option className="fondo fw-bolder" value="" selected>
                                {t("lbl5")}
                            </option>

                            {departments.map((i) => {
                                return (
                                <option className="fondo fw-bolder" value={i.id}>
                                    {i.name}
                                </option>
                                );
                            })}
                            </Field>

                            <ErrorMessage
                            name="department"
                            component="div"
                            className="msgerror mx-2"
                            />
                        </div>

                        {/* <div class="form-group mb-4">
                          <label for="number_phone" class="form-label">{t("lbl6")}</label>
                            <Field
                            id="number_phone"
                            type="text"
                            name="number_phone"
                            className="form-control imputText"
                            placeholder={t("lbl6")}
                            />

                            <ErrorMessage
                            name="number_phone"
                            component="div"
                            className="msgerror mx-2"
                            />
                        </div> */}
                        {
                            loadd == 0 ?
                            <div class="divider d-flex align-items-center my-2">
                                <button
                                type="submit"
                                class="btn btn-warning  btn-block  buttons "
                                >
                                {t("lbl2")}
                                </button>
                            </div>
                            : 
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        }
                    </Form>
                  )}
                </Formik>
            </div>
        </>
    );
}

export default RegisterEducation;