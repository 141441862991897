import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import basura from "../../img/Basura.png";
import editar from "../../img/edita.png";


const EducationalInstitutionsList = (props) => {
    const [t, i18n] = useTranslation("dataadmin")

    return (
        <>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t("tbl18")}</th>
                        <th scope="col">{t("tbl16")}</th>
                        <th scope="col">{t("tbl15")}</th>
                        <th scope="col">{t("tbl19")}</th>
                        <th scope="col">{t("tbl3")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.list.map((edtinst) => (
                            <tr>
                                <th scope="row"> {edtinst.id} </th>
                                <td> {edtinst.name} </td>
                                <td> {edtinst.country.name} </td>
                                <td> {edtinst.department.name} </td>
                                <td> {edtinst.number_phone} </td>
                                <td> 
                                    <div class="row">
                                        {/* <div class="col-2">
                                            <div class="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={edtinst.id}
                                                    onClick={() => {
                                                    props.handleSwitchChange(
                                                        edtinst.id,
                                                        edtinst.status,
                                                        edtinst.name
                                                    );
                                                    }}
                                                    checked={
                                                    edtinst.status == 1
                                                        ? true
                                                        : false
                                                    }
                                                />
                                            </div>
                                        </div> */}

                                        <div class="col-2">
                                            <a
                                                title={t("lbl1")}
                                                href="javascript:void(0)"
                                                onClick={() =>
                                                props.deleter(
                                                    edtinst.id
                                                )
                                                }
                                            >
                                                <img
                                                width="16"
                                                height="20"
                                                class="icoOpciones"
                                                src={basura}
                                                />
                                            </a>
                                        </div>

                                        <div class="col-2">
                                          {" "}
                                          <a
                                            href="javascript:void(0)"
                                            title={t("lbl2")}
                                            onClick={() =>
                                              props.updateForm(
                                                edtinst,
                                                edtinst.status
                                              )
                                            }
                                          >
                                            {"   "}
                                            <img
                                              width="16"
                                              height="20"
                                              class="icoOpciones mx-3"
                                              src={editar}
                                            />{" "}
                                          </a>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        ))
                    }
                    
                </tbody>
            </table>
        </div>
        </>
    )
}

export default EducationalInstitutionsList;