import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import Swal from "sweetalert2";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import imgform from "../../img/formhrh.png";
import geojobscanada from "../../img/formhrh.png";

import UserProfile from "../../Login/UserProfile";
import RegisterActivity from "../../components/RegisterActivity"


import { components } from "react-select";
import Select from "react-select";
import Resizer from "react-image-file-resizer";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",

    padding: 20,
  }),
  control: () => ({
    width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};


const Option = (props) => {

  return (
    <div>
      <components.Option {...props}>
        <input
          class="form-check-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


const FormRegisterCompany = (props) => {

  const [t, i18n] = useTranslation("regcomp")
// i18n.changeLanguage("")

  const [ pais, setpais ] = useState([]);
  const [ departamento, setdepartamento ] = useState([]);
  const [ selcountry, setselcountry ] = useState(0);
  const [ conta, setconta ] = useState(0);
  const [ reponsable, setreponsable ] = useState([]);
  const [ cargo, setcargo ] = useState([]);
  
  const [ actividades, setactividades ] = useState([]);
  const [ optionSelected, setoptionSelected ] = useState([]);
  const [ actividadesempresa, setactividadesempresa ] = useState([]);
  
  const [ newImage, setnewImage ] = useState("");
  const [ pdfcoding, setpdfcoding ] = useState("");

  const [ regerrors, setregerrors ] = useState([]);
  const [ countdescp, setcountdescp ] = useState(400);
  

    // fileChangedHandler = fileChangedHandler.bind(this);

    const addNewActivity = (data) => {
      setactividades([...actividades, data])
    }

  const fileChangedHandler = (event) => {

    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          500,
          500,
          "png",
          85,
          0,
          (uri) => {
            setnewImage(uri)
          },
          "base64"
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  const registro = (val, resetForm) => {
    axios
    .post(`${process.env.REACT_APP_BASE_URL}/api/companies`,val, 
    {
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`, 
      }
    })
    .then((res) => {
      console.log(res);
      if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
      if(res.data.status===true){
        props.history.push("/company")
        resetForm();
      }
      if(res.data.status===false){
        setregerrors(res.data.error)
      }
    }).catch((error) => {
      console.log(error)
      props.history.push("/login")
    });
  };

  const handleChange = (selected) => {
    setoptionSelected(selected)
  };

  const getActividad = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/activities`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setactividades(res.data.data.activities)
      });
  };

  // const getCargo = async () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
  //       headers: {
  //         Authorization: `Bearer  ${UserProfile.getToken()}`,
  //       },
  //     })
  //     .then((res) => {
  //       setcargo(res.data.data.positions)
  //     });
  // };

  const getCargo = async () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/attrs/filterpositions/1`, [], {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setcargo(res.data.data)
      });
  };

  const getDeparment = async (val) => {
    // val = val.id
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setdepartamento(res.data.data.department)
      });
  };

  const getCountry = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpais(res.data.data.country)
      });
  };

  const filedocChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      var ext = event.target.files[0].name.split('.').pop();
      if(ext == "pdf"){
        if(event.target.files[0].size > 5242880){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg1"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        let fileToLoad = event.target.files[0];
        let file = null;
        let fileName = null;
        fileName = fileToLoad.name;
        // FileReader function for read the file.
        let fileReader = new FileReader();
        // Onload of file read the file content
        // let cntx = this
        fileReader.onload = function(fileLoadedEvent) {
            file = fileLoadedEvent.target.result;
            setpdfcoding(file)
        };
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
      }
      else
      {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("msg2"),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      }
    }
  }

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      getCountry();
      getCargo();
      getActividad();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg3"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

  const renderList = () => {
    return actividades.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  }


  const renderListActividad = () => {
    return optionSelected.map((data) => ({
      activity: data.value,
    }));
  }

    return (
      <>
        <div>
          <section class="">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="">
                    <div class="form-group  row justify-content-center ">
                      <img
                        src={geojobscanada}
                        class="img-responsive  imgform"
                      />
                    </div>

                    <Formik
                      initialValues={{
                        identification: "",
                        name: "",
                        description: "",
                        phone: "",
                        addres: "",
                        country: "",
                        departmen: "",
                        codepostal: "",

                        //responsable
                        namerespon: "",
                        namerespons: "",
                        identificacionespons: "",
                        correorespons: "",
                        positionrespon: "",
                        conditions: "",
                      }}
                      
                      validate={(values) => {
                        const pattern = new RegExp(
                          "^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$",
                          "i"
                        );
                        const errors = {};
                        if (!values.name) errors.name = t("fr");

                        if (!values.namerespon)
                          errors.namerespon = t("fr");
                        else if (!pattern.test(values.namerespon))
                          errors.namerespon = t("ot");

                        if (!values.namerespons)
                          errors.namerespons = t("fr");
                        else if (!pattern.test(values.namerespons))
                          errors.namerespons = t("ot");
                      if (selcountry < 1)
                        errors.country = t("fr");
                        // if (!values.country)
                        //   errors.country = t("fr");
                        // else
                        //  getDeparment(values.country)

                        if (values.description) {
                          setcountdescp(401-(values.description.length))
                        }

                        return errors;
                      }}

                      validationSchema={Yup.object().shape({
                        identification: Yup.string()
                          .typeError(t("fin"))
                          .required(t("fr")),

                        description: Yup.string().required(t("fr")),
                        conditions: Yup.string().required(t("fr")),
                        phone: Yup.number()
                          .typeError(t("fin"))
                          .required(t("fr")),
                        addres: Yup.string().required(t("fr")),
                        country: Yup.string(),
                        departmen: Yup.string().required(t("fr")),
                        codepostal: Yup.string()
                          .typeError(t("fin"))
                          .required(t("fr")),

                        identificacionespons: Yup.number()
                          .typeError(t("fin"))
                          .required(t("fr")),

                        correorespons: Yup.string()
                          .required(t("fr"))
                          .email(t("ein")),

                        positionrespon:
                          Yup.string().required(t("fr")),

                        //  actividadempresa:
                          //Yup.string().required(t("fr")),

                      })}


                      onSubmit={(val, { resetForm }) => {
                        const data = {
                          name: val.name,
                          logo: newImage,
                          document: pdfcoding.replace('data:application/pdf;base64,', ''),
                          nit:val.identification,
                          description: val.description, 
                          phone_number: val.phone,
                          address: val.addres,
                          country: selcountry,
                          department: val.departmen,
                          postal_code: val.codepostal,
                          status: 1,
                          // company_activities: 
                          //   renderListActividad()
                          // ,
                    
                          legal_representative: [
                            {
                              first_name: val.namerespon.trim(),
                              last_name:val.namerespons.trim(),
                              identification: val.identificacionespons.trim(),
                              email:val.correorespons.trim(), 
                              position: val.positionrespon
                            }
                          ]
                        };
                        registro(data, resetForm);
                      }}

                      enableReinitialize
                      render={({ errors, status, touched }) => (
                        <Form class="container-fluid mt-5">

                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group mb-4">
                                <label for="identification" class="form-label">{t("lbl1")} *</label>
                                <Field
                                  id="identification"
                                  name="identification"
                                  type="text"
                                  class="form-control imputText"
                                  placeholder={t("lbl1")}
                                />

                                <ErrorMessage
                                  name="identification"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="name" class="form-label">{t("lbl2")} *</label>
                                <Field
                                  id="name"
                                  name="name"
                                  type="text"
                                  class="form-control imputText"
                                  placeholder={t("lbl2")}
                                />

                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="phone" class="form-label">{t("lbl4")} *</label>
                                <Field
                                  id="phone"
                                  name="phone"
                                  type="number"
                                  class="form-control imputText"
                                  placeholder={t("lbl4")}
                                />

                                <ErrorMessage
                                  name="phone"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="addres" class="form-label">{t("lbl5")} *</label>
                                <Field
                                  id="addres"
                                  name="addres"
                                  type="text"
                                  class="form-control imputText"
                                  placeholder={t("lbl5")}
                                />

                                <ErrorMessage
                                  name="addres"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="description" class="form-label">{t("lbl3")} *</label>
                                <Field
                                  id="description"
                                  name="description"
                                  type="textarea"
                                  class="form-control imputText textArea"
                                  component="textarea"
                                  placeholder={t("lbl3")}
                                />

                                <ErrorMessage
                                  name="description"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                                <label>{countdescp}</label>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group mb-4">
                                <label for="country" class="form-label">{t("lbl6")} *</label>
                                <select
                                  id="country"
                                  name="country"
                                  className="form-select fondoamarillo imputText"
                                  onChange={(e)=>(setselcountry(e.target.value), getDeparment(e.target.value))}
                                  // value={country}
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl6")}
                                  </option>

                                  {pais.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.name}
                                      </option>
                                    );
                                  })}
                                </select>

                                <ErrorMessage
                                  name="country"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="departmen" class="form-label">{t("lbl7")} *</label>
                                <Field as="select"
                                  id="departmen"
                                  name="departmen"
                                  className="form-select fondoamarillo imputText"
                                  
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl7")}
                                  </option>

                                  {departamento.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.name}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <ErrorMessage
                                  name="departmen"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="codepostal" class="form-label">{t("lbl8")} *</label>
                                <Field
                                  id="codepostal"
                                  type="text"
                                  name="codepostal"
                                  class="form-control imputText"
                                  placeholder={t("lbl8")}
                                />

                                <ErrorMessage
                                  name="codepostal"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              {/* <div class="form-group mb-5">
                                <label for="actividadempresa" class="form-label">{t("lbl18")} * </label>
                                <button
                                  className="btn btn-warning btn-block m-3 "
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#mdlformnewactivity"
                                >
                                  {t("lbl20")} +
                                </button>

                                <Select
                                  name="actividadempresa"
                                  placeholder={
                                    <div className="fondoamarillo">
                                      {t("lbl18")}
                                    </div>
                                  }
                                  options={renderList()}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  components={{
                                    Option,
                                  }}
                                  onChange={handleChange}
                                  allowSelectAll={true}
                                  value={optionSelected}
                                />

                                    <ErrorMessage
                                      name="actividadempresa"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                              </div> */}


                              <div class="form-group mb-4">
                                <div class="color-fuente-gris textbold">
                                  {" "}
                                  {t("lbl9")}  &nbsp;
                                </div>

                                <input
                                  id="file"
                                  type="file"
                                  class="form-control imputText field-input"
                                  accept="image/png, image/jpeg,image/jpg "
                                  title="Choose a photo please"
                                  webkitRelativePath
                                  directory multiple

                                  onChange={fileChangedHandler}
                                /> 
                                <ErrorMessage
                                  name="file"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <div class="color-fuente-gris textbold">
                                  {" "}
                                  {t("lbl10")}  &nbsp;
                                </div>

                                <div class="alert alert-light" role="alert">
                                  {t("lbl11")}
                                </div>

                                <input
                                  id="filedoc"
                                  type="file"
                                  class="form-control imputText field-input"
                                  accept="application/pdf"
                                  title="Choose a photo please"
                                  webkitRelativePath
                                  directory multiple

                                  onChange={filedocChangedHandler}
                                />

                                <ErrorMessage
                                  name="filedoc"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>
                            </div>
                          </div>
                          

                          <div class="row">
                            <div class="col-md-12">
                              <div class="card">
                                <div class="card-body">
                                  <div class="divider d-flex align-items-center my-2">
                                    <div class="color-fuente-gris textbold">
                                      {" "}
                                      {t("lbl12")} &nbsp;
                                    </div>
                                  </div>
                                  <div class="form-group mb-4">
                                    <label for="namerespon" class="form-label">{t("lbl13")} *</label>
                                    <Field
                                      id="namerespon"
                                      type="text"
                                      name="namerespon"
                                      class="form-control imputText"
                                      placeholder={t("lbl13")}
                                    />

                                    <ErrorMessage
                                      name="namerespon"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="namerespons" class="form-label">{t("lbl14")} *</label>
                                    <Field
                                      id="namerespons"
                                      type="text"
                                      name="namerespons"
                                      class="form-control imputText"
                                      placeholder={t("lbl14")}
                                    />

                                    <ErrorMessage
                                      name="namerespons"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="identificacionespons" class="form-label">{t("lbl15")} *</label>
                                    <Field
                                      id="identificacionespons"
                                      type="text"
                                      name="identificacionespons"
                                      class="form-control imputText"
                                      placeholder={t("lbl15")}
                                    />

                                    <ErrorMessage
                                      name="identificacionespons"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="correorespons" class="form-label">{t("lbl16")} *</label>
                                    <Field
                                      id="correorespons"
                                      type="text"
                                      name="correorespons"
                                      class="form-control imputText"
                                      placeholder={t("lbl16")}
                                    />

                                    <ErrorMessage
                                      name="correorespons"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                  <label for="positionrespon" class="form-label">{t("lbl17")} *</label>
                                    <Field
                                      // id="positionrespon"
                                      // name="positionrespon"

                                      // className="form-select fondoamarillo imputText"
                                      // aria-label="Default select example"

                                      as="select"
                                      id="positionrespon"
                                      name="positionrespon"
                                      className="form-select fondoamarillo imputText"
                                    >
                                      <option
                                        className="fondo fw-bolder"
                                        value=""
                                        selected
                                      >
                                        {t("lbl17")}
                                      </option>

                                      {cargo.map((i) => {
                                        return (
                                          <option
                                            className="fondo fw-bolder"
                                            value={i.id}
                                          >
                                            {i.name}
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="positionrespon"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          

                          <hr/>
                          <div class=" left">
                              <Field
                                id="conditions"
                                name="conditions"
                                class="form-check-input"
                                type="checkbox"
                                placeholder={t("lbl22")}
                              />

                              <label
                                class="color-fuente-gris textbold mx-2"
                                for="conditions"
                              >
                                {" "}
                                {t("lbl22")} {" "}
                              </label>

                              <ErrorMessage
                                name="conditions"
                                component="div"
                                className="msgerror mx-2"
                              />
                            <a class="fs-6" target="_blank" onClick={()=>window.open(process.env.REACT_APP_DOC_TRAT_DATA, '_blank')} > {t("lbl23")} </a>
                          </div>
                          
                          {
                            regerrors.map((item)=>(
                              <div class="alert alert-danger" role="alert">
                                {item}
                              </div>
                            ))
                          }

                          <div class="divider d-flex align-items-center my-2">
                            <button
                              class="btn btn-warning  btn-block  buttons "
                              type="submit"
                            >
                              {t("lbl19")}
                            </button>
                          </div>
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* modal new activity */}
        <div class="modal fade" id="mdlformnewactivity" aria-labelledby="mdlformnewactivityLabel" aria-hidden="true">
          <div class="modal-dialog modal-xs">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="mdlformnewactivityLabel">{t("lbl20")}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <RegisterActivity addNewActivity={addNewActivity}/>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl21")}</button>
              </div>
            </div>
          </div>
        </div>
        {/* end modal new activity */}
      </>
    );
}

export default FormRegisterCompany;
