import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";

import Swal from "sweetalert2";

import axios from "axios";

import basura from "../../img/Basura.png";
import editar from "../../img/edita.png";

import listplas from "../../img/listplas.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import ReactPaginate from "react-paginate";


const UsersAdmin = (props) => {

const [t, i18n] = useTranslation("useradm")
// i18n.changeLanguage("")

  const [ users, setusers ] = useState([]);
  const [ usersTem, setusersTem ] = useState([]);
  const [ typeDocument, settypeDocument ] = useState([]);
  const [ typeUser, settypeUser ] = useState([]);
  const [ UsuarioForm, setUsuarioForm ] = useState([]);
  const [ estado, setestado ] = useState(0);
  const [ filter, setfilter ] = useState(0);

    //    /*paginacion*/ 
  const [ pageNex, setpageNex ] = useState(0);
  const [ pageCount, setpageCount ] = useState(0);
  const [ selectedPage, setselectedPage ] = useState(1);

  //filters
  const [ filname, setfilname ] = useState("");
  const [ filemail, setfilemail ] = useState("");
  const [ filtypeuser, setfiltypeuser ] = useState("");
  const [ filstatus, setfilstatus ] = useState("");



  const AddUser = async (value) => {
    const user ={
        name: value.name,
        email: value.email,
        password: value.password2,
        type: value.type,
        status: 1
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/users`,user,
    {      
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      }
    })
      .then((res) => {
        if (res.data.status == true) {
          list();
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.error[0],
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
        }
      })
      .catch((error) => console.log(error));
  };


  const ListTypeUser = async () => {
    settypeUser([])
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/user_type`,
    {      
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`, 
      }
    })
      .then((res) => {
        settypeUser(res.data.data.user_type)
      })
      .catch((error) => console.log(error));
  };



 const list = async (search) => {
      setusers([])
      var newsearch = {...search, page : selectedPage}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/users`,
      {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
        params: newsearch
    })
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setusers(res.data.data.users)
        setpageCount(res.data.data.pages)
      })
      .catch((error) => console.log(error));
  };

  const resetList  = () => {
    var search = {}
    setfilemail("")
    setfilname("")
    setfiltypeuser("")
    setfilstatus(0)
    list(search)
}

  const handleClickFilter = async () => {
    var search = {}
    if (filname.length > 0) search.name = filname
    if (filemail.length > 0) search.email = filemail
    if (filtypeuser > 0) search.type = filtypeuser
    if (filstatus > 0) search.status = filstatus
    list(search)
  }

    const handlePageClick = (val) => {
      setselectedPage(val.selected+1)
      setpageNex(val.selected)
    }
 
 useEffect(() => {
  handleClickFilter()
},[selectedPage, filname, filemail, filtypeuser, filstatus])


  const updateForm  = async (value,estadoval,opc) => {
    setUsuarioForm([])
    setestado(0)

    setUsuarioForm(value)
    setestado(estadoval)
    if(opc=="form"){
      document.getElementById("updateModalUser").click();
    }else{
      document.getElementById("updateModalUserPassword").click();
    }
  };



  const UpdateFormUserData = async (value) => {
    const valuesupdateData = {
      name: value.name,
      email: value.email,
      type: value.type,
      status: value.status,
      };
      if (value.password.length > 0) {
        valuesupdateData.password = value.password
      }
      axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/users/${value.id}`,
            valuesupdateData
          )
          .then((res) => {
            if (res.data.status == true) {
              list();
            }else{
              console.log(res)
            }
          })
          .catch((error) => console.log(error));
    document.getElementById("updateModalUser").click();
  };


  const handleSwitchChange = async (value,estado) => {
      if (estado == 1) {
        const valuesupdate = {
          name: value.name,
          email: value.email,
          type: value.type,
          status: 2
        };
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/users/${value.id}`,
            valuesupdate
          )
          .then((res) => {
            if (res.data.status == true) {
              list();
            }else{
              console.log(res)
            }
          })
          .catch((error) => console.log(error));
      } else {
        const valuesupdate = {
          name: value.name,
          email: value.email,
          type: value.type,
          status: 1
        };
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/users/${value.id}`,
            valuesupdate
          )
          .then((res) => {
            if (res.data.status == true) {
              list();
            }else{
              console.log(res)
            }
          })
          .catch((error) => console.log(error));
      }
  };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
      ListTypeUser()
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])


  let paginationElement;
    if (pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Sig"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
          onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
          forcePage={pageNex}
          containerClassName={"pagination justify-content-center"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
            <div class="row  d-flex  h-100  filtrosfondoxz">
              <div class="col-12 col-sm-12 col-md-2 filtrosopcioes  ">
                <div class=" row filtrosfondo">
                  <div class="filtros mx-2 my-4">{t("lbl1")}</div>
                </div>
                <div class="row my-3">
                  <div class="form-group mb-4 col-md-12">
                    <input
                      id="name"
                      type="text"
                      name="name"
                      className="form-control imputTextSelect mx-1"
                      aria-describedby="emailHelp"
                      placeholder={t("lbl24")}
                      onChange={(e) => setfilname(e.target.value)}
                    />
                  </div>
                  
                  <div class="form-group mb-4 col-md-12">
                    <input
                      id="email"
                      type="text"
                      name="email"
                      className="form-control imputTextSelect mx-1"
                      aria-describedby="emailHelp"
                      placeholder={t("lbl25")}
                      onChange={(e) => setfilemail(e.target.value)}
                    />
                  </div>
                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#typeusr"}
                    aria-expanded="false"
                    aria-controls={"typeusr"}
                  >
                    {t("lbl3")}
                  </a>

                  <ul className="collapse mx-3" id={"typeusr"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                          id="typeuser"
                          name="typeuser"
                          className="form-select fondoamarillo imputTextSelect"
                          aria-label={t("lbl7")}
                          onChange={(e) =>
                            setfiltypeuser(e.target.value)
                          }
                        >
                          <option className="fondo fw-bolder" value="todos" selected>
                            {t("lbl4")}
                          </option>

                          {typeUser.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.description}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>

                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#estado"}
                    aria-expanded="false"
                    aria-controls={"estado"}
                  >
                    {t("lbl6")}
                  </a>


                  <ul className="collapse mx-3" id={"estado"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                          id="departmen"
                          name="departmen"
                          className="form-select fondoamarillo imputTextSelect"
                          aria-label={t("lbl7")}
                          onChange={(e) => setfilstatus(e.target.value)}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl8")}
                          </option>

                          <option className="fondo fw-bolder" value="todos" selected>
                            {t("lbl9")}
                          </option>

                          <option className="fondo fw-bolder" value="1">
                            {t("lbl10")}
                          </option>
                          <option className="fondo fw-bolder" value="2">
                            {t("lbl11")}
                          </option>
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                  
                  <div class="row my-3">
                    <a
                      href="#"
                      className="mx-2  items-center txtlargeAzul"
                      data-bs-toggle="collapse"
                      data-bs-target={"#reset"}
                      aria-expanded="false"
                      aria-controls={"reset"}
                      onClick={() => resetList()}
                    >
                      {t("lbl23")}
                    </a>

                    <hr />
                </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-10 center-block ">
                {/* tabla de bits */}
                <div className="my-3">
                  <div className="container my-2 ocultar ">
                    <div className="col-6 col-sm-6 col-md-6 text-center">
                      <div class="row txtlargeAzul">

                        {/* <button
                          id="addUser"
                          type="button"
                          class="btn btn-warning  btn-block  buttonLarge "
                          data-bs-toggle="modal"
                          data-bs-target="#staticAddUser"
                        >
                          {" "}
                          <span>
                            <img
                              className="img-fluid"
                              width="15px"
                              height="15px"
                              src={listplas}
                            />{" "}
                          </span>{" "}
                          Añadir Usuario
                        </button> */}


                        <button
                      id="updateModalUser"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateUser"
                    >
                    </button>
                    <button
                      id="updateModalUserPassword"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateUserPassword"
                    >
                    </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-3">
                  <div className="container my-2 ocultar ">
                    <div class="row  my-1 ">
                      <div class="col-1 text-center ">
                        <div className="fondo  funeteblanca">#</div>
                      </div>

                      <div class="col-3 text-center ">
                        <div className="fondo funeteblanca">{t("tbl1")}</div>
                      </div>

                      <div class="col-3 text-center ">
                        <div className="fondo funeteblanca">{t("tbl2")}</div>
                      </div>

                      <div class="col-2 text-center ">
                        <div className="fondo funeteblanca">{t("tbl3")}</div>
                      </div>

                      <div class="col-3 text-center  ">
                        <div className="fondo funeteblanca">{t("tbl4")}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container my-2 mostrar ">
                  <div class="row  my-1 ">
                    <div class="col-12 text-center ">
                      <div className="fondo funeteblanca">{t("lbl5")}</div>
                    </div>
                  </div>
                </div>
                
                {Object.keys(users).map((i) => {
                    return (

                      <div className="container my-2">
                        <div className="accordion-item card sombra my-2">
                          <div className="accordion-header" id="headingOne">
                            <div className="row  my-2 p-2">
                              <div className="col-7 col-sm-7 col-md-1 text-center">
                                <div class="row txtlargeAzul">
                                  <br />
                                  <div class="text-center txtSmallAzul ">
                                    {users[i].id}
                                    {/* {parseInt(i, 10) + 1} */}
                                  </div>
                                </div>
                              </div>
  
                              <div className="col-7 col-sm-7 col-md-3 text-center">
                                <div class="row txtlargeAzul">
                                  <br />
                                  <div class="text-center txtSmallAzul">
                                    {users[i].name}
                                  </div>
                                </div>
                              </div>
  
                              <div className="col-7 col-sm-7 col-md-3 text-center">
                                <div class="row txtlargeAzul">
                                  <br />
                                  <div class="text-center txtSmallAzul">
                                    {users[i].email}
                                  </div>
                                </div>
                              </div>
  
                              <div className="col-7 col-sm-7 col-md-2 text-center">
                                <div class="row txtlargeAzul">
                                  <br />
                                  <div class="text-center txtSmallAzul">
                                    {users[i].type == 1
                                      ? t("txt1")
                                      : users[i].type == 2
                                      ? t("txt2")
                                      : t("txt3")}
                                  </div>
                                </div>
                              </div>
  
                              <div className="col-4 col-sm-4 col-md-3 ">
                                <div class="row justify-content-center align-items-center ">
                                  <div class="col-5 col-sm-5 col-md-3">
                                    <div class="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={users[i].id}
                                        onClick={() => {
                                          handleSwitchChange(
  
                                            users[i],
                                            users[i].status.id,
                                          );
                                        }}
                                        checked={
                                          users[i].status.id == 1
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  </div>
  
                                  <div class="col-1 col-sm-1 col-md-1">
                                    {" "}
                                    <a
                                      title={t("lbl12")}
                                      href="javascript:void(0)"
                                      onClick={() =>
                                        updateForm( users[i],
                                          users[i].status.id,"password")
                                      }
                                    >
                                      <img
                                        width="16"
                                        height="20"
                                        class="icoOpciones"
                                        src={basura}
                                      />
                                    </a>
                                  </div>
  

                                  <div class="col-1 col-sm-1 col-md-1">
                                    {" "}
                                    <a
                                      href="javascript:void(0)"
                                      title={t("lbl13")}
                                      onClick={() =>
                                        updateForm(
                                          users[i],
                                          users[i].status.id,"form"
                                        )
                                      }
                                    >
                                      {"   "}
                                      <img
                                        width="16"
                                        height="20"
                                        class="icoOpciones mx-3"
                                        src={editar}
                                      />{" "}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                })}
                <div>{paginationElement}</div>
              </div>
              

            </div>
          </div>
        </div>

         {/* modal add  */}



         {/* <div
          class="modal fade"
          id="staticAddUser"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("lbl14")}
                </h5>
                <button
                  id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    password: "",
                    password2: "",
                    type:"",
                    status: 1,
                  }}

                  validate={(values) => {   

                    const pattern = new RegExp('^[A-Z ]+$', 'i')

                    const errors = {};
                   
                    if (!values.name) 
                      errors.name = t("fr");     

                    else if (!pattern.test(values.name))               
                      errors.name = t("ot");
                    return errors;           
                    }  
                  }

                  

                  validationSchema={Yup.object().shape({
                  
                    email: Yup.string().required(t("fr")).email(t("ei")),
                    password: Yup.string().required(t("fr")),
                    password2: Yup.string().required(t("fr")).oneOf([Yup.ref("password"), null], t("ipss")), 
                    type: Yup.string().required(t("fr")),
                  
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();

                    closedmodal();

                    AddUser(val)


                  }}
                  render={({ errors, status, touched }) => (
                    <Form class="container">

                      <div class="form-group mb-4">
                        <Field
                          id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl15")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="email"
                          type="text"
                          name="email"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl16")}
                        />

                        <ErrorMessage
                          name="email"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="type"
                          name="type"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl17")}
                          </option>

                          {typeUser.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.description}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="type"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="password"
                          type="text"
                          name="password"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl18")}
                        />

                        <ErrorMessage
                          name="password"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="password2"
                          type="text"
                          name="password2"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl18")}
                        />

                        <ErrorMessage
                          name="password2"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>




                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl19")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div> */}

         {/* modal update data  */}

         <div
          class="modal fade"
          id="staticBackdropUpdateUser"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl1")}
                </h5>
                <button
                  id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: UsuarioForm.id,
                    name: UsuarioForm.name,
                    email: UsuarioForm.email,
                    type: UsuarioForm.type,
                    password: "",
                    status: estado,
                  }}


                  validate={(values) => {
                    const pattern = new RegExp('^[A-Z ]+$', 'i')
                    const errors = {};
                    if (!values.name) 
                      errors.name = t("fr");
                    // else if (!pattern.test(values.name))               
                    //   errors.name = t("ot");
                    return errors;
                    }  
                  }

                  validationSchema={Yup.object().shape({
                    email: Yup.string().required(t("fr")).email(t("ei")), 
                    type: Yup.string().required(t("fr")),
                  })}

                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    UpdateFormUserData(val)
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">

                      <div class="form-group mb-4">
                        <Field
                          id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl15")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="email"
                          type="text"
                          name="email"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl16")}
                        />

                        <ErrorMessage
                          name="email"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="type"
                          name="type"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl17")}
                          </option>

                          {typeUser.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.description}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="type"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="password"
                          type="text"
                          name="password"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl18")}
                        />

                        <ErrorMessage
                          name="password"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>


                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl21")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

            {/* modal update passwsord  */}

            <div
          class="modal fade"
          id="staticBackdropUpdateUserPassword"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("lbl22")}
                </h5>
                <button
                  id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">

                <Formik
                  initialValues={{
                    id: UsuarioForm.id,
                    name: UsuarioForm.name,
                    email: UsuarioForm.email,
                    type:UsuarioForm.type,
                    password: "",
                    password2: "",
                    status: estado,
                  }}
                  validationSchema={Yup.object().shape({
                    
                    password: Yup.string().required(t("fr")),
                    password2: Yup.string().required(t("fr")).oneOf([Yup.ref("password"), null], t("ipss")), 
                  })}

                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    UpdateFormUserData(val)
                  }}

                  enableReinitialize
                  render={({ errors, status, touched }) => (

                    <Form class="container">

                    <div class="form-group mb-4">
                        <Field
                          id="password"
                          type="text"
                          name="password"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl18")}
                        />

                        <ErrorMessage
                          name="password"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="password2"
                          type="text"
                          name="password2"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl18")}
                        />

                        <ErrorMessage
                          name="password2"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl21")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default UsersAdmin;
