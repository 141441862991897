import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import axios from "axios";
import ReactPDF from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';

import userchat from "../../img/userchat.png";
import listplas from "../../img/listplas.png";
import imgmodal from "../../img/imgmodal.png";
import sendimg from "../../img/send.png";
import user from "../../img/user.png";

import { Card, Container, Alert, Image, ProgressBar } from "react-bootstrap";

import PrintApplicant from "./AdminPrint/PrintApplicant";
import UserProfile from "../../Login/UserProfile";


const DetailsApplicantAdmin = (props) => {

  const [t, i18n] = useTranslation("detailapadmin")
  // i18n.changeLanguage("")

  const [ applicant, setapplicant ] = useState([]);
  const [ id_type, setid_type ] = useState([]);
  const [ countr, setcountr ] = useState([]);
  const [ depart, setdepart ] = useState([]);
  const [ applicantTem, setapplicantTem ] = useState([]);
  const [ countr_loc, setcountr_loc ] = useState([]);
  const [ depart_loc, setdepart_loc ] = useState([]);
  const [ languages_applicant, setlanguages_applicant ] = useState([]);
  const [ social_networks, setsocial_networks ] = useState([]);
  const [ studies, setstudies ] = useState([]);
  const [ experiences, setexperiences ] = useState([]);
  const [ applicant_activities, setapplicant_activities ] = useState([]);
  
  
  const [ status, setstatus ] = useState(0);
  const [ errorss, seterrorss ] = useState(0);
  const [ comentarios, setcomentarios ] = useState(0);


  const getApplicant = async (id) => {
    // if (id<1) {
    //   Swal.fire({
    //     title: "No hay un identificador que buscar.",
    //     icon: "warning",
    //     confirmButtonColor: "#3085d6",
    //     confirmButtonText: t("ok"),
    //   })
    // }
    await axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/applicants/${id}`,{
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      }})
    .then(async (res) => {
        if (!res.data.status.status && res.data.code == '406') props.history.push('/login')
        if (!res.data.status) {
          seterrorss(res.data.error)
          return
        }
        setapplicant(res.data.data)
        setid_type(res.data.data.identification_type.description)
        setcountr(res.data.data.country.name)
        setdepart(res.data.data.department.name)
        setcountr_loc(res.data.data.country_location.name)
        setdepart_loc(res.data.data.department_location.name)
        setstatus(res.data.data.status)
        // listas
        setlanguages_applicant(res.data.data.languages_applicant)
        setsocial_networks(res.data.data.applicant_social_networks)
        setstudies(res.data.data.studies)
        setexperiences(res.data.data.experiences)
        setapplicant_activities(res.data.data.applicant_activities)
        
      })
      .catch((error) => console.log(error));
  };

  const handleSwitchChange = async ({id, status}) => {
    status = status ? 1 : 2
    axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/api/applicants/${id}`,
      {status:status}
    )
    .then((res) => {
      if (res.data.status == true) {
        getApplicant(id);
      }
    })
    .catch((error) => console.log(error));
  };

  // print curriculum
  const printCurriculum = (applicant) => {
    return ReactDOM.render(
      <PDFViewer style={{width:"100%", height:"100vh"}}>
        <PrintApplicant applicant={applicant.applicant}/>
      </PDFViewer>
    , document.getElementById('printing'))
  }

  const listChat = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/commentcv/${applicant.id}`,{
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setcomentarios(res.data.data.comment_cv)
      });
  }

  const send = async () => {
    if (document.getElementById("mensaje").value != "") {
      var data = {
        comment: document.getElementById("mensaje").value,
        applicant: applicant.id,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/comment_cv`, data, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            listChat();
          }
        })
        .catch((error) => console.log(error));
        document.getElementById("mensaje").value=""
    }
  };

  const downloaddocgettoken = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/download/document?applicant=${props.dataxtr}`,{
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg2"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        downloaddoc(res.data.data.token_download)
      });
  }

  const downloaddoc = (token) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/download/document/${token}?applicant=${props.dataxtr}`,{
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        const fileName = res.data.data.name;
        const linkSource = `data:application/pdf;base64,${res.data.data.pdf}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  }

  useEffect(async () => {
    if (navigator.onLine) {
      await getApplicant(props.dataxtr);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

    // select percent level languages
    var level = {
      1 : "17",
      2 : "33",
      3 : "50",
      4 : "67",
      5 : "85",
      6 : "100",
    }
    var levellabel = {
      1 : "A1",
      2 : "A2",
      3 : "B1",
      4 : "B2",
      5 : "C1",
      6 : "C2",
    }
    var varcolor = {
      1 : "Secondary",
      2 : "danger",
      3 : "warning",
      4 : "info",
      5 : "success",
      6 : "success",
    }
    return (
      <>
        <Container>
          {
            errorss ? 
              errorss.map((variant, idx) => (
                <Alert key={idx} variant="warning">
                  {variant}
                </Alert>
              )) : ""
          }
          
          <Card>
            <Card.Body>
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-2 col-sm-12">
                    <Image src={applicant.img_perfil || 'https://www.focusedu.org/wp-content/uploads/2018/12/circled-user-male-skin-type-1-2.png'} roundedCircle fluid/>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <div class="row mt-4">
                      <h5 class="fw-bold col">{t("ttl1")}</h5>
                      <div class="col text-end">
                        <div class="form-check form-switch">
                          <input
                            className="form-check-input lg-switch"
                            type="checkbox"
                            id={applicant.id}
                            onClick={() => {
                              handleSwitchChange({
                                id: applicant.id, status: status.id
                              });
                            }}
                            checked={
                              status.id == 1 ? true : false
                            }
                          />
                        </div>
                      </div>
                      <div class="col text-end">
                        <div class="text-left ">
                          <div className="mb-4">
                            <div className="container ">
                              <div class="row  my-1 ">
                                <div class="col-11 text-left txtSmallAzulModal ">
                                  <div class="dropdownn">
                                    <button
                                      class="btn btn-lightModal buttonSmallAzulModal"
                                      type="button"
                                      id="dropdownMenuButton2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      onClick={() => listChat()}
                                    >
                                      {" "}
                                      <span>
                                        <img
                                          className="img-fluid "
                                          width="12px"
                                          height="12px"
                                          src={listplas}
                                        />{" "}
                                      </span>{" "}
                                      {t("lbl1")}
                                    </button>
                                    <div
                                      class="dropdown-menu fondoAzulModal"
                                      aria-labelledby="dropdownMenuButton2"
                                    >
                                      <div class="container">
                                        <div class="form-group mb-1 text-center ">
                                          <img
                                            className=" mt-2 img-fluid"
                                            width="20px"
                                            height="20px"
                                            src={user}
                                          />
                                        </div>
                                        <div class="form-group mb-2 text-center ">
                                          {t("ttl2")}
                                        </div>
                                      </div>
                                      <div className="linea lineamovil"></div>
                                      <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                                        <div className="anyClass anyClassMovil" id="topabajo">
                                          {comentarios ? 
                                            comentarios.map((i) => {
                                              if (i.type != 1) {
                                                return (
                                                  <>
                                                    <div class="form-group mb-2 text-left mt-2 ">
                                                      <div class="row  my-1 ">
                                                        <div class="col-2 col-sm-2 col-md-2 ">
                                                          <img
                                                            className=" mt-2 img-fluid ingMpvilchat"
                                                            width="50px"
                                                            height="50px"
                                                            src={userchat}
                                                          />
                                                        </div>

                                                        <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                                                          <div className="txtSmallGris">
                                                          {i.first_name}
                                                          </div>

                                                          <p className="text-left"> {i.comment} </p>
                                                          
                                                          <div class="txtSmallGris"> {i.created_at} </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <div class="form-group mb-2  mt-2 ">
                                                      <div class="row  my-1   justify-content-end">
                                                        <div class=" col-5 col-sm-5 col-md-5 text-end  mt-1 globo">
                                                          <div className="txtSmallGris">
                                                            {i.name}
                                                          </div>
                                                          <p className="">{i.comment}</p>
                                                          <div class="txtSmallGris"> {i.created_at} </div>
                                                        </div>
                                                        <div class="col-2 col-sm-2 col-md-2 ">
                                                          <img
                                                            className=" mt-2 img-fluid ingMpvilchat"
                                                            width="50px"
                                                            height="50px"
                                                            src={userchat}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              }
                                            }): "" 
                                          } 
                                        </div>
                                      </div>

                                      <div class="container">
                                        <div class="row  my-1 ">
                                          <div class="col-10 ">
                                            <input
                                              id="mensaje"
                                              type="text"
                                              name="mensaje"
                                              className="mt-1 form-control imputTextModal"
                                              aria-describedby="emailHelp"
                                              placeholder={t("lbl2")}
                                            />
                                          </div>

                                          <div class="col-2 text-left ">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                send();
                                              }}
                                            >
                                              <img
                                                className=" mt-2 img-fluid"
                                                width="20px"
                                                height="20px"
                                                src={sendimg}
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("lbl3")}</div>
                              <div class="col-6 text-center">{applicant.first_name}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("lbl4")}</div>
                              <div class="col-6 text-center">{applicant.second_name}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("lbl5")}</div>
                              <div class="col-6 text-center">{applicant.first_lastname}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("lbl6")}</div>
                              <div class="col-6 text-center">{applicant.second_lastname}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("lbl7")}</div>
                              <div class="col-6 text-center">{id_type}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("lbl8")}</div>
                              <div class="col-6 text-center">{applicant.identification}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("lbl9")}</div>
                              <div class="col-6 text-center">{countr}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("lbl10")}</div>
                              <div class="col-6 text-center">{depart}</div>
                            </div>
                          </li>
                          <li class="list-group-item">
                            <div class="row">
                              <div class="col-6">{t("lbl12")}</div>
                              <div class="col-6 text-center">{applicant.email}</div>
                            </div>
                          </li>
                      </ul>
                    </div>

                    <div class="row mt-5">
                      <button
                        class="btn btn-lightModal buttonSmallAzulModal"
                        type="button"
                        onClick={() => {downloaddocgettoken()}}
                      >
                        {t("lbl13")}
                      </button>
                    </div>
                    
                    <div class="row mt-5">
                      <h5 class="fw-bold">{t("ttl3")}</h5>
                      {languages_applicant.map((i) => {
                        return <>
                          <div key={i.language.name} class="row mt-5">
                            <div class="col-3">
                             { i.language.name }
                            </div>
                            <div class="col-9">
                            <ProgressBar now={level[i.level.id]} label={levellabel[i.level.id]} variant={varcolor[i.level.id]} />
                            </div>
                          </div>
                        </>;
                      })}

                    </div>

                    <div class="row mt-5">
                        <h5 class="fw-bold">{t("ttl4")}</h5>
                        {social_networks.map((i) => {
                          return <>
                            <div key={i.nickname+i.applicant} class="row mt-5">
                              <div class="col-3">
                                {i.network.name}
                              </div>
                              <div class="col-9">
                                <p>{i.nickname}</p>
                              </div>
                            </div>
                          </>;
                        })}
                      
                    </div>
                  </div>

                  {/* lado derecho */}
                  <div class="col-md-5 col-sm-12 col-xs-12">
                    <div class="row mt-5">
                      <h5 class="fw-bold">{t("ttl5")}</h5>
                      <div class="row">
                        <div class="col">
                          {applicant.description}
                        </div>
                      </div>
                      <div class="row">
                        <h5 class="fw-bold">{t("ttl6")}</h5>
                        <div class="col">
                          {depart_loc + " - " + countr_loc}
                        </div>
                      </div>
                    </div>

                    <div class="row mt-5">
                    <h5 class="fw-bold">{t("ttl10")}</h5>
                      <div class="col">
                        {
                          applicant_activities.map((item) => (
                              item.activity.name + " - " 
                            ))
                        }
                      </div>
                    </div>

                    <div class="row mt-5">
                      <h5 class="fw-bold">{t("ttl7")}</h5>
                      <div class="col">
                        <table class="table table-responsive ">
                          <tbody>
                            {studies.map((i) => {
                              return <>
                                    <tr key={i.name}>
                                      <td>{i.name}</td>
                                      <td>{i.duration_time + " - " + i.unit_measurement.name}</td>
                                      <td>{i.finish == 1 ? "completed" : ""}</td>
                                      <td>{i.educational_institution.name}</td>
                                      <td>{i.level_study.description}</td>
                                    </tr>
                              </>;
                            })}
                            
                          </tbody>
                        </table>
                      </div>

                    </div>

                    <div class="row mt-5">
                      <h5 class="fw-bold">{t("ttl8")}</h5>
                      <div class="col">
                        <table class="table table-responsive ">
                          <tbody>
                            {experiences.map((i) => {
                              return <>
                                    <tr key={i.position.name}>
                                      <td>{i.position.name}</td>
                                      <td>{i.duration_time + " - " + i.unit_measurement.name}</td>
                                      <td>{i.company.name}</td>
                                      <td>{i.initial_year}</td>
                                      <td></td>
                                    </tr>
                              </>;
                            })}
                            <tr>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row mt-5">
                      <h5 class="fw-bold">{t("ttl9")}</h5>
                      <div class="p-3">
                        <div class="ratio ratio-16x9">
                          <iframe src={applicant.video_presentation ? applicant.video_presentation : ""} title="YouTube video" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>

                    {/* <div class="row mt-5 justify-content-center">
                      <div class="col-auto">
                        <button type="submit" class="btn btn-warning  btn-block  buttons"
                        data-bs-toggle="modal" data-bs-target="#mdlprinting"
                        onClick={() => {
                          printCurriculum({
                            applicant: applicant
                          });
                        }}
                        >
                          To print
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
        {/* modal para chat con candidato */}
        <div class="modal fade " id="mdlprinting"  aria-labelledby="mdlprintingLabel" aria-hidden="true">
                <div class="modal-dialog modal-fullscreen">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div id="printing" style={{minHeight:"100vh"}}></div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl11")}</button>
                    </div>
                  </div>
                </div>
              </div>  
      </>
    );
}
export default DetailsApplicantAdmin;
