import React from 'react';
import { Card, Container, Alert, Image, ProgressBar } from "react-bootstrap";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import DetailsApplicantsAdmin from '../DetailsApplicantsAdmin'
import PrintApplicantFormat from '../AdminPrint/PrintApplicantFormat'
import 'bootstrap/dist/css/bootstrap.min.css';

// Create styles
const styles = StyleSheet.create({
  page: {
    
    backgroundColor: '#FFFFFF'
  },
  section: {
    margin: 10,
    padding: 10,
    
  }
});

// Create Document Component
const PrintApplicant = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>
          {/* <DetailsApplicantsAdmin dataxtr={props.applicant.id}/> */}
          <PrintApplicantFormat dataxtr="1"/>
          
        </Text>
      </View>
    </Page>
  </Document>
);

export default PrintApplicant;