import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import ingfis from "../img/ingfis.svg";
import geo from "../img/geo.svg";
import meca from "../img/meca.svg";

import OfertasJobs from "./OfertasJobs"

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/HomeApplicant.css';

const HomeApplicant = (props) => {

  const [t, i18n] = useTranslation("homeappl")
  
 const [activities, setactivities] = useState([])
 const [regiones, setregiones] = useState([])
 const [componente, setcomponente] = useState(true)
 const [idCountry, setidCountry] = useState("")
 const [form, setform] = useState({
                                  regiones: "n",
                                })

  const handleChange = (e) => {
    // setState({ : { ...form, [e.target.name]: ,},});
    setform(e.target.value)
  };

  const buscar = async () => {
    if (form.regiones != "n") {
      setcomponente(false)
    } else {
      
    }
  };

  const getActivities = () => {
    // const idsactivities = [18, 16, 19, 25, 2, 47, 44, 27]
    setactivities([
      {
        "id": 1,
        "name": t("act1"),
        "url": ingfis,
        "status": 1
      },
      {
        "id": 2,
        "name": t("act2"),
        "url": geo,
        "status": 1
      },
      {
        "id": 3,
        "name": t("act3"),
        "url": meca,
        "status": 1
      }
    ])
  }

  const getCountries = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`)
      .then((res) => {
        setregiones(res.data.data.country)
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    getCountries()
    getActivities()
  }, []);


    return (
      <>
        {
        componente ? (
          <>
            <div class="item-group" xyz="fade">
              <div class="square xyz-in">
                <div class="container-fluid backgroundblue text-center p-3">
                  <div class="row justify-content-center">
                    <h1 class="boldtxt">{t("ttl3")}</h1>
                  </div>
                  <div class="row justify-content-center">
                    <h1 class="boldtxt backcontrast">{t("ttl4")}</h1>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-8 p-3">
                      {/* <h5>{t("txt1")}</h5> */}
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-4">
                      <div class="row">
                        <div class="col-9">
                          <select
                            name="regiones"
                            onChange={handleChange}
                            class="form-select imputSelect form-control"
                            aria-label="Default select example"
                          >
                            <option
                              className="fondo fw-bolder"
                              value="n"
                              disabled
                              selected
                            >
                              {t("search")}
                            </option>
                            {Object.keys(regiones).map((i) => {
                              return <option key={regiones[i].code} value={regiones[i].id}>{regiones[i].name}</option>
                            })}
                          </select>
                        </div>

                        <div class="col-3">
                          <button
                            onClick={() => buscar()}
                            type="button"
                            class="btn btn-warning btn-lg"
                          >
                            <span>
                              <FontAwesomeIcon icon={faSearch} />
                            </span>{" "}
                            {t("go")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container text-center p-3">
                  <div class="row justify-content-center">
                    <h1 class="boldtxt">{t("ttl5")}</h1>
                  </div>
                  
                  <div class="row justify-content-center">
                    <h5>{t("txt2")}</h5>
                  </div>

                  <div class="row justify-content-center p-5">
                    {
                      activities.map((i)=>(
                        <div class="col-6 activity text-center" >
                          <img class="img-fluid align-middle rounded-circle" src={i.url} />
                          <p class="align-middle boldtxt">{i.name}</p>
                        </div>
                      ))
                    }
                  </div>
                </div>
                
              </div>
            </div>
          </>
        ) : (
          <>
            <OfertasJobs idcontry={form.regiones} history={props.history} />
          </>
        )}
      </>
    )
}

export default HomeApplicant;
