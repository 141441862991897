import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import UserProfile from "../../Login/UserProfile";

import Swal from "sweetalert2";
import DatePicker from "react-datepicker"
import ReactPaginate from "react-paginate";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'moment';

import axios from "axios";

import basura from "../../img/Basura.png";
import editar from "../../img/edita.png";
import listplas from "../../img/listplas.png";
import imgmodal from "../../img/imgmodal.png";
import sendimg from "../../img/send.png";
import userimg from "../../img/user.png";
import ver from "../../img/ver.png";
import userchat from "../../img/userchat.png";
import list from "../../img/list.png";
import nube from "../../img/nube.png";
import comentariosimg from "../../img/comentario.png";

const OfertasLaboralesAdmin = (props) => {

const [t, i18n] = useTranslation("offersadmin")

  const [ ofertas, setofertas ] = useState([]);
  const [ jobofert, setjobofert ] = useState([]);
  const [ idioma, setidioma ] = useState([]);
  
  const [ datos, setdatos ] = useState([]);
  const [ country, setcountry ] = useState(0);
  const [ deparmet, setdeparmet ] = useState(0);
  const [ countryName, setcountryName ] = useState("");
  const [ deparmetName, setdeparmetName ] = useState("");
  const [ estadoName, setestadoName ] = useState("");
  
  const [ status, setstatus ] = useState(0);
  const [ user, setuser ] = useState(0);
  const [ usuario, setusuario ] = useState([]);
  
  //   //selects
  const [ countries, setcountries ] = useState([]);
  const [ departments, setdepartments ] = useState([]);
  const [ cities, setcities ] = useState([]);
  const [ companies, setcompanies ] = useState([]);
  const [ activities, setactivities ] = useState([]);
  const [ positions, setpositions ] = useState([]);
  const [ salaries, setsalaries ] = useState([]);
  const [ schedules, setschedules ] = useState([]);

    //   // filtros aplicados
  const [ filtxtsearch, setfiltxtsearch ] = useState("");
  const [ filidentcompan, setfilidentcompan ] = useState("");
  const [ filcompany, setfilcompany ] = useState("");
  const [ filcountry, setfilcountry ] = useState("");
  const [ fildepartment, setfildepartment ] = useState("");
  const [ filcity, setfilcity ] = useState("");
  const [ filactivity, setfilactivity ] = useState("");
  const [ filposition, setfilposition ] = useState("");
  const [ filsalary, setfilsalary ] = useState("");
  const [ filschedule, setfilschedule ] = useState("");
  const [ filstatus, setfilstatus ] = useState("");
  const [ fildatestart, setfildatestart ] = useState(new Date());
  const [ fildateend, setfildateend ] = useState(new Date());
  
  
  //   // paginacion
  const [ offset, setoffset ] = useState(0);
  const [ pageCount, setpageCount ] = useState(0);
  const [ perPage, setperPage ] = useState(0);
  // const [ identificador, setidentificador ] = useState(0);
  const [ targetmodel, settargetmodel ] = useState(0);
  const [ pageNex, setpageNex ] = useState(0);
  const [ selectedPage, setselectedPage ] = useState(1);
  const [ coment, setcoment ] = useState([]);
  // const [ identificador, setidentificador ] = useState("inicio");
  
  const [ idoffert, setidoffert ] = useState(0);
  const [ comentarios, setcomentarios ] = useState([]);

  const [ idEmpresa, setidEmpresa ] = useState(0);
  const [ loading, setloading ] = useState(0);
  

  const resetList  = () => {
    var search = {}
    setfiltxtsearch("")
    setfilidentcompan("")
    setfilcompany(0)
    setfilcountry(0)
    setfildepartment(0)
    setfilcity(0)
    setfilactivity(0)
    setfilposition(0)
    setfilsalary(0)
    setfilschedule(0)
    setfilstatus(0)
    list(search)
}

const handleClickFilter = async () => {
    var search = {}
    if (filtxtsearch.length > 0) search.query = filtxtsearch
    if (filcompany > 0) search.company = filcompany
    if (filidentcompan.length > 0) search.companynit = filidentcompan
    if (filcountry > 0) search.country = filcountry
    if (fildepartment > 0) search.department = fildepartment
    if (filcity > 0) search.city = filcity
    if (filactivity > 0) search.activity = filactivity
    if (filposition > 0) search.position = filposition
    if (filsalary > 0) search.salary = filsalary
    if (filschedule > 0) search.schedule = filschedule
    if (filstatus > 0) search.status = filstatus
    list(search)
}
//changes filters
useEffect(() => {
  handleClickFilter()
}, [filtxtsearch, selectedPage, filstatus, filcompany, filidentcompan, filcountry, fildepartment, filcity, filactivity, filposition, filsalary, filschedule])

// changes dates
useEffect(() => {
  var search = {}
  if (filtxtsearch.length > 0) search.query = filtxtsearch
  if (filcompany > 0) search.company = filcompany
  if (filidentcompan > 0) search.companynit = filidentcompan
  if (filcountry > 0) search.country = filcountry
  if (fildepartment > 0) search.department = fildepartment
  if (filcity > 0) search.city = filcity
  if (filactivity > 0) search.activity = filactivity
  if (filposition > 0) search.position = filposition
  if (filsalary > 0) search.salary = filsalary
  if (filschedule > 0) search.schedule = filschedule
  if (filstatus > 0) search.status = filstatus
  search.datestart = Moment(fildatestart).format("YYYY-MM-DD")
  search.dateend = Moment(fildateend).format("YYYY-MM-DD")
  list(search)
}, [fildatestart, fildateend])


const handlePageClick = (val) => {
    setselectedPage(val.selected+1)
    setpageNex(val.selected)
}

const send = async () => {
    if (document.getElementById("mensaje").value != "") {
      var data = {
        comment: document.getElementById("mensaje").value,
        offer: idoffert,
      };

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/comment_offer`, data, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            listChat(idoffert);
          }
        })
        .catch((error) => console.log(error));
      document.getElementById("mensaje").value = "";
    }
  };

  const listChat = async (id) => {
    setcomentarios([])
    setidoffert(id)
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/commentoffer/${id}`, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status == true) {
          setcomentarios(res.data.data.comment_offer)
        }
      });
  };

  const getUser = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/user_type`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setusuario(res.data.data.user_type)
      });
  };

  const getCountry = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`)
      .then((res) => {
        setcountries(res.data.data.country)
      });
  }

  const getDeparmentByCountry = ({id}) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`)
      .then((res) => {
        setdepartments(res.data.data.department)
      });
  }

  const getCityByDepartment = ({id}) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/cities/by_department/${id}`)
      .then((res) => {
        setcities(res.data.data.city);
        // setdepartmentselect(id);
      });
  }

  const getCompany = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/companies`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setcompanies(res.data.data.companies)
      });
  };

  const getActivity = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/activities`)
      .then((res) => {
        setactivities(res.data.data.activities)
      });
  };

  const getCargo = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/positions`,
      )
      .then((res) => {
        setpositions(res.data.data.positions)
      });
  };

  const getSalaries = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/salary_ranges`)
      .then((res) => {
        setsalaries(res.data.data.salary_ranges)
      });
  }

  const getSchedule = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/work_hours`)
      .then((res) => {
        setschedules(res.data.data.work_hours)
      });
  }

  const lenguaje = async (value) => {
    setidioma(value.language_offers)
    setidEmpresa(value.company.id)
  };

  const list = async (search) => {
    setloading(1)
    if (props.dataxtr > 0) {
      search = {...search, iddata: props.dataxtr}
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/job_offers/search`,
          search, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
            params: {page: selectedPage}
          })
        .then((res) => {
          if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
          setofertas(res.data.data.job_offers)
          setpageCount(res.data.data.pages)
          setloading(0)
        })
        .catch((error) => console.log(error));
  };

  const handleSwitchChange = async (value) => {
    let sts = 4;
    (value.status.id == 4) ? sts = 2 : sts = 4
      const values = {
        id: value.id,
        company: value.company.id,
        status: sts,
      };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/job_offers/${value.id}`,
          values,
          {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status == true) {
            list();
          }
        })
        .catch((error) => console.log(error));
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('lngselect'))
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      getCountry();
      getCompany();
      getActivity();
      getCargo();
      getSalaries();
      getSchedule();
      list();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("mg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [props.timeseg])

  useEffect(() => {
    list();
  }, [props.timeseg])

    let paginationElement;
    if (pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Sig"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
          onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
          forcePage={pageNex}
          containerClassName={"pagination justify-content-center"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
            <div class="row  d-flex  h-100  filtrosfondoxz">
              <div class="col-12 col-sm-12 col-md-2 filtrosopcioes">
                <div class=" row filtrosfondo">
                  <div class="filtros mx-2 my-4">{t("ttl1")}</div>
                </div>

                <div class="row my-3">
                  <div class="form-group mb-4 col-md-12">
                    <input
                      id="phone_number"
                      type="text"
                      name="phone_number"
                      className="form-control imputTextSelect mx-1"
                      placeholder={t("lbl1")}
                      onChange={(e)=>setfiltxtsearch(e.target.value)}
                    />
                  </div>

                  <hr />
                </div>

                <div class="row">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#date"}
                    aria-expanded="false"
                    aria-controls={"date"}
                    >
                    {t("lbl22")}
                  </a>
                  <ul className="collapse mx-1" id={"date"}>
                    <li class="mt-2">
                      <div class="form-group col-md-12">
                        <label htmlFor="fildatestart">{t("lbl23")}</label>
                        <DatePicker 
                          selected={fildatestart}
                          dateFormat="Y-M-d"
                          className="form-control"
                          id="fildatestart"
                          name="fildatestart"
                          onChange={(e) => {
                            setfildatestart(e)
                          }}
                        />
                      </div>
                      <div class="form-group mb-2 col-md-12">
                        <label htmlFor="fildateend">{t("lbl24")}</label>
                        <DatePicker 
                          selected={fildateend}
                          dateFormat="Y-M-d"
                          className="form-control"
                          id="fildateend"
                          name="fildateend"
                          onChange={(e) => {
                            setfildateend(e)
                          }}
                        />
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>

                <div class="row my-3">
                        <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#countr"}
                        aria-expanded="false"
                        aria-controls={"countr"}
                        >
                        {t("lbl2")}
                        </a>

                        <ul className="collapse mx-3" id={"countr"}>
                            <li class="mt-2">
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="filcountry"
                                        name="filcountry"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label={t("lbl3")}
                                        onChange={async (e) => (setfilcountry(e.target.value), getDeparmentByCountry({id: e.target.value}))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl2")}
                                        </option>

                                        {countries.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {i.name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="fildepartment"
                                        name="fildepartment"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label={t("lbl3")}
                                        onChange={async (e) => (setfildepartment(e.target.value), getCityByDepartment({id:e.target.value}))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl4")}
                                        </option>

                                        {departments.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {i.name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="filcity"
                                        name="filcity"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        onChange={(e) => (setfilcity(e.target.value))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl25")}
                                        </option>

                                        {cities.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {i.name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                            </li>
                        </ul>
                        <hr />
                    </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#empresa"}
                    aria-expanded="false"
                    aria-controls={"empresa"}
                  >
                    {t("lbl5")}
                  </a>

                  <ul className="collapse mx-3" id={"empresa"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <input
                          id="ident"
                          type="text"
                          name="ident"
                          className="form-control imputTextSelect"
                          placeholder={t("lbl6")}
                          onChange={async (e) => (setfilidentcompan(e.target.value))}
                        />
                      </div>
                      <div class="form-group mb-4 col-md-11">
                        <select
                          id="empresa"
                          name="empresa"
                          className="form-select fondoamarillo imputTextSelect"
                          aria-label={t("lbl3")}
                          onChange={async (e) => (setfilcompany(e.target.value))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl7")}
                          </option>

                          {companies.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>

                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#activdad"}
                    aria-expanded="false"
                    aria-controls={"activdad"}
                  >
                    {t("lbl8")}
                  </a>

                  <ul className="collapse mx-3" id={"activdad"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                          id="activdad"
                          name="activdad"
                          className="form-select fondoamarillo imputTextSelect"
                          aria-label={t("lbl3")}
                          onChange={async (e) => (setfilactivity(e.target.value))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl9")}
                          </option>

                          {activities.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>

                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#cargo"}
                    aria-expanded="false"
                    aria-controls={"cargo"}
                  >
                    {t("lbl10")}
                  </a>

                  <ul className="collapse mx-3" id={"cargo"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                          id="cargo"
                          name="cargo"
                          className="form-select fondoamarillo imputTextSelect"
                          aria-label={t("lbl3")}
                          onChange={async (e) => (setfilposition(e.target.value))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl10")}
                          </option>

                          {positions.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#salary"}
                    aria-expanded="false"
                    aria-controls={"salary"}
                  >
                    {t("lbl11")}
                  </a>

                  <ul className="collapse mx-3" id={"salary"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                          id="salary"
                          name="salary"
                          className="form-select fondoamarillo imputTextSelect"
                          aria-label={t("lbl3")}
                          onChange={async (e) => (setfilsalary(e.target.value))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl11")}
                          </option>

                          {salaries.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>
                
                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#schedul"}
                    aria-expanded="false"
                    aria-controls={"schedul"}
                  >
                    {t("lbl12")}
                  </a>

                  <ul className="collapse mx-3" id={"schedul"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                          id="schedul"
                          name="schedul"
                          className="form-select fondoamarillo imputTextSelect"
                          aria-label={t("lbl3")}
                          onChange={async (e) => (setfilschedule(e.target.value))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl12")}
                          </option>

                          {schedules.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>


                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#estado"}
                    aria-expanded="false"
                    aria-controls={"estado"}
                  >
                    {t("lbl13")}
                  </a>

                  <ul className="collapse mx-3" id={"estado"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                          id="departmen"
                          name="departmen"
                          className="form-select fondoamarillo imputTextSelect"
                          aria-label={t("lbl3")}
                          onChange={(e) => (setfilstatus(e.target.value) )}
                        >
                          <option className="fondo fw-bolder" value="0" selected>
                          {t("lbl13")}
                          </option>
                          <option className="fondo fw-bolder" value="1">
                            {t("lbl14")}
                          </option>
                          <option className="fondo fw-bolder" value="2">
                            {t("lbl15")}
                          </option>
                          <option className="fondo fw-bolder" value="4">
                            {t("lbl16")}
                          </option>
                          <option className="fondo fw-bolder" value="3">
                            {t("lbl17")}
                          </option>
                        </select>
                      </div>
                    </li>
                  </ul>

                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    onClick={() => resetList() }
                  >
                    {t("lbl18")}
                  </a>

                  <hr />
                </div>
              </div>
        
              <div class="col-12 col-sm-12 col-md-10 center-block ">
                {/* tabla de bits */}
                <div className="my-2">
                  {/* <div className="container my-2 ocultar ">
                    <div class="row  my-1 ">
                      <div class="col-1 text-center ">
                        <div className="fondo funeteblanca">#</div>
                      </div>

                      <div class="col text-center ">
                        <div className="fondo funeteblanca">{t("tbl1")}</div>
                      </div>

                      <div class="col text-center  ">
                        <div className="fondo funeteblanca">{t("tbl2")}</div>
                      </div>

                      <div class="col text-center  ">
                        <div className="fondo funeteblanca">{t("tbl3")}</div>
                      </div>

                      <div class="col text-center  ">
                        <div className="fondo funeteblanca">{t("tbl4")}</div>
                      </div>

                      <div class="col text-center  ">
                        <div className="fondo funeteblanca">{t("lbl24")}</div>
                      </div>

                      <div class="col-3 text-center  ">
                        <div className="fondo funeteblanca">{t("tbl5")}</div>
                      </div>
                    </div>
                  </div> */}

                    <div>
                      <div class="col text-center  ">
                      {
                          (loading == 1) ? 
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                          : ""
                        }
                      </div>
                      { 
                        ofertas.length > 0 ?
                        ofertas.map((oferta) => {
                          return (
                            <div className="container my-2">
                              <div className="accordion-item card sombra my-2">
                                <div className="accordion-header" id="headingOne">
                                  <div className="row  my-2 p-2">
                                    <div className="col-12 col-sm-12 col-md-1 text-center text-movil">
                                      <div class="row txtlargeAzul">
                                        <div class="text-center txtSmallAzul ">{oferta.id}</div>
                                      </div>
                                    </div>

                                    <div className="col text-center">
                                      <div class="text-center txtSmallerGris">{t("tbl1")} : </div>
                                      <div class="row txtlargeAzul">
                                        <div class="text-center txtSmallAzul">{oferta.title}</div>
                                      </div>
                                    </div>

                                    <div className="col text-center">
                                      <div class="text-center txtSmallerGris">{t("tbl2")}:</div>
                                      <div class="row txtlargeAzul">
                                        <div class="text-center txtSmallAzul">
                                          {oferta.company_nit}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col text-center">
                                      <div class="text-center txtSmallerGris">{t("tbl3")} : </div>
                                      <div class="row txtlargeAzul">
                                        <div class="text-center txtSmallAzul">
                                          {oferta.company}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col text-center">
                                      <div class="text-center txtSmallerGris">{t("tbl4")} : </div>
                                      <div class="row txtlargeAzul">
                                        <div class="text-center txtSmallAzul">
                                          {oferta.end_selection}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col text-center">
                                      <div class="text-center txtSmallerGris">{t("lbl24")} : </div>
                                      <div class="row txtlargeAzul">
                                        <div class="text-center txtSmallAzul">
                                          {oferta.end_offer}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-3 text-center">
                                      {/* <div class="text-center txtSmallerGris">{t("tbl5")} : </div> */}
                                      <div class="row justify-content-center align-items-center ">
                                        <div class="col-3">
                                          <div class="row">
                                            <div class="form-check form-switch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={oferta.id}
                                                onClick={() => {
                                                  handleSwitchChange(oferta);
                                                }}
                                                checked={(oferta.status.id == 1 || oferta.status.id == 4) ? true : false}
                                              />
                                            </div>
                                          </div>
                                          <div class="row">
                                            {oferta.status.description}
                                          </div>
                                        </div>

                                        <div class="col-3">
                                          {" "}
                                          <a
                                            href="javascript:void(0)"
                                            title={t("lbl20")}
                                            data-bs-toggle="collapse"
                                            data-bs-target={"#c" + oferta.id}
                                            aria-expanded="false"
                                            aria-controls={"c" + oferta.id}
                                            onClick={() => lenguaje(oferta)}
                                          >
                                            {"   "}
                                            <img
                                              width="30"
                                              height="30"
                                              class="icoOpciones mx-3"
                                              src={ver}
                                            />{" "}
                                          </a>
                                        </div>

                                        <div class="col-3">
                                          {" "}
                                          <a
                                            href="javascript:void(0)"
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalOfetas"
                                            title={t("lbl19")}
                                            onClick={() => listChat(oferta.id)}
                                          >
                                            {"   "}
                                            <img
                                              width="20"
                                              height="20"
                                              class="icoOpciones mx-3"
                                              src={comentariosimg}
                                            />{" "}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="collapse" id={"c" + oferta.id}>
                                  <div className="card card-body txtSmallerGris ">
                                    <div className="row">
                                      <div className=" col-md-6 ">
                                        {/* <div class="txtSmallAzul">{t("ttl2")}: {oferta.postulates}</div> */}

                                        <div class="txtSmallAzul mt-1">{t("tbl6")}</div>
                                        {oferta.positions}

                                        <div class="txtSmallAzul mt-1">{t("tbl7")}</div>

                                        {oferta.salary}

                                        <div class="txtSmallAzul mt-1">{t("tbl8")}</div>
                                        { oferta.experience_required }

                                        <div class="txtSmallAzul mt-1">{t("tbl9")}</div>
                                        {oferta.department}

                                        <div class="txtSmallAzul mt-1">{t("tbl10")}</div>
                                        {oferta.vacancy_numbers}

                                        <div class="txtSmallAzul mt-1">{t("tbl11")}</div>
                                        {oferta.description}
                                      </div>

                                      <div className=" col-md-6 ">
                                        <div class="txtSmallAzul">{t("tbl12")}</div>

                                        {oferta.languages.map((i) => {
                                          return <>{i.language.name + " - "}</>;
                                        })}

                                        <div class="txtSmallAzul mt-1">{t("tbl13")}</div>

                                        {oferta.schedule.name}

                                        <div class="txtSmallAzul mt-1">{t("tbl14")}</div>
                                        {oferta.country}

                                        <div class="txtSmallAzul mt-1">{t("tbl15")}</div>

                                        {oferta.postal_code}

                                        <div class="txtSmallAzul mt-1">{t("tbl16")}</div>
                                        {oferta.status.description}

                                        <div class="txtSmallAzul mt-1">{t("tbl17")}</div>
                                        {oferta.activities.map((i) => {
                                          return <>{i.activity ? i.activity.name + " - " : "" }</>;
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                        : 
                        <div class="alert alert-warning" role="alert">
                          {t("msg2")}
                        </div>
                      }
                      <div>{paginationElement}</div>
                    </div>
                </div>
              </div>
              {/* fin tabla vieja */}
            </div>
          </div>
        </div>


        {/* modal ofertas */}

        <div
          class="modal fade"
          id="modalOfetas"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog   modal-dialog-centered">
            {" "}
            .
            <div class="modal-content ">
              <div class="modal-header modalheader">
                <div class="container">
                  <div class="form-group mb-1 text-center ">
                    <img
                      className=" mt-2 img-fluid"
                      width="25px"
                      height="25px"
                      src={userimg}
                    />
                  </div>
                  <div class="form-group mb-2 text-center colormodal ">
                    {t("ttl3")}
                  </div>
                </div>
                <button

                  id="clocedModal"
                  type="button"
                  class="btn text-white colormodal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  >X</button>
              </div>

              <div className="linea lineamovil"></div>
              <div class="modal-body">
                <div className="anyClass anyClassMovil">
                  {comentarios.map((i) => {
                    if (i.type == 3) {
                      return (
                        <>
                          <div class="form-group mb-2 text-left mt-2 ">
                            <div class="row  my-1 ">
                              <div class="col-2 col-sm-2 col-md-2 ">
                                <img
                                  className=" mt-2 img-fluid ingMpvilchat"
                                  width="40px"
                                  height="40px"
                                  src={userchat}
                                />
                              </div>

                              <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                                <div className="txtSmallGris">
                                {i.name}
                                </div>

                                <p className="text-left"> {i.comment} </p>
                                <div class="txtSmallGris"> {i.created_at} </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div class="form-group mb-2  mt-2 ">
                            <div class="row  my-1   justify-content-end    ">
                              <div class=" col-5 col-sm-5 col-md-5 text-end mt-1 globo">
                                <div className="mt-2 txtSmallGris">
                                {i.name}
                                </div>

                                <p className="">
                                {i.comment}
                                </p>
                                <div class="txtSmallGris"> {i.created_at} </div>
                              </div>

                              <div class="col-2 col-sm-2 col-md-2 ">
                                <img
                                  className=" mt-2 img-fluid ingMpvilchat"
                                  width="40px"
                                  height="40px"
                                  src={userchat}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}
                </div>
              </div>

              <div class="modal-footer modalheader ">
                <div class="container">
                  <div class="row  my-1 ">
                    <div class="col-10 ">
                      <input
                        id="mensaje"
                        type="text"
                        name="mensaje"
                        className="mt-1 form-control imputTextModal"
                        aria-describedby="emailHelp"
                        placeholder={t("lbl21")}
                      />
                    </div>

                    <div class="col-2 text-left ">
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          send();
                        }}
                      >
                        <img
                          className=" mt-2 img-fluid"
                          width="20px"
                          height="20px"
                          src={sendimg}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default OfertasLaboralesAdmin;
