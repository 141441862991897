import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import UserProfile from "../Login/UserProfile";
import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";


const RegisterPosition = (props) => {

    const [t, i18n] = useTranslation("formcmnps")

    const [ loadd, setloadd ] = useState(0)

    const savePosition = (values, resetForm) =>{
        setloadd(1)
        axios
        .post(
            `${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, values,
            {      
            headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`, 
                }
            }
        )
        .then((res) => {
            props.addNewPosition(res.data.data)
            setloadd(0)
            resetForm()
        })
        .catch((error) => console.log(error));
    }
    

    return (
        <>
            <div>
                <Formik
                  initialValues={{
                    name: "",
                  }}
              
                  validationSchema={Yup.object().shape({
                    name:
                      Yup.string().required(t("fr"))
                  })}

                  onSubmit={(val, { resetForm }) => {
                    val.status = 2
                    savePosition(val, resetForm);
                  }}

                  render={({ errors, status, touched }) => (
                    <Form class="container mt-3">
                        <div class="form-group mb-4">
                            <label for="name" class="form-label">{t("lbl1")}</label>
                            <Field
                            id="name"
                            name="name"
                            type="text"
                            class="form-control imputText"
                            placeholder={t("lbl1")}
                            />

                            <ErrorMessage
                            name="name"
                            component="div"
                            className="msgerror mx-2"
                            />
                        </div>
                        {
                            loadd == 0 ?
                            <div class="divider d-flex align-items-center my-2">
                                <button
                                type="submit"
                                class="btn btn-warning  btn-block  buttons "
                                >
                                {t("lbl2")}
                                </button>
                            </div>
                            : 
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        }
                    </Form>
                  )}
                />
            </div>
        </>
    );
}

export default RegisterPosition;