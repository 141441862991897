import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker"
import Moment from 'moment';

import UserProfile from "../../Login/UserProfile";
import "bootstrap/dist/css/bootstrap.min.css";

const MyOffers = (props) => {

  const [t, i18n] = useTranslation("myoffers")

  const [ offerts, setofferts ] = useState([]);
  // const [ titleoffer, settitleoffer ] = useState([]);
  
  //selects
  // const [ countries, setcountries ] = useState([]);
  // const [ departments, setdepartments ] = useState([]);
  // const [ activities, setactivities ] = useState([]);
  // const [ positions, setpositions ] = useState([]);
  // const [ salaries, setsalaries ] = useState([]);
  // const [ schedules, setschedules ] = useState([]);
  
  //         // filtros aplicados
  const [ filtxtsearch, setfiltxtsearch ] = useState("");
  const [ filcountry, setfilcountry ] = useState("");
  const [ fildepartment, setfildepartment ] = useState("");
  const [ filactivity, setfilactivity ] = useState("");
  const [ filposition, setfilposition ] = useState("");
  const [ filsalary, setfilsalary ] = useState("");
  const [ filschedule, setfilschedule ] = useState("");
  const [ fildatestart, setfildatestart ] = useState(new Date());
  const [ fildateend, setfildateend ] = useState(new Date());
  
  //         // pagination
  const [ pageCount, setpageCount ] = useState(0);
  const [ pageNex, setpageNex ] = useState(0);
  const [ selectedPage, setselectedPage ] = useState(0);

      
    const validDate = (date) => {
      var fechauno = new Date();
      var fechados = new Date(date+"T00:00:00");
      return fechauno.getTime() > fechados.getTime();
    }

    const list = (search) => {
      let newsearch = {...search, page: selectedPage}
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/api/job_offers/searchmyoffers`,search, {
            headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`,
              },
              params: newsearch
          })
          .then((res) => {
              setofferts(res.data.data.job_offers)
              setpageCount(res.data.data.pages)
          })
          .catch((error) => console.log(error));
    }

    const changetextcollapse = (targt, txtone, txttwo) =>{
      targt.target.textContent = targt.target.ariaExpanded == "false" ? txtone : txttwo
    }

    const handleClickFilter = async () => {
        var search = {}
        if (filtxtsearch.length > 0) search.query = filtxtsearch
        if (filactivity > 0) search.activity = filactivity 
        if (filposition > 0) search.position = filposition 
        if (filcountry > 0) search.country = filcountry 
        if (fildepartment > 0) search.department = fildepartment 
        if (filschedule > 0) search.schedule = filschedule 
        if (filsalary > 0) search.salary = filsalary 
        list(search)
    }

    // changes dates
    useEffect(() => {
      var search = {}
      // if (filtxtsearch.length > 0) search.query = filtxtsearch
      // if (filcompany > 0) search.company = filcompany
      // if (filidentcompan > 0) search.companynit = filidentcompan
      // if (filcountry > 0) search.country = filcountry
      // if (fildepartment > 0) search.department = fildepartment
      // if (filactivity > 0) search.activity = filactivity
      // if (filposition > 0) search.position = filposition
      // if (filsalary > 0) search.salary = filsalary
      // if (filschedule > 0) search.schedule = filschedule
      // if (filstatus > 0) search.status = filstatus
      search.datestart = Moment(fildatestart).format("YYYY-MM-DD")
      search.dateend = Moment(fildateend).format("YYYY-MM-DD")
      list(search)
    }, [fildatestart, fildateend])

    const handlePageClick = (val) => {
        setselectedPage(val.selected+1)
        setpageNex(val.selected)
    }

 useEffect(() => {
  handleClickFilter()
}, [filtxtsearch, selectedPage, filcountry, fildepartment, filactivity, filposition, filsalary, filschedule,])

    useEffect(() => {
        list()
    }, [])
    
        let paginationElement
        if(pageCount > 1) {
            paginationElement = (
                <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Sig"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
                onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
                forcePage={pageNex}
                containerClassName={"pagination justify-content-center"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                />
            )
        }

        return (
          <>
            <div class="item-group" xyz="fade">
              <div class="square xyz-in">
                <div class="row  d-flex  h-100  filtrosfondoxz">
                  <div class="col-12 col-sm-12 col-md-2 filtrosopcioes  ">
                    <div class=" row filtrosfondo">
                      <div class="filtros mx-2 my-4">{t("ttl1")}</div>
                    </div>
    
                    {/* <div class="row my-3">
                      <div class="form-group mb-4 col-md-12">
                        <input
                          id="phone_number"
                          type="text"
                          name="phone_number"
                          className="form-control imputTextSelect mx-1"
                          placeholder={t("lbl1")}
                          onChange={(e)=>setfiltxtsearch(e.target.value)}
                        />
                      </div>

                      <hr />
                    </div> */}
                    <div class="row my-3">
                      <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#date"}
                        aria-expanded="false"
                        aria-controls={"date"}
                        >
                        {t("lbl4")}
                      </a>
                      <ul className="collapse mx-1" id={"date"}>
                        <li class="mt-2">
                          <div class="form-group col-md-12">
                            <label htmlFor="fildatestart">{t("ttl2")}</label>
                            <DatePicker 
                              selected={fildatestart}
                              dateFormat="Y-M-d"
                              className="form-control"
                              id="fildatestart"
                              name="fildatestart"
                              onChange={(e) => {
                                setfildatestart(e)
                              }}
                            />
                          </div>
                          <div class="form-group mb-2 col-md-12">
                            <label htmlFor="fildateend">{t("ttl3")}</label>
                            <DatePicker 
                              selected={fildateend}
                              dateFormat="Y-M-d"
                              className="form-control"
                              id="fildateend"
                              name="fildateend"
                              onChange={(e) => {
                                setfildateend(e)
                              }}
                            />
                          </div>
                        </li>
                      </ul>
                      <hr />
                    </div>
                  </div>
    
                  <div class="col-12 col-sm-12 col-md-10 center-block ">
                    {/* tabla de bits */}
                    <div className="my-5">
                      {offerts.map((i) => {
                          return (
                            <div className="container my-2">
                              <div className="accordion-item card sombra my-2">
    
    
                                <div className="accordion-header" id="headingOne">
                                  <div className="row  my-2 p-2">
                                    <div className="col-md-2 col-sm-12 text-center"></div>
    
                                    <div className="col-md-5 col-sm-12 text-center">
                                      <div class="row txtlargeAzul">
                                        {i.title}
                                        <br />
                                        <div class="row txtSmallGris">
                                            { i.description.substr(0, 50) } ...
                                        </div>
                                      </div>
                                    </div>
    
                                    <div className="col-md-1 col-sm-12 text-center border-end">
                                      <div className="row txtSmallGris ">
                                        {i.end_offer}
                                      </div>
    
                                      <div className="row txtSmallAzul text-center">
                                        {t("lbl2")}
                                      </div>
                                    </div>
    
                                    <div className="col-md-2 col-sm-12 text-center">
                                      <button
                                        className="btn btn-warning buttonsfcontent"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={
                                          "#c" + i.id
                                        }
                                        aria-expanded="false"
                                        aria-controls={
                                          "c" + i.id
                                        }
                                        onClick={(e)=>changetextcollapse(e, t("lbl3"), t("lbl13") )}
                                      >
                                        {t("lbl3")}
                                      </button>
                                    </div>
                                    <div className="col">
                                          {/* <div class="txtSmallAzul mt-1">{t("lbl12")}</div> */}
                                          <button
                                              className={i.selected != 1 ? 
                                                validDate(i.end_selection) ?
                                                  "btn btn-warning disabled buttonsfcontent" :
                                                "btn btn-warning disabled buttonsfcontent" : "btn btn-success disabled buttonsfcontent"}
                                              type="button"
                                          >
                                              {i.selected != 1 ? 
                                                validDate(i.end_selection) ?
                                                  t("sts1") :
                                                t("sts2") : t("sts3")}
                                          </button>
                                        </div>
                                  </div>
                                </div>
    
                                <div
                                  className="collapse"
                                  id={"c" + i.id}
                                >
                                  <div className="card card-body txtSmallerGris ">
                                    
                                    <div className="row">
                                      <div className=" col-md-6 ">
                                        <div class="txtSmallAzul mt-1">{t("lbl4")}</div>
                                        {i.end_selection}
    
                                        <div class="txtSmallAzul mt-1">{t("lbl5")}</div>
                                        {/* {
                                          props.lng == 'en' ?
                                            i.experience_required :
                                          props.lng == 'es' ?
                                            i.experience_required_es :
                                          props.lng == 'fr' ?
                                            i.experience_required_fr : ""
                                        } */}
                                        { i.experience_required }

                                        <div class="txtSmallAzul mt-1">
                                          {t("lbl6")}
                                        </div>
                                        {i.vacancy_numbers}
    
                                        <div class="txtSmallAzul">{t("lbl7")}</div>
                                        {i.description}
                                      </div>
    
                                      <div className=" col-md-6 ">
                                        <div class="txtSmallAzul">{t("lbl8")}</div>
                                        {/* {
                                          i.activities.map((act)=>act.activity ? 
                                            props.lng == 'en' ?
                                              act.activity.name :
                                            props.lng == 'es' ?
                                              act.activity.name_es :
                                            props.lng == 'fr' ?
                                              act.activity.name_fr : ""
                                          +" - " : "")
                                        } */}
                                        {
                                          i.activities.map((act)=>act.activity ? 
                                              act.activity.name
                                          + " - " : "")
                                        }
                                        <div class="txtSmallAzul mt-1">{t("lbl9")}</div>
                                        {/* {
                                          props.lng == 'en' ?
                                            i.department :
                                          props.lng == 'es' ?
                                            i.department_es :
                                          props.lng == 'fr' ?
                                            i.department_fr : ""
                                        } */}
                                        {
                                            i.department
                                        }
    
                                        <div class="txtSmallAzul mt-1">{t("lbl10")}</div>
                                        {/* {
                                          props.lng == 'en' ?
                                            i.positions :
                                          props.lng == 'es' ?
                                            i.positions_es :
                                          props.lng == 'fr' ?
                                            i.positions_fr : ""
                                        } */}
                                        { i.positions }
                                        
                                        <div class="txtSmallAzul mt-1">{t("lbl11")}</div>
                                        {i.salary}
                                      </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                          <div class="txtSmallAzul mt-1">{t("lbl12")}</div>
                                          <button
                                              className={i.selected != 1 ? 
                                                validDate(i.end_selection) ?
                                                  "btn btn-warning disabled buttonsfcontent" :
                                                "btn btn-warning disabled buttonsfcontent" : "btn btn-success disabled buttonsfcontent"}
                                              type="button"
                                          >
                                              {i.selected != 1 ? 
                                                validDate(i.end_selection) ?
                                                  t("sts1") :
                                                t("sts2") : t("sts3")}
                                          </button>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                      })}
                    </div>
                    {paginationElement}
                  </div>
                </div>
              </div>
            </div>
            
            
          </>
        )
  }

export default MyOffers