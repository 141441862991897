import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReactPaginate from "react-paginate";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

import UserProfile from "../../Login/UserProfile";
import "bootstrap/dist/css/bootstrap.min.css";

import AspiranteApplicant from "./aspiranteApplicant";

const InicioApplicant = (props) => {

const [t, i18n] = useTranslation("homeappl")
// i18n.changeLanguage("")
const [ limitdescr, setlimitdescr ] = useState(200);


  const [ chekDespliega, setchekDespliega ] = useState(false);
  const [ chekDespliegaCargo, setchekDespliegaCargo ] = useState(false);
  const [ estado, setestado ] = useState(false);
  
  const [ idoffert, setidoffert ] = useState(0);
  const [ oferts, setoferts ] = useState([]);
  const [ country, setcountry ] = useState([]);
  const [ titleoffer, settitleoffer ] = useState([]);

  const [ cities, setcities ] = useState([]);
  
  //selects
  const [ countries, setcountries ] = useState([]);
  const [ departments, setdepartments ] = useState([]);
  const [ activities, setactivities ] = useState([]);
  const [ positions, setpositions ] = useState([]);
  const [ salaries, setsalaries ] = useState([]);
  const [ schedules, setschedules ] = useState([]);
  
  //         // filtros aplicados
  const [ filcountry, setfilcountry ] = useState("");
  const [ fildepartment, setfildepartment ] = useState("");
  const [ filcity, setfilcity ] = useState("");
  const [ filactivity, setfilactivity ] = useState("");
  const [ filposition, setfilposition ] = useState("");
  const [ filsalary, setfilsalary ] = useState("");
  const [ filschedule, setfilschedule ] = useState("");
  
  //         // pagination
  const [ offset, setoffset ] = useState(0);
  const [ pageCount, setpageCount ] = useState(0);
  const [ perPage, setperPage ] = useState(0);
  const [ identificador, setidentificador ] = useState(0);
  const [ targetmodel, settargetmodel ] = useState(0);
  const [ pageNex, setpageNex ] = useState(0);
  const [ selectedPage, setselectedPage ] = useState(0);


      const getCountry = async () => {
        await axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`,
          {      
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            }
          })
          .then((res) => {
            setcountries(res.data.data.country)
          });
      }

      const getDeparmentByCountry = ({id}) => {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`,
            {
              headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`,
              },
            })
          .then((res) => {
            setdepartments(res.data.data.department)
          });
      }

      const getCityByDepartment = ({id}) => {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/cities/by_department/${id}`,
            {
              headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`,
              },
            }
          )
          .then((res) => {
            setcities(res.data.data.city);
            // setdepartmentselect(id);
          });
      }

      
      const getActivity = async () => {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/attrs/activities`)
          .then((res) => {
            setactivities(res.data.data.activities)
          });
      };

      const getPositions = async () => {
        await axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            setpositions(res.data.data.positions)
          });
      };

      const getSalaries = async () => {
        await axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/salary_ranges`)
          .then((res) => {
            setsalaries(res.data.data.salary_ranges)
          });
      }

      const getSchedule = async () => {
        await axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/work_hours`)
          .then((res) => {
            setschedules(res.data.data.work_hours)
          });
      }
      
      const changeTitle = ({title, idoffer}) => {
          settitleoffer(title)
          setidoffert(idoffer)
      }
    
      const close = async (values) => {
        document.getElementById("clocedModal").click()
      }

      const validprofile = async () => {
        const valid = await axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/has_profile_applicant`,
        {      
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          }
        })
        .then((res) => {
          if(res.data.status===true){
            return true
          }else{
            return false
          }
        });
        return valid
      }

      const saveApply = async (values) => {
        const valid = await validprofile()
        if (!valid) {
          props.history.push("/registerapplicant")
          return
        }

        var data = {
            "offer": values.offer,
            "reason": values.reason,
            "applicant": 1,
        }
        axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/applicant_offers`,data, {
          headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            }
        })
        .then((res) => {
            if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
            if (!res.data.status) {
                Swal.fire({
                    icon: "warning",
                    title: t("msg1"),
                    text: res.data.error[0],
                    confirmButtonColor: "#071b30",
                    confirmButtonText: t("ok"),
                  });
                  return
            }
            Swal.fire({
                icon: "success",
                title: t("msg2"),
                confirmButtonColor: "#071b30",
                confirmButtonText: t("ok"),
              });
        })
        .catch((error) => console.log(error));
      }

      const confirmeditprofile = (values) => {
        Swal.fire({
          title: t("msg4"),
          showDenyButton: true,
          confirmButtonText: t("lbl22"),
          denyButtonText: t("lbl10"),
          denyButtonColor: "#ffc107"
        }).then((result) => {
          if (result.isConfirmed) {
            document.getElementById('btnmdleditapplicant').click()
            return
          }else{
            saveApply(values)
          }
        })
      }

      // const autoSaveApply = (data) => {
      //   if (localStorage.getItem('applyoffer') === null || localStorage.getItem('applyoffer').length < 1) {
      //     return
      //   }
      //   var data = JSON.parse(localStorage.getItem('applyoffer'))
      //   axios
      //   .post(`${process.env.REACT_APP_BASE_URL}/api/applicant_offers`,data, {
      //     headers: {
      //         Authorization: `Bearer  ${UserProfile.getToken()}`,
      //       }
      //   })
      //   .then((res) => {
      //       if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
      //       if (!res.data.status) {
      //           Swal.fire({
      //               icon: "warning",
      //               title: t("msg1"),
      //               text: res.data.error[0],
      //               confirmButtonColor: "#071b30",
      //               confirmButtonText: t("ok"),
      //             });
      //             return
      //       }
      //       Swal.fire({
      //           icon: "success",
      //           title: t("msg2"),
      //           confirmButtonColor: "#071b30",
      //           confirmButtonText: t("ok"),
      //         });
      //   })
      //   .catch((error) => console.log(error));
      //   localStorage.removeItem('applyoffer')
      // }

      const list = (search) => {
        let newsearch = {...search, page: selectedPage}
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/api/job_offers/search`,search, {
              headers: {
                  Authorization: `Bearer  ${localStorage.getItem('token')}`,
                },
                params: newsearch
            })
            .then((res) => {
                setoferts(res.data.data.job_offers)
                setpageCount(res.data.data.pages)
            })
            .catch((error) => console.log(error));
      }

    const resetList  = async () => {
        var search = {}
        setfilactivity(0)
        setfilposition(0)
        setfilcountry(0)
        setfildepartment(0)
        setfilcity(0)
        setfilschedule(0)
        setfilsalary(0)
        list(search)
    }

    const handleClickFilter = async () => {
        var search = {}
        if (filactivity > 0) search.activity = filactivity 
        if (filposition > 0) search.position = filposition 
        if (filcountry > 0) search.country = filcountry 
        if (fildepartment > 0) search.department = fildepartment
        if (filcity > 0) search.city = filcity
        if (filschedule > 0) search.schedule = filschedule 
        if (filsalary > 0) search.salary = filsalary 
        list(search)
    }

    const handlePageClick = (val) => {
        setselectedPage(val.selected+1)
        setpageNex(val.selected)
    }

 useEffect(() => {
  handleClickFilter()
}, [selectedPage, filcountry, fildepartment, filcity, filactivity, filposition, filsalary, filschedule,])


  const changetextcollapse = (targt, txtone, txttwo) =>{
    targt.target.textContent = targt.target.ariaExpanded == "false" ? txtone : txttwo
  }

    useEffect(() => {
        getCountry()
        getActivity()
        getPositions()
        getSalaries()
        getSchedule()
        list()
        // autoSaveApply()
    }, [])
    

        let paginationElement
        
        if(pageCount > 1) {
            paginationElement = (
                <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Sig"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
                onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
                forcePage={pageNex}
                containerClassName={"pagination justify-content-center"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                />
            )
        }

        return (
          <>
            <div class="item-group" xyz="fade">
              <div class="square xyz-in">
                <div class="row  d-flex  h-100  filtrosfondoxz">
                  <div class="col-12 col-sm-12 col-md-2 filtrosopcioes  ">
                    <div class=" row filtrosfondo">
                      <div class="filtros mx-2 my-4">{t("ttl1")}</div>
                    </div>
    
                    <div class="row my-3">
                        <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#countr"}
                        aria-expanded="false"
                        aria-controls={"countr"}
                        >
                        {t("lbl1")}
                        </a>

                        <ul className="collapse mx-3" id={"countr"}>
                            <li class="mt-2">
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="filcountry"
                                        name="filcountry"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        onChange={async (e) => (await setfilcountry(e.target.value), getDeparmentByCountry({id: e.target.value}))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl1")}
                                        </option>

                                        {countries.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                              {
                                                props.lng == 'en' ?
                                                  i.name :
                                                props.lng == 'es' ?
                                                  i.name_es :
                                                props.lng == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="fildepartment"
                                        name="fildepartment"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        // onChange={async (e) => (await setfildepartment(e.target.value),handleClickFilter())}
                                        onChange={(e) => (setfildepartment(e.target.value), getCityByDepartment({id: e.target.value, fil: 1}))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl2")}
                                        </option>

                                        {departments.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                              {
                                                props.lng == 'en' ?
                                                  i.name :
                                                props.lng == 'es' ?
                                                  i.name_es :
                                                props.lng == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="filcity"
                                        name="filcity"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        onChange={(e) => (setfilcity(e.target.value))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl21")}
                                        </option>

                                        {cities.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {/* {i.name} */}
                                                {
                                                  props.lng == 'en' ?
                                                    i.name :
                                                  props.lng == 'es' ?
                                                    i.name_es :
                                                  props.lng == 'fr' ?
                                                    i.name_fr : ""
                                                }
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                            </li>
                        </ul>
                        <hr />
                    </div>

                    <div class="row my-3">
                        <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#posi"}
                        aria-expanded="false"
                        aria-controls={"posi"}
                        >
                        {t("lbl3")}
                        </a>

                        <ul className="collapse mx-3" id={"posi"}>
                            <li class="mt-2">
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="filposition"
                                        name="filposition"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        onChange={async (e) => (setfilposition(e.target.value))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl3")}
                                        </option>

                                        {positions.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                              {
                                                props.lng == 'en' ?
                                                  i.name :
                                                props.lng == 'es' ?
                                                  i.name_es :
                                                props.lng == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                            </li>
                        </ul>
                        <hr />
                    </div>

                    <div class="row my-3">
                        <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#activ"}
                        aria-expanded="false"
                        aria-controls={"activ"}
                        >
                        {t("lbl4")}
                        </a>

                        <ul className="collapse mx-3" id={"activ"}>
                            <li class="mt-2">
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="activity"
                                        name="activity"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        onChange={async (e) => (setfilactivity(e.target.value))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl4")}
                                        </option>

                                        {activities.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                              {
                                                props.lng == 'en' ?
                                                  i.name :
                                                props.lng == 'es' ?
                                                  i.name_es :
                                                props.lng == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                            </li>
                        </ul>
                        <hr />
                    </div>

                    <div class="row my-3">
                        <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#salar"}
                        aria-expanded="false"
                        aria-controls={"salar"}
                        >
                        {t("lbl5")}
                        </a>

                        <ul className="collapse mx-3" id={"salar"}>
                            <li class="mt-2">
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="salary"
                                        name="salary"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        onChange={async (e) => (setfilsalary(e.target.value),handleClickFilter())}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl5")}
                                        </option>

                                        {salaries.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                              {i.name}
                                                {/* {
                                                props.lng == 'en' ?
                                                  i.name :
                                                props.lng == 'es' ?
                                                  i.name_es :
                                                props.lng == 'fr' ?
                                                  i.name_fr : ""
                                                } */}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                            </li>
                        </ul>
                        <hr />
                    </div>

                    {/* <div class="row my-3">
                        <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#sched"}
                        aria-expanded="false"
                        aria-controls={"sched"}
                        >
                        {t("lbl6")}
                        </a>

                        <ul className="collapse mx-3" id={"sched"}>
                            <li class="mt-2">
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="schedule"
                                        name="schedule"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label="Default select example"
                                        onChange={async (e) => (setfilschedule(e.target.value),handleClickFilter())}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl6")}
                                        </option>

                                        {schedules.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {i.name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                            </li>
                        </ul>
                        <hr />
                    </div> */}
    
                    <div class="row my-3">
                      <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        onClick={() =>resetList()}
                      >
                        {t("lbl7")}
                      </a>
                      <hr />
                    </div>
                  </div>
    
                  <div class="col-12 col-sm-12 col-md-10 center-block ">
                    {/* tabla de bits */}
                    <div className="my-5">
                      {oferts.map((i) => {
                          return (
                            <div className="container my-2">
                              <div className="accordion-item card sombra my-2">
                                <div className="accordion-header" id="headingOne">
                                  <div className="row  my-2 pt-2 justify-content-center">
                                    {/* <div className="col-md-1 col-sm-12 text-center"></div> */}
    
                                    <div className="col-md-5 col-sm-12 text-center">
                                      <div class="row txtlargeAzul">
                                        {i.title}
                                        <br />
                                        <div class="row txtSmallGris">
                                            { i.description.substr(0, 50) } ...
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-1 col-sm-12 text-center">
                                      <div className="row txtSmallGris ">
                                        {
                                          props.lng == 'en' ?
                                            i.country :
                                          props.lng == 'es' ?
                                            i.country_es :
                                          props.lng == 'fr' ?
                                            i.country_fr : ""
                                        }
                                      </div>
    
                                      <div className="row txtSmallAzul text-center">
                                        {t("lbl1")}
                                      </div>
                                    </div>
    
                                    <div className="col-md-1 col-sm-12 text-center border-end">
                                      <div className="row txtSmallGris ">
                                        {i.end_offer}
                                      </div>
    
                                      <div className="row txtSmallAzul text-center">
                                        {t("lbl8")}
                                      </div>
                                    </div>
    
                                    <div className="col-md-2 col-sm-12 text-center">
                                      <button
                                        className="btn btn-warning  buttonsMirarMAs"
                                        type="button"
                                        id={"btnc" + i.id}
                                        data-bs-toggle="collapse"
                                        data-bs-target={
                                          "#c" + i.id
                                        }
                                        aria-expanded="false"
                                        aria-controls={
                                          "c" + i.id
                                        }
                                        onClick={(e)=>changetextcollapse(e, t("lbl9"), t("lbl20") )}
                                      >
                                        {t("lbl9")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
    
                                <div
                                  className="collapse"
                                  id={"c" + i.id}
                                >
                                  <div className="card card-body txtSmallerGris ">
                                    
                                    <div className="row">
                                      <div className=" col-md-6 ">
                                        <div class="txtSmallAzul mt-1">{t("lbl11")}</div>
                                        {i.end_selection}
    
                                        <div class="txtSmallAzul mt-1">{t("lbl19")}</div>
                                        {
                                          props.lng == 'en' ?
                                            i.experience_required :
                                          props.lng == 'es' ?
                                            i.experience_required_es :
                                          props.lng == 'fr' ?
                                            i.experience_required_fr : ""
                                        }

                                        <div class="txtSmallAzul mt-1">
                                          {t("lbl12")}
                                        </div>
                                        {i.vacancy_numbers}
    
                                        <div class="txtSmallAzul">{t("lbl13")}</div>
                                        {i.description}
                                      </div>
    
                                      <div className=" col-md-6 ">
                                        <div class="txtSmallAzul mt-1">{t("lbl15")}</div>
                                        {
                                          props.lng == 'en' ?
                                            i.department :
                                          props.lng == 'es' ?
                                            i.department_es :
                                          props.lng == 'fr' ?
                                            i.department_fr : ""
                                        } - 
                                        {
                                          props.lng == 'en' ?
                                            i.country :
                                          props.lng == 'es' ?
                                            i.country_es :
                                          props.lng == 'fr' ?
                                            i.country_fr : ""
                                        }

                                        <div class="txtSmallAzul mt-1">{t("lbl5")}</div>
                                        {i.salary}
    
                                        <div class="txtSmallAzul mt-1">{t("lbl3")}</div>
                                        {
                                          props.lng == 'en' ?
                                            i.positions :
                                          props.lng == 'es' ?
                                            i.positions_es :
                                          props.lng == 'fr' ?
                                            i.positions_fr : ""
                                        }

                                        <div class="txtSmallAzul">{t("lbl14")}</div>
                                        {
                                          i.activities.map((act)=>act.activity ? 
                                            props.lng == 'en' ?
                                              act.activity.name :
                                            props.lng == 'es' ?
                                              act.activity.name_es :
                                            props.lng == 'fr' ?
                                              act.activity.name_fr : ""
                                            + " - " : "" )
                                        }
                                      </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                          {
                                            i.appl == localStorage.getItem('id') ?
                                              <button
                                                  className="btn btn-success disabled buttonsMirarMAs"
                                                  type="button"
                                              >
                                                  {t("msg3")}
                                              </button>
                                            : 
                                              <button
                                                  className="btn btn-info buttonsMirarMAs"
                                                  type="button"
                                                  // data-bs-toggle="modal"
                                                  // data-bs-target="#mdlapplyoffer"
                                                  // onClick={changeTitle.bind(this, {title:i.title, idoffer: i.id})}
                                                  onClick={()=>{ confirmeditprofile({ reason:"", offer: i.id }) }}
                                              >
                                                  {t("lbl10")}
                                              </button>
                                          }
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                      })}
                    </div>
                    {paginationElement}
                  </div>
                </div>
              </div>
            </div>
            
            {/* modal para aplicar a x oferta */}
            <div class="modal fade" id="mdlapplyoffer" tabindex="-1" aria-labelledby="mdlapplyoffer" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="mdlapplyoffer">{t("ttl2")} {titleoffer}</h5>
                        <button id="clocedModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <Formik
                            initialValues={{
                                reason: ""
                            }}

                            validationSchema={Yup.object().shape({
                                reason: Yup.string()
                            })}

                            validate={(values) => {
                              setlimitdescr(200 - values.reason.length)
                            }}

                            onSubmit={(val, { resetForm }) => {
                                saveApply(val);
                                resetForm();
                                close();
                            }}
                            render={({ errors, status, touched }) => (

                            <Form class="container">
                                <div class="form-group mb-4">
                                    <Field
                                        id="reason"
                                        name="reason"
                                        component="textarea"
                                        class="form-control imputText"
                                        placeholder={t("lbl16")}
                                        maxlength="200"
                                    />

                                    <ErrorMessage
                                        name="reason"
                                        component="div"
                                        className="msgerror mx-2"
                                    />
                                    <label for="description" class="form-label">{limitdescr}</label>
                                </div>

                                <div class="form-group align-items-center my-2">
                                    <button
                                        type="submit"
                                        class="btn btn-warning  btn-block  buttons "
                                    >
                                        {t("lbl17")}
                                    </button>
                                </div>
                            </Form>
                            )}
                        />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl18")}</button>
                    </div>
                    </div>
                </div>
            </div>

            {/* modal para aplicar a x oferta */}
            <button class="d-none" id="btnmdleditapplicant" type="button" data-bs-toggle="modal" data-bs-target="#mdleditapplicant">open</button>
            <div class="modal fade" id="mdleditapplicant" tabindex="-1" aria-labelledby="mdleditapplicant" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="mdleditapplicant">{t("lbl23")}</h5>
                        <button id="clocedModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <AspiranteApplicant  history={props.history} lng={props.lng}  />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl18")}</button>
                    </div>
                    </div>
                </div>
            </div>
          </>
        )
  }

export default InicioApplicant