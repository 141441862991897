import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";

import Swal from "sweetalert2";

import axios from "axios";

import basura from "../../img/Basura.png";
import editar from "../../img/edita.png";

import listplas from "../../img/listplas.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import imgmodal from "../../img/imgmodal.png";
import sendimg from "../../img/send.png";
import userimg from "../../img/user.png";
import ver from "../../img/ver.png";
import userchat from "../../img/userchat.png";
import listicon from "../../img/list.png";
import ReactPaginate from "react-paginate";

const EmpresasAdmin = (props) => {

const [t, i18n] = useTranslation("companiesadm")
  
  const [ loading, setloading ] = useState(0);
  const [ companies, setcompanies ] = useState([]);
  const [ representantes, setrepresentantes ] = useState([]);
  
  const [ comentarios, setcomentarios ] = useState([]);
  const [ jobofert, setjobofert ] = useState([]);
  
  const [ datos, setdatos ] = useState([]);
  const [ countryName, setcountryName ] = useState("");
  const [ deparmetName, setdeparmetName ] = useState("");
  const [ estadoName, setestadoName ] = useState("");
  
  const [ idEmpresa, setidEmpresa ] = useState("");
  const [ idUser, setidUser ] = useState(0);
  
  const [ user, setuser ] = useState(0);
  const [ countries, setcountries ] = useState([]);
  const [ departments, setdepartments ] = useState([]);
  const [ activities, setactivities ] = useState([]);

  // filtros aplicados
  const [ filname, setfilname ] = useState("");
  const [ filnit, setfilnit ] = useState("");
  const [ filcountry, setfilcountry ] = useState("");
  const [ fildepartment, setfildepartment ] = useState("");
  const [ filactivity, setfilactivity ] = useState("");
  const [ filstatus, setfilstatus ] = useState("");

  //     /*paginacion*/
  const [ pageCount, setpageCount ] = useState(0);
  const [ selectedPage, setselectedPage ] = useState(1);
  const [ pageNex, setpageNex ] = useState(0);

  
  const listChat = async () => {
    setcomentarios([])
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/commentcompany/${idEmpresa}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcomentarios(res.data.data.comment_company)
      });
  }

  const send = async () => {
    if (document.getElementById("mensaje").value != "") {
      var data = {
        comment: document.getElementById("mensaje").value,
        company: idEmpresa,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/comment_company`, data, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            listChat();
          }
        })
        .catch((error) => console.log(error));
        document.getElementById("mensaje").value=""
    }
  }

  const getCountry = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/country`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcountries(res.data.data.country)
      });
  };

  const getActivity = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/activities`)
      .then((res) => {
        setactivities(res.data.data.activities)
      });
  };


  const openModal = async (value, pais, departamento, estado, empresa, usuario) => {
    console.log(value);
    setdatos([])
    setrepresentantes([])

    setcountryName("")
    setdeparmetName("")
    setestadoName("")

    setdatos(value)
    setrepresentantes(value.legal_representative)
    setcountryName(pais)
    setidEmpresa(empresa)
    setidUser(usuario)
    setdeparmetName(departamento)
    setestadoName(estado)
    document.getElementById("openModal").click();
  };

  const openModalOfertas = async (idCompany) => {
    setjobofert([])
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/job_offers?company=${idCompany}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setjobofert(res.data.data.job_offers)
      });
    document.getElementById("openModalOfertas").click();
  };


  const getDeparmentByCountry = ({id}) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`)
      .then((res) => {
        setdepartments(res.data.data.department)
      });
  }

  const downloaddocgettoken = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/companies/download/document?company=${idEmpresa}`,{
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg2"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        downloaddoc(res.data.data.token_download)
      });
  }

  const downloaddoc = (token) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/companies/download/document/${token}?company=${idEmpresa}`,{
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        const fileName = res.data.data.name;
        const linkSource = `data:application/pdf;base64,${res.data.data.pdf}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  }

  const list = async (search) => {
    setcompanies([])
    setloading(1)
    let newsearch = {...search, page: selectedPage}
    if (props.dataxtr > 0) {
      newsearch = {id: props.dataxtr}
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/companies`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
          params: newsearch
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setcompanies(res.data.data.companies )
        setpageCount(res.data.data.pages)
        setloading(0)
      })
      .catch((error) => console.log(error));
  };

  const resetList  = async () => {
    var search = {}
    setfilname("")
    setfilnit("")
    setfilcountry(0)
    setfildepartment(0)
    setfilactivity(0)
    setfilstatus(0)
    list(search)
}

const handleClickFilter = async () => {
    var search = {}
    if (filname.length > 0) search.name = filname 
    if (filnit.length > 0) search.nit = filnit
    if (filcountry > 0) search.country = filcountry 
    if (fildepartment > 0) search.department = fildepartment 
    if (filactivity > 0) search.company_activities_activity = filactivity
    if (filstatus > 0) search.status = filstatus
    list(search)
}

const handlePageClick = async (val) => {
    setselectedPage(val.selected+1)
    setpageNex(val.selected)
}

useEffect(() => {
  handleClickFilter()
},[filname, filnit, filcountry, fildepartment, filactivity, filstatus, selectedPage])

  const handleSwitchChange = async (value) => {
    var values = []
    if (value.status.id == 1) {
      values = {
        status: 2,
      };
    }else{
      values = {
        status: 1,
      };
    }
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/companies/status/${value.id}`,values ,{
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status == true) {
            list();
          }
        })
        .catch((error) => console.log(error));
  };


  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
      getCountry();
      getActivity()
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

  useEffect(() => {
    list();
  }, [props.timeseg])


    let paginationElement;
    if (pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Sig"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
          onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
          forcePage={pageNex}
          containerClassName={"pagination justify-content-center"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
            <div class="row  d-flex  h-100  filtrosfondoxz">
              <div class="col-12 col-sm-12 col-md-2 filtrosopcioes  ">
                <div class=" row filtrosfondo">
                  <div class="filtros mx-2 my-4">{t("ttl1")}</div>
                </div>

                <div class="row my-3">
                  <div class="form-group mb-4 mx-1 col-md-12">
                  <label htmlFor="namecomp">{t("lbl21")}</label>
                    <input
                      id="namecomp"
                      type="text"
                      name="namecomp"
                      className="form-control imputTextSelect mx-1"
                      aria-describedby="emailHelp"
                      placeholder={t("lbl21")}
                      onChange={(e) => setfilname(e.target.value)}
                    />
                  </div>

                  <div class="form-group mb-4 mx-1 col-md-12">
                    <label htmlFor="nitcompany">{t("lbl22")}</label>
                    <input
                      id="nitcompany"
                      type="text"
                      name="nitcompany"
                      className="form-control imputTextSelect mx-1"
                      aria-describedby="emailHelp"
                      placeholder={t("lbl22")}
                      onChange={(e) => setfilnit(e.target.value)}
                    />
                  </div>

                  <hr />
                </div>

                <div class="row my-3">
                        <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#countr"}
                        aria-expanded="false"
                        aria-controls={"countr"}
                        >
                          {t("lbl3")}
                        </a>

                        <ul className="collapse mx-3" id={"countr"}>
                            <li class="mt-2">
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="filcountry"
                                        name="filcountry"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label={t("lbl2")}
                                        onChange={(e) => (setfilcountry(e.target.value), getDeparmentByCountry({id: e.target.value}))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                        {t("lbl3")}
                                        </option>

                                        {countries.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {i.name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="fildepartment"
                                        name="fildepartment"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label={t("lbl2")}
                                        onChange={(e) => (setfildepartment(e.target.value))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                            {t("lbl4")}
                                        </option>

                                        {departments.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {i.name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                            </li>
                        </ul>
                        <hr />
                    </div>

                    <div class="row my-3">
                        <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#activities"}
                        aria-expanded="false"
                        aria-controls={"activities"}
                        >
                          {t("lbl20")}
                        </a>

                        <ul className="collapse mx-3" id={"activities"}>
                            <li class="mt-2">
                                <div class="form-group mb-4 col-md-11">
                                    <select
                                        id="filactivity"
                                        name="filactivity"
                                        className="form-select fondoamarillo imputTextSelect"
                                        aria-label={t("lbl20")}
                                        onChange={(e) => (setfilactivity(e.target.value))}
                                    >
                                        <option className="fondo fw-bolder" value="" selected>
                                        {t("lbl20")}
                                        </option>

                                        {activities.map((i) => {
                                        return (
                                            <option className="fondo fw-bolder" value={i.id}>
                                                {i.name}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                            </li>
                        </ul>
                        <hr />
                    </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#estado"}
                    aria-expanded="false"
                    aria-controls={"estado"}
                  >
                    {t("lbl5")}
                  </a>

                  <ul className="collapse mx-3" id={"estado"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                          id="departmen"
                          name="departmen"
                          className="form-select fondoamarillo imputTextSelect"
                          aria-label={t("lbl2")}
                          onChange={(e) => setfilstatus(e.target.value)}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl6")}
                          </option>
                          <option className="fondo fw-bolder" value="1">
                            {t("lbl7")}
                          </option>
                          <option className="fondo fw-bolder" value="2">
                            {t("lbl8")}
                          </option>
                          <option className="fondo fw-bolder" value="3">
                            {t("lbl9")}
                          </option>
                        </select>
                      </div>
                    </li>
                  </ul>

                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#reset"}
                    aria-expanded="false"
                    aria-controls={"reset"}
                    onClick={() => resetList()}
                  >
                    {t("lbl9")}
                  </a>
                  <hr />
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-10 center-block ">
                {/* tabla de bits */}
                <div className="my-2">
                  {/* <div className="container my-2 ocultar ">
                    <div class="row  my-1 ">
                      <div class="col-1 text-center ">
                        <div className="fondo funeteblanca">#</div>
                      </div>

                      <div class="col-2 text-center ">
                        <div className="fondo funeteblanca">{t("tbl1")}</div>
                      </div>

                      <div class="col-4 text-center  ">
                        <div className="fondo funeteblanca">{t("tbl2")}</div>
                      </div>

                      <div class="col-2 text-center  ">
                        <div className="fondo funeteblanca">{t("tbl3")}</div>
                      </div>

                      <div class="col-3 text-center  ">
                        <div className="fondo funeteblanca">{t("tbl4")}</div>
                      </div>
                    </div>
                  </div> */}

                  <div class="col text-center  ">
                    {
                      (loading == 1) ? 
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      : ""
                    }
                  </div>
                  {companies.length > 0 && (
                    <div>
                      <div> 
                        {
                          companies.map((companie, i) => {
                            return (
                              <div className="container my-2">
                                <div className="accordion-item card sombra my-2">
                                  <div className="accordion-header" id="headingOne">
                                    <div className="row  my-2 p-2">
                                      <div className="col-12 col-sm-12 col-md-1 text-center text-movil">
                                        <div class="row txtlargeAzul">
                                          <br />
                                          <div class="text-center txtSmallAzul ">{companie.id}</div>
                                        </div>
                                      </div>
                    
                                      <div className="col-12 col-sm-12 col-md-2 text-center">
                                        <div class="row txtlargeAzul">
                                          <div class="text-center txtSmallerGris">{t("tbl1")}</div>
                                          <div class="text-center txtSmallAzul">
                                            {companie.name}
                                          </div>
                                        </div>
                                      </div>
                    
                                      <div className="col-12 col-sm-12 col-md-4 text-center">
                                        <div class="row txtlargeAzul">
                                          <div class="text-center txtSmallerGris">{t("tbl2")}</div>
                                          <div class="text-center txtSmallAzul">
                                            {companie.description}
                                          </div>
                                        </div>
                                      </div>
                    
                                      <div className="col-12 col-sm-12 col-md-2 text-center">
                                        <div class="row txtlargeAzul">
                                          <div class="text-center txtSmallerGris">{t("tbl3")}</div>
                                          <div class="text-center txtSmallAzul">
                                            {companie.phone_number}
                                          </div>
                                        </div>
                                      </div>
                    
                                      <div className="col-6 col-sm-6 col-md-3 text-center">
                                        <div class="text-center txtSmallerGris">{t("tbl4")} : {companie.status.description}</div>
                                        <div class="row justify-content-center align-items-center ">
                                          <div class="col-5 col-sm-5 col-md-3">
                                            <div class="form-check form-switch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={companies[i].id}
                                                onClick={() => {
                                                  handleSwitchChange(companie);
                                                }}
                                                checked={companie.status.id == 1 ? true : false}
                                              />
                                            </div>
                                          </div>
                    
                                          <div class="col-3 col-sm-3 col-md-2">
                                            {" "}
                                            <a
                                              href="javascript:void(0)"
                                              title="Details"
                                              onClick={() =>
                                                openModal(
                                                  companie,
                                                  companie.country.name,
                                                  companie.department.name,
                                                  companie.status.description,
                                                  companie.id,
                                                  companie.user.id
                                                )
                                              }
                                            >
                                              {"   "}
                                              <img
                                                width="30"
                                                height="30"
                                                class="icoOpciones mx-3"
                                                src={ver}
                                              />{" "}
                                            </a>
                                          </div>
                    
                                          <div class="col-3 col-sm-3 col-md-2">
                                            {" "}
                                            <a
                                              href="javascript:void(0)"
                                              title="Job offers"
                                              onClick={() => openModalOfertas(companie.id)}
                                            >
                                              {"   "}
                                              <img
                                                width="16"
                                                height="20"
                                                class="icoOpciones mx-3"
                                                src={listicon}
                                              />{" "}
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div>{paginationElement}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          id="openModal"
          type="button"
          class="ocultarBtn"
          data-bs-toggle="modal"
          data-bs-target="#staticAddUser"
        ></button>

        <button
          id="openModalOfertas"
          type="button"
          class="ocultarBtn"
          data-bs-toggle="modal"
          data-bs-target="#modalOfetas"
        ></button>

        <button
          id="openModalUpdate"
          type="button"
          class="ocultarBtn"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdropUpdate"
        ></button>

        {/* modal add  */}

        <div
          class="modal fade bd-example-modal-lg"
          id="staticAddUser"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog  modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                ></h5>
                <button
                  id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid  my-2 mb-5">
                  <div class="row ">
                    <div class="col-md-5 ocultarMovil">
                      <div class="text-center">
                        <br />
                        <br />
                        <img
                          className="img-fluid center-block "
                          width="80%"
                          height="80%"
                          src={datos.logo ? datos.logo : imgmodal}
                        />
                      </div>
                    </div>

                    <div class="col-md-7">
                      {/* <div class="container-fluid"> */}
                        {/* <div class="text-left "> */}
                          {/* <div className="mb-4"> */}
                            {/* <div className="container-fluid"> */}
                              <div class="row mb-3 my-1 align-items-center">
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                  {/* <div class="dropdownn"> */}
                                    <button
                                      class="btn buttonAzulModal"
                                      type="button"
                                      id="dropdownMenuButton2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      onClick={()=>{listChat()}}
                                    >
                                      {" "}
                                      <span>
                                        <img
                                          className="img-fluid "
                                          width="12px"
                                          height="12px"
                                          src={listplas}
                                        />{" "}
                                      </span>{" "}
                                      {t("lbl10")}
                                    </button>

                                    <div
                                      class="dropdown-menu fondoAzulModal"
                                      aria-labelledby="themes"
                                    >
                                      <div class="container">
                                        <div class="form-group mb-1 text-center ">
                                          <img
                                            className=" mt-2 img-fluid"
                                            width="20px"
                                            height="20px"
                                            src={userimg}
                                          />
                                        </div>
                                        <div class="form-group mb-2 text-center ">
                                          {t("lbl11")}
                                        </div>
                                      </div>

                                      <div className="linea lineamovil"></div>
                                      <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                                        <div className="anyClass anyClassMovil" id="topabajo">
                                          {comentarios.map((i) => {
                                            if (i.type == 1) {
                                              return (
                                                <>
                                                  <div class="form-group mb-2 text-left mt-2 ">
                                                    <div class="row  my-1 ">
                                                      <div class="col-2 col-sm-2 col-md-2 ">
                                                        <img
                                                          className=" mt-2 img-fluid ingMpvilchat"
                                                          width="50px"
                                                          height="50px"
                                                          src={userchat}
                                                        />
                                                      </div>

                                                      <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                                                        <div className="txtSmallGris">
                                                        {i.name}
                                                        </div>

                                                        <p className="text-left"> {i.comment} </p>
                                                        <div class="txtSmallGris"> {i.created_at} </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            } else {
                                              return (
                                                <>
                                                <div class="form-group mb-2  mt-2 ">
                                                    <div class="row  my-1   justify-content-end    ">
                                                      <div class=" col-5 col-sm-5 col-md-5 text-end mt-1 globo">
                                                        <div className="mt-2 txtSmallGris">
                                                        {i.compname}
                                                        </div>

                                                        <p className="">
                                                        {i.comment}
                                                        </p>
                                                        <div class="txtSmallGris"> {i.created_at} </div>
                                                      </div>

                                                      <div class="col-2 col-sm-2 col-md-2 ">
                                                        <img
                                                          className=" mt-2 img-fluid ingMpvilchat"
                                                          width="50px"
                                                          height="50px"
                                                          src={userchat}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          })}
                                        </div>
                                      </div>

                                      <div class="container">
                                        <div class="row  my-1 ">
                                          <div class="col-10 ">
                                            <input
                                              id="mensaje"
                                              type="text"
                                              name="mensaje"
                                              className="mt-1 form-control imputTextModal"
                                              aria-describedby="emailHelp"
                                              placeholder={t("lbl12")}
                                            />
                                          </div>

                                          <div class="col-2 text-left ">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                send();
                                              }}
                                            >
                                              <img
                                                className=" mt-2 img-fluid"
                                                width="20px"
                                                height="20px"
                                                src={sendimg}
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  {/* </div> */}
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                  <button
                                    class="btn buttonAzulModal"
                                    type="button"
                                    onClick={() => {downloaddocgettoken()}}
                                  >
                                    {t("lbl13")}
                                  </button>
                                </div>
                              </div>
                            {/* </div> */}
                          {/* </div> */}
                        {/* </div> */}

                        {/* <div class="">
                          <div className="">
                            <div className="container "> */}
                              <div class="row  my-1 text-left mb-2">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("tbl5")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {datos.nit}
                                </div>
                              </div>
                            {/* </div>
                          </div>
                        </div> */}

                        {/* <div class="text-left ">
                          <div className="mb-2">
                            <div className="container "> */}
                              <div class="row  my-1 text-left mb-2">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("tbl6")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {datos.name}
                                </div>
                              </div>
                            {/* </div>
                          </div>
                        </div> */}

                        {/* <div class="text-left ">
                          <div className="mb-2">
                            <div className="container "> */}
                              <div class="row  my-1 text-left mb-2">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("tbl3")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {datos.phone_number}
                                </div>
                              </div>
                            {/* </div>
                          </div>
                        </div> */}

                        {/* <div class="text-left ">
                          <div className="mb-2">
                            <div className="container "> */}
                              <div class="row  my-1 text-left mb-2">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("tbl7")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {datos.address}
                                </div>
                              </div>
                            {/* </div>
                          </div>
                        </div> */}

                        {/* <div class="text-left ">
                          <div className="mb-2">
                            <div className="container "> */}
                              <div class="row  my-1 text-left mb-2">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("tbl8")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {countryName}
                                </div>
                              </div>
                            {/* </div>
                          </div>
                        </div> */}

                        {/* <div class="text-left ">
                          <div className="mb-2">
                            <div className="container "> */}
                              <div class="row  my-1 text-left mb-2">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("tbl9")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {deparmetName}
                                </div>
                              </div>
                            {/* </div>
                          </div>
                        </div> */}

                        {/* <div class="text-left ">
                          <div className="mb-2">
                            <div className="container "> */}
                              <div class="row  my-1 text-left mb-2">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("tbl10")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {datos.postal_code}
                                </div>
                              </div>
                            {/* </div>
                          </div>
                        </div> */}

                        {/* <div class="text-left ">
                          <div className="mb-2">
                            <div className="container "> */}
                              <div class="row  my-1 text-left mb-2">
                                <div class="col-6 text-left txtSmallAzulModal ">
                                  {t("tbl4")}
                                </div>

                                <div class="col-4 text-left txtSmallAzulModalLeter ">
                                  {" "}
                                  {estadoName}
                                </div>
                              </div>
                            {/* </div>
                          </div>
                        </div> */}
                      {/* </div> */}
                    </div>
                  </div>

                  {/* <div className="container my-2  mb-2">
                    <div class="row  my-1 ">
                      <div class="col-12 text-left  txtSmallAzulModal">
                        {t("ttl2")}
                      </div>

                      <div class="col-12 text-left txtSmallGris">
                        {representantes.map((i) => {
                          return (
                            <>
                              {i.first_name} {i.last_name} {i.email}{" "}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div> */}

                  <div className="container my-2  mb-2">
                    <div class="row  my-1 ">
                      <div class="col-12 text-left  txtSmallAzulModal">
                        {t("ttl2")}
                      </div>

                      <div class="col-12 text-left txtSmallGris">
                        {representantes.map((i) => {
                          return (
                            <>
                              {i.first_name} {i.last_name} {i.email}{" "}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="container my-2  mb-5">
                    <div class="row  my-1 ">
                      <div class="col-12 text-left  txtSmallAzulModal">
                        {t("ttl3")}
                      </div>

                      <div class="col-12 text-left txtSmallGris">
                        {datos.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modal update passwsord  */}

        
        {/* modal ofertas */}

        <div
          class="modal fade bd-example-modal-lg"
          id="modalOfetas"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog  modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")}
                </h5>
                <button
                  id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label={t("lbl14")}
                ></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid  my-2 mb-5">
                  <div className="container my-2  mb-5">
                    

                    {Object.keys(jobofert).map((i) => {
                      return (
                        <>
                          <div className="container my-2">
                            <div className="accordion-item card sombra my-2">
                              <div className="accordion-header" id="headingOne">
                                <div className="row  my-2 p-2">
                                  <div className="col-md-2 col-sm-12 text-center"></div>

                                  <div className="col-md-5 col-sm-12 text-center">
                                    <div class="row txtlargeAzul">
                                      {jobofert[i].title}
                                      <br />
                                    </div>
                                  </div>

                                  <div className="col-md-1 col-sm-12 text-center border-end">
                                    <div className="row txtSmallGris ">
                                      {jobofert[i].end_offer}
                                    </div>

                                    <div className="row txtSmallAzul text-center">
                                      {t("lbl5")}
                                    </div>

                                    <div className="row txtSmallGris  text-center">
                                      {
                                        jobofert[i].status
                                          .description
                                      }
                                    </div>
                                  </div>

                                  <div className="col-md-2 col-sm-12 text-center">
                                    <button
                                      className="btn btn-warning   buttonsMirarMAs"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={
                                        "#c" + jobofert[i].id
                                      }
                                      aria-expanded="false"
                                      aria-controls={
                                        "c" + jobofert[i].id
                                      }
                                    >
                                      {t("lbl16")}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="collapse"
                                id={"c" + jobofert[i].id}
                              >
                                <div className="card card-body txtSmallerGris ">
                                  <div className="row">
                                    <div className=" col-md-6 ">
                                      <div class="txtSmallAzul">
                                        {t("lbl17")}
                                      </div>
                                      {jobofert[i].description}

                                      <div class="txtSmallAzul mt-1">
                                        {t("lbl5")}
                                      </div>
                                      {
                                        jobofert[i].status
                                          .description
                                      }

                                      <div class="txtSmallAzul mt-1">
                                        {t("lbl18")}
                                      </div>
                                      {jobofert[i].schedule.name}

                                      <div class="txtSmallAzul mt-1">
                                        {t("lbl19")}
                                      </div>
                                      {jobofert[i].vacancy_numbers}
                                    </div>

                                    <div className=" col-md-6 ">
                                      <div class="txtSmallAzul">{t("lbl20")}</div>
                                      {jobofert[i].activity.name}
                                      <div class="txtSmallAzul mt-1">{t("lbl14")}</div>
                                      {jobofert[i].department.name}

                                      <div class="txtSmallAzul mt-1">
                                        {t("lbl15")}
                                      </div>
                                      {jobofert[i].salary.name}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  
}
export default EmpresasAdmin;
