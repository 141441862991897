import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Router from './Router/Router';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

//en
import header_en from "./translations/en/header.json"
import global_en from "./translations/en/global.json"
import home_en from "./translations/en/home.json"
import about_en from "./translations/en/about.json"
import canada_en from "./translations/en/canada.json"
import mandv_en from "./translations/en/mandv.json"
import contact_en from "./translations/en/contact.json"
import login_en from "./translations/en/login.json"
import jooffh_en from "./translations/en/joboffershome.json"
import hedradm_en from "./translations/en/headeradmin.json"
import applsadmin_en from "./translations/en/applicantsAdmin.json"
import dataadmin_en from "./translations/en/dataadmin.json"
import detailap_en from "./translations/en/detailappadmin.json"
import companiesadm_en from "./translations/en/companiesAdmin.json"
import offeradm_en from "./translations/en/offersadmin.json"
import serviceadm_en from "./translations/en/serviceAdmin.json"
import useradm_en from "./translations/en/userAdmin.json"
import headerappl_en from "./translations/en/headerApplicant.json"
import detailapplap_en from "./translations/en/detailApplicant.json"
import registerappl_en from "./translations/en/registerappli.json"
import homeappl_en from "./translations/en/homeApplicant.json"
import headercomp_en from "./translations/en/headerCompany.json"
import applscomp_en from "./translations/en/applicantsCompany.json"
import detailapplcomp_en from "./translations/en/detailapplcomp.json"
import regcomp_en from "./translations/en/registercompany.json"
import offercomp_en from "./translations/en/offercompany.json"
import perfilcomp_en from "./translations/en/perfilCompany.json"
import formcmnps_en from "./translations/en/formComponents.json"
import recentsAppl_en from "./translations/en/recentsAppl.json"
import recentsComps_en from "./translations/en/recentsComps.json"
import demandReport_en from "./translations/en/demandReport.json"
import applSkillReport_en from "./translations/en/applSkillReport.json"
import salesAdmin_en from "./translations/en/salesAdmin.json"
import notifi_en from "./translations/en/notifications.json"
import homecomp_en from "./translations/en/homeCompany.json"
import myoffers_en from "./translations/en/myoffersApplicant.json"
import companiesapplicant_en from "./translations/en/companiesapplicant.json"
import footer_en from "./translations/en/footer.json"
import comments_en from "./translations/en/comments.json"
import payment_en from "./translations/en/payment.json"


//spanish
import global_es from "./translations/es/global.json"
import header_es from "./translations/es/header.json"
import home_es from "./translations/es/home.json"
import about_es from "./translations/es/about.json"
import canada_es from "./translations/es/canada.json"
import mandv_es from "./translations/es/mandv.json"
import contact_es from "./translations/es/contact.json"
import login_es from "./translations/es/login.json"
import jooffh_es from "./translations/es/joboffershome.json"
import hedradm_es from "./translations/es/headeradmin.json"
import applsadmin_es from "./translations/es/applicantsAdmin.json"
import dataadmin_es from "./translations/es/dataadmin.json"
import detailap_es from "./translations/es/detailappadmin.json"
import companiesadm_es from "./translations/es/companiesAdmin.json"
import offeradm_es from "./translations/es/offersadmin.json"
import serviceadm_es from "./translations/es/serviceAdmin.json"
import useradm_es from "./translations/es/userAdmin.json"
import headerappl_es from "./translations/es/headerApplicant.json"
import detailapplap_es from "./translations/es/detailApplicant.json"
import registerappl_es from "./translations/es/registerappli.json"
import homeappl_es from "./translations/es/homeApplicant.json"
import headercomp_es from "./translations/es/headerCompany.json"
import applscomp_es from "./translations/es/applicantsCompany.json"
import detailapplcomp_es from "./translations/es/detailapplcomp.json"
import regcomp_es from "./translations/es/registercompany.json"
import offercomp_es from "./translations/es/offercompany.json"
import perfilcomp_es from "./translations/es/perfilCompany.json"
import formcmnps_es from "./translations/es/formComponents.json"
import recentsAppl_es from "./translations/es/recentsAppl.json"
import recentsComps_es from "./translations/es/recentsComps.json"
import demandReport_es from "./translations/es/demandReport.json"
import applSkillReport_es from "./translations/es/applSkillReport.json"
import salesAdmin_es from "./translations/es/salesAdmin.json"
import notifi_es from "./translations/es/notifications.json"
import homecomp_es from "./translations/es/homeCompany.json"
import myoffers_es from "./translations/es/myoffersApplicant.json"
import companiesapplicant_es from "./translations/es/companiesapplicant.json"
import footer_es from "./translations/es/footer.json"
import comments_es from "./translations/es/comments.json"
// import payment_es from "./translations/en/payment.json"


//frances 
import global_fr from "./translations/fr/global.json"
import header_fr from "./translations/fr/header.json"
import home_fr from "./translations/fr/home.json"
import about_fr from "./translations/fr/about.json"
import canada_fr from "./translations/fr/canada.json"
import mandv_fr from "./translations/fr/mandv.json"
import contact_fr from "./translations/fr/contact.json"
import login_fr from "./translations/fr/login.json"
import jooffh_fr from "./translations/fr/joboffershome.json"
import hedradm_fr from "./translations/fr/headeradmin.json"
import applsadmin_fr from "./translations/fr/applicantsAdmin.json"
import dataadmin_fr from "./translations/fr/dataadmin.json"
import detailap_fr from "./translations/fr/detailappadmin.json"
import companiesadm_fr from "./translations/fr/companiesAdmin.json"
import offeradm_fr from "./translations/fr/offersadmin.json"
import serviceadm_fr from "./translations/fr/serviceAdmin.json"
import useradm_fr from "./translations/fr/userAdmin.json"
import headerappl_fr from "./translations/fr/headerApplicant.json"
import detailapplap_fr from "./translations/fr/detailApplicant.json"
import registerappl_fr from "./translations/fr/registerappli.json"
import homeappl_fr from "./translations/fr/homeApplicant.json"
import headercomp_fr from "./translations/fr/headerCompany.json"
import applscomp_fr from "./translations/fr/applicantsCompany.json"
import detailapplcomp_fr from "./translations/fr/detailapplcomp.json"
import regcomp_fr from "./translations/fr/registercompany.json"
import offercomp_fr from "./translations/fr/offercompany.json"
import perfilcomp_fr from "./translations/fr/perfilCompany.json"
import formcmnps_fr from "./translations/fr/formComponents.json"
import recentsAppl_fr from "./translations/fr/recentsAppl.json"
import recentsComps_fr from "./translations/fr/recentsComps.json"
import demandReport_fr from "./translations/fr/demandReport.json"
import applSkillReport_fr from "./translations/fr/applSkillReport.json"
import salesAdmin_fr from "./translations/fr/salesAdmin.json"
import notifi_fr from "./translations/fr/notifications.json"
import homecomp_fr from "./translations/fr/homeCompany.json"
import myoffers_fr from "./translations/fr/myoffersApplicant.json"
import companiesapplicant_fr from "./translations/fr/companiesapplicant.json"
import footer_fr from "./translations/fr/footer.json"
import comments_fr from "./translations/fr/comments.json"
// import payment_fr from "./translations/en/payment.json"


document.title = 'HRHWORKFORCE';
document.querySelector('meta[name="description"]').setAttribute('content', 'Get more and more relevant candidates. Save costs and time in your selection processes and get the ideal candidate quickly and easily | Obtenga más y más candidatos relevantes. Ahorra costes y tiempo en tus procesos de selección y consigue el candidato ideal de forma rápida y sencilla, empleos, employees')

if (localStorage.getItem('lngselect') === null) {
  localStorage.setItem('lngselect', 'en');
}

i18next.init({
  interpolation: {
    escapeValue: false
  },
  lng: localStorage.getItem('lngselect') || "en",
  resources: {
    en: { 
      global: global_en, 
      header: header_en,
      home: home_en,
      about: about_en,
      canada: canada_en,
      mandv: mandv_en,
      contact: contact_en,
      login: login_en,
      jobofferh: jooffh_en,
      hedradm: hedradm_en,
      applsadmin: applsadmin_en,
      dataadmin: dataadmin_en,
      detailapadmin: detailap_en,
      companiesadm: companiesadm_en,
      offersadmin: offeradm_en,
      serviceadm: serviceadm_en,
      useradm: useradm_en,
      headerappl: headerappl_en,
      detailapplap: detailapplap_en,
      registerappl: registerappl_en,
      homeappl: homeappl_en,
      headercomp: headercomp_en,
      applscomp: applscomp_en,
      detailapplcomp: detailapplcomp_en,
      regcomp: regcomp_en,
      offercomp: offercomp_en,
      perfilcomp: perfilcomp_en,
      formcmnps: formcmnps_en,
      recentapps: recentsAppl_en,
      recentscomps: recentsComps_en,
      demandReport: demandReport_en,
      applSkillReport: applSkillReport_en,
      salesAdmin: salesAdmin_en,
      notifi: notifi_en,
      homecomp: homecomp_en,
      myoffers: myoffers_en,
      companiesapplicant: companiesapplicant_en,
      footer: footer_en,
      comments: comments_en,
      payment: payment_en,
    }, 
    es: { 
      global: global_es,
      header: header_es,
      home: home_es,
      about: about_es,
      canada: canada_es,
      mandv: mandv_es,
      contact: contact_es,
      login: login_es,
      jobofferh: jooffh_es,
      hedradm: hedradm_es,
      applsadmin: applsadmin_es,
      dataadmin: dataadmin_es,
      detailapadmin: detailap_es,
      companiesadm: companiesadm_es,
      offersadmin: offeradm_es,
      serviceadm: serviceadm_es,
      useradm: useradm_es,
      headerappl: headerappl_es,
      detailapplap: detailapplap_es,
      registerappl: registerappl_es,
      homeappl: homeappl_es,
      headercomp: headercomp_es,
      applscomp: applscomp_es,
      detailapplcomp: detailapplcomp_es,
      regcomp: regcomp_es,
      offercomp: offercomp_es,
      perfilcomp: perfilcomp_es,
      formcmnps: formcmnps_es,
      recentapps: recentsAppl_es,
      recentscomps: recentsComps_es,
      demandReport: demandReport_es,
      applSkillReport: applSkillReport_es,
      salesAdmin: salesAdmin_es,
      notifi: notifi_es,
      homecomp: homecomp_es,
      myoffers: myoffers_es,
      companiesapplicant: companiesapplicant_es,
      footer: footer_es,
      comments: comments_es,
      // payment: payment_es,
    },
    fr: { 
      global: global_fr,
      header: header_fr,
      home: home_fr,
      about: about_fr,
      canada: canada_fr,
      mandv: mandv_fr,
      contact: contact_fr,
      login: login_fr,
      jobofferh: jooffh_fr,
      hedradm: hedradm_fr,
      applsadmin: applsadmin_fr,
      dataadmin: dataadmin_fr,
      detailapadmin: detailap_fr,
      companiesadm: companiesadm_fr,
      offersadmin: offeradm_fr,
      serviceadm: serviceadm_fr,
      useradm: useradm_fr,
      headerappl: headerappl_fr,
      detailapplap: detailapplap_fr,
      registerappl: registerappl_fr,
      homeappl: homeappl_fr,
      headercomp: headercomp_fr,
      applscomp: applscomp_fr,
      detailapplcomp: detailapplcomp_fr,
      regcomp: regcomp_fr,
      offercomp: offercomp_fr,
      perfilcomp: perfilcomp_fr,
      formcmnps: formcmnps_fr,
      recentapps: recentsAppl_fr,
      recentscomps: recentsComps_fr,
      demandReport: demandReport_fr,
      applSkillReport: applSkillReport_fr,
      salesAdmin: salesAdmin_fr,
      notifi: notifi_fr,
      homecomp: homecomp_fr,
      myoffers: myoffers_fr,
      companiesapplicant: companiesapplicant_fr,
      footer: footer_fr,
      comments: comments_fr,
      // payment: payment_fr,

    },
  }
})

// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <Router />
      </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
