import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";

import axios from "axios";
import Moment from 'moment';
import Swal from "sweetalert2";
import Table from 'react-bootstrap/Table'
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css";

import ReactPaginate from "react-paginate";

const SalesAdmin = (props) => {

const [t, i18n] = useTranslation("salesAdmin")
  
  const [ sales, setSales ] = useState([]);
  const [ extra, setextra ] = useState([]);
  // const [ companies, setcompanies ] = useState([]);
  
  const [ countries, setcountries ] = useState([]);
  const [ departments, setdepartments ] = useState([]);
  // const [ countryName, setcountryName ] = useState("");
  // const [ deparmetName, setdeparmetName ] = useState("");
  // const [ estadoName, setestadoName ] = useState("");
  
  
  // filtros aplicados
  const [ filtext, setfiltext ] = useState("");
  const [ filidentcompan, setfilidentcompan ] = useState("");
  const [ filstatus, setfilstatus ] = useState(0);
  const [ filcompany, setfilcompany ] = useState(props.dataxtr ?? "");
  const [ filcountry, setfilcountry ] = useState("");
  const [ fildepartment, setfildepartment ] = useState("");
  const [ filtypesales, setfiltypesales ] = useState(props.type ?? "service");
  const [ filstartdate, setfilstartdate ] = useState(new Date());
  const [ filenddate, setfilenddate ] = useState(new Date());

  //     /*paginacion*/
  const [ pageCount, setpageCount ] = useState(0);
  const [ selectedPage, setselectedPage ] = useState([]);
  const [ pageNex, setpageNex ] = useState(0);

  const [ loading, setloading ] = useState(0);

  // const getCompany = async () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/api/companies`,
  //       {
  //         headers: {
  //           Authorization: `Bearer  ${UserProfile.getToken()}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
  //       setcompanies(res.data.data.companies)
  //     });
  // };

  const getCountry = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/country`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcountries(res.data.data.country)
      });
  };

  const getDeparmentByCountry = ({id}) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`)
      .then((res) => {
        setdepartments(res.data.data.department)
      });
  }

  useEffect(() => {
    var search = {}
    // if (filcompany > 0) search.company = filcompany
    if (filstatus > 0) search.status_pay = filstatus
    if (filidentcompan > 0) search.nit_company = filidentcompan
    if (filcountry > 0) search.country = filcountry
    if (fildepartment > 0) search.department = fildepartment
    search.type = filtypesales
    search.datestart = Moment(filstartdate).format("YYYY-MM-DD")
    search.dateend = Moment(filenddate).format("YYYY-MM-DD")
    list(search)
  }, [filstartdate, filenddate])
  
  const handleSwitchChange = async (value) => {
    let type = value == "service" ? "applicants" : "service"
    setfiltypesales(type)
  };

  const list = async (search) => {
    setSales([])
    setextra([])
    setloading(1)
    console.log(loading);
    console.log("loading types");
    console.log(filtypesales);
    console.log("loading 1111");
    let newsearch = {...search, page: selectedPage, company: filcompany > 0 ? filcompany : 0}
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/inform_sales`, [],{
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
          params: newsearch
        })
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setSales( res.data.data.sales )
        setextra(res.data.data.extra)
        setpageCount(res.data.data.pages)
        setloading(0)
        setfilcompany("")
        // setElementsForCurrentPage();
      })
      .catch((error) => console.log(error));
  };


  const resetList  = async () => {
    var search = {}
    setfilidentcompan("")
    setfilstatus(0)
    setfilcompany("")
    setfilcountry(0)
    setfildepartment(0)
    setfiltypesales("service")
    list(search)
  }

  const handleClickFilter = async () => {
      var search = {}
      // if (filcompany > 0) search.company = filcompany
      if (filstatus > 0) search.status_pay = filstatus
      if (filidentcompan > 0) search.nit_company = filidentcompan
      if (filcountry > 0) search.country = filcountry
      if (fildepartment > 0) search.department = fildepartment
      search.type = filtypesales
      list(search)
  }

  const handlePageClick = async (val) => {
      setselectedPage(val.selected+1)
      setpageNex(val.selected)
  }

  useEffect(() => {
    handleClickFilter()
  },[filtext, filstatus, filidentcompan, filcountry, fildepartment, selectedPage, filtypesales])
 
  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      // if (props.dataxtr > 0) {
        // setfiltypesales(props.type)
      //   setfilcompany(props.dataxtr)
      // }
      getCountry();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

    let paginationElement;
    if (pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Sig"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
          onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
          forcePage={pageNex}
          containerClassName={"pagination justify-content-center"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      );
    }

    return (
      <>
        <div class="row" xyz="fade">
            {/* Filter */}
            <div class="col center-block filtrosopcioes">
                <div class="row filtrosfondo">
                    <div class="filtros mx-2 my-4">{t("ttl2")}</div>
                </div>

                <div class="row my-3">
                    <div class="form-group mb-4 col-md-12">
                        <input
                        id="search"
                        type="text"
                        name="search"
                        className="form-control imputTextSelect mx-1"
                        placeholder={t("lbl7")}
                        onChange={(e)=>setfilidentcompan(e.target.value)}
                        />
                    </div>
                    <hr />
                </div>

                <div class="row my-3">
                    <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#countr"}
                    aria-expanded="false"
                    aria-controls={"countr"}
                    >
                    {t("lbl8")}
                    </a>

                    <ul className="collapse mx-3" id={"countr"}>
                        <li class="mt-2">
                            <div class="form-group mb-4 col-md-11">
                                <select
                                    id="filcountry"
                                    name="filcountry"
                                    className="form-select fondoamarillo imputTextSelect"
                                    aria-label={t("lbl8")}
                                    onChange={async (e) => (setfilcountry(e.target.value), getDeparmentByCountry({id: e.target.value}))}
                                >
                                    <option className="fondo fw-bolder" value="" selected>
                                    {t("lbl8")}
                                    </option>

                                    {countries.map((i) => {
                                    return (
                                        <option key={i.id} className="fondo fw-bolder" value={i.id}>
                                            {i.name}
                                        </option>
                                    );
                                    })}
                                </select>
                            </div>
                            <div class="form-group mb-4 col-md-11">
                                <select
                                    id="fildepartment"
                                    name="fildepartment"
                                    className="form-select fondoamarillo imputTextSelect"
                                    aria-label={t("lbl9")}
                                    onChange={async (e) => (setfildepartment(e.target.value))}
                                >
                                    <option className="fondo fw-bolder" value="" selected>
                                        {t("lbl9")}
                                    </option>

                                    {departments.map((i) => {
                                    return (
                                        <option className="fondo fw-bolder" value={i.id} key={i.id}>
                                            {i.name}
                                        </option>
                                    );
                                    })}
                                </select>
                            </div>
                        </li>
                    </ul>
                    <hr />
                </div>

                <div class="row my-3">
                    <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#dates"}
                        aria-expanded="false"
                        aria-controls={"dates"}
                    >
                        {t("lbl13")}
                    </a>
                    <ul className="collapse mx-3" id={"dates"}>
                        <li class="mt-2">
                            <div class="form-group mb-4 col-md-11">
                                <label for="titleoffer" class="form-label">{t("lbl14")} </label>
                                <DatePicker 
                                    selected={filstartdate}
                                    dateFormat="Y-M-d"
                                    className="form-control"
                                    id="startdate"
                                    name="startdate"
                                    onChange={(e) => setfilstartdate(e)}
                                />
                            </div>
                        </li>
                        <li class="mt-2">
                            <div class="form-group mb-4 col-md-11">
                                <label for="titleoffer" class="form-label">{t("lbl15")} </label>
                                <DatePicker 
                                    selected={filenddate}
                                    dateFormat="Y-M-d"
                                    className="form-control"
                                    id="enddate"
                                    name="enddate"
                                    onChange={(e) => setfilenddate(e)}
                                />
                            </div>
                        </li>
                    </ul>
                    <hr />
                </div>

                <div class="row my-3">
                    <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#status"}
                        aria-expanded="false"
                        aria-controls={"status"}
                    >
                        {t("lbl19")}
                    </a>

                    <ul className="collapse mx-3" id={"status"}>
                        <li class="mt-2">
                        <div class="form-group mb-4 col-md-11">
                            <select
                            id="status"
                            name="status"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label={t("lbl19")}
                            onChange={(e) => setfilstatus(e.target.value)}
                            >
                              <option className="fondo fw-bolder" value="0" selected>
                                  {t("lbl20")}
                              </option>
                              <option className="fondo fw-bolder" value="1" selected>
                                  {t("lbl21")}
                              </option>
                              <option className="fondo fw-bolder" value="2" selected>
                                  {t("lbl22")}
                              </option>
                            </select>
                        </div>
                        </li>
                    </ul>
                    <hr />
                </div>

                <div class="row my-3">
                    <a
                        href="#"
                        className="mx-2  items-center txtlargeAzul"
                        data-bs-toggle="collapse"
                        data-bs-target={"#reset"}
                        aria-expanded="false"
                        aria-controls={"reset"}
                        onClick={() => resetList()}
                    >
                        {t("lbl11")}
                    </a>
                    <hr />
                </div>
                {/* END FIlter */}
            </div>
            <div class="col-10 center-block">
                <div class="row">
                    <div class="col">
                      <div class="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onClick={() => {
                            handleSwitchChange(filtypesales);
                          }}
                          checked={(filtypesales == "service") ? false : true}
                        />
                        <label>{filtypesales == "service" ? t("ttl3") : t("ttl4")}</label>
                      </div>
                    </div>
                    <h5> {t('lbl16')} $ {extra ? extra.total_price : 0} </h5>
                </div>
                <div class="row">
                    <div className="my-2 table-responsive">
                        <Table striped bordered size="sm">
                        <thead>
                            <tr>
                            <th className="fondoi funeteblanca">{t("lbl19")} </th>
                            <th className="fondoi funeteblanca">{t("lbl12")} </th>
                            <th className="fondoi funeteblanca">{t("lbl1")} </th>
                            <th className="fondoi funeteblanca">{t("lbl2")} </th>
                            <th className="fondoi funeteblanca">{t("lbl3")} </th>
                            <th className="fondoi funeteblanca">{t("lbl4")} </th>
                            <th className="fondoi funeteblanca">{t("lbl18")} </th>
                            {
                              filtypesales == "service" ?
                              <th className="fondoi funeteblanca">{t("lbl5")} </th>
                              : ""
                            }
                            <th className="fondoi funeteblanca">{t("lbl6")} </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                          loading == 0 ?
                            sales.map((sale) => {
                                return (
                                    <>
                                      <tr key={sale.id}>
                                          <td> {sale.status_pay == 2 ? "No paid" : "Paid"} </td>
                                          <td> {sale.date_payment} </td>
                                          <td> {sale.nit} </td>
                                          <td> {sale.name} </td>
                                          <td> {sale.first_name} {sale.first_lastname} </td>
                                          <td> {sale.identification} </td>
                                          <td> {sale.title} </td>
                                          {
                                            filtypesales == "service" ?
                                            <td> {sale.nameser} </td>
                                            : ""
                                          }
                                          <td> {sale.value} </td>
                                      </tr>
                                    </>
                                )
                            }) 
                          :
                          <div class="col text-center">
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        }
                        
                        </tbody>
                        </Table>
                    </div>
                    <div>{paginationElement}</div>
                </div>
            </div>
        </div>
      </>
    );
  
}
export default SalesAdmin;
