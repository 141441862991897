import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { components } from "react-select";
import DatePicker from "react-datepicker"

import UserProfile from "../../Login/UserProfile";
import geojobscanada from "../../img/formhrh.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Resizer from "react-image-file-resizer";
import RegisterLanguage from "../../components/RegisterLanguage"
import RegisterEducation from "../../components/RegisterEducation"
import RegisterLevelEducation from "../../components/RegisterLevelEducation"
import RegisterXpCompany from "../../components/RegisterXpCompany"
import RegisterPosition from "../../components/RegisterPosition"
import RegisterSkill from "../../components/RegisterSkill"
import { date } from "yup/lib/locale";


const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          class="form-check-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


const FormRegisterApplicant = (props) => {
  
const [t, i18n] = useTranslation("registerappl")
// i18n.changeLanguage("")

    // fileChangedHandler = fileChangedHandler.bind(this);
    // filedocChangedHandler = filedocChangedHandler.bind(this);
  
    // const [ idApplicant, setidApplicant ] = useState(0);
    // const [ applicant, setapplicant ] = useState([]);
    // const [ id_type, setid_type ] = useState([]);
    // const [ countr, setcountr ] = useState([]);
    // const [ depart, setdepart ] = useState([]);
    // const [ applicantTem, setapplicantTem ] = useState([]);
    // const [ countr_loc, setcountr_loc ] = useState([]);
    // const [ depart_loc, setdepart_loc ] = useState([]);
    // const [ languages_applicant, setlanguages_applicant ] = useState([]);
    // const [ social_networks, setsocial_networks ] = useState([]);
    const [ studies, setstudies ] = useState([]);
    const [ experiences, setexperiences ] = useState([]);
    const [ activities, setactivities ] = useState([]);
    
    // const [ status, setstatus ] = useState(0);
    // const [ errors, seterrors ] = useState([]);
    
    // const [ comentarios, setcomentarios ] = useState([]);
    // const [ nameapplicant, setnameapplicant ] = useState("");
    
    // const [ first_nameRes, setfirst_nameRes ] = useState("");
    // const [ middle_nameRes, setmiddle_nameRes ] = useState("");
    // const [ first_lastnameRes, setfirst_lastnameRes ] = useState("");
    // const [ second_lasnameRes, setsecond_lasnameRes ] = useState("");
    // const [ titleRes, settitleRes ] = useState("");
    // const [ descriptionRes, setdescriptionRes ] = useState("");
    // const [ identification_typeRes, setidentification_typeRes ] = useState("");
    // const [ identificationRes, setidentificationRes ] = useState("");
    // const [ countryRes, setcountryRes ] = useState("");
    // const [ departmentRes, setdepartmentRes ] = useState("");
    // const [ cityRes, setcityRes ] = useState("");
    // const [ country_nacionalityRes, setcountry_nacionalityRes ] = useState("");
    // const [ department_nacionalityRes, setdepartment_nacionalityRes ] = useState("");
    // const [ emailRes, setemailRes ] = useState("");
    // const [ civil_statusRes, setcivil_statusRes ] = useState("");
    // const [ img_perfilRes, setimg_perfilRes ] = useState("");
    // const [ video_presentationRes, setvideo_presentationRes ] = useState("");
    
    const [ network, setnetwork ] = useState([]);

    // const [ birthdayRes, setbirthdayRes ] = useState("");
    // const [ networkRes, setnetworkRes ] = useState("");
    // const [ nicknameRes, setnicknameRes ] = useState("");
    // const [ urlRes, seturlRes ] = useState("");
    
    const [ statusRes, setstatusRes ] = useState(0);
    
    // const [ languageRes, setlanguageRes ] = useState([]);
    // const [ educationRes, seteducationRes ] = useState([]);
    // const [ experienceRes, setexperienceRes ] = useState([]);
    // const [ socialNetworkRes, setsocialNetworkRes ] = useState([]);

    const [ starting_date, setstarting_date ] = useState(new Date());
    const [ finishing_date, setfinishing_date ] = useState(new Date());
    
    const [ pais, setpais ] = useState([]);
    const [ paisLocation, setpaisLocation ] = useState([]);
    const [ departamento, setdepartamento ] = useState([]);
    const [ city, setcity ] = useState([]);
    
    const [ departamentoLocation, setdepartamentoLocation ] = useState([]);
    
    const [ civilStatus, setcivilStatus ] = useState([]);
    const [ socialNetwork, setsocialNetwork ] = useState([]);
    const [ gender, setgender ] = useState([]);

    const [ languagetipo, setlanguagetipo ] = useState([]);
    const [ nivelLanguage, setnivelLanguage ] = useState([]);
    const [ levelStudy, setlevelStudy ] = useState([]);
    const [ educacionInstitucion, seteducacionInstitucion ] = useState([]);
    const [ unidad, setunidad ] = useState([]);
    const [ positions, setpositions ] = useState([]);
    
    // const [ conta, setconta ] = useState(0);
    // const [ reponsable, setreponsable ] = useState(0);
    const [ companyWork, setcompanyWork ] = useState([]);
    const [ cargo, setcargo ] = useState(0);
    const [ identificationType, setidentificationType ] = useState([]);
    
    const [ actividades, setactividades ] = useState([]);
    const [ optionSelected, setoptionSelected ] = useState([]);
    // const [ actividadesempresa, setactividadesempresa ] = useState([]);
    
    const [ LanguageList, setLanguageList ] = useState([]);
    const [ viewLanguage, setviewLanguage ] = useState([]);
    
    const [ viewEducation, setviewEducation ] = useState([]);
    const [ viewNetwork, setviewNetwork ] = useState([]);
    const [ viewExperiences, setviewExperiences ] = useState([]);
    
    const [ splitDataURI, setsplitDataURI ] = useState([]);
    const [ estadoDeparment, setestadoDeparment ] = useState("");
    const [ estadoDeparment2, setestadoDeparment2 ] = useState([]);
    const [ estadoCity, setestadoCity ] = useState([]);
    
    const [ selectedFile, setselectedFile ] = useState([]);
    const [ newImage, setnewImage ] = useState("");
    const [ pdfcoding, setpdfcoding ] = useState("");
    const [ loadd, setloadd ] = useState(0)

    const [ datauser, setdatauser ] = useState([])



  const addlanguagetipo = (data) => {
    setlanguagetipo([...languagetipo, data])
  }

  const addNewEducation = (data) => {
    seteducacionInstitucion([...educacionInstitucion, data])
  }

  const addNewlevelEducation = (data) => {
    setlevelStudy([...levelStudy, data])
  }

  const addNewXpCompany = (data) => {
    setcompanyWork([...companyWork, data])
  }

  const addNewPosition = (data) => {
    setpositions([...positions, data])
  }

  const addNewSkill = (data) => {
    setactividades([...actividades, data])
    renderList()
    document.getElementById("clocedModal").click()
  }
  
  const filedocChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      var ext = event.target.files[0].name.split('.').pop();
      if(ext == "pdf"){
        if(event.target.files[0].size > 5242880){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg1"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        let fileToLoad = event.target.files[0];
        let file = null;
        let fileName = null;
        fileName = fileToLoad.name;
        // FileReader function for read the file.
        let fileReader = new FileReader();
        // Onload of file read the file content
        // let cntx = this
        fileReader.onload = function(fileLoadedEvent) {
            file = fileLoadedEvent.target.result;
            setpdfcoding(file)
        };
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
      }
      else
      {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("msg2"),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      }
    }
  }

  const fileChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          500,
          500,
          "png",
          85,
          0,
          (uri) => {
            setnewImage(uri)
          },
          "base64"
        );
      } catch (err) {
        console.log(err);
      }
    }
  }


  const close = async (values) => {
    document.getElementById("clocedModal").click();
  };

  const closeEducation = async (values) => {
    document.getElementById("clocedModalEducation").click();
  };

  const addLanguages = (val) => {
    LanguageList.push(val);
    setElementsForCurrentPage();
  };

  const deleteLanguages = (val) => {
    LanguageList.splice(val, 1);
    setElementsForCurrentPage();
  };

  const setElementsForCurrentPage  = () => {
    let elements = LanguageList.map((a, i) => {
      return (
        <div class="card">
          <div class="card-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => deleteLanguages(i)}
            ></button>
          </div>

          <div class="card-body">
            <div id="language">
              <div class="form-group mb-4">
                {languagetipo.map((i) => {
                  if (a.language == i.id) {
                    return (
                      <input
                        type="text"
                        id="idLanguage"
                        name="idLanguage"
                        className="form-control imputText"
                        
                        value={i.name}
                        readonly
                      />
                    );
                  }
                })}
              </div>

              <div class="form-group mb-4">
                {nivelLanguage.map((i) => {
                  if (a.level == i.id) {
                    return (
                      <input
                        type="text"
                        id="idLanguage"
                        name="idLanguage"
                        className="form-control imputText"
                        value={i.description}
                        readonly
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      );
    });

    setviewLanguage(elements)
  }

  const addEducation = (val) => {
    const data ={
      name: val.name.trim(),
      educational_institution: val.educational_institution,
      starting_date: val.starting_date,
      finishing_date: val.finishing_date,
      // duration_time: val.duration_time,
      // unit_measurement: val.unit_measurement,
      level_study: val.level_study,
      inprogress: 1,
    }
    studies.push(data);
    setElementsEducation();
  };

  const deleteEducation = (val) => {
    studies.splice(val, 1);
    setElementsEducation();
  };

  const setElementsEducation = () => {
    let elementsEducation = studies.map((a, i) => {
      console.log(i);
      return (
        <div class="card">
          <div class="card-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => deleteEducation(i)}
            ></button>
          </div>

          <div class="card-body">
            <div class="form-group mb-4">
              <input
                type="text"
                // id="idLanguage"
                // name="idLanguage"
                className="form-control imputText"
                value={a.name}
                readonly
              />
            </div>

            <div class="form-group mb-4">
              {educacionInstitucion.map((i) => {
                if (a.educational_institution == i.id) {
                  return (
                    <input
                      type="text"
                      // id="idLanguage"
                      // name="idLanguage"
                      className="form-control imputText"
                      value={i.name}
                      readonly
                    />
                  );
                }
              })}
            </div>

            {/* <div class="form-group mb-4">
              <input
                type="text"
                // id="idLanguage"
                // name="idLanguage"
                className="form-control imputText"
                value={a.duration_time}
                readonly
              />
            </div> */}

            <div class="form-group mb-4">
              {/* {unidad.map((i) => {
                if (a.unit_measurement == i.id) {
                  return ( */}
                    <input
                      type="text"
                      // id="idLanguage"
                      // name="idLanguage"
                      className="form-control imputText"
                      value={a.starting_date}
                      readonly
                    />
                   {/* ); */}
                 {/* } */}
               {/* })} */}
            </div>

            <div class="form-group mb-4">
              <input
                type="text"
                // id="idLanguage"
                // name="idLanguage"
                className="form-control imputText"
                value={a.finishing_date}
                readonly
              />
            </div>

            

            <div class="form-group mb-4">
              {levelStudy.map((i) => {
                if (a.level_study == i.id) {
                  return (
                    <input
                      type="text"
                      // id="idLanguage"
                      // name="idLanguage"
                      className="form-control imputText"
                      value={i.description}
                      readonly
                    />
                  );
                }
              })}
            </div>

          
          </div>
        </div>
      );
    });

    setviewEducation(elementsEducation)
  }

  const closeNetwork = () => {
    document.getElementById("clocedModalNetwork").click();
  };


  const addNetwork = (val) => {
    let temp = network
    temp.push(val);
    setnetwork(temp)
    setElementNetwork();
  };

  

  const deleteNetwork = (val) => {
    let temp = network
    temp.splice(val, 1);
    setnetwork(temp)
    setElementNetwork(); 
  };



  const setElementNetwork = () => {
    let elementsNetwork = network.map((a, i) => {

      return (
        <div class="card">
          <div class="card-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => deleteNetwork(i)}
            ></button>
          </div>

          <div class="card-body">
            <div class="form-group mb-4">
              {socialNetwork.map((i) => {
                if (a.network == i.id) {
                  return (
                    <input
                      type="text"
                      id="idLanguage"
                      name="idLanguage"
                      className="form-control imputText"
                      value={i.name}
                      readonly
                    />
                  );
                }
              })}
            </div>
            <div class="form-group mb-4">
              <input
                type="text"
                id="idLanguage"
                name="idLanguage"
                className="form-control imputText"
                value={a.nickname}
                readonly
              />
            </div>

            <div class="form-group mb-4">
              <input
                type="text"
                id="idLanguage"
                name="idLanguage"
                className="form-control imputText"
                value={a.url}
                readonly
              />
            </div>
          </div>
        </div>
      );
    });
    setviewNetwork(elementsNetwork)
  }


  const closeExperience = () => {
    document.getElementById("clocedModalExperience").click();
  };

  const addExperience = (val) => {
    experiences.push(val);
    console.log(studies);
    setElementsExperience();
  };

  const deleteExperience = (val) => {
    experiences.splice(val, 1);
    setElementsExperience();
  };


  const setElementsExperience = () => {
    let elementsExperience = experiences.map((a, i) => {
      return (
        <div class="card">
          <div class="card-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => deleteExperience(i)}
            ></button>
          </div>

          <div class="card-body">

            <div class="form-group mb-4">
              {positions.map((i) => {
                if (a.position == i.id) {
                  return (
                    <input
                      type="text"
                      id="idPosition"
                      name="idPosition"
                      className="form-control imputText"
                      value={i.name}
                      readonly
                    />
                  );
                }
              })}
            </div>

            <div class="form-group mb-4">
              <input
                type="text"
                id="idDurationTime"
                name="idDurationTime"
                className="form-control imputText"
                value={a.duration_time}
                readonly
              />
            </div>

            <div class="form-group mb-4">
              {unidad.map((i) => {
                if (a.unit_measurement == i.id) {
                  return (
                    <input
                      type="text"
                      id="idUnitMeas"
                      name="idUnitMeas"
                      className="form-control imputText"
                      value={i.name}
                      readonly
                    />
                  );
                }
              })}
            </div>

            <div class="form-group mb-4">
              {companyWork.map((i) => {
                if (a.company == i.id) {
                  return (
                    <input
                      type="text"
                      id="idXpCompany"
                      name="idXpCompany"
                      className="form-control imputText"
                      value={i.name}
                      readonly
                    />
                  );
                }
              })}
            </div>

            <div class="form-group mb-4">
              <input
                type="text"
                id="idInitYear"
                name="idInitYear"
                className="form-control imputText"
                value={a.initial_year}
                readonly
              />
            </div>

               <div class="form-group mb-4">
                            <input
                              id="functions"
                              name="functions"
                              type="textarea"
                              class="form-control rounded-0 textArea"
                              component="textarea"
                              aria-describedby="emailHelp"
                              placeholder={t("lbl1")}
                              rows="3"
                              readonly
                              value={a.functions}
                            />
                          </div>     
                


               <div class="form-group mb-4">
                            <input
                              id="goals"
                              name="goals"
                              type="textarea"
                              class="form-control rounded-0 textArea"
                              component="textarea"
                              aria-describedby="emailHelp"
                              placeholder={t("lbl1")}
                              rows="3"
                              readonly
                              value={a.goals}
                            />
                          </div>   
          </div>
        </div>
      );
    });
    setviewExperiences(elementsExperience)
  }


  const registro = (val) => {
    setloadd(1)
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/applicants`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.error[0],
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          setloadd(0)
          return
        }

        if (res.data.status === true) {
            // setloadd(0)
            props.history.push("/applicant")
        }
        setloadd(0)
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (selected) => {
    setoptionSelected(selected)
  };

  const getLevelStudy = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/level_study`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setlevelStudy(res.data.data.level_study)
      });
  };


  const getCompanyWork = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/company_experience`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setcompanyWork(res.data.data.company_experience)
      });
  };

  const getPositions = async () => {
    await axios
      // .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
      .post(`${process.env.REACT_APP_BASE_URL}/api/attrs/filterpositions/2`, [], {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpositions(res.data.data.positions)
      });
  };

  const getInitMeasure = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/unit_measurement`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setunidad(res.data.data.unit_measurement)
      });
  };

  const getEducationInstitution = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/educational_institutions`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        seteducacionInstitucion(res.data.data.educational_institutions)
      });
  };

  const getLanguaje = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/languages`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setlanguagetipo(res.data.data.languages)
      });
  };

  const getLevelLanguaje = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/language_level`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setnivelLanguage(res.data.data.language_level)
      });
  };

  const getSocialNetwork = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/social_network`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setsocialNetwork(res.data.data.social_network)
      });
  };

  const getCivilStatus = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/civil_status`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setcivilStatus(res.data.data.civil_status)
      });
  };

  const getIdentificationType = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/identification_type`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setidentificationType(res.data.data.identification_type)
      });
  };

  const getActividad = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/activities`)
      .then((res) => {
        setactividades(res.data.data.activities)
      });
  };

  const getCargo = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setcargo(res.data.data.positions)
      });
  };

  const getDeparment = async (val, opc) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (opc === "1")
          setdepartamento(res.data.data.department)
        else setdepartamentoLocation(res.data.data.department)
      });
  };


  const getCities = async (val) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/cities/by_department/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcity(res.data.data.city)
      });
  };


  const getCountry = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpais(res.data.data.country)
        setpaisLocation(res.data.data.country)
      });
  };

  const getGender = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/genders`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setgender(res.data.data.genders)
      });
  };

  const renderList = () => {
    return actividades.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  }

  const renderListActividad = () => {
    return optionSelected.map((data) => ({
      activity: data.value,
    }));
  }

  useEffect(() => {
    if (navigator.onLine) {
      setdatauser(JSON.parse(localStorage.getItem('user')))
      UserProfile.setToken(localStorage.getItem("token"));
      getCountry();
      getIdentificationType();
      getCivilStatus();
      getLevelStudy();
      getSocialNetwork();
      getLanguaje();
      getLevelLanguaje();
      getEducationInstitution();
      getActividad();
      getPositions();
      getCompanyWork();
      getInitMeasure();
      getCargo();
      getGender();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

  
    return (
      <>
        <div>
          <section class="">
            <div class="container py-5 h-100">
              <div class="row d-flex  justify-content-center h-100">
                <div class="col-md-12 col-lg-12 col-xl-12">
                  <div class="square xyz-in" xyz="fade up big">
                    <div class="form-group  row justify-content-center ">
                      <img
                        src={geojobscanada}
                        class=" imgform"
                      />
                    </div>

                    <Formik
                      initialValues={{
                        firstName: datauser.name,
                        middleName: "",
                        firstLastName: "",
                        secondLastMame: "",
                 
                        description: "",
                        idTypeidentification: "",
                        identification: "",
                        idCountry: "",
                        idDepartament: "",
                        idCity:"",
                        idCountrynacionality: "",
                        idDepartamentnacionality: "",
                        emailr: datauser.email,
                        videoPresentation: "",
                        civilStatus: "",

                        birthday: "",
                        address: "",
                        idgender: "",
                        conditions: "",
                      }}
                      validate={(values) => {
                        const pattern = new RegExp(
                          "^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$",
                          "i"
                        );

                        const errors = {};

                        if (!values.firstName)
                          errors.firstName = t("fr");
                        else if (!pattern.test(values.firstName))
                          errors.firstName = t("ot");

                        // if (!values.middleName)
                        //   errors.middleName = t("fr");
                        // else if (!pattern.test(values.middleName))
                        //   errors.middleName = t("ot");

                        if (!values.firstLastName)
                          errors.firstLastName = t("fr");
                        else if (!pattern.test(values.firstLastName))
                          errors.firstLastName = t("ot");

                        // if (!values.secondLastMame)
                        //   errors.secondLastMame = t("fr");
                        // else if (!pattern.test(values.secondLastMame))
                        //   errors.secondLastMame = t("ot");

                        if (!values.address)
                          errors.address = t("fr");
                        else if (!pattern.test(values.address))
                          errors.address = t("ot");

                        // if (!values.description)
                        //   errors.description = t("fr");
                        // else 
                        if (!pattern.test(values.description) && values.description)
                          errors.description = t("ot");
                     
                        if (!values.idCountry) {
                          errors.idCountry = t("fr");
                        } else {
                          if (values.idCountry != estadoDeparment) {
                            getDeparment(values.idCountry, "1");
                            setestadoDeparment(values.idCountry)
                          }
                        }

                        if (!values.idCountrynacionality) {
                          errors.idCountrynacionality = t("fr");
                        } else {
                          if (
                            values.idCountrynacionality !=
                            estadoDeparment2
                          ) {
                            getDeparment(values.idCountrynacionality, "2");
                            setestadoDeparment2(values.idCountrynacionality)
                          }
                        }
                        if (!values.idDepartament) {
                          errors.idDepartament = t("fr");
                        } else {
                          if (
                            values.idDepartament !=
                            estadoCity
                          ) {
                            getCities(values.idDepartament);
                            setestadoCity(values.idDepartament)
                          }
                        }

                        // if ( optionSelected < 1 ) {
                        //   console.log("no pasa")
                        //   errors.applicantActivites = t("fr");
                        // }

                        return errors;
                      }}

                      validationSchema={Yup.object().shape({

                        idTypeidentification:
                          Yup.string(),

                        identification: Yup.number()
                          .typeError(t("onum")),

                        idCountry: Yup.string(),
                        idDepartament: Yup.string(),
                        idCity: Yup.string(),

                        idDepartamentnacionality:
                          Yup.string(),

                        civilStatus: Yup.string().required(t("fr")),
             
                        emailr: Yup.string()
                          .required(t("fr"))
                          .email(t("ei")),

                        videoPresentation:
                          Yup.string(),

                        birthday: Yup.string().required(t("fr")),
                        idgender: Yup.string().required(t("fr")),
                        conditions: Yup.string().required(t("fr")),

                      })}
                      onSubmit={(val, { resetForm }) => {
                      //  resetForm();
                        const data ={
                          first_name: val.firstName.trim(),
                          second_name:val.middleName.trim(),
                          first_lastname: val.firstLastName.trim(),
                          second_lastname:val.secondLastMame.trim(),
                          title: val.address.trim(),
                          description:val.description.trim() ,
                          identification_type:val.idTypeidentification ,
                          identification:val.identification.trim() ,
                          country: val.idCountry,
                          department: val.idDepartament,
                          city: val.idCity,
                          country_nacionality: val.idCountrynacionality,
                          department_nacionality: val.idDepartamentnacionality,
                          email: val.emailr.trim(),
                          civil_status: val.civilStatus,
                          img_perfil: typeof newImage === 'string' ? newImage : "",
                          document: pdfcoding ? pdfcoding.replace('data:application/pdf;base64,', '') : "",
                          birthday:val.birthday,
                          video_presentation: val.videoPresentation.trim(),
                          status: 1,
                          gender: val.idgender,
                          activities: renderListActividad(),

                          networks: socialNetwork,
                          languagues: LanguageList,
                          studies: studies,
                          experiences: experiences,

                        }

                      registro(data)
                      }}
                      enableReinitialize
                      render={({
                        errors,
                        touched,
                        values,
                        handleSubmit,
                        setFieldValue,
                      }) => (

                        <Form class="container">
                          <div class="form-group mb-4 text-center mt-5">
                            <div class="color-fuente-gris textbold">
                              <h3> {t("lbl2")} </h3>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-md-6 col-sm-6">
                              <div class="form-group mb-4">
                                <label for="firstName" class="form-label">{t("lbl3")} *</label>
                                <Field
                                  id="firstName"
                                  name="firstName"
                                  type="numeric"
                                  class="form-control imputText"
                                  placeholder={t("lbl3")}
                                />

                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="middleName" class="form-label">{t("lbl4")}</label>
                                <Field
                                  id="middleName"
                                  name="middleName"
                                  type="text"
                                  class="form-control imputText"
                                  placeholder={t("lbl4")}
                                />

                                <ErrorMessage
                                  name="middleName"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="firstLastName" class="form-label">{t("lbl5")} *</label>
                                <Field
                                  id="firstLastName"
                                  name="firstLastName"
                                  type="text"
                                  class="form-control imputText"
                                  placeholder={t("lbl5")}
                                />

                                <ErrorMessage
                                  name="firstLastName"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="secondLastMame" class="form-label">{t("lbl6")}</label>
                                <Field
                                  id="secondLastMame"
                                  name="secondLastMame"
                                  type="text"
                                  class="form-control imputText"
                                  placeholder={t("lbl6")}
                                />

                                <ErrorMessage
                                  name="secondLastMame"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="address" class="form-label">{t("lbl7")} *</label>
                                <Field
                                  id="address"
                                  name="address"
                                  type="text"
                                  class="form-control imputText"
                                  placeholder={t("lbl7")}
                                />

                                <ErrorMessage
                                  name="address"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="emailr" class="form-label">{t("lbl16")} *</label>
                                <Field
                                  id="emailr"
                                  name="emailr"
                                  type="text"
                                  class="form-control imputText"
                                  placeholder={t("lbl16")}
                                />

                                <ErrorMessage
                                  name="emailr"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="idTypeidentification" class="form-label">{t("lbl9")}</label>
                                <Field
                                  as="select"
                                  id="idTypeidentification"
                                  name="idTypeidentification"
                                  className="form-select fondoamarillo imputText"
                                  
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl9")}
                                  </option>

                                  {identificationType.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.description}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <ErrorMessage
                                  name="idTypeidentification"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="identification" class="form-label">{t("lbl10")}</label>
                                <Field
                                  id="identification"
                                  name="identification"
                                  type="text"
                                  class="form-control imputText"
                                  placeholder={t("lbl10")}
                                />

                                <ErrorMessage
                                  name="identification"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group mb-4">
                                <label for="birthday" class="form-label">{t("lbl22")} *</label>
                                {/* <div class="color-fuente-gris textbold">
                                  {" "}
                                  {t("lbl22")} &nbsp;
                                </div> */}
                                <Field
                                  id="birthday"
                                  name="birthday"
                                  type="date"
                                  class="form-control imputText"
                                  placeholder={t("lbl22")}
                                />

                                <ErrorMessage
                                  name="birthday"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>
                              <div class="form-group mb-4">
                                <label for="civilStatus" class="form-label">{t("lbl17")} *</label>
                                <Field
                                  as="select"
                                  id="civilStatus"
                                  name="civilStatus"
                                  className="form-select fondoamarillo imputText"
                                  aria-label="Default select example"
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl17")}
                                  </option>

                                  {civilStatus.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.description}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <ErrorMessage
                                  name="civilStatus"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="idgender" class="form-label">{t("lbl23")} *</label>
                                <Field
                                  as="select"
                                  id="idgender"
                                  name="idgender"
                                  className="form-select fondoamarillo imputText"
                                  aria-label="Default select example"
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl23")}
                                  </option>

                                  {gender.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.name}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <ErrorMessage
                                  name="idgender"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>
                              
                              <div class="form-group mb-4">
                                <label for="idCountrynacionality" class="form-label">{t("lbl14")} *</label>
                                <Field
                                  as="select"
                                  id="idCountrynacionality"
                                  name="idCountrynacionality"
                                  className="form-select fondoamarillo imputText"
                                  aria-label="Default select example"
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl14")}
                                  </option>

                                  {paisLocation.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.name}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <ErrorMessage
                                  name="idCountrynacionality"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              {/* <div class="form-group mb-4">
                                <label for="idDepartamentnacionality" class="form-label">{t("lbl15")} *</label>
                                <Field
                                  as="select"
                                  id="idDepartamentnacionality"
                                  name="idDepartamentnacionality"
                                  className="form-select fondoamarillo imputText"
                                  aria-label="Default select example"
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl15")}
                                  </option>

                                  {departamentoLocation.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.name}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <ErrorMessage
                                  name="idDepartamentnacionality"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div> */}

                              <div class="card mb-3">
                                <div class="card-body">
                                  <h5 class="card-title">{t("lbl48")}</h5>
                                  <div class="form-group mb-4">
                                    <label for="idCountry" class="form-label">{t("lbl11")} *</label>
                                    <Field
                                      as="select"
                                      id="idCountry"
                                      name="idCountry"
                                      className="form-select fondoamarillo imputText"
                                      
                                    >
                                      <option
                                        className="fondo fw-bolder"
                                        value=""
                                        selected
                                      >
                                        {t("lbl11")}
                                      </option>

                                      {pais.map((i) => {
                                        return (
                                          <option
                                            className="fondo fw-bolder"
                                            value={i.id}
                                          >
                                            {i.name}
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="idCountry"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="idDepartament" class="form-label">{t("lbl12")} *</label>
                                    <Field
                                      as="select"
                                      id="idDepartament"
                                      name="idDepartament"
                                      className="form-select fondoamarillo imputText"
                                      
                                    >
                                      <option
                                        className="fondo fw-bolder"
                                        value=""
                                        selected
                                      >
                                        {t("lbl12")}
                                      </option>

                                      {departamento.map((i) => {
                                        return (
                                          <option
                                            className="fondo fw-bolder"
                                            value={i.id}
                                          >
                                            {i.name}
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="idDepartament"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>

                                  <div class="form-group mb-4">
                                    <label for="idCity" class="form-label">{t("lbl13")} *</label>
                                    <Field
                                      as="select"
                                      id="idCity"
                                      name="idCity"
                                      className="form-select fondoamarillo imputText"
                                    >
                                      <option
                                        className="fondo fw-bolder"
                                        value=""
                                        selected
                                      >
                                        {t("lbl13")}
                                      </option>

                                      {city.map((i) => {
                                        return (
                                          <option
                                            className="fondo fw-bolder"
                                            value={i.id}
                                          >
                                            {i.name}
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="idCity"
                                      component="div"
                                      className="msgerror mx-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

<hr/>

                          <div class="row justify-content-around">
                            <div class="col-md-5">
                              <div class="row border rounded-3">
                                <div class="form-group mb-4">
                                  <div class="row d-flex justify-content-end my-2">
                                    <div class=" col-5 col-sm-5 col-md-5 text-end">
                                      <button
                                        type="button"
                                        class="btn btn-primary  btn-block"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modalEducation"
                                    
                                      >
                                        {t("lbl26")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div> {viewEducation}</div>
                              </div>

                              <div class="row border rounded-3">
                                <div class="form-group mb-4">
                                  <div class="row d-flex justify-content-end my-2">
                                    <div class=" col-5 col-sm-5 col-md-5 text-end">
                                      <button
                                        type="button"
                                        class="btn btn-primary  btn-block"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modalLanguage"
                                      >
                                        {t("lbl25")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div> {viewLanguage}</div>
                              </div>
                            </div>
                            
                            <div class="col-md-5">
                              <div class="row border rounded-3">
                                <div class="form-group mb-4">
                                  <div class="row d-flex justify-content-end my-2">
                                    <div class=" col-5 col-sm-5 col-md-5 text-end">
                                      <button
                                        type="button"
                                        class="btn btn-primary  btn-block"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modalExperience"
                                      >
                                        {t("lbl27")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div> {viewExperiences}</div>
                              </div>

                              <div class="row border rounded-3">
                                <div class="form-group mb-4">
                                  <div class="row d-flex justify-content-end my-2">
                                    <div class=" col-5 col-sm-5 col-md-5 text-end">
                                      <button
                                        type="button"
                                        class="btn btn-primary  btn-block"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modalNetwork"
                                      >
                                        {t("lbl24")}
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div> {viewNetwork}</div>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-md-6 col-sm-6">
                              <div class="form-group mb-4">
                                <label for="file" class="form-label">{t("lbl18")}</label>
                                <input
                                  id="file"
                                  type="file"
                                  class="form-control imputText field-input"
                                  accept="image/png, image/jpeg,image/jpg "
                                  title="Choose a photo please"
                                  webkitRelativePath
                                  directory multiple
                                onChange={fileChangedHandler}
                                /> 

                                <ErrorMessage
                                  name="file"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>
                            </div>

                            <div class="col-md-6 col-sm-6">
                              <div clasS="row align-items-center">
                                <div class="col-md-10">
                                  <div class="form-group mb-4">
                                    <label for="applicantActivites" class="form-label">{t("lbl47")}</label>
                                    <Select
                                      id="applicantActivites"
                                      name="applicantActivites"
                                      placeholder={
                                        <div className="fondoamarillo">
                                          {t("lbl47")}
                                        </div>
                                      }
                                      options={renderList()}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      components={{
                                        Option,
                                      }}
                                      onChange={handleChange}
                                      allowSelectAll={true}
                                      value={optionSelected}
                                    />

                                        <ErrorMessage
                                          name="applicantActivites"
                                          component="div"
                                          className="msgerror mx-2"
                                        />
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <button
                                    className="btn fondo buttonAzulModal"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#mdlnewskill"
                                  >
                                    {t("lbl53")}
                                  </button>
                                </div>
                              </div>
                            </div>

                          </div>

<hr/>

                          <div class="row">
                            <div class="col-md-6 col-sm-12">
                              <div class="form-group mb-4">
                                <label for="videoPresentation" class="form-label">{t("lbl21")}</label>
                                <Field
                                  id="videoPresentation"
                                  name="videoPresentation"
                                  type="text"
                                  class="form-control imputText field-input"
                                  accept="image/png, image/jpeg,image/jpg "
                                  placeholder="https://youtube.com/HRH"
                                  // placeholder={t("lbl21")}
                                />

                                <ErrorMessage
                                  name="videoPresentation"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                              <div class="form-group mb-4">
                                <label for="filedoc" class="form-label">{t("lbl19")}</label>
                                <div class="alert alert-light" role="alert">
                                  {t("lbl20")}
                                </div>

                                <input
                                  id="filedoc"
                                  type="file"
                                  class="form-control imputText field-input"
                                  accept="application/pdf"
                                  title="Choose a photo please"
                                  webkitRelativePath
                                  directory multiple
                                  onChange={filedocChangedHandler}
                                />

                                <ErrorMessage
                                  name="filedoc"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-12 col-sm-6">
                              <div class="form-group mb-4">
                                <label for="description" class="form-label">{t("lbl8")} </label>
                                <Field
                                  id="description"
                                  name="description"
                                  type="textarea"
                                  class="form-control rounded-0 textArea"
                                  component="textarea"
                                  aria-describedby="emailHelp"
                                  placeholder={t("lbl8")}
                                  rows="3"
                                />

                                <ErrorMessage
                                  name="description"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>
                            </div>
                          </div>

                          
                          {/* <div class="form-group mb-4">
                            <label for="idCity" class="form-label">{t("lbl13")}</label>
                            <Field
                              as="select"
                              id="idCity"
                              name="idCity"
                              className="form-select fondoamarillo imputText"
                              
                            >
                              <option
                                className="fondo fw-bolder"
                                value=""
                                selected
                              >
                                {t("lbl13")}
                              </option>

                              {city.map((i) => {
                                return (
                                  <option
                                    className="fondo fw-bolder"
                                    value={i.id}
                                  >
                                    {i.name}
                                  </option>
                                );
                              })}
                            </Field>

                            <ErrorMessage
                              name="idCity"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div> */}


                          

                              {/* <hr/> */}
                        <div class="form-group mb-4">
                          <p>{t("lbl46")}</p>
                        </div>

                        <div class=" left">
                            <Field
                              id="conditions"
                              name="conditions"
                              class="form-check-input"
                              type="checkbox"
                              placeholder={t("lbl49")}
                            />

                            <label
                              class="color-fuente-gris textbold mx-2"
                              for="form1Example4"
                            >
                              {" "}
                              {t("lbl49")} {" "}
                            </label>

                            <ErrorMessage
                              name="conditions"
                              component="div"
                              className="msgerror mx-2"
                            />
                          <a class="fs-6" target="_blank" onClick={()=>window.open(process.env.REACT_APP_DOC_TRAT_DATA, '_blank')} > {t("lbl50")} </a>
                        </div>
                        
                        {
                          loadd == 0 ?
                          <div class="divider d-flex align-items-center my-2">
                            <button
                              type="submit"
                              class="btn btn-warning  btn-block  buttons "
                            >
                              {t("lbl28")}
                            </button>
                          </div>
                          :
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        }
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* modal add other skills */}
        <div
            class="modal fade"
            id="mdlnewskill"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5
                    class="modal-title txtSmallerGrisModal "
                    id="staticBackdropLabel"
                  >
                    {t("lbl53")}
                  </h5>
                  <button
                    id="clocedModal"
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <RegisterSkill addNewSkill={addNewSkill}/>
                </div>
              </div>
            </div>
          </div>

        {/* modal add language */}

        <div
          class="modal fade"
          id="modalLanguage"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl1")}
                </h5>
                <button
                  id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
              
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="tab-o" data-bs-toggle="tab" data-bs-target="#actuallanguage" type="button" role="tab" aria-controls="actuallanguage" aria-selected="true">{t("ttl1")}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link " id="tab-t" data-bs-toggle="tab" data-bs-target="#newlanguage" type="button" role="tab" aria-controls="newlanguage" aria-selected="false">{t("ttl5")}</button>
                  </li>
                </ul>

              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade " id="newlanguage" role="tabpanel" aria-labelledby="tab-t">
                  {/* componente de registro de lenguaje */}
                  <RegisterLanguage addlanguagetipo={addlanguagetipo}/>
                </div>
                <div class="tab-pane fade show active" id="actuallanguage" role="tabpanel" aria-labelledby="tab-o">
                  <Formik
                    initialValues={{
                      language: "",
                      level: "",
                    }}
                    validationSchema={Yup.object().shape({
                      language: Yup.string().required(t("fr")),
                      level: Yup.string().required(t("fr")),
                    })}
                    onSubmit={(val, { resetForm }) => {
                      resetForm();

                      close();
                      addLanguages(val);
                    }}
                    // render={({ errors, status, touched }) => (
                      enableReinitialize
                  >
                    {
                      ({handleSubmit}) => ( 

                      <Form class="container" onSubmit={handleSubmit}>
                        <div class="form-group mb-4">
                          <label for="language" class="form-label">{t("lbl29")}</label>
                          <Field
                            as="select"
                            id="language"
                            name="language"
                            className="form-select fondoamarillo imputText"
                            aria-label="Default select example"
                          >
                            <option className="fondo fw-bolder" value="" selected>
                              {t("lbl29")}
                            </option>

                            {languagetipo.map((i) => {
                              return (
                                <option className="fondo fw-bolder" value={i.id}>
                                  {i.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="language"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>

                        <div class="form-group mb-4">
                          <label for="level" class="form-label">{t("lbl30")}</label>
                          <Field
                            as="select"
                            id="level"
                            name="level"
                            className="form-select fondoamarillo imputText"
                            aria-label="Default select example"
                          >
                            <option className="fondo fw-bolder" value="" selected>
                              {t("lbl30")}
                            </option>

                            {nivelLanguage.map((i) => {
                              return (
                                <option className="fondo fw-bolder" value={i.id}>
                                  {i.description}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="level"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>

                        <div class="divider d-flex align-items-center my-2">
                          <button
                            type="submit"
                            class="btn btn-warning  btn-block  buttons "
                          >
                            {t("lbl28")}
                          </button>
                        </div>
                      </Form>
                    )
                    }
                  </Formik>
                </div>
              </div>

                
              </div>
            </div>
          </div>
        </div>

        {/* modal add Education */}

        <div
          class="modal fade"
          id="modalEducation"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl2")}
                </h5>
                <button
                  id="clocedModalEducation"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">

              <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="tab-o-edu" data-bs-toggle="tab" data-bs-target="#actualeducation" type="button" role="tab" aria-controls="actualeducation" aria-selected="true">{t("ttl2")}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link " id="tab-t-edu" data-bs-toggle="tab" data-bs-target="#neweducation" type="button" role="tab" aria-controls="neweducation" aria-selected="false">{t("ttl6")}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link " id="tab-tr-edu" data-bs-toggle="tab" data-bs-target="#newleveleducation" type="button" role="tab" aria-controls="newleveleducation" aria-selected="false">{t("ttl7")}</button>
                  </li>
                </ul>
{/* educacionInstitucion, levelStudy */}
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade " id="neweducation" role="tabpanel" aria-labelledby="tab-t-edu">
                  <RegisterEducation addNewEducation={addNewEducation}/>
                </div>
                <div class="tab-pane fade " id="newleveleducation" role="tabpanel" aria-labelledby="tab-tr-edu">
                  <RegisterLevelEducation addNewlevelEducation={addNewlevelEducation}/>
                </div>
                <div class="tab-pane fade show active" id="actualeducation" role="tabpanel" aria-labelledby="tab-o-edu">

                    <Formik
                      initialValues={{
                        name: "",
                        educational_institution: "",
                        starting_date: new Date(),
                        finishing_date: new Date(),
                        // duration_time: "",
                        // unit_measurement: "",
                        level_study: "",
                        inprogress: "",
                      }}
                      validate={(values) => {
                        const errors = {};
                        const pattern = new RegExp(
                          "^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$",
                          "i"
                        );

                        if (!values.name) errors.name = t("fr");
                        else if (!pattern.test(values.name))
                          errors.name = t("ot");

                        return errors;
                      }}
                      validationSchema={Yup.object().shape({
                        educational_institution:
                          Yup.string().required(t("fr")),
                          
                          starting_date: Yup.date(),
                          finishing_date: Yup.date(),
                        level_study: Yup.string().required(t("fr")),
                      })}
                      onSubmit={(val, { resetForm }) => {
                        resetForm();

                        closeEducation();
                        addEducation(val);
                      }}
                      render={({ errors, status, touched }) => (
                        <Form class="container mt-3">
                          <div class="form-group mb-4">
                            <label for="name" class="form-label">{t("lbl31")}</label>
                            <Field
                              id="name"
                              name="name"
                              type="text"
                              class="form-control imputText"
                              placeholder={t("lbl31")}
                            />

                            <ErrorMessage
                              name="name"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <label for="educational_institution" class="form-label">{t("lbl32")}</label>
                            <Field
                              as="select"
                              id="educational_institution"
                              name="educational_institution"
                              className="form-select fondoamarillo imputText"
                              aria-label="Default select example"
                            >
                              <option className="fondo fw-bolder" value="" selected>
                                {t("lbl32")}
                              </option>

                              {educacionInstitucion.map((i) => {
                                return (
                                  <option className="fondo fw-bolder" value={i.id}>
                                    {i.name}
                                  </option>
                                );
                              })}
                            </Field>

                            <ErrorMessage
                              name="educational_institution"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                            <div class="form-group mb-4">
                              <label htmlFor="starting_date">{t("lbl51")}</label>
                              <Field
                                id="starting_date"
                                name="starting_date"
                                type="date"
                                class="form-control imputText"
                              />
                              {/* <DatePicker 
                                selected={starting_date}
                                dateFormat="Y-M-d"
                                className="form-control"
                                id="starting_date"
                                name="starting_date"
                                onChange={(e) => {
                                  setstarting_date(e)
                                }}
                              /> */}

                              <ErrorMessage
                                name="starting_date"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>

                            <div class="form-group mb-4">
                              <label htmlFor="finishing_date">{t("lbl52")}</label>
                              <Field
                                id="finishing_date"
                                name="finishing_date"
                                type="date"
                                class="form-control imputText"
                              />
                              {/* <DatePicker 
                                selected={finishing_date}
                                dateFormat="Y-M-d"
                                className="form-control"
                                id="finishing_date"
                                name="finishing_date"
                                onChange={(e) => {
                                  setfinishing_date(e)
                                }}
                              /> */}

                              <ErrorMessage
                                name="finishing_date"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>

                          <div class="form-group mb-4 mb-4 ">
                            <label for="level_study" class="form-label">{t("lbl35")}</label>
                            <Field
                              as="select"
                              id="level_study"
                              name="level_study"
                              className="form-select fondoamarillo imputText"
                              aria-label="Default select example"
                            >
                              <option className="fondo fw-bolder" value="" selected>
                                {t("lbl35")}
                              </option>

                              {levelStudy.map((i) => { 
                                return (
                                  <option className="fondo fw-bolder" value={i.id}>
                                    {i.description}
                                  </option>
                                );
                              })}
                            </Field>

                            <ErrorMessage
                              name="level_study"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <div class=" left">
                              <Field
                                type="checkbox"
                                name="inprogress"
                                className={
                                  "form-check-input " +
                                  (errors.acceptTerms && touched.acceptTerms
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <label
                                class="color-fuente-gris textbold mx-2"
                                for="inprogress"
                              >
                                {" "}
                                {t("lbl36")} {" "}
                              </label>

                              <ErrorMessage
                                name="inprogress"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </div>
                          </div>

                          <div class="divider d-flex align-items-center my-2">
                            <button
                              type="submit"
                              class="btn btn-warning  btn-block  buttons "
                            >
                              {t("lbl28")}
                            </button>
                          </div>
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>





           {/* modal add Experience */}

           <div
          class="modal fade"
          id="modalExperience"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl3")}
                </h5>

                <button
                  id="clocedModalExperience"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="tab-o-xp" data-bs-toggle="tab" data-bs-target="#actualxpcomp" type="button" role="tab" aria-controls="actualxpcomp" aria-selected="true">{t("ttl8")}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link " id="tab-t-xp" data-bs-toggle="tab" data-bs-target="#newxpComp" type="button" role="tab" aria-controls="newxpComp" aria-selected="false">{t("ttl9")}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link " id="tab-tr-xp" data-bs-toggle="tab" data-bs-target="#newPosition" type="button" role="tab" aria-controls="newPosition" aria-selected="false">{t("ttl10")}</button>
                  </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade " id="newxpComp" role="tabpanel" aria-labelledby="tab-t-xp">
                    <RegisterXpCompany addNewXpCompany={addNewXpCompany}/>
                  </div>
                  <div class="tab-pane fade " id="newPosition" role="tabpanel" aria-labelledby="tab-tr-xp">
                    <RegisterPosition addNewPosition={addNewPosition}/>
                  </div>
                  <div class="tab-pane fade show active" id="actualxpcomp" role="tabpanel" aria-labelledby="tab-o-xp">

                    <Formik
                      initialValues={{

                        position: "",
                        company: "",
                        duration_time: "",
                        unit_measurement: "",
                        initial_year: "",
                        functions: "",
                        goals: ""
                      }}
                  
                      validationSchema={Yup.object().shape({
                        position:
                          Yup.string().required(t("fr")),
                        company:
                          Yup.string().required(t("fr")),

                        duration_time: Yup.number()
                          .typeError(t("onum"))
                          .required(t("fr")),

                        unit_measurement: Yup.string().required(t("fr")),
                        
                        initial_year: Yup.string().required(t("fr")),
                        functions: Yup.string().required(t("fr")),
                        goals: Yup.string().required(t("fr")),
                      })}
                      onSubmit={(val, { resetForm }) => {
                        resetForm();
                        closeExperience();
                        addExperience(val);
                      }}
                      render={({ errors, status, touched }) => (
                        <Form class="container mt-3">
                              <div class="form-group mb-4">
                                <label for="position" class="form-label">{t("lbl37")}</label>
                                <Field
                                  as="select"
                                  id="position"
                                  name="position"
                                  className="form-select fondoamarillo imputText"
                                  aria-label="Default select example"
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl37")}
                                  </option>

                                  {positions.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.name}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <ErrorMessage
                                  name="position"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="duration_time" class="form-label">{t("lbl33")}</label>
                                <Field
                                  id="duration_time"
                                  name="duration_time"
                                  type="number"
                                  class="form-control imputText"
                                  placeholder={t("lbl33")}
                                />

                                <ErrorMessage
                                  name="duration_time"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="unit_measurement" class="form-label">{t("lbl34")}</label>
                                <Field
                                  as="select"
                                  id="unit_measurement"
                                  name="unit_measurement"
                                  className="form-select fondoamarillo imputText"
                                  aria-label="Default select example"
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl34")}
                                  </option>

                                  {unidad.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.name}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <ErrorMessage
                                  name="unit_measurement"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="company" class="form-label">{t("lbl39")}</label>
                                <Field
                                  as="select"
                                  id="company"
                                  name="company"
                                  className="form-select fondoamarillo imputText"
                                  aria-label="Default select example"
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl39")}
                                  </option>

                                  {companyWork.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.name}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <ErrorMessage
                                  name="company"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="initial_year" class="form-label">{t("lbl40")}</label>
                                <Field
                                  id="initial_year"
                                  name="initial_year"
                                  type="date"
                                  class="form-control imputText"
                                  placeholder="Initial Year"
                                />

                                <ErrorMessage
                                  name="initial_year"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="functions" class="form-label">{t("lbl41")}</label>
                                <Field
                                  id="functions"
                                  name="functions"
                                  type="textarea"
                                  class="form-control rounded-0 textArea"
                                  component="textarea"
                                  aria-describedby="emailHelp"
                                  placeholder={t("lbl41")}
                                  rows="3"
                                />

                                <ErrorMessage
                                  name="functions"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="goals" class="form-label">{t("lbl42")}</label>
                                <Field
                                  id="goals"
                                  name="goals"
                                  type="textarea"
                                  class="form-control rounded-0 textArea"
                                  component="textarea"
                                  aria-describedby="emailHelp"
                                  placeholder={t("lbl42")}
                                  rows="3"
                                />

                                <ErrorMessage
                                  name="goals"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                          <div class="divider d-flex align-items-center my-2">
                            <button
                              type="submit"
                              class="btn btn-warning  btn-block  buttons "
                            >
                              {t("lbl28")}
                            </button>
                          </div>
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

           {/* modal add network */}

           <div
          class="modal fade"
          id="modalNetwork"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")}
                </h5>

                <button
                  id="clocedModalNetwork"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    network: "",
                    nickname: "",
                    url: "",
                  }}
              
                  validationSchema={Yup.object().shape({
                    network:
                      Yup.string().required(t("fr")),
                      nickname:
                      Yup.string().required(t("fr")),
                    url: Yup.string().required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closeNetwork();
                    addNetwork(val);
                  }}
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                        <div id="network">
                          <div class="form-group mb-4">
                            <label for="goals" class="form-label">{t("lbl43")}</label>
                            <Field
                              as="select"
                              id="network"
                              name="network"
                              className="form-select fondoamarillo imputText"
                              aria-label="Default select example"
                            >
                              <option
                                className="fondo fw-bolder"
                                value=""
                                selected
                              >
                                {t("lbl43")}
                              </option>

                              {socialNetwork.map((i) => {
                                return (
                                  <option
                                    className="fondo fw-bolder"
                                    value={i.id}
                                  >
                                    {i.name}
                                  </option>
                                );
                              })}
                            </Field>

                            <ErrorMessage
                              name="network"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <label for="nickname" class="form-label">{t("lbl44")}</label>
                            <Field
                              id="nickname"
                              name="nickname"
                              type="text"
                              class="form-control imputText"
                              placeholder={t("lbl44")}
                            />

                            <ErrorMessage
                              name="nickname"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <label for="url" class="form-label">{t("lbl45")}</label>
                            <Field
                              id="url"
                              name="url"
                              type="text"
                              class="form-control imputText"
                              placeholder={t("lbl45")}
                            />

                            <ErrorMessage
                              name="url"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>
                          </div>
                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl28")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default FormRegisterApplicant;
