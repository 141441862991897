import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";

import Swal from "sweetalert2";

import axios from "axios";

import verimg from "../../img/ver.png";
// import listicon from "../../img/list.png";
// import ReactPaginate from "react-paginate";

const AppliSkillsReport = (props) => {

const [t, i18n] = useTranslation("applSkillReport")
  
  const [ applicants, setapplicants ] = useState([]);
  const [ count, setcount ] = useState(0);

  const list = async () => {
    // setapplicants([])
    // setapplicants([
    //   {
    //     "activity" : {"id":"3", "name": "Perforador"},
    //     "cant" : 30
    //   },
    //   {
    //     "activity" : {"id":"1", "name": "soldador"},
    //     "cant" : 24
    //   }
    // ])
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/applicant_activities_count`, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
          params:{
            page: 1
        }}
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setapplicants( res.data.data )
      })
      .catch((error) => console.log(error));
  };

  const counter = () => {
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/applicants`, {
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      }
    })
    .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setcount(res.data.data.total)
      })
      .catch((error) => console.log(error));
  }

  
  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
      counter()
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

    return (
      <>
        <div class="card">
            <h5 class="card-header">{t('ttl1')} - {t('ttl5')} {count} </h5>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">{t('ttl2')}</th>
                            <th scope="col">{t('ttl3')}</th>
                            {/* <th scope="col">{t('ttl4')}</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {   
                          applicants.map((applic)=>(
                              <tr>
                                  <td>{applic.activity.name}</td>
                                  <td>{applic.count_applicants}</td>
                                  {/* <td>
                                      <div class="col-5 col-sm-5 col-md-3">
                                          {" "}
                                          <a
                                            href="javascript:void(0)"
                                            title={t('lbl1')}
                                            onClick={() => {
                                              console.log("Searching applicants ...")
                                            }}
                                          >
                                              <img
                                              width="30"
                                              height="30"
                                              class=" mx-3"
                                              src={verimg}
                                              />
                                          </a>
                                      </div>
                                  </td> */}
                              </tr>
                          ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
      </>
    );
}
export default AppliSkillsReport;
