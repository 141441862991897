import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import withFirebaseAuth from "react-with-firebase-auth";
// import firebase from "firebase/app";
import "firebase/auth";
// import firebaseConfig from "./firebaseConfig";
// import MicrosoftLogin from "react-microsoft-login";
import axios from "axios";
import Swal from "sweetalert2";
// import { Redirect, BrowserRouter, Switch } from "react-router-dom";
import UserProfile from "./UserProfile";
// import Header from "../Header/Header";

// import svgFacebook from "../img/facebook.svg";
import icoGoogle from "../img/google_ico.png";
import linkedin from "../img/linkedinicon.png"
// import imgform from "../img/imgfroms.png";
import geojobscanada from "../img/formhrh.png";

// import Loginform from "../Login/Loginform"

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// const firebaseApp = !firebase.apps.length
//   ? firebase.initializeApp(firebaseConfig)
//   : firebase.app();

const Login = (props) => {
  const [t, i18n] = useTranslation("login")

  const [ chekContras, setchekContras ] = useState(false);
  const [ tokentemp, settokentemp ] = useState("");
  const [ idusertemp, setidusertemp ] = useState("");
  

  const iniciaSesionGoogle = (nombre, correo, id) => {
    const user = { id: 1}
    // const user = { id: id, name: nombre, email: correo }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/auth/social/google/login`, user)
      .then((res) => {
        if (res.data.status == true) {
          UserProfile.setToken(res.data.data.token);
          props.history.push("/home");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "to Log in",
          });
        }
      });
  }

  // const registerFacebook = (nombre, correo, id, foto) => {}

  const check = async () => {
    if (chekContras != false) {
      setchekContras(false)
    } else {
      setchekContras(true)
    }
  }

  const crearCuenta = async () => {
    props.history.push("/register");
  }

  const recuperacionCuenta = async () => {
    props.history.push("/paswordrecovery");
  }

  const setLogin = async (value) => {
    const user = {
      email: value.email,
      password: value.password,
    }
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/auth/login`, user)
        .then((res) => {
          if (res.data.status == true) {
            if (res.data.data.user.status.id == 2) {
              activeAcount(res)
            }else{
              loadSessionData(res)
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "could not login",
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
  }

  const changeTypeUser = (type) => {
    const valuesupdate = {
      userid: idusertemp,
      type: type,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/changetypeuser`,
        valuesupdate, {
           headers: {
             Authorization: `Bearer  ${tokentemp}`,
           }
         }
      )
      .then((res) => {
        document.getElementById("closemodal").click()
        loadSessionData(res)
      })
      .catch((error) => console.log(error));
  }

  const loadSessionData = (res) => {
    if ( !((res.data.data.user.type ? res.data.data.user.type.id : 0) > 0) || res.data.data.user.type.id == 4 ) {
      document.getElementById("btnmdlchangeusertype").click()
      settokentemp(res.data.data.token)
      setidusertemp(res.data.data.user.id)
    }else{
      switch (res.data.data.user.type.id) {
        case 1:
          localStorage.setItem('token',res.data.data.token);
          localStorage.setItem('id',res.data.data.user.id);
          localStorage.setItem('user',JSON.stringify(res.data.data.user));
          props.history.push("/admin")
        break;

        case 2:
          localStorage.setItem('token',res.data.data.token);
          localStorage.setItem('id',res.data.data.user.id);
          localStorage.setItem('user',JSON.stringify(res.data.data.user));
          axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/has_profile_applicant`,{      
            headers: {
              Authorization: `Bearer  ${res.data.data.token}`,
            }
          })
          .then((res) => {
            if(res.data.status===true){
              props.history.push("/applicant")
            }else{
              props.history.push("/applicant")
              // props.history.push("/registerapplicant")
            }
          });
        break;

        case 3:
        localStorage.setItem('user',JSON.stringify(res.data.data.user));
        localStorage.setItem('token',res.data.data.token);
        localStorage.setItem('id',res.data.data.user.id);
          axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/has_companies`, {      
            headers: {
              Authorization: `Bearer  ${res.data.data.token}`,
            }
          })
          .then((res) => {
            if(res.data.status===true){
              props.history.push("/company")
            }else{
              props.history.push("/registercompany")
            }
          });
        break;
      }
    }
  } 

  const activeAcount = (res) => {
    Swal.fire({
      title: t("lbl14"),
      text: t("lbl15"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t("lbl16")
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus(res)
        loadSessionData(res)
      }
    })
  }

  const updateStatus = (user) => {
    const valuesupdate = {
      name: user.data.data.user.name,
      email: user.data.data.user.email,
      type: 2,
      status: 1
    }
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/users/${user.data.data.user.id}`,
        valuesupdate
      )
      .then(() => {
        Swal.fire({
          icon: "success",
          title: t('msg6'),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      })
      .catch((error) => console.log(error));
  }
  
    // const auth = firebase.auth()
  
    const validregisterlinkedin = () => {
      let params = props.match.params
      if (params.data) {
        const data = JSON.parse(params.data)
        if (data.original.status) {
          const res = {
            'data': {
              'data': {
                'user' : data.original.data.user,
                'token' : data.original.data.token
              }
            }
          }
          loadSessionData(res)
        }else{
          Swal.fire({
            icon: "success",
            title: "Opss",
            text: data.original.error[0],
          });
        }
      }
    }

    useEffect(()=>{
      validregisterlinkedin()
    }, [])

    return (
      <>
        <section class="vh-90">
          <div class="container h-100">
            <div class="row d-flex align-items-center justify-content-center h-100">
              <div class="col-md-12 col-lg-5 col-xl-5 offset-xl-1">
                {/* {user ? (
                  <p>
                    {iniciaSesionGoogle(
                      user.displayName,
                      user.email,
                      user.uid
                    )}
                  </p>
                ) : (
                  <p>{() => setloginGoogle(false )}</p>
                )} */}

                <div class="square xyz-in" xyz="fade up big">
                  
                  <div class="form-group  row justify-content-center mb-4" onClick={()=>props.history.push("/")}>
                    <img src={geojobscanada} class="img-fluid imgform cursorpointer" />
                  </div>

                  <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().email("Invalid email").required("Field is required"),
                    password: Yup.string().required("Field is required"),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    setLogin(val)
                  }}
                  render={({ errors, status, touched }) => (
                   <Form>
                    <div class="form-group mb-4">
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        class="form-control imputText"
                        aria-describedby="emailHelp"
                        placeholder={t("lbl2")}
                      />

                        <ErrorMessage
                          name="email"
                          component="div"
                          className="msgerror mx-2"
                        />
                    </div>

                    <div class="form-group mb-4">
                      <Field
                        id="password"
                        name="password"
                        type={chekContras ? "text" : "password"}
                        class="form-control imputText"
                        aria-describedby="emailHelp"
                        placeholder={t("lbl5")}

                      />

                        <ErrorMessage
                          name="password"
                          component="div"
                          className="msgerror mx-2"
                        />
                    </div>

                    <div class="d-flex   mb-4">
                      <div class=" left">
                        <input
                          id="checTerminos"
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          onClick={() => check()}
                        />
                        <label
                          class="leftLabel  form-check-label inputCheckbox"
                          for="checTerminos"
                        >
                          {t("lbl31")}
                        </label>
                      </div>
                    </div>


                    <div class="divider d-flex  mt-1">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl27")}
                        </button>
                    </div>
                  </Form>
                  )}
                  />

                  <div class="divider d-flex align-items-center my-2">
                    <div class="textbold color-fuente-gris">
                      {t("lbl30")}
                    </div>
                  </div>

                  <div class="divider d-flex align-items-center mb-2">
                    {/* <div
                      class="tamanoLetra textbold  color-fuente-negro"
                      href="#"
                    > */}
                    <button
                      type="submit"
                      class="btn btn-warning  btn-block  buttons "
                      onClick={() => crearCuenta()}
                    >
                      {t("lbl28")}
                    </button>
                    {/* </div> */}
                  </div>

                  <div class="divider d-flex align-items-center ">
                    <button
                      type="submit"
                      class="btn btn-warning buttons widtauto"
                      wi
                      onClick={() => recuperacionCuenta()}
                    >
                      {t("lbl29")}
                    </button>
                  </div>

                  <div class="divider d-flex align-items-center my-1">
                    <p class="text-center fw-bold mx-3 mb-0 text-muted fuente-gris my-3">
                      {t("lbl25")}
                    </p>
                  </div>

                  <div class="divider d-flex align-items-center my-1">
                    <a  href={process.env.REACT_APP_BASE_URL+"/auth/google-redirect"} >
                      <div class="boton">
                        <img
                          class="img-responsive iconoRedes"
                          src={icoGoogle}
                        ></img>
                      </div>
                    </a>

                    {/* <a href="#" onClick={signInWithFacebook}>
                      <div class="botonfacebook">
                        <img
                          class="img-responsive iconoRedes"
                          src={svgFacebook}
                        />
                      </div>
                    </a> */}

                    {/* <a target="_blank" href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=00000001&scope=r_liteprofile%20r_emailaddress&client_id=7804ewjbbzl1jv&redirect_uri=https%3A%2F%2Foauth.pstmn.io%2Fv1%2Fcallback"  >  */}
                    <a href={process.env.REACT_APP_URLENCODE_LOGIN_LINKEDIN} >
                      <div class="botonfacebook">
                        <img
                          class="img-responsive iconoRedes"
                          src={linkedin}
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <button class="invisible" id="salirRedes" >
          {t("ttl4")}
        </button>


        <button type="button" id="btnmdlchangeusertype" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#mdlchangeusertype"></button>
        <div class="modal fade" id="mdlchangeusertype" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="mdlchangeusertypeLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="mdlchangeusertypeLabel">{t("txt1")}</h5>
                <button id="closemodal" type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="row justify-content-center text-center">
                    <div class="col">
                      <button type="button" class="btn btn-info" value="2" onClick={(e)=>changeTypeUser(e.target.value)}> {t("lbl23")} </button>
                    </div>
                    <div class="col">
                      <p class=""> {t("lbl25")} </p>
                    </div>
                    <div class="col">
                      <button type="button" class="btn btn-success" value="3" onClick={(e)=>changeTypeUser(e.target.value)}> {t("lbl24")} </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Understood</button>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  
}

// const firebaseAppAuth = firebaseApp.auth();

// const providers = {
  // googleProvider: new firebase.auth.GoogleAuthProvider(),
  // facebookProvider: new firebase.auth.FacebookAuthProvider(),
// }

// export default withFirebaseAuth({
//   providers,
//   firebaseAppAuth,
// })(Login);

export default Login;
