import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import geojobs from "../img/goejobs.png";
import img_1 from "../img/1.jpeg";
import geojobscanada from "../img/imghrh.png";
import geojobs11 from "../img/11.png";

import '../Css/homeCompany.css';

const ServicesPublic = (props) => {
  const [t, i18n] = useTranslation("about")
  // i18n.changeLanguage("")

 const [listServices, setlistServices] = useState([])
 const [ opc, setopc ] = useState("1");



  const list = async () => {
    setlistServices([])
    setlistServices([{
      "id":1,
      "name": "Candidate information",
      "name_es": "Información del solicitante",
      "name_fr": "Informations du candidat",
      "description" : "Unlocks the candidate's complete contact information (full name, phone, email)",
      "description_es" : "Desbloquear los datos de contacto completos del solicitante (nombre completo, teléfono, correo electrónico)",
      "description_fr" : "Débloque les coordonnées complètes du candidat (nom complet, téléphone, courriel)",
      "price" : "150 $CA"
    },
    {
      "id":2,
      "name": "Previous references",
      "name_es": "Referencias anteriores",
      "name_fr": "Références antérieures",
      "description" : "Carrying out professional reference checks and criminal record checks",
      "description_es" : "Realización de comprobaciones de referencias profesionales y de antecedentes penales",
      "description_fr" : "Réalisation des vérifications des références professionnelles et du casier judiciaire",
      "price" : "150 $CA"
    },
    {
      "id":3,
      "name": "Preliminary interview (preparation and mediation)",
      "name_es": "Entrevista previa (preparación y mediación)",
      "name_fr": "Entretien préliminaire (préparation et médiation)",
      "description" : "Organization of the interview between the two parties, participation if necessary for translation and mediation (30 min meeting)",
      "description_es" : "Organización de la entrevista entre las dos partes, participación si es necesario para la traducción y la mediación (reunión de 30 minutos)",
      "description_fr" : "Organisation de l’entretien entre les deux parties, participation au besoin pour fin de traduction et médiation (30 min de réunion)",
      "price" : "150 $CA"
    },
    {
      "id":4,
      "name": "Professional inspection",
      "name_es": "Inspección profesional",
      "name_fr": "Inspection professionnelle",
      "description" : "Professional technical test for specific skills of the candidate, possibility of video recording and attestation of success by a certified instructor (certain skills only)",
      "description_es" : "Prueba técnica profesional para habilidades específicas del candidato, posibilidad de grabación en vídeo y certificado de éxito por parte de un instructor certificado (sólo para ciertas habilidades)",
      "description_fr" : "Test technique professionnel pour des aptitudes précises du candidat, possibilité d’enregistrement vidéo et attestation de réussite par un instructeur certifié (certaines aptitudes seulement)",
      "price" : "300 $CA"
    }])
  };

  const hederClick = async (item) => {
    setopc(item)
    switch (item) {
      case "6":
        props.history.push("/login");
        break;
    }
  };

  useEffect(() => {
    list()
    }, []);

    return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
            <div class="row align-items-center my-4">
              {
                listServices.map((i)=>(
                  <>
                  <div class="col-md-3 col-sm-12 col-xs-12 col-lg-3 mb-2 heig" 
                      onClick={() => {
                        hederClick("6");
                      }}>
                    <div class="cardservice pt-5">
                      <div class="orangelinelg mb-5 text-center">
                        <div class="col">
                          <h2 class="negr">$ {i.price}</h2>
                        </div>
                      </div>
                      <div class="container-fluid">
                        <div class="row text-white text-center">
                          <div class="col">
                            <h5>
                            {
                              localStorage.getItem('lngselect') == "en" ?
                                i.name :
                              localStorage.getItem('lngselect') == "es" ?
                                i.name_es :
                              localStorage.getItem('lngselect') == "fr" ? 
                                i.name_fr : ""
                            }</h5>
                            <p>{
                              localStorage.getItem('lngselect') == "en" ?
                                i.description :
                              localStorage.getItem('lngselect') == "es" ?
                                i.description_es :
                              localStorage.getItem('lngselect') == "fr" ? 
                                i.description_fr : ""
                              }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                ))
              }
              
            </div>
          </div>
        </div>
      </>
    );
}

export default ServicesPublic;
