import React, { Component } from "react";
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';

import Login from '../Login/Login';
import Home from '../Header/Header';
import Admin from '../Admin/Header/HeaderAdmin';
import Company from '../Company/Header/HeaderCompany';
import Register from '../Register/Register';
import PasswordRecovery from '../PasswordRecovery/PasswordRecovery';
import ChangePassword from '../PasswordRecovery/ChangePassword';
import FormRegisterCompany from "../Company/Pages/FormRegisterCompany";
import FormRegisterApplicant from "../Applicant/Pages/FormRegisterApplicant";
import Applicant from "../Applicant/Header/HeaderApplicant";

import PaymentCompleted from "../components/PaymentCompleted";
import PaymentCanceled from "../components/PaymentCanceled";
import PaymentFailed from "../components/PaymentFailed";

import "../Css/Estilos.css"
import "../Css/header.css";
import '@animxyz/core'

const Router = () => (
    <HashRouter>
        <Switch>

            <Route exact path="/" component={Home} /> 
            <Route exact path="/login/:data?" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/company" component={Company} />
            <Route exact path="/registercompany" component={FormRegisterCompany} />
            <Route exact path="/registerapplicant" component={FormRegisterApplicant} />
            <Route exact path="/applicant" component={Applicant} />

            <Route exact path="/paswordrecovery" component={PasswordRecovery} />
            <Route exact path="/changepassword" component={ChangePassword} />
            
            <Route exact path="/PaymentCompleted/:status" component={PaymentCompleted} />
            <Route exact path="/PaymentCanceled/:status" component={PaymentCanceled} />
            <Route exact path="/PaymentFailed/:status" component={PaymentFailed} />
            
        </Switch>
    </HashRouter>
);

export default Router;