import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";

import { components } from "react-select";
import Select from "react-select";

import RegisterActivity from "../../components/RegisterActivity"

import UserProfile from "../../Login/UserProfile";
// import geojobscanada from "../../img/formhrh.png";
// import imgform from "../../img/formhrh.png";
// import listplas from "../../img/listplas.png";
// import sendimg from "../../img/send.png";
// import user from "../../img/user.png";
// import userchat from "../../img/userchat.png";
import defaultimage from "../../img/defaultimage.png";


const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
  
      padding: 20,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 200,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
  
      return { ...provided, opacity, transition };
    },
  };
  
  
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            class="form-check-input"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  

const PerfilCompany = (props) => {

const [t, i18n] = useTranslation("perfilcomp")
// i18n.changeLanguage("")
 
        // fileChangedHandler = fileChangedHandler.bind(this);
        // filedocChangedHandler = filedocChangedHandler.bind(this);
      
    const [ idEmpresa, setidEmpresa ] = useState(0);
    const [ pais, setpais ] = useState([]);
    const [ departamento, setdepartamento ] = useState([]);
    const [ conta, setconta ] = useState(0);
    const [ reponsable, setreponsable ] = useState([]);
    const [ cargo, setcargo ] = useState([]);
    
    const [ actividades, setactividades ] = useState([]);
    const [ optionSelected, setoptionSelected ] = useState(null);

    const [ actividadesempresa, setactividadesempresa ] = useState([]);
    
    const [ newImage, setnewImage ] = useState("");
    const [ pdfcoding, setpdfcoding ] = useState("");
    
    const [ profile, setprofile ] = useState([]);
    const [ countryId, setcountryId ] = useState("");
    const [ departmentId, setdepartmentId ] = useState("");
    
    const [ nomMana, setnomMana ] = useState("");
    const [ lasnameMana, setlasnameMana ] = useState("");
    const [ identiMana, setidentiMana ] = useState("");
    const [ emailMana, setemailMana ] = useState("");
    const [ posiMana, setposiMana ] = useState("");
    
    const [ actividadesres, setactividadesres ] = useState([]);
    
    const [ comentarios, setcomentarios ] = useState([]);


    const addNewActivity = (data) => {
      setactividades([...actividades, data])
    }


  const fileChangedHandler = (event) => {

    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          500,
          500,
          "png",
          85,
          0,
          (uri) => {
            setnewImage(uri)
          },
          "base64"
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  const filedocChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      var ext = event.target.files[0].name.split('.').pop();
      if(ext == "pdf"){
        if(event.target.files[0].size > 5242880){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg1"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        let fileToLoad = event.target.files[0];
        let file = null;
        let fileName = null;
        fileName = fileToLoad.name;
        // FileReader function for read the file.
        let fileReader = new FileReader();
        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
            file = fileLoadedEvent.target.result;
            setpdfcoding(file)
        };
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
      }
      else
      {
        // Swal.fire({
        //   icon: "error",
        //   title: "Oops...",
        //   text: t("msg2"),
        //   confirmButtonColor: "#071b30",
        //   confirmButtonText: t("ok"),
        // });
      }
    }
  }

  // const listChat = async () => {
  //   setcomentarios([])
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/api/commentcompany/`+idEmpresa,
  //       {
  //         headers: {
  //           Authorization: `Bearer  ${UserProfile.getToken()}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
  //       setcomentarios(res.data.data.comment_company)
  //     });
  // };

  // const send = async () => {
  //   if (document.getElementById("mensaje").value != "") {
  //     var data = {
  //       comment: document.getElementById("mensaje").value,
  //       company: 1,
  //     };
  //     axios
  //       .post(`${process.env.REACT_APP_BASE_URL}/api/comment_company`, data, {
  //         headers: {
  //           Authorization: `Bearer  ${UserProfile.getToken()}`,
  //         },
  //       })
  //       .then((res) => {
  //         if (res.data.status === true) {
  //           listChat();
  //         }
  //       })
  //       .catch((error) => console.log(error));
  //       document.getElementById("mensaje").value=""
  //   }
  // };

    const getProfile  = async () => {
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/companies/profile`, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
          const data = res.data.data
          setidEmpresa(data.id)
          setprofile(res.data.data)
          setnewImage(res.data.logo)
          setcountryId(res.data.data.country.id)
          getDeparment(res.data.data.country.id)
          setdepartmentId(res.data.data.department.id)

          setnomMana(res.data.data.legal_representative[0].first_name)
          setlasnameMana(res.data.data.legal_representative[0].last_name)
          setidentiMana(res.data.data.legal_representative[0].identification)
          setemailMana(res.data.data.legal_representative[0].email)
          setposiMana(res.data.data.legal_representative[0].position)

          setactividadesres(res.data.data.activities)
          let opactsel = []
          for (const {id, name} of data.activities) {
            opactsel.push({"value":id, "label":name})
          }
          setoptionSelected(opactsel)
        });
    };
  
    const registro = (val, id) => {
        axios
        .put(`${process.env.REACT_APP_BASE_URL}/api/companies/${id}`,val,{
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if(res.data.status===true){
            getProfile()
            Swal.fire({
              icon: "success",
              title: "Success",
              text: t("msg3"),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
          }
        }).catch((error) => console.log(error));
    };

      const handleChange = (selected) => {
        setoptionSelected(selected)
      };

    const getActividad = async () => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/activities`, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            setactividades(res.data.data.activities)
          });
    };
    
      const getCargo = async () => {
        axios
          // .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
          .post(`${process.env.REACT_APP_BASE_URL}/api/attrs/filterpositions/1`, [], {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            setcargo(res.data.data)
          });
      };
    
      const getDeparment = async (val) => {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${val}`,
            {
              headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`,
              },
            }
          )
          .then((res) => {
            setdepartamento(res.data.data.department)
          });
      };
    
      const getCountry = async () => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            setpais(res.data.data.country)
          });
      };

  const renderList = () => { 
    return actividades.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  }

  const renderselect  = () => {
    return actividadesres.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  }


  const renderListActividad = () => {
    return optionSelected.map((data) => ({
      activity: data.value,
    }));
  }

    useEffect(() => {
        if (navigator.onLine) {
          UserProfile.setToken(localStorage.getItem('token'));
          getCountry();
          getCargo();
          getActividad();
          getProfile();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg4"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
        }
    }, [])


    return (
      <>
        <div>
          <section class="">
            <div class="container py-5 h-100">
              <div class="row d-flex  justify-content-center h-100">
                <div class="col-md-12 col-lg-5 col-xl-5 offset-xl-1">
                    
                    {/* start chat with admin */}

                    <div class="form-group  row justify-content-center ">
                      <div class="text-left ">
                        <div className="mb-4">
                          <div className="container ">
                            <div class="row  my-1 ">
                              <div class="col-11 text-left txtSmallAzulModal ">
                                {/* <div class="dropdownn">
                                  <button
                                    class="btn btn-lightModal buttonSmallAzulModal"
                                    type="button"
                                    id="dropdownMenuButton2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={listChat.bind()}
                                  >
                                    <span>
                                      <img
                                        className="img-fluid "
                                        width="12px"
                                        height="12px"
                                        src={listplas}
                                      />
                                    </span>
                                    {t("lbl1")}
                                  </button>

                                  <div
                                    class="dropdown-menu fondoAzulModal"
                                    aria-labelledby="themes"
                                  >
                                    <div class="container">
                                      <div class="form-group mb-1 text-center ">
                                        <img
                                          className=" mt-2 img-fluid"
                                          width="20px"
                                          height="20px"
                                          src={user}
                                        />
                                      </div>
                                      <div class="form-group mb-2 text-center ">
                                        {t("lbl2")}
                                      </div>
                                    </div>

                                    <div className="linea lineamovil"></div>
                                    <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                                      <div className="anyClass anyClassMovil" id="topabajo">
                                        {comentarios.map((i) => {
                                          if (i.type != 3) {
                                            return (
                                              <>
                                                <div class="form-group mb-2 text-left mt-2 ">
                                                  <div class="row  my-1 ">
                                                    <div class="col-2 col-sm-2 col-md-2 ">
                                                      <img
                                                        className=" mt-2 img-fluid ingMpvilchat"
                                                        width="50px"
                                                        height="50px"
                                                        src={userchat}
                                                      />
                                                    </div>

                                                    <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                                                      <div className="txtSmallGris">
                                                      {i.name}
                                                      </div>

                                                      <p className="text-left"> {i.comment} </p>
                                                      <div class="txtSmallGris"> {i.created_at} </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <div class="form-group mb-2  mt-2 ">
                                                  <div class="row  my-1 justify-content-end">
                                                    <div class=" col-5 col-sm-5 col-md-5 text-end mt-1 globo">
                                                      <div className="mt-2 txtSmallGris">
                                                      {i.compname}
                                                      </div>

                                                      <p className="">
                                                      {i.comment}
                                                      </p>
                                                      <div class="txtSmallGris"> {i.created_at} </div>
                                                    </div>

                                                    <div class="col-2 col-sm-2 col-md-2">
                                                      <img
                                                        className="mt-2 img-fluid ingMpvilchat"
                                                        width="50px"
                                                        height="50px"
                                                        src={userchat}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        })}
                                      </div>
                                    </div>

                                    <div class="container">
                                      <div class="row  my-1 ">
                                        <div class="col-10 ">
                                          <input
                                            id="mensaje"
                                            type="text"
                                            name="mensaje"
                                            className="mt-1 form-control imputTextModal"
                                            aria-describedby="emailHelp"
                                            placeholder={t("lbl3")}
                                          />
                                        </div>

                                        <div class="col-2 text-left ">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => {
                                              send();
                                            }}
                                          >
                                            <img
                                              className=" mt-2 img-fluid"
                                              width="20px"
                                              height="20px"
                                              src={sendimg}
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* end chat with admin */}

                    <div class="form-group  row justify-content-center ">
                      <img
                        src={profile.logo ?profile.logo : defaultimage}
                        style={{maxHeight:"190px"}}
                        class="img-responsive imgformcircle rounded-circle mb-2"
                      />
                    </div> 

                    <Formik
                      initialValues={{

                        id:profile.id,
                        logo: newImage,
                        identification: profile.nit,
                        name: profile.name,
                        description: profile.description,
                        phone: profile.phone_number,
                        addres: profile.address,
                        country: countryId,
                        departmen: departmentId,
                        codepostal: profile.postal_code,

                        //responsable
                        namerespon: nomMana,
                        namerespons:lasnameMana,
                        identificacionespons: identiMana,
                        correorespons: emailMana,
                        positionrespon:  posiMana,

                        //actividadempresa:"",

                      }}
                      validate={(values) => {
                        const pattern = new RegExp(
                          "^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$",
                          "i"
                        );
                        const errors = {};
                        if (!values.name) errors.name = t("fr");

                        if (!values.namerespon)
                          errors.namerespon = t("fr");
                        else if (!pattern.test(values.namerespon))
                          errors.namerespon = t("ot");

                        if (!values.namerespons)
                          errors.namerespons = t("fr");
                        else if (!pattern.test(values.namerespons))
                          errors.namerespons = t("ot");
                        if (!values.country)
                          errors.country = t("fr");
                        else
                         getDeparment(values.country)
                        return errors;

                      }}
                      validationSchema={Yup.object().shape({
                        identification: Yup.string()
                          .typeError(t("fin"))
                          .required(t("fr")),

                        description: Yup.string().required(t("fr")),
                        phone: Yup.number()
                          .typeError(t("fin"))
                          .required(t("fr")),
                        addres: Yup.string().required(t("fr")),
                        country: Yup.string().required(t("fr")),
                        departmen: Yup.string().required(t("fr")),
                        codepostal: Yup.string()
                          .typeError(t("fin"))
                          .required(t("fr")),

                        identificacionespons: Yup.number()
                          .typeError(t("fin"))
                          .required(t("fr")),

                        correorespons: Yup.string()
                          .required(t("fr"))
                          .email(t("ein")),

                        positionrespon:
                          Yup.string().required(t("fr")),

                        //  actividadempresa:
                          //Yup.string().required(t("fr")),

                      })}


                      onSubmit={(val, { resetForm }) => {
                        const data = {

                          name: val.name,
                          logo: val.logo,
                          document: pdfcoding.replace('data:application/pdf;base64,', ''),
                          nit:val.identification,
                          description: val.description, 
                          phone_number: val.phone,
                          address: val.addres,
                          country: val.country,
                          department: val.departmen,
                          postal_code: val.codepostal,
                          status: 1,
                          company_activities: 
                            renderListActividad()
                          ,
                          legal_representative: [
                            {
                              first_name: val.namerespon.trim(),
                              last_name:val.namerespons.trim(),
                              identification: val.identificacionespons.trim(),
                              email:val.correorespons.trim(), 
                              position: val.positionrespon
                            }
                          ]
                        };
                        registro(data,val.id);
                      }}

           

                      enableReinitialize
                      render={({ errors, status, touched }) => (

                        <Form class="container">
                          <div class="form-group mb-4">
                            <label for="identification" class="form-label">{t("lbl4")}</label>
                            <Field
                              id="identification"
                              name="identification"
                              type="text"
                              class="form-control imputText"
                              placeholder={t("lbl4")}
                            />

                            <ErrorMessage
                              name="identification"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <label for="name" class="form-label">{t("lbl5")}</label>
                            <Field
                              id="name"
                              name="name"
                              type="text"
                              class="form-control imputText"
                              placeholder={t("lbl5")}
                            />

                            <ErrorMessage
                              name="name"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <label for="description" class="form-label">{t("lbl6")}</label>
                            <Field
                              id="description"
                              name="description"
                              type="textarea"
                              class="form-control imputText textArea"
                              component="textarea"
                              placeholder={t("lbl6")}
                            />

                            <ErrorMessage
                              name="description"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <label for="phone" class="form-label">{t("lbl7")}</label>
                            <Field
                              id="phone"
                              name="phone"
                              type="number"
                              class="form-control imputText"
                              placeholder={t("lbl7")}
                            />

                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <label for="addres" class="form-label">{t("lbl8")}</label>
                            <Field
                              id="addres"
                              name="addres"
                              type="text"
                              class="form-control imputText"
                              placeholder={t("lbl8")}
                            />

                            <ErrorMessage
                              name="addres"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <label for="country" class="form-label">{t("lbl9")}</label>
                            <Field
                              as="select"
                              id="country"
                              name="country"
                              className="form-select fondoamarillo imputText"
                              aria-label="Default select example"
                            >
                              <option
                              
                                className="fondo fw-bolder"
                                value=""
                                selected
                              >
                                {t("lbl9")}
                              </option>

                              {pais.map((i) => {
                                return (
                                  <option
                                    className="fondo fw-bolder"
                                    value={i.id}
                                  >
                                    {i.name}
                                  </option>
                                );
                              })}
                            </Field>

                            <ErrorMessage
                              name="country"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <label for="departmen" class="form-label">{t("lbl10")}</label>
                            <Field as="select"
                              id="departmen"
                              name="departmen"
                              className="form-select fondoamarillo imputText"
                              aria-label="Default select example"
                            >
                              <option
                                className="fondo fw-bolder"
                                value=""
                                selected
                              >
                                {t("lbl10")}
                              </option>

                              {departamento.map((i) => {
                                return (
                                  <option
                                    className="fondo fw-bolder"
                                    value={i.id}
                                  >
                                    {i.name}
                                  </option>
                                );
                              })}
                            </Field>

                            <ErrorMessage
                              name="departmen"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <label for="codepostal" class="form-label">{t("lbl11")}</label>
                            <Field
                              id="codepostal"
                              type="text"
                              name="codepostal"
                              class="form-control imputText"
                              placeholder={t("lbl11")}
                            />

                            <ErrorMessage
                              name="codepostal"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-5">
                            <label for="actividadempresa" class="form-label">{t("lbl23")}</label>
                            <Select
                              id="actividadempresa"
                              name="actividadempresa"
                              placeholder={
                                <div className="fondoamarillo">
                                  {t("lbl23")}
                                </div>
                              }
                              options={renderList()}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={handleChange}
                              allowSelectAll={true}

                              value={optionSelected}

                            />

                                <ErrorMessage
                                  name="actividadempresa"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                          </div>


                          <div class="form-group mb-4">
                            <div class="color-fuente-gris textbold">
                              {" "}
                              {t("lbl12")}  &nbsp;
                            </div>
                            <input
                              id="file"
                              type="file"
                              class="form-control imputText field-input"
                              accept="image/png, image/jpeg,image/jpg "
                              title={t("lbl13")}
                              webkitRelativePath
                              directory multiple

                              onChange={fileChangedHandler}
                            />

                            <ErrorMessage
                              name="file"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>

                          <div class="form-group mb-4">
                            <div class="color-fuente-gris textbold">
                              {" "}
                              {t("lbl14")}  &nbsp;
                            </div>

                            <div class="alert alert-light" role="alert">
                              {t("lbl15")}
                            </div>

                            <input
                              id="filedoc"
                              type="file"
                              class="form-control imputText field-input"
                              accept="application/pdf"
                              title={t("lbl16")}
                              webkitRelativePath
                              directory multiple

                              onChange={filedocChangedHandler}
                            />

                            <ErrorMessage
                              name="filedoc"
                              component="div"
                              className="msgerror mx-2"
                            />
                          </div>


                          <div class="divider d-flex align-items-center my-2">
                            <div class="color-fuente-gris textbold">
                              {" "}
                              {t("lbl17")} &nbsp;
                            </div>
                          </div>

                          <div class="card">
                            <div class="card-body">
                              <div class="form-group mb-4">
                                <label for="namerespon" class="form-label">{t("lbl18")}</label>
                                <Field
                                  id="namerespon"
                                  type="text"
                                  name="namerespon"
                                  class="form-control imputText"
                                  placeholder={t("lbl18")}
                                />

                                <ErrorMessage
                                  name="namerespon"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="namerespons" class="form-label">{t("lbl19")}</label>
                                <Field
                                  id="namerespons"
                                  type="text"
                                  name="namerespons"
                                  class="form-control imputText"
                                  placeholder={t("lbl19")}
                                />

                                <ErrorMessage
                                  name="namerespons"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="identificacionespons" class="form-label">{t("lbl20")}</label>
                                <Field
                                  id="identificacionespons"
                                  type="text"
                                  name="identificacionespons"
                                  class="form-control imputText"
                                  placeholder={t("lbl20")}
                                />

                                <ErrorMessage
                                  name="identificacionespons"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="correorespons" class="form-label">{t("lbl21")}</label>
                                <Field
                                  id="correorespons"
                                  type="text"
                                  name="correorespons"
                                  class="form-control imputText"
                                  placeholder={t("lbl21")}
                                />

                                <ErrorMessage
                                  name="correorespons"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>

                              <div class="form-group mb-4">
                                <label for="positionrespon" class="form-label">{t("lbl22")}</label>
                                <Field
                                  as="select"
                                  id="positionrespon"
                                  name="positionrespon"
                                  className="form-select fondoamarillo imputText"
                                >
                                  <option
                                    className="fondo fw-bolder"
                                    value=""
                                    selected
                                  >
                                    {t("lbl22")}
                                  </option>

                                  {
                                  cargo.map((i) => {
                                    return (
                                      <option
                                        className="fondo fw-bolder"
                                        value={i.id}
                                      >
                                        {i.name}
                                      </option>
                                    );
                                  })
                                  }
                                </Field>

                                <ErrorMessage
                                  name="positionrespon"
                                  component="div"
                                  className="msgerror mx-2"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="divider d-flex align-items-center my-2">
                            <button
                              class="btn btn-warning  btn-block  buttons "
                              type="submit"
                            >
                              {t("lbl24")}
                            </button>
                          </div>
                        </Form>
                      )}
                    />
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* modal new activity */}
        <div class="modal fade" id="mdlformnewactivity" aria-labelledby="mdlformnewactivityLabel" aria-hidden="true">
          <div class="modal-dialog modal-xs">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="mdlformnewactivityLabel">{t("lbl20")}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <RegisterActivity addNewActivity={addNewActivity}/>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl21")}</button>
              </div>
            </div>
          </div>
        </div>
        {/* end modal new activity */}
            </>
        );
}

export default PerfilCompany
