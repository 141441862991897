import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Redirect } from 'react-router';

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Swal from "sweetalert2";

import "bootstrap/dist/css/bootstrap.min.css";

const OfertasJobs = (props) => {

  const [t, i18n] = useTranslation("jobofferh");
  // i18n.changeLanguage("")

  const [ chekDespliega, setchekDespliega ] = useState(false)
  const [ chekDespliegaCargo, setchekDespliegaCargo ] = useState(false)
  const [ estado, setestado ] = useState(false)
  
  const [ idoffert, setidoffert ] = useState(0)
  const [ oferts, setoferts ] = useState([])
  const [ country, setcountry ] = useState("")
  const [ titleoffer, settitleoffer ] = useState("")
  
  // //selects
  const [ countries, setcountries ] = useState([])
  const [ departments, setdepartments ] = useState([])
  const [ activities, setactivities ] = useState([])
  const [ positions, setpositions ] = useState([])
  const [ salaries, setsalaries ] = useState([])
  const [ schedules, setschedules ] = useState([])
  
  // // filtros aplicados
  const [ filcountry, setfilcountry ] = useState("")
  const [ fildepartment, setfildepartment ] = useState("")
  const [ filactivity, setfilactivity ] = useState("")
  const [ filposition, setfilposition ] = useState("")
  const [ filsalary, setfilsalary ] = useState("")
  const [ filschedule, setfilschedule ] = useState("")
  
  // // pagination
  const [ offset, setoffset ] = useState(0)
  const [ pageCount, setpageCount ] = useState(0)
  const [ perPage, setperPage ] = useState(0)
  const [ identificador, setidentificador ] = useState(0)
  const [ targetmodel, settargetmodel ] = useState(0)
  const [ pageNex, setpageNex ] = useState(0)
  const [ selectedPage, setselectedPage ] = useState(0)

    

  const getCountry = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`,
      {      
        // headers: {
        //   Authorization: `Bearer  ${UserProfile.getToken()}`,
        // }
      })
      .then((res) => {
        setcountries(res.data.data.country)
      });
  }

  const getDeparmentByCountry = ({id}) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`,
        {
          // headers: {
          //   Authorization: `Bearer  ${UserProfile.getToken()}`,
          // },
        })
      .then((res) => {
        setdepartments(res.data.data.department)
      });
  }

  
  const getActivity = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/activities`,
      {      
        // headers: {
        //   Authorization: `Bearer  ${UserProfile.getToken()}`,
        // }
      })
      .then((res) => {
        setactivities(res.data.data.activities)
      });
  }

  const getPositions = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`,
      {      
        // headers: {
        //   Authorization: `Bearer  ${UserProfile.getToken()}`,
        // }
      })
      .then((res) => {
        setpositions(res.data.data.positions)
      });
  }

  const getSalaries = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/salary_ranges`,
      {      
        // headers: {
        //   Authorization: `Bearer  ${UserProfile.getToken()}`,
        // }
      })
      .then((res) => {
        setsalaries(res.data.data.salary_ranges)
      });
  }

  const getSchedule = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/work_hours`,
      {      
        // headers: {
        //   Authorization: `Bearer  ${UserProfile.getToken()}`,
        // }
      })
      .then((res) => {
        setschedules(res.data.data.work_hours)
      });
  }
  
  const changeTitle = ({title, idoffer}) => {
      settitleoffer(title)
      setidoffert(idoffer)
  }

  const close = async (values) => {
    document.getElementById("clocedModal").click()
  }

  const saveApply = (values) => {
    var data = {
        "offer": idoffert,
        "reason": values.reason,
        "applicant": 1,
    }
    localStorage.setItem('applyoffer', JSON.stringify(data))
    props.history.push("/register")
  }

  const list = (search) => {
    setoferts([])
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/job_offers/search`,search, {
          // headers: {
          //     Authorization: `Bearer  ${UserProfile.getToken()}`,
          //   },
            params:{
              page: selectedPage
            }
        })
        .then((res) => {
            setoferts(res.data.data.job_offers )
            setpageCount(res.data.data.pages)
        })
        .catch((error) => console.log(error));
  }


  const changetextcollapse = (targt, txtone, txttwo) =>{
    targt.target.textContent = targt.target.ariaExpanded == "false" ? txtone : txttwo
  }

const resetList  = async () => {
    var search = {}
    setfilactivity(0)
    setfilposition(0)
    setfilcountry(0)
    setfildepartment(0)
    setfilschedule(0)
    setfilsalary(0)
    list(search)
}

const handleClickFilter = async () => {
    var search = {}
    if (filcountry > 0) search.country = filcountry 
    if (fildepartment > 0) search.department = fildepartment 
    if (filactivity > 0) search.activity = filactivity 
    if (filposition > 0) search.position = filposition 
    if (filsalary > 0) search.salary = filsalary 
    if (filschedule > 0) search.schedule = filschedule
    list(search)
}
//changes filters
useEffect(() => {
  handleClickFilter()
}, [selectedPage, filcountry, fildepartment, filactivity, filposition, filsalary, filschedule])

const handlePageClick = (val) => {
    setselectedPage( val.selected+1)
    setpageNex(val.selected)
}


useEffect(() => {
    getCountry()
    getActivity()
    getPositions()
    getSalaries()
    getSchedule()
    list();
}, [])


    let paginationElement
    
    if(pageCount > 1) {
        paginationElement = (
            <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Sig"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
            onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
            forcePage={pageNex}
            containerClassName={"pagination justify-content-center"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            />
        )
    }

    return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
            <div class="row  d-flex  h-100  filtrosfondoxz">
              <div class="col-12 col-sm-12 col-md-2 filtrosopcioes  ">
                <div class=" row filtrosfondo">
                  <div class="filtros mx-2 my-4">{t("ttl1")}</div>
                </div>

                <div class="row my-3">
                    <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#countr"}
                    aria-expanded="false"
                    aria-controls={"countr"}
                    >
                    {t("ttl2")}
                    </a>

                    <ul className="collapse mx-3" id={"countr"}>
                        <li class="mt-2">
                            <div class="form-group mb-4 col-md-11">
                                <select
                                    id="filcountry"
                                    name="filcountry"
                                    className="form-select fondoamarillo imputTextSelect"
                                    aria-label={t("lbl1")}
                                    onChange={async (e) => (setfilcountry(e.target.value), getDeparmentByCountry({id: e.target.value}))}
                                >
                                    <option className="fondo fw-bolder" value="" selected>
                                      {t("ttl2")}
                                    </option>

                                    {countries.map((i) => {
                                    return (
                                        <option className="fondo fw-bolder" value={i.id}>
                                            {i.name}
                                        </option>
                                    );
                                    })}
                                </select>
                            </div>
                            <div class="form-group mb-4 col-md-11">
                                <select
                                    id="fildepartment"
                                    name="fildepartment"
                                    className="form-select fondoamarillo imputTextSelect"
                                    aria-label={t("lbl1")}
                                    onChange={async (e) => (setfildepartment(e.target.value))}
                                >
                                    <option className="fondo fw-bolder" value="" selected>
                                        {t("ttl3")}
                                    </option>

                                    {departments.map((i) => {
                                    return (
                                        <option className="fondo fw-bolder" value={i.id}>
                                            {i.name}
                                        </option>
                                    );
                                    })}
                                </select>
                            </div>
                        </li>
                    </ul>
                    <hr />
                </div>

                {/* <div class="row my-3">
                    <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#posi"}
                    aria-expanded="false"
                    aria-controls={"posi"}
                    >
                     {t("ttl4")}
                    </a>

                    <ul className="collapse mx-3" id={"posi"}>
                        <li class="mt-2">
                            <div class="form-group mb-4 col-md-11">
                                <select
                                    id="filposition"
                                    name="filposition"
                                    className="form-select fondoamarillo imputTextSelect"
                                    aria-label={t("lbl1")}
                                    onChange={async (e) => (setfilposition(e.target.value))}
                                >
                                    <option className="fondo fw-bolder" value="" selected>
                                      {t("ttl4")}
                                    </option>

                                    {positions.map((i) => {
                                    return (
                                        <option className="fondo fw-bolder" value={i.id}>
                                            {i.name}
                                        </option>
                                    );
                                    })}
                                </select>
                            </div>
                        </li>
                    </ul>
                    <hr />
                </div> */}

                <div class="row my-3">
                    <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#activ"}
                    aria-expanded="false"
                    aria-controls={"activ"}
                    >
                    {t("ttl4")}
                    </a>

                    <ul className="collapse mx-3" id={"activ"}>
                        <li class="mt-2">
                            <div class="form-group mb-4 col-md-11">
                                <select
                                    id="activity"
                                    name="activity"
                                    className="form-select fondoamarillo imputTextSelect"
                                    aria-label={t("lbl1")}
                                    onChange={async (e) => (setfilactivity(e.target.value))}
                                >
                                    <option className="fondo fw-bolder" value="" selected>
                                      {t("ttl4")}
                                    </option>

                                    {activities.map((i) => {
                                    return (
                                        <option className="fondo fw-bolder" value={i.id}>
                                            {i.name}
                                        </option>
                                    );
                                    })}
                                </select>
                            </div>
                        </li>
                    </ul>
                    <hr />
                </div>

                <div class="row my-3">
                    <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#salar"}
                    aria-expanded="false"
                    aria-controls={"salar"}
                    >
                    {t("ttl6")}
                    </a>

                    <ul className="collapse mx-3" id={"salar"}>
                        <li class="mt-2">
                            <div class="form-group mb-4 col-md-11">
                                <select
                                    id="salary"
                                    name="salary"
                                    className="form-select fondoamarillo imputTextSelect"
                                    aria-label={t("lbl1")}
                                    onChange={async (e) => (setfilsalary(e.target.value))}
                                >
                                    <option className="fondo fw-bolder" value="" selected>
                                      {t("ttl6")}
                                    </option>

                                    {salaries.map((i) => {
                                    return (
                                        <option className="fondo fw-bolder" value={i.id}>
                                            {i.name}
                                        </option>
                                    );
                                    })}
                                </select>
                            </div>
                        </li>
                    </ul>
                    <hr />
                </div>

                {/* <div class="row my-3">
                    <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#sched"}
                    aria-expanded="false"
                    aria-controls={"sched"}
                    >
                      {t("ttl7")}
                    </a>

                    <ul className="collapse mx-3" id={"sched"}>
                        <li class="mt-2">
                            <div class="form-group mb-4 col-md-11">
                                <select
                                    id="schedule"
                                    name="schedule"
                                    className="form-select fondoamarillo imputTextSelect"
                                    aria-label={t("lbl1")}
                                    onChange={async (e) => (setfilschedule(e.target.value))}
                                >
                                    <option className="fondo fw-bolder" value="" selected>
                                      {t("ttl7")}
                                    </option>

                                    {schedules.map((i) => {
                                    return (
                                        <option className="fondo fw-bolder" value={i.id}>
                                            {i.name}
                                        </option>
                                    );
                                    })}
                                </select>
                            </div>
                        </li>
                    </ul>
                    <hr />
                </div> */}

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    onClick={() =>resetList()}
                  >
                    {t("ttl8")}
                  </a>
                  <hr />
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-10 center-block ">
                {/* tabla de bits */}
                <div className="my-5">
                  {
                  oferts.length > 0  ? 
                  oferts.map((i) => {
                      return (
                        <div className="container my-2" key={i.id}>
                          <div className="accordion-item card sombra my-2">
                            <div className="accordion-header" id="">
                              <div className="row  my-2 p-2">
                                <div className="col-md-2 col-sm-12 text-center"></div>

                                <div className="col-md-5 col-sm-12 text-center">
                                  <div class="row txtlargeAzul">
                                    {i.title}
                                    <br />
                                    <div class="row txtSmallGris">
                                        { i.description.substr(0, 50) } ...
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-1 col-sm-12 text-center border-end">
                                  <div className="row txtSmallGris ">
                                    {i.end_offer}
                                  </div>

                                  <div className="row txtSmallAzul text-center">
                                    {t("ttl10")}
                                  </div>
                                </div>

                                <div className="col-md-2 col-sm-12 text-center">
                                  <button
                                    className="btn btn-warning   buttonsMirarMAs"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={
                                      "#c" + i.id
                                    }
                                    aria-expanded="false"
                                    aria-controls={
                                      "#c" + i.id
                                    }
                                    onClick={(e)=>changetextcollapse(e, t("lbl9"), t("lbl20") )}
                                    // onClick={() => {
                                    //   setidoffert(i.id)
                                    // }}
                                    
                                  >
                                    {t("ttl11")}
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div
                              className="collapse"
                              id={"c" + i.id}
                            >
                              <div className="card card-body txtSmallerGris ">
{/*  cosas nuevas */}
                                <div className="row">
                                  <div className=" col-md-6 ">
                                    {/* <div class="txtSmallAzul">{t("ttl2")}: {i.postulates}</div> */}

                                    <div class="txtSmallAzul mt-1">{t("ttl4")}</div>
                                    {i.positions}

                                    <div class="txtSmallAzul mt-1">{t("ttl6")}</div>
                                    {i.salary}

                                    <div class="txtSmallAzul mt-1">{t("ttl22")}</div>
                                    {i.duration_time} - {i.unit_measurement.name}

                                    <div class="txtSmallAzul mt-1">{t("ttl3")}</div>
                                    {i.department}

                                    <div class="txtSmallAzul mt-1">{t("ttl14")}</div>
                                    {i.vacancy_numbers}

                                    <div class="txtSmallAzul mt-1">{t("ttl15")}</div>
                                    {i.description}
                                  </div>

                                  <div className=" col-md-6 ">
                                    <div class="txtSmallAzul">{t("ttl23")}</div>
                                    {i.languages.map((i) => {
                                      return <>{i.language.name + " - "}</>;
                                    })}

                                    <div class="txtSmallAzul mt-1">{t("ttl7")}</div>
                                    {i.schedule.name}

                                    <div class="txtSmallAzul mt-1">{t("ttl2")}</div>
                                    {i.country}

                                    <div class="txtSmallAzul mt-1">{t("ttl24")}</div>
                                    {i.postal_code}

                                    <div class="txtSmallAzul mt-1">{t("ttl16")}</div>
                                    {i.activities.map((i) => {
                                      return <>{i.activity.name + " - "}</>;
                                    })}
                                  </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <button
                                            className="btn btn-info buttonsMirarMAs"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#mdlapplyoffer"
                                            onClick={changeTitle.bind(this, {title:i.title, idoffer: i.id})}
                                        >
                                            {t("ttl12")}
                                        </button>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                  })
                : 
                <div class="alert alert-warning" role="alert">
                  {t("msg4")}
                </div>
                }
                </div>
                {paginationElement}
              </div>
            </div>
          </div>
        </div>
        
        {/* modal para aplicar a x oferta */}
        <div class="modal fade" id="mdlapplyoffer"  aria-labelledby="mdlapplyoffer" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="mdlapplyoffer">{t("ttl19")} {titleoffer}</h5>
                    <button id="clocedModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <Formik
                        initialValues={{
                            reason: ""
                        }}

                        validationSchema={Yup.object().shape({
                            reason: Yup.string().required(t("msg3"))
                        })}

                        onSubmit={(val, { resetForm }) => {
                            document.getElementById("clocedModal").click()
                            saveApply(val);
                        }}
                        >
                        {({handleSubmit}) => ( 
                        <Form class="container" onSubmit={handleSubmit}>
                            <div class="form-group mb-4">
                                <Field
                                    id="reason"
                                    name="reason"
                                    component="textarea"
                                    class="form-control imputText"
                                    placeholder={t("lbl2")}
                                />

                                <ErrorMessage
                                    name="reason"
                                    component="div"
                                    className="msgerror mx-2"
                                />
                            </div>

                            <div class="form-group align-items-center my-2">
                                <button
                                    type="submit"
                                    class="btn btn-warning  btn-block  buttons "
                                >
                                    {t("ttl20")}
                                </button>
                            </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("ttl21")}</button>
                </div>
                </div>
            </div>
        </div>
      </>
    );
}
export default OfertasJobs
