import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import UserProfile from "../Login/UserProfile";
import * as Yup from "yup";

import { Formik, Field, Form, ErrorMessage } from "formik";


const RegisterXpCompany = (props) => {

    const [t, i18n] = useTranslation("formcmnps")
    const [ loadd, setloadd ] = useState(0)

    const saveXpCompany = (values,resetForm) =>{
        setloadd(1)
        axios
        .post(
            `${process.env.REACT_APP_BASE_URL}/api/attrs/company_experience`, values,
            {      
            headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`, 
                }
            }
        )
        .then((res) => {
            setloadd(0)
            resetForm()
            props.addNewXpCompany(res.data.data)
        })
        .catch((error) => console.log(error));
    }


    return (
        <>
            <div>
                <Formik
                  initialValues={{
                    id: "",
                    name: "",
                    email: "",
                    phone_number: "",
                    status: "",
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(t("fr")),
                    email: Yup.string()
                      .email(t("er"))
                      .required(t("fr")),
                    phone_number: Yup.number()
                      .typeError(t("fn"))
                      .min(7)
                      .positive(t("er"))
                      .required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    val.status = 2
                    saveXpCompany(val, resetForm);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container mt-3">
                      <div class="form-group mb-4">
                        <label for="name" class="form-label">{t("lbl1")}</label>
                        <Field
                          id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl1")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <label for="email" class="form-label">{t("lbl7")}</label>
                        <Field
                          id="email"
                          type="text"
                          name="email"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl7")}
                        />

                        <ErrorMessage
                          name="email"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <label for="phone_number" class="form-label">{t("lbl6")}</label>
                        <Field
                          id="phone_number"
                          type="text"
                          name="phone_number"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl6")}
                        />

                        <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      {
                            loadd == 0 ?
                            <div class="divider d-flex align-items-center my-2">
                                <button
                                type="submit"
                                class="btn btn-warning  btn-block  buttons "
                                >
                                {t("lbl2")}
                                </button>
                            </div>
                            : 
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        }
                    </Form>
                  )}
                />
            </div>
        </>
    );
}

export default RegisterXpCompany;