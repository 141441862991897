import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";

import ReactPaginate from "react-paginate";
import Table from 'react-bootstrap/Table'

const NotificationList = (props) => {
    const [t, i18n] = useTranslation("notifi")

    // s
    const [ notifications, setnotifications ] = useState([])

    // pagination
    const [ pageCount, setpageCount ] = useState(0);
    const [ pageNex, setpageNex ] = useState(0);
    const [ selectedPage, setselectedPage ] = useState(1);

    const list = async () => {
      setnotifications([])
      let newsearch = {page: selectedPage}
      await axios
      .post(
          `${process.env.REACT_APP_BASE_URL}/api/allnotifications`, [], {      
            headers: {
              Authorization: `Bearer  ${localStorage.getItem('token')}`,
            },
            params: newsearch
        })
      .then((res) => {
          if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
          setnotifications(res.data.data.notifications)
          setpageCount(res.data.data.pages)
      })
      .catch((error) => console.log(error));
    }

    const handlePageClick = (val) => {
      setselectedPage(val.selected+1)
      setpageNex(val.selected)
    }

    const redirect = (data) => {
      let type = data.type
      var now = new Date();
      // let rol = localStorage.getItem('userrol')
      if (data.iddata > 0) {
          if (type == 'company' || type == 'commentscompany') {
              props.changeOpc({opc: "3", data: data.iddata, time: now.getMilliseconds()})
          }
          if (type == 'applicants' || type == 'commentsapplicants') {
              props.changeOpc({opc: "21", data: data.iddata})
          }
          if (type == 'job_offers' || type == 'commentsjob_offers') {
              props.changeOpc({opc: "9", data: data.iddata, time: now.getMilliseconds()})
          }
          if (type == 'applicant_pays') {
              props.changeOpc({opc: "4", data: data.iddata, type: "applicants", time: now.getMilliseconds()})
          }
          if (type == 'company_services') {
              props.changeOpc({opc: "4", data: data.iddata, type: "service", time: now.getMilliseconds()})
          }
      }
  }
    
    useEffect(() => {
      // handleClickFilter()
      list()
    },[ selectedPage ])

    useEffect(()=>{
      list()
    },[])

    let paginationElement
    if(pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Sig"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
          onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
          forcePage={pageNex}
          containerClassName={"pagination justify-content-center"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      ) 
    }

    return (
      <>
        <div class="container">
          <div class="row">
            <div class="col">
              <h2> {t("ttl1")} </h2>
            </div>

          </div>
          <div class="row ">
            <div class="col-md-12 align-self-center ">
              <div className="my-2 table-responsive">
                <Table striped bordered size="sm">
                  <thead>
                    <tr>
                      <th className="fondoi funeteblanca">{t("lbl1")} </th>
                      <th className="fondoi funeteblanca">{t("lbl2")} </th>
                      <th className="fondoi funeteblanca">{t("lbl3")} </th>
                      <th className="fondoi funeteblanca"> </th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    notifications.map((i) => {
                      return (
                        <>
                          <tr key={i.id}>
                            <td>{ i.name }</td>
                            <td>{ i.description }</td>
                            <td>{ i.created_at ? i.created_at.split("T")[0] : "" }</td>
                            <td class="text-center">
                              <a  aria-current="true" href="javascript:void(0)"
                                  onClick={()=>redirect({id: i.id, type: i.table, iddata: i.idvalue})}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z"/>
                                </svg>
                              </a>
                            </td>
                          </tr> 
                        </>
                      );
                    })
                  }
                  </tbody>
                </Table>
              </div>
            </div>
            <div>{paginationElement}</div>
          </div>
        </div>
      </>
    );
  
}

export default NotificationList;
