import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import axios from "axios";

import UserProfile from "../../Login/UserProfile";
import ReactPaginate from "react-paginate";
import Table from 'react-bootstrap/Table'

// import { Link } from "react-router-dom";

// import basura from "../../img/Basura.png";
// import editar from "../../img/edita.png";

// import listplas from "../../img/listplas.png";
// import imgmodal from "../../img/imgmodal.png";
// import send from "../../img/send.png";
// import user from "../../img/user.png";
// import userchat from "../../img/userchat.png";
// import list from "../../img/list.png";
// import { date } from "yup/lib/locale";
import ver from "../../img/ver.png";


const ApplicantAdmin = (props) => {

    const [t, i18n] = useTranslation("applsadmin")
    // i18n.changeLanguage("")

    const [ applicant, setapplicant ] = useState([]);
    const [ jobofert, setjobofert ] = useState([]);
    const [ datos, setdatos ] = useState([]);

    const [ country, setcountry ] = useState(0);
    const [ deparmet, setdeparmet ] = useState(0);
    const [ countryName, setcountryName ] = useState("");
    const [ deparmetName, setdeparmetName ] = useState("");
    
    const [ status, setstatus ] = useState(0);
    const [ user, setuser ] = useState(0);

    const [ countryfilter, setcountryfilter ] = useState([]);
    const [ departments, setdepartments ] = useState([]);
    const [ departmentsloc, setdepartmentsloc ] = useState([]);
    const [ documents, setdocuments ] = useState([]);
    const [ civilstatus, setcivilstatus ] = useState([]);
    const [ activities, setactivities ] = useState([]);
    
    const [ usuario, setusuario ] = useState([]);
    
    // filtros aplicados
    const [ filsearch, setfilsearch ] = useState("");
    const [ filemail, setfilemail ] = useState("");
    const [ filcountry, setfilcountry ] = useState("");
    const [ fildepartment, setfildepartment ] = useState("");
    const [ filcountryloc, setfilcountryloc ] = useState("");
    const [ fildepartmentloc, setfildepartmentloc ] = useState("");
    const [ fildoctype, setfildoctype ] = useState("");
    const [ filcivilstatus, setfilcivilstatus ] = useState("");
    const [ filactivity, setfilactivity ] = useState("");
    const [ filstatus, setfilstatus ] = useState("");

    // pagination
    const [ pageCount, setpageCount ] = useState(0);
    const [ pageNex, setpageNex ] = useState(0);
    const [ selectedPage, setselectedPage ] = useState(1);


  const getCountry = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`)
      .then((res) => {
        setcountryfilter(res.data.data.country)
      });
  }

  const getDeparmentByCountry = ({id}) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
      .then((res) => {
        setdepartments(res.data.data.department)
      });
  }

  const getDeparmentByCountryloc = ({id}) => {
    // axios
    //   .get(
    //     `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer  ${UserProfile.getToken()}`,
    //       },
    //     })
    //   .then((res) => {
    //     setState({departmentsloc: res.data.data.department} );
    //   });
  }

  const getActivity = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/activities`)
      .then((res) => {
        setactivities(res.data.data.activities)
      });
  };
  
  // const  getDocuments = async () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/identification_type`)
  //     .then((res) => {
  //       setdocuments(res.data.data.identification_type)
  //     });
  // }

  // const getCivilstatus = async () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/civil_status`)
  //     .then((res) => {
  //       setcivilstatus(res.data.data.civil_status)
  //     });
  // }
  

const resetList  = async () => {
  var search = {}
  setfilsearch("")
  setfilemail("")
  setfilcountry(0)
  setfildepartment(0)
  setfilcountryloc(0)
  setfildepartmentloc(0)
  setfildoctype(0)
  setfilcivilstatus(0)
  setfilstatus(0)
  setfilactivity(0)
  list(search)
}

const handleClickFilter = async () => {
  var search = {}
  if (filsearch.length > 0) search.search_1 = filsearch
  if (filemail.length > 0) search.email = filemail
  if (filcountry > 0) search.country = filcountry
  if (fildepartment > 0) search.department = fildepartment
  if (filcountryloc > 0) search.country_location = filcountryloc
  // if (fildepartmentloc > 0) search.activity = fildepartmentloc
  // if (fildoctype > 0) search.activity = fildoctype
  // if (filcivilstatus > 0) search.activity = filcivilstatus
  if (filactivity > 0) search.activity = filactivity
  if (filstatus > 0) search.status = filstatus
  list(search)
}

const handlePageClick = (val) => {
  setselectedPage(val.selected+1)
  setpageNex(val.selected)
}

useEffect(() => {
  handleClickFilter()
},[selectedPage, filsearch, filemail, filcountry, fildepartment, filcountryloc, fildepartmentloc, filstatus, filactivity])

  //get data X applicant
  const seeApplDetails = async ({id}) => {
    props.changeOpc({opc:"21", data:id})
  }

  const list = (search) => {
    setapplicant([])
    let newsearch = {...search, page: selectedPage}
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/applicants`, {
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      },
      params: newsearch
    })
    .then((res) => {
      if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setapplicant(res.data.data.applicants)
        setpageCount(res.data.data.pages)
      })
      .catch((error) => {
        console.log(error)
        props.history.push('/login'); return
      });
  }

  const handleSwitchChange = async ({id, status}) => {
    status = (status===1) ? 2 : 1
    axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/api/applicants/${id}`,
      {status:status},
      {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        }
      }
    )
    .then((res) => {
      if (res.data.status == true) {
        list();
      }
    })
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    if (navigator.onLine) {
      getCountry();
      // getCivilstatus()
      getActivity()
      list()
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])



    let itemx = 1
    let paginationElement
    if(pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Sig"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
          onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
          forcePage={pageNex}
          containerClassName={"pagination justify-content-center"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      ) 
    }
  

  return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
            <div class="row  d-flex  h-100  filtrosfondoxz">
              <div class="col-12 col-sm-12 col-md-2 filtrosopcioes  ">
                <div class=" row filtrosfondo">
                  <div class="filtros mx-2 my-4">{t("ttl1")}</div>
                </div>

                {/* <div class="row my-3">
                  <div class="form-group mb-4 col-md-12">
                    <input
                      id="email_applicant"
                      type="text"
                      name="email_applicant"
                      className="form-control imputTextSelect mx-1"
                      placeholder={t("lbl7")}
                      onChange={(e) => setfilemail(e.target.value)}
                    />
                  </div>
                </div> */}

                <div class="row my-3">
                  <div class="form-group mb-4 col-md-12">
                    <input
                      type="text"
                      className="form-control imputTextSelect mx-1"
                      placeholder={t("lbl7")}
                      onChange={(e) => setfilsearch(e.target.value)}
                    />
                  </div>
                  <hr />
                </div>
                

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#countr"}
                    aria-expanded="false"
                    aria-controls={"countr"}
                  >
                    {t("ttl2")}
                  </a>

                  <ul className="collapse mx-3" id={"countr"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="filcountry"
                            name="filcountry"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label={t("lbl2")}
                            onChange={(e) => (setfilcountry(e.target.value), getDeparmentByCountry({id: e.target.value}))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("ttl2")}
                          </option>

                          {countryfilter.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.name}
                              </option>
                          );
                          })}
                        </select>
                      </div>
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="fildepartment"
                            name="fildepartment"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label={t("lbl2")}
                            onChange={(e) => (setfildepartment(e.target.value))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                              {t("ttl3")}
                          </option>

                          {departments.map((i) => {
                            return (
                                <option className="fondo fw-bolder" value={i.id}>
                                    {i.name}
                                </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#countrloc"}
                    aria-expanded="false"
                    aria-controls={"countrloc"}
                  >
                    {t("ttl4")}
                  </a>

                  <ul className="collapse mx-3" id={"countrloc"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="filcountryloc"
                            name="filcountryloc"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label={t("lbl2")}
                            onChange={(e) => (setfilcountryloc(e.target.value), getDeparmentByCountryloc({id: e.target.value}))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("ttl4")}
                          </option>

                          {countryfilter.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.name}
                              </option>
                          );
                          })}
                        </select>
                      </div>
                      {/* <div class="form-group mb-4 col-md-11">
                        <select
                            id="fildepartmentloc"
                            name="fildepartmentloc"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label="Default select example"
                            onChange={async (e) => (await setState({fildepartmentloc: e.target.value}),handleClickFilter())}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                              Department Location
                          </option>

                          {departmentsloc.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.name}
                              </option>
                          );
                          })}
                        </select>
                      </div> */}
                    </li>
                  </ul>
                  <hr />
                </div>

                {/* <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#doctype"}
                    aria-expanded="false"
                    aria-controls={"doctype"}
                  >
                    {t("ttl5")}
                  </a>

                  <ul className="collapse mx-3" id={"doctype"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="fildoctyp"
                            name="fildoctyp"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label={t("lbl2")}
                            onChange={(e) => (setfildoctype( e.target.value) )}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("ttl5")}
                          </option>

                          {documents.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.description}
                              </option>
                          );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div> */}

                {/* <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#civilstatus"}
                    aria-expanded="false"
                    aria-controls={"civilstatus"}
                  >
                    {t("ttl6")}
                  </a>

                  <ul className="collapse mx-3" id={"civilstatus"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="civilstatuss"
                            name="civilstatuss"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label={t("lbl2")}
                            onChange={(e) => (setfilcivilstatus(e.target.value) )}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("ttl6")}
                          </option>

                          {civilstatus.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.description}
                              </option>
                          );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div> */}

                {/* <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#activities"}
                    aria-expanded="false"
                    aria-controls={"activities"}
                  >
                    {t("lbl6")}
                  </a>

                  <ul className="collapse mx-3" id={"activities"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="activiti"
                            name="activiti"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label={t("lbl6")}
                            onChange={(e) => (setfilactivity(e.target.value) )}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl6")}
                          </option>

                          {activities.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.name}
                              </option>
                          );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div> */}

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#estado"}
                    aria-expanded="false"
                    aria-controls={"estado"}
                  >
                    {t("ttl7")}
                  </a>

                  <ul className="collapse mx-3" id={"estado"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                          id="status"
                          name="status"
                          className="form-select fondoamarillo imputTextSelect"
                          aria-label={t("lbl2")}
                          onChange={(e) => (setfilstatus(e.target.value) )}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl3")}
                          </option>
                          <option className="fondo fw-bolder" value="1">
                            {t("lbl4")}
                          </option>
                          <option className="fondo fw-bolder" value="2">
                            {t("lbl5")}
                          </option>
                        </select>
                      </div>
                    </li>
                  </ul>

                  <hr />
                </div>
                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#reset"}
                    aria-expanded="false"
                    aria-controls={"reset"}
                    onClick={() => resetList()}
                  >
                    {t("ttl8")}
                  </a>

                  <hr />
                </div>
              </div>

{/*  inicio de tabla  */}
              <div class="col-12 col-sm-12 col-md-10 center-block ">
                <div className="my-2 table-responsive">
                  <Table striped bordered size="sm">
                    <thead>
                      <tr>
                        <th nowrap="" className="fondoi funeteblanca" ># </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl1")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl2")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl3")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl4")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl5")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl6")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl7")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl8")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl9")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl10")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl13")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl11")} </th>
                        <th nowrap="" className="fondoi funeteblanca" >{t("tbl12")} </th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      Object.keys(applicant).map((i) => {
                        let datet = applicant[i]['created_at'].split("T")[0]
                        return (
                          <>
                            <tr>
                              <td>{ applicant[i]['id'] }</td>
                              <td>{ applicant[i]['first_name'] }</td>
                              <td>{ applicant[i]['first_lastname'] }</td>
                              <td>{ applicant[i]['title'] }</td>
                              <td>{ applicant[i]['identification_type'] ? applicant[i]['identification_type']['description'] : ""}</td>
                              <td>{ applicant[i]['identification'] }</td>
                              <td>{ applicant[i]['email'] }</td>
                              <td>{ applicant[i]['country'] ? applicant[i]['country']['name'] : "" }</td>
                              <td>{ applicant[i]['department'] ? applicant[i]['department']['name'] : "" }</td>
                              <td>{ applicant[i]['country_nacionality'] ? applicant[i]['country_nacionality']['name'] : "" }</td>
                              <td>{ applicant[i]['civil_status'] ? applicant[i]['civil_status']['description'] : "" }</td>
                              <td>{datet}</td>
                              <td>
                                <div class="col-5 col-sm-5 col-md-3">
                                  <div class="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={applicant[i].id}
                                      onClick={() => {
                                        handleSwitchChange({
                                          id: applicant[i].id, status: applicant[i].status.id
                                        });
                                      }}
                                      checked={
                                        applicant[i].status.id == 1 ? true : false
                                      }
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="col-5 col-sm-5 col-md-3">
                                  {" "}
                                  <a
                                    href="javascript:void(0)"
                                    title="Ver Curriculum"
                                    onClick={() => {
                                      seeApplDetails({
                                        id: applicant[i].id
                                      });
                                    }}
                                  >
                                    {"   "}
                                    <img
                                      width="30"
                                      height="30"
                                      class=" mx-3"
                                      src={ver}
                                    />{" "}
                                  </a>
                                </div>
                              </td>
                            </tr> 
                          </>
                        );
                      })
                    }
                    </tbody>
                  </Table>
                </div>
                <div>{paginationElement}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default ApplicantAdmin;
