import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
// import axios from "axios";
// import withFirebaseAuth from "react-with-firebase-auth";
// import firebase from "firebase/app";
// import "firebase/auth";
// import firebaseConfig from "../Login/firebaseConfig";
// import Swal from "sweetalert2";
// import Header from "../Header/Header";
// import Footer from "../Footer/Footer";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";

import tuerca from "../img/tuerca.jpeg";

// import papel from "../img/papel.jpeg";
// import maleta from "../img/maleta.jpeg";
// import person from "../img/person.png";
import hojapng from "../img/hoja.png";
import hojablanco from "../img/hojablanco.png";
import maletin from "../img/maletin.png";
import personas from "../img/personas.png";
import Mesatrabajo2 from "../img/Mesatrabajo2.png";
// import man from "../img/man.png";
import photo4 from "../img/photo4.png";

import OfertasJobs from "./OfertasJobs"
import LoginSimple from "../Login/LoginSimple"
import ServicesPublic from "./ServicesPublic"
import SliderInfinite from "./SliderInfinite"

import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/homeCompany.css';

const HomeCompany = (props) => {

  const [t, i18n] = useTranslation("homecomp")
 // i18n.changeLanguage(localStorage.getItem('lngselect') || "en")

  const login = () => {
    props.history.push('/register')
    // props.changeOpc({opc:"21"})
  }

  // useEffect(() => {
    // axios
    //   .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`)
    //   .then((res) => {
    //     setregiones(res.data.data.country)
    //   })
    //   .catch((error) => console.log(error));
    // }, []);


    return (
      // <>
      //   {
      //   componente ? (
          <>
            <div class="item-group" >
              <div className="contenedorImgMovil  align-items-center">
                <div class="container-fluid ">
                  <div class="row align-items-center">
                    <div class="col-md-4 col-sm-12 col-lg-5 px-0 ">
                      <div class="backimgpublishoffer">
                        <div class="imagpublishoffer position-relative">
                          <div class="bg-img-mesatrabajo"></div>
                          {/* <img class="position-absolute top-0 end-0" src={Mesatrabajo2} alt="Responsive"/> */}
                        </div>
                      </div>
                    </div>
                    <div class="col text-center">
                      <h4 class="negr">{t("ttl1")}</h4>
                      <h4 class="negr">{t("ttl1.1")}</h4>
                      <h5 class="fontmont" >{t('txt1')}</h5>
                      <div class="row">
                        <div className="col">
                          <button
                            className="btn fondo buttonsMirarMAs"
                            type="button"
                            onClick={()=>(login())}
                          >
                            {t("lbl1")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="titulopading">
                  <div class="row  d-flex">
                    <div class="col-12 col-sm-12 col-md-7 center-block  ">
                      <div class="letreroWeb  fs-1 px-3 my-4  ">
                        <div class="textmov ">
                          {t("lifexpwork")}
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-5 center-block ">
                      <div class="letreroWeb  fs-1 px-2">
                        <img
                          src={tuerca}
                          class="img-fluid  imgmov"
                          alt="Responsive image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container titulopading">
                <div class="row d-flex my-5">
                  
                  <div class="col-12 col-sm-12 col-md-3 my-2 color">
                    <div class="row d-flex">
                      <div class="col-6 col-sm-6 col-md-3 mx-2">
                        <img src={hojapng}  height="80" class="img-flud" />
                      </div>

                      <div class="col-6 col-sm-6 col-md-6">
                        <div class="row textoImg  d-flex align-items-center justify-content-center h-100 ">
                          <div class="txtMediano">200</div>
                          <div class="txtPequeno">{t("vacnci")}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-3 my-2 color">
                    <div class="row d-flex">
                      <div class="col-6 col-sm-6 col-md-3 mx-2">
                        <img src={hojablanco}  height="80" class="img-flud" />
                      </div>

                      <div class="col-6 col-sm-6 col-md-6">
                        <div class="row textoImg  d-flex align-items-center justify-content-center h-100 ">
                          <div class="txtMediano">1.500</div>
                          <div class="txtPequeno">{t("busyjobs")}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-3 my-2 color">
                    <div class="row d-flex">
                      <div class="col-6 col-sm-6 col-md-3 mx-2">
                        <img src={maletin} width="80" height="80" class="img-flud" />
                      </div>

                      <div class="col-6 col-sm-6 col-md-6">
                        <div class="row textoImg  d-flex align-items-center justify-content-center h-100 ">
                          <div class="txtMediano">120</div>
                          <div class="txtPequeno">{t("companies")}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="negr">
                      <h2>{t("ttl2")} </h2>
                      <div class="orangeline"> <h2 class="">{t("ttl3")} </h2> </div>
                      <h2>{t("ttl4")} </h2>
                    </div>
                      <p class="margin-bottom fontmont">{t("ttl5")}</p>
                    <div class="row">
                      <div class="col mx-2">
                        <LoginSimple history={props.history} />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        {t("ttl6")}
                        {t("lbl2")}
                      </div>
                    </div>
                  </div>
                  <div class="col text-center">
                    {/* <img
                      src={man}
                      class="img-fluid person personImg"
                      alt="Responsive image"
                    /> */}
                    <img
                      src={photo4}
                      class="img-fluid person personImg rounded"
                      alt="Responsive image"
                    />
                  </div>
                </div>
              </div>

              <div class="container mt-5">
                <div class="row">
                  <div class="col text-center">
                    <h3 class="negr">{t("ttl7")}</h3>
                    <p class="fontmont">{t("ttl8")}</p>
                  </div>
                </div>
                <div class="row">
                  <ServicesPublic opc={props.opc} history={props.history} />
                </div>
                <div class="row">
                  <div class="col text-center">
                    <h3 class="negr">{t("ttl9")}</h3>
                    <p class="fontmont">{t("lbl3")}</p>
                  </div>
                </div>
              </div>

              <div class="container mb-3">
                <SliderInfinite />
              </div>
            </div>
          {/* </>
        ) : (
          <>
            <OfertasJobs idcontry={form.regiones}/>
          </>
        )} */}
      </>
    )
}

export default HomeCompany;
