import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";

import userchat from "../img/userchat.png";
// import listplas from "../img/listplas.png";
import cloudtext from "../img/cloudtext.png";
import sendimg from "../img/send.png";
import userimg from "../img/user.png";


const Comments = (props) => {
    const [t, i18n] = useTranslation("comments")

    const [ chekContras, setchekContras ] = useState(false);
    const [ idApplicant, setidApplicant ] = useState(0);
    const [ comments, setcomments ] = useState([]);

  
    const getApplicant = () => {
        axios
        .get(
            `${process.env.REACT_APP_BASE_URL}/api/applicants/profile`,
            {
                headers: {
                Authorization: `Bearer  ${localStorage.getItem('token')}`,
                },
            }
        )
        .then((res) => {
            if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
            setidApplicant(res.data.data.id)
        })
        .catch((error) => console.log(error));
    };

    const listChat = async () => {
        setcomments([])
        axios
        .get(
        `${process.env.REACT_APP_BASE_URL}/api/commentcv/`+idApplicant, {
            headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
            },
        }
        )
        .then((res) => {
            if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
            setcomments(res.data.data.comment_cv)
        });
    };

    const send = async () => {
        if (document.getElementById("mensaje").value != "") {
            var data = {
                comment: document.getElementById("mensaje").value.toString(),
                applicant: idApplicant,
            };
            axios
            .post(`${process.env.REACT_APP_BASE_URL}/api/comment_cv`, data, {
                headers: {
                Authorization: `Bearer  ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                if (res.data.status === true) {
                    listChat();
                }
            })
            .catch((error) => console.log(error));
            document.getElementById("mensaje").value = "";
        }
    };

    useEffect(()=>{
        getApplicant()
    },[])

    return (
      <>
        <div class="dropdownn floatcomments">
            <button
                class="btn btn-lightModal btnfloatcomments"
                type="button"
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={() => listChat()}
            >
                <span>
                    <img
                        className="img-fluid "
                        width="30px"
                        height="30px"
                        src={cloudtext}
                    />
                </span>
                {/* {t("lbl54")} */}
            </button>
            <div
                class="dropdown-menu fondoAzulModal"
                aria-labelledby="themes"
            >
                <div class="container">
                    <div class="form-group mb-1 text-center ">
                        <img
                        className=" mt-2 img-fluid"
                        width="20px"
                        height="20px"
                        src={userimg}
                        />
                    </div>
                    <div class="form-group mb-2 text-center ">
                        {t("lbl1")}
                    </div>
                </div>
                <div className="linea lineamovil"></div>
                <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                    <div className="anyClass anyClassMovil" id="topabajo">
                        {comments ? 
                        comments.map((i) => {
                            if (i.type != 2) {
                            return (
                                <>
                                <div class="form-group mb-2 text-left mt-2 ">
                                    <div class="row  my-1 ">
                                        <div class="col-2 col-sm-2 col-md-2 ">
                                            <img
                                            className=" mt-2 img-fluid ingMpvilchat"
                                            width="50px"
                                            height="50px"
                                            src={userchat}
                                            />
                                        </div>

                                        <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                                            <div className="txtSmallGris">
                                            {i.name}
                                            </div>

                                            <p className="text-left"> {i.comment} </p>
                                            
                                            <div class="txtSmallGris"> {i.created_at} </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                            );
                            } else {
                            return (
                                <>
                                <div class="form-group mb-2  mt-2 ">
                                    <div class="row  my-1   justify-content-end    ">
                                        <div class=" col-5 col-sm-5 col-md-5 text-end  mt-1 globo">
                                            <div className="txtSmallGris">
                                            {i.name}
                                            </div>
                                            <p className="">{i.comment}</p>
                                            <div class="txtSmallGris"> {i.created_at} </div>
                                        </div>
                                        <div class="col-2 col-sm-2 col-md-2 ">
                                            <img
                                            className=" mt-2 img-fluid ingMpvilchat"
                                            width="50px"
                                            height="50px"
                                            src={userchat}
                                            />
                                        </div>
                                    </div>
                                </div>
                                </>
                            );
                            }
                        }): "" 
                        } 
                    </div>
                </div>

                <div class="container">
                    <div class="row  my-1 ">
                        <div class="col-10 ">
                            <input
                                id="mensaje"
                                type="text"
                                name="mensaje"
                                className="mt-1 form-control imputTextModal"
                                aria-describedby="emailHelp"
                                placeholder={t("lbl2")}
                            />
                        </div>

                        <div class="col-2 text-left ">
                            <a
                                href="javascript:void(0)"
                                onClick={() => {
                                send();
                                }}
                            >
                                <img
                                className=" mt-2 img-fluid"
                                width="20px"
                                height="20px"
                                src={sendimg}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
  
}

export default Comments;
