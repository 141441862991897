import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import UserProfile from "../../Login/UserProfile";
import Swal from "sweetalert2";

import axios from "axios";

import basura from "../../img/Basura.png";
import editar from "../../img/edita.png";
// import campana from "../../img/campana.png";
import listplas from "../../img/listplas.png";
import progres from "../../img/progres.gif";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import ReactPaginate from "react-paginate";
import SocialNetworkList from "../components/SocialNetworkList"
import LanguagesList from "../components/LanguagesList"
import Genders from "../components/Genders"
import LanguageLevel from "../components/LanguageLevel"
import UnitMeasureList from "../components/UnitMeasureList"
import LevelStudyList from "../components/LevelStudyList"
import ActivitiesList from "../components/ActivitiesList"
import PositionsList from "../components/PositionsList"
import SalaryRangerList from "../components/SalaryRangerList"
import WorkHoursList from "../components/WorkHoursList"
import IdentificationTypeList from "../components/IdentificationTypeList"
import CivilStatusList from "../components/CivilStatusList"
// import CountryList from "../components/CountryList"
// import DeparmentList from "../components/DeparmentList"
// import MunicipalityList from "../components/MunicipalityList"
import EducationalInstitutionsList from "../components/EducationalInstitutionsList"
import CompanyExperienceList from "../components/CompanyExperienceList"
import ExperienceRequired from "../components/ExperienceRequired";
// import ExperienceRequired from "../components/ExperienceRequired"



const DatosAdmin = (props) => {

  const [t, i18n] = useTranslation("dataadmin")
  // i18n.changeLanguage("")

    const [ table, settable ] = useState(" ");
    const [ tableName, settableName ] = useState(" ");
    const [ atributivas, setatributivas ] = useState([]);
    const [ listTables, setlistTables ] = useState([]);
    const [ country, setcountry ] = useState([]);
    const [ Units, setUnits ] = useState([]);
    const [ department, setdepartment ] = useState([]);
    const [ estado, setestado ] = useState(true);
    const [ ban, setban ] = useState(false);
    const [ btn, setbtn ] = useState("");
    
    const [ idCountry, setidCountry ] = useState("");
    const [ setidContry, setsetidContry ] = useState("");
    const [ idDepartment, setidDepartment ] = useState("");
    const [ setidDeparmet, setsetidDeparmet ] = useState("");
    const [ idUnits, setidUnits ] = useState("");

    const [ time, settime ] = useState(false);

    const [ form, setform ] = useState([]);
    const [ status, setstatus ] = useState(0);
    const [ measurement, setmeasurement ] = useState(0);

  //     /*paginacion*/
  const [ pageNex, setpageNex ] = useState(1);
  const [ pageCount, setpageCount ] = useState(0);
  const [ numeropaginas, setnumeropaginas ] = useState(0);
  


const reset = async () => {
    document.getElementById("resetFor").click();
  };

const list = async () => {
    setatributivas([])
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/list_tables`,
      {      
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        }
      })
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setatributivas(res.data.data.tables)
      })
      .catch((error) => console.log(error));
  };

const handleSwitchChange = async (id, estatus, name) => {
    if (table != " ") {
      if (estatus == 1) {
        const values = {
          name: name,
          status: 2,
        };

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${id}`,
            values
          )
          .then((res) => {
            if (res.data.status == true) {
              listTablas(table);
            }
          })
          .catch((error) => console.log(error));
      } else {
        const values = {
          name: name,
          status: 1,
        };

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${id}`,
            values
          )
          .then((res) => {
            if (res.data.status == true) {
              listTablas(table);
            }
          })
          .catch((error) => console.log(error));
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  };

  const closedmodal = async (values) => {
    document.getElementById("clocedModal").click();
  };

  const getCountry = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, 
      {      
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        }
      })
      .then((res) => {
        setcountry(res.data.data.country)
      });
  };

  const getDeparment = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/department`,
      {      
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        }
      })
      .then((res) => {
        setdepartment(res.data.data.department)
      });
  };

  const getUnits = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/unit_measurement`,
      {      
        headers: {
           Authorization: `Bearer  ${UserProfile.getToken()}`,
        }
      })
      .then((res) => {
        setUnits(res.data.data.unit_measurement)
      });
  };

  const btnAdd = async () => {
    if (table != " ") {
      switch (table) {
        case "salary_ranges":
          document.getElementById("btnRangos").click();
          break;

        case "level_study":
          document.getElementById("btnAddNiveles").click();
          break;

        case "company_experience":
          document.getElementById("btnAddCompany").click();
          break;

        case "duration_time":
          getUnits();
          document.getElementById("btnAddTiempos").click();
          break;

        case "educational_institutions":
          getCountry();
          getDeparment();
          document.getElementById("btnAddInstitutions").click();
          break;

        // case "municipality":
        //   getCountry();
        //   getDeparment();
        //   document.getElementById("btnAddMunicipality").click();
        //   break;

        // case "department":
        //   getCountry();

        //   document.getElementById("btnAddDepartmen").click();
        //   break;

        // case "country":
        //   document.getElementById("btnAddDescription").click();
        //   break;

        case "identification_type":
          document.getElementById("btnAddDescription").click();
          break;

        case "experience_required":
          document.getElementById("btnxpreq").click();
          break;

        case "civil_status":
          document.getElementById("btncivilstatus").click();
          break;
          

        default:
          document.getElementById("btnAdd").click();
          break;
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg2"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  };

  const addForm = async (values) => {
    if (table != " ") {
      switch (table) {
        case "salary_ranges":
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,values,
              {      
                headers: {
                  Authorization: `Bearer  ${UserProfile.getToken()}`, 
                }
              }
            )
            .then((res) => {
              if (res.data.status == true) {
                listTablas(table);
              } else {
                console.log(res);
              }
            })
            .catch((error) => console.log(error));

          document.getElementById("btnRangos").click();
          break;


        case "level_study":
          const datalevel = {
            description: values.name,
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
              datalevel,
              {      
                headers: {
                  Authorization: `Bearer  ${UserProfile.getToken()}`, 
                }
          
              }
            )
            .then((res) => {
              if (res.data.status == true) {
                listTablas(table);
              } else {
                console.log(res);
              }
            })
            .catch((error) => console.log(error));

          document.getElementById("btnAddNiveles").click();

          break;

        case "company_experience":
          const dataCompany = {
            name: values.name,
            email: values.email,
            phone_number: values.phone_number,
            status: 1,
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
              dataCompany,
              {      
                headers: {
                  Authorization: `Bearer  ${UserProfile.getToken()}`, 
                }
              }
            )
            .then((res) => {
              if (res.data.status == true) {
                listTablas(table);
              } else {
                console.log(res);
              }
            })
            .catch((error) => console.log(error));

          document.getElementById("btnAddCompany").click();

          break;

        case "duration_time":
          const dataTime = {
            duration: values.duration,
            unit_measurement: values.unit_measurement,
            status: 1,
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
              dataTime,
              {      
                headers: {
                  Authorization: `Bearer  ${UserProfile.getToken()}`,
                }
              }
            )
            .then((res) => {
              if (res.data.status == true) {
                listTablas(table);
              } else {
                console.log(res);
              }
            })
            .catch((error) => console.log(error));

          document.getElementById("btnAddTiempos").click();

          break;

        case "level_study":
          const dataStudy = {
            name: values.name,
            status: 1,
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
              dataStudy,
              {      
                headers: {
                  Authorization: `Bearer  ${UserProfile.getToken()}`,
                }
          
              }
            )
            .then((res) => {
              if (res.data.status == true) {
                listTablas(table);
              } else {
                console.log(res);
              }
            })
            .catch((error) => console.log(error));

          document.getElementById("btnAdd").click();

          break;

        case "educational_institutions":
          const dataInstitutions = {
            name: values.name,
            country: values.country,
            department: values.departmen,
            number_phone: values.phone,
          };
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
              dataInstitutions,
              {      
                headers: {
                  Authorization: `Bearer  ${UserProfile.getToken()}`,
                }
          
              }
            )
            .then((res) => {
              if (res.data.status == true) {
                listTablas(table);
              } else {
                console.log(res);
              }
            })
            .catch((error) => console.log(error));

          document.getElementById("btnAddInstitutions").click();
          break;

        // case "municipality":
        //   const dataMunicipality = {
        //     code: values.code,
        //     name: values.name,
        //     department: values.departmen,
        //   };

        //   axios
        //     .post(
        //       `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
        //       dataMunicipality,
        //       {      
        //         headers: {
        //           Authorization: `Bearer  ${UserProfile.getToken()}`, 
        //         }
          
        //       }
        //     )
        //     .then((res) => {
        //       if (res.data.status == true) {
        //         listTablas(table);
        //       } else {
        //         console.log(res);
        //       }
        //     })
        //     .catch((error) => console.log(error));

        //   document.getElementById("btnAddMunicipality").click();

        //   break;

        // case "department":
        //   const dataDepartment = {
        //     name: values.name,
        //     country: values.country,
        //   };

        //   axios
        //     .post(
        //       `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
        //       dataDepartment,
        //       {      
        //         headers: {
        //           Authorization: `Bearer  ${UserProfile.getToken()}`, 
        //         }
          
        //       }
        //     )
        //     .then((res) => {
        //       if (res.data.status == true) {
        //         listTablas(table);
        //       } else {
        //         console.log(res);
        //       }
        //     })
        //     .catch((error) => console.log(error));

        //   document.getElementById("btnAddDepartmen").click();

        //   break;

        // case "country":
        //   const dataCountry = {
        //     code: values.code,
        //     name: values.name,
        //   };

        //   axios
        //     .post(
        //       `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
        //       dataCountry,
        //       {      
        //         headers: {
        //           Authorization: `Bearer  ${UserProfile.getToken()}`, 
        //         }
        //       }
        //     )
        //     .then((res) => {
        //       if (res.data.status == true) {
        //         listTablas(table);
        //       }
        //     })
        //     .catch((error) => console.log(error));

        //   document.getElementById("btnAddDescription").click();

        //   break;

        case "civil_status":
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
              values,
              {      
                headers: {
                  Authorization: `Bearer  ${UserProfile.getToken()}`, 
                }
              }
            )
            .then((res) => {
              if (res.data.status == true) {
                listTablas(table);
              }
            })
            .catch((error) => console.log(error));
          // document.getElementById("btnAdd").click();
          break;

        case "identification_type":
          const dataDescription = {
            description: values.name,
            code: values.code,
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
              dataDescription,
              {      
                headers: {
                  Authorization: `Bearer  ${UserProfile.getToken()}`, 
                }
              }
            )
            .then((res) => {
              if (res.data.status == true) {
                listTablas(table);
              }
            })
            .catch((error) => console.log(error));

          document.getElementById("btnAddDescription").click();

          break;

        default:
          const data = {
            name: values.name,
            status: 1,
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
              data,
              {      
                headers: {
                  Authorization: `Bearer  ${UserProfile.getToken()}`,
                }
          
              }
            )
            .then((res) => {
              if (res.data.status == true) {
                listTablas(table);
              }
            })
            .catch((error) => console.log(error));

          document.getElementById("btnAdd").click();
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg2"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  };

  const updateFormNivel = (value) => {
    setform([])
    setform(value)

    document.getElementById("updateModalNivel").click();
  };

  const updateFormNivelRegister = async (value) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        value
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        } else {
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalNivel").click();
  };


  const updateFormRangos = (value) => {
    setform([])
    setform(value)
    document.getElementById("updateModalRangosa").click();
  };


  const updateFormRangosRegister = async (value) => {
    const dataActividadesUpdate = {
      name: value.name,
      status: value.status,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        dataActividadesUpdate
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        } else {
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalRangosa").click();
  };



  const updateFormActividades = (value) => {
    setform([])
    setform(value)
    document.getElementById("updateModalActividades").click();
  };

  const  updateFormActividadesRegister = async (value) => {
    const dataActividadesUpdate = {
      name: value.name,
      status: value.status,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        dataActividadesUpdate
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        } else {
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalActividades").click();
  };

  const updateFormTrabajo = (value) => {
    setform([])
    setform(value)

    document.getElementById("updateModalTrabajo").click();
  };

  const updateFormTrabajoRegister = async (value) => {
    const dataActividadesUpdate = {
      name: value.name,
      status: value.status,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        dataActividadesUpdate
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        } else {
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalTrabajo").click();
  };

  const updateFormCompany = (value) => {
    setform([])
    setform(value)
    document.getElementById("updateModalCompany").click();
  };

  const updateFormCompanyRegister = async (value) => {
    const dataCompanyUpdate = {
      name: value.name,
      email: value.email,
      phone_number: value.phone_number,
      status: value.status,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        dataCompanyUpdate
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        } else {
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalCompany").click();
  };

  const  updateFormEstados = (value) => {
    setform([])
    setform(value)
    document.getElementById("updateModalEstados").click();
  };

  const updateFormDuration = (value, estado, measurement) => {
    getUnits();

    setform([])
    setform(value)
    setstatus(value)
    
    // setState({ ...measurement, measurement: measurement, });
    setmeasurement(measurement)

    document.getElementById("updateModalDuration").click();
  };

  const updateFormCountry = (value) => {
    setform([])
    setform(value)
    // setState({ form: [] });
    // setState({ ...form, form: value });
    document.getElementById("updateModalCountry").click();
  };

  const updateFormDescription = (value) => {
    setform([])
    setform(value)
    document.getElementById("updateModalDescription").click();
  };

  const updateForm = (value, estado) => {
    setform([])
    setstatus(0)

    setform(value)
    setstatus(estado)
    document.getElementById("updateModal").click();
  };

  const updateFormDeparment = (value, idCountryres) => {
    getCountry();
    setform([])
    setform(value)
    
    setidContry(0)
    setidCountry(idCountryres )
    document.getElementById("updateModalDeparment").click();
  };

  const updateFormMunicipality = (value, idDeparmetres) => {
    getDeparment();
    setform([])
    setidDeparmet(0)

    setform(value)
    setidDeparmet( idDeparmetres )
    document.getElementById("updateModalMunicipality").click();
  };

  const updateFormMunicipalityRegister = async (value) => {
    const dataMunicipalityUpdate = {
      code: value.code,
      name: value.name,
      department: value.departmen,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        dataMunicipalityUpdate
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        } else {
          console.log(res);
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalMunicipality").click();
  };

  const updateFormEducation = (value, idContryres, idDepartmentres) => {
    getCountry();
    getDeparment();

    setform([])
    setidContry(0)
    setidDeparmet(0)
    
    setform(value)
    setidContry({ idContry: idContryres })
    setidDeparmet({ idDepartment: idDepartmentres, })

    // setState({ form: [] });
    // setState({ idContry: 0 });
    // setState({ idDeparmet: 0 });
    // setState({ ...form, form: value });
    // setState({ ...idCountry, idCountry: idContryres });
    // setState({
    //   ...idDepartment,
    //   idDepartment: idDepartmentres,
    // });

    document.getElementById("updateModalEducation").click();
  };

  const updateFormEducationRegister = async (value) => {
    const dataMunicipalityUpdate = {
      name: value.name,
      country: value.country,
      department: value.departmen,
      number_phone: value.phone,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        dataMunicipalityUpdate
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalEducation").click();
  };

  const updateFormName = async (value) => {
    const values = {
      name: value.name,
      name_es: value.name_es,
      name_fr: value.name_fr,
      status: value.status,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        values
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        } else {
          console.log(res);
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModal").click();
  };

  const updateFormDescriptionRegister = async (value) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        value
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalDescription").click();
  };

  const updateFormDescriptionRegisterCountry = async (value) => {
    const countrydataUpdate = {
      code: value.code,
      name: value.name,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        countrydataUpdate
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        } else {
          console.log(res);
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalCountry").click();
  };

  const updateFormDurationRegister = async (value) => {
    const dataTiemposUpdate = {
      duration: value.duration,
      unit_measurement: value.measurement,
      status: value.status,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        dataTiemposUpdate
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalDuration").click();
  };

  const updateFormDurationEstados = async (value) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        value
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalEstados").click();
  };

  const updateFormDeparmentRegister = async (value) => {
    const dataDeparmetUpdate = {
      code: value.code,
      name: value.name,
      country: value.country,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${value.id}`,
        dataDeparmetUpdate
      )
      .then((res) => {
        if (res.data.status == true) {
          listTablas(table);
        }
      })
      .catch((error) => console.log(error));

    document.getElementById("updateModalDeparment").click();
  };

  const addItem = async (id) => {
    if (table != " ") {
      Swal.fire({
        title: t("msg3"),
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: t("ok"),
        showLoaderOnConfirm: true,
        cancelButtonText: t("cancel"),
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",

        preConfirm: (login) => {
          return fetch(`//api.github.com/users/${login}`)
            .then((response) => {
              if (!response.ok) {
                throw new Error(response.statusText);
              }
              return response.json();
            })
            .catch((error) => {
              Swal.showValidationMessage(t("fr"));
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          const values = {
            name: result.value.login,
            status: 1,
          };

          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
              values,
              {      
                headers: {
                  Authorization: `Bearer  ${UserProfile.getToken()}`,
                }
          
              }
            )
            .then((res) => {
              if (res.data.status == true) {
                listTablas(table);
              }
            })
            .catch((error) => console.log(error));
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  };

  // const time = async () => {
    // setState({ ...ban, ban: false });
    // setban({ ...ban, ban: false })
  // };

  const handlePageClick = (page) => {
     const selectedPage = page.selected;
    //  setState({ ...pageNex, pageNex: selectedPage+1 });
     setpageNex(selectedPage+1)
     listTablasBtnNex()
  };

  const listTablasBtnNex = async () => {
    // setState({ ...numeropaginas, numeropaginas: 0 });
    setnumeropaginas(0)
    if (time === false) {
      // settime({ ...time, time: true })
      settime(true)
      setlistTables([])

      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/${table}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`, 
          },
          params:{
              page: pageNex
          }
      }
        )
        .then((res) => {
          if (res.data.status != false) {
            setlistTables(res.data.data[table])
            setpageCount(res.data.data.pages)
          }
          settime(true)
        })
        .catch((error) => console.log(error));
    }
  };

  const listTablasBtn = async (tabla, title) => {
    if (time === false) {
      document.getElementById("btn" + tabla).className =
        " btn btn-naranja  buttonSmallNaranja";
      setbtn("btn" + tabla)

      if (btn != "") {
        document.getElementById(btn).className =
          "btn btn-light  buttonSmallAzul";
      }

      settime(true)
      settable(tabla)
      settableName(title)
    
      setlistTables([])
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/${tabla}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`, 
          },
          params:{
              page: pageNex
          }
      })
        .then((res) => {
          if (res.data.status != false) {
            setlistTables(res.data.data[tabla])
            setpageCount(res.data.pages)
          }
          settime(false)
        })
        .catch((error) => console.log(error));
    }
  };

  const listTablas = async (tabla) => {
    if (time === false) {
      settime(true)
      settable(tabla)
      setlistTables([])
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/${tabla}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`, 
          },
          params:{
              page: pageNex
          }
        })
        .then((res) => {
          if (res.data.status != false) {
            setlistTables(res.data.data[tabla])
          }
          settime(false)
        })
        .catch((error) => console.log(error));
    }
  };

  const deleter = async (id) => {
    Swal.fire({
      title: t("msg5"),
      text: t("msg4"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ok"),
      cancelButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/api/attrs/${table}/${id}`
          )
          .then((res) => {
            if (res.data.status == true) {
              listTablas(table);
            }else{
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res.data.error[0],
                confirmButtonColor: "#071b30",
                confirmButtonText: t("ok"),
              });
              // 
            }
          })
          .catch((error) => console.log(error));
      }
    });
  };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg6"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, []);

    return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
            <div className="container my-1">
              <div className="row  my-1 p-2">
                <div className="col-6 col-sm-6 col-md-6 text-center">
                  <div class="row txtlargeAzul">
                    <button
                      type="button"
                      class="btn btn-warning  btn-block  buttonLarge "
                      onClick={() => btnAdd()}
                    >
                      {" "}
                      <span>
                        <img
                          className="img-fluid"
                          width="15px"
                          height="15px"
                          src={listplas}
                        />{" "}
                      </span>{" "}
                      {t("ttl1")}
                    </button>

                    <button
                      id="btnAddDescription"
                      type="button"
                      class="btn btn-warning  btn-block  buttonLarge invisible "
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropDescription"
                    ></button>

                    <button
                      id="btnAdd"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    ></button>

                    {/* <button
                      id="btnAddDepartmen"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropDepartment"
                    ></button> */}

                    {/* <button
                      id="btnAddMunicipality"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropMunicipality"
                    ></button> */}

                    <button
                      id="btnAddInstitutions"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropInstitutions"
                    ></button>

                    <button
                      id="btnAddTiempos"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropTiempos"
                    ></button>

                    <button
                      id="btnAddCompany"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropCompany"
                    ></button>

                    <button
                      id="btnAddNiveles"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#addNiveles"
                    ></button>

                    <button
                      id="btnRangos"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticRangos"
                    ></button>

                    <button
                      id="btnxpreq"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropxpreq"
                    ></button>

                    <button
                      id="btncivilstatus"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropcivilstatus"
                    ></button>
                    



                    {/* modales */}

                    <button
                      id="updateModal"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdate"
                    ></button>

                    <button
                      id="updateModalDescription"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateDescription"
                    ></button>

                    <button
                      id="updateModalDuration"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateDuration"
                    ></button>

                    <button
                      id="updateModalEstados"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateEstados"
                    ></button>

                    <button
                      id="updateModalCountry"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateContry"
                    ></button>

                    <button
                      id="updateModalDeparment"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateDeparment"
                    ></button>

                    {/* <button
                      id="updateModalMunicipality"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateMunicipality"
                    ></button> */}

                    <button
                      id="updateModalEducation"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateeducation"
                    ></button>

                    <button
                      id="updateModalCompany"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateCompany"
                    ></button>

                    <button
                      id="updateModalNivel"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdropUpdateNivel"
                    ></button>

                    <button
                      id="updateModalActividades"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#updateModalActividadesmodal"
                    ></button>

                    <button
                      id="updateModalRangosa"
                      type="button"
                      class="ocultarBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#updateModalRangos"
                    ></button>
                  </div>
                </div>
              </div>
            </div>

            {/* tablas */}

            <div className="container">
              <div className="row  my-1 p-2">
                {/* {Object.keys(atributivas).map((i) => {
                  return (
                    <div className="col-4 col-sm-4 col-md-2 text-center">
                      <div class="row txtlargeAzul p-1">
                        <button
                          onClick={() =>
                            listTablasBtn( atributivas[i].name, atributivas[i].title )
                          }
                          id={"btn" + atributivas[i].name}
                          type="button"
                          class="btn btn-light buttonSmallAzul"
                        >
                          {atributivas[i].title}
                        </button>
                      </div>
                    </div>
                  );
                })} */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "social_network", t('tbl1') )
                      }
                      id={"btnsocial_network"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl1')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "languages", t('tbl4') )
                      }
                      id={"btnlanguages"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl4')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "genders", t('tbl25') )
                      }
                      id={"btngenders"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl25')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "language_level", t('tbl26') )
                      }
                      id={"btnlanguage_level"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl26')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "unit_measurement", t('tbl5') )
                      }
                      id={"btnunit_measurement"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl5')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "level_study", t('tbl6') )
                      }
                      id={"btnlevel_study"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl6')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "activities", t('tbl7') )
                      }
                      id={"btnactivities"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl7')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "positions", t('tbl8') )
                      }
                      id={"btnpositions"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl8')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "salary_ranges", t('tbl9') )
                      }
                      id={"btnsalary_ranges"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl9')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "work_hours", t('tbl10') )
                      }
                      id={"btnwork_hours"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl10')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "identification_type", t('tbl27') )
                      }
                      id={"btnidentification_type"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl27')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "civil_status", t('tbl14') )
                      }
                      id={"btncivil_status"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl14')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                {/* <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "country", t('tbl16') )
                      }
                      id={"btncountry"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl16')}
                    </button>
                  </div>
                </div> */}
                {/* --- */}
                {/* <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "department", t('tbl15') )
                      }
                      id={"btndepartment"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl15')}
                    </button>
                  </div>
                </div> */}
                {/* --- */}
                {/* <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "municipality", t('tbl17') )
                      }
                      id={"btnmunicipality"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl17')}
                    </button>
                  </div>
                </div> */}
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "educational_institutions", t('tbl28') )
                      }
                      id={"btneducational_institutions"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl28')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "company_experience", t('tbl21') )
                      }
                      id={"btncompany_experience"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl21')}
                    </button>
                  </div>
                </div>
                {/* --- */}
                <div className="col text-center">
                  <div class="txtlargeAzul p-1">
                    <button
                      onClick={() =>
                        listTablasBtn( "experience_required", t('tbl29') )
                      }
                      id={"btnexperience_required"}
                      type="button"
                      class="btn btn-light buttonSmallAzull"
                    >
                      {t('tbl29')}
                    </button>
                  </div>
                </div>
                {/* --- */}
              </div>
            </div>

            {/* tabla */}

            {/* inicio tabla */}

            <div class="container">
              {time && 
                <div className="container my-2">
                  <div className="text-center">
                    <img
                      src={
                        progres
                      }
                    />
                  </div>
                </div>
              }
              {
                table == "social_network" ? ( listTables.length > 0 ? (<SocialNetworkList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "languages" ? ( listTables.length > 0 ? (<LanguagesList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "genders" ? ( listTables.length > 0 ? (<Genders list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "language_level" ? ( listTables.length > 0 ? (<LanguageLevel list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "unit_measurement" ? ( listTables.length > 0 ? (<UnitMeasureList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "level_study" ? ( listTables.length > 0 ? (<LevelStudyList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "activities" ? ( listTables.length > 0 ? (<ActivitiesList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "positions" ? ( listTables.length > 0 ? (<PositionsList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "salary_ranges" ? ( listTables.length > 0 ? (<SalaryRangerList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "work_hours" ? ( listTables.length > 0 ? (<WorkHoursList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "identification_type" ? ( listTables.length > 0 ? (<IdentificationTypeList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateFormDescription} /> ) : "" ) : ""
              }
              {
                table == "civil_status" ? ( listTables.length > 0 ? (<CivilStatusList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {/* {
                table == "country" ? ( listTables.length > 0 ? (<CountryList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "department" ? ( listTables.length > 0 ? (<DeparmentList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "municipality" ? ( listTables.length > 0 ? (<MunicipalityList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              } */}
              {
                table == "educational_institutions" ? ( listTables.length > 0 ? (<EducationalInstitutionsList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "company_experience" ? ( listTables.length > 0 ? (<CompanyExperienceList list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              {
                table == "experience_required" ? ( listTables.length > 0 ? (<ExperienceRequired list={listTables} handleSwitchChange={handleSwitchChange} deleter={deleter} updateForm={updateForm} /> ) : "" ) : ""
              }
              
            </div>

            {/* fin tabla */}

            {  
               pageCount > 1 ?
               <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Sig"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
                onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
                //forcePage={pageNex}
                containerClassName={"pagination justify-content-center"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />:<></>
            }
          </div>
        </div>

        {/* modal add  */}

        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>

                <button
                  // id="clocedModal"
                  type="reset"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    name: "",
                    status: 1,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    addForm(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal add civil status */}
        <div
          class="modal fade"
          id="staticBackdropcivilstatus"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropcivilstatusLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropcivilstatusLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>

                <button
                  type="reset"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    description: "",
                    description_es: "",
                    description_fr: "",
                    status: 1,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.description) errors.description = t("fr");
                    else if (!pattern.test(values.description))
                      errors.description = t("ot");

                    return errors;
                  }}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    console.log(val);
                    addForm(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="description"
                          type="text"
                          name="description"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3")}
                        />

                        <ErrorMessage
                          name="description"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="description_es"
                          type="text"
                          name="description_es"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("es")}
                        />

                        <ErrorMessage
                          name="description_es"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="description_fr"
                          type="text"
                          name="description_fr"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("frc")}
                        />

                        <ErrorMessage
                          name="description_fr"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>


        {/* modal add experience required */}

        <div
          class="modal fade"
          id="staticBackdropxpreq"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropxpreqLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropxpreqLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>

                <button
                  // id="clocedModal"
                  type="reset"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    name: "",
                    name_es: "",
                    name_fr: "",
                    status: 1,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                      if (!values.name_es) errors.name_es = t("fr");
                    else if (!pattern.test(values.name_es))
                      errors.name_es = t("ot");

                      if (!values.name_fr) errors.name_fr = t("fr");
                    else if (!pattern.test(values.name_fr))
                      errors.name_fr = t("ot");

                    return errors;
                  }}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    addForm(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name_es"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("es")}
                        />

                        <ErrorMessage
                          name="name_es"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name_fr"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("frc")}
                        />

                        <ErrorMessage
                          name="name_fr"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>





  {/* modal add rangos  */}

        <div
          class="modal fade"
          id="staticRangos"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")}  {tableName}
                </h5>

                <button
                  // id="clocedModal"
                  type="reset"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    name: "",
                    status: 1,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    
                    

                    return errors;
                  }}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    addForm(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl6")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>




        {/* modal add niveles  */}

        <div
          class="modal fade"
          id="addNiveles"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>

                <button
                  // id="clocedModal"
                  type="reset"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    name: "",
                    name_es: "",
                    name_fr: "",
                    status: 1,
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(t("fr")),
                    name_es: Yup.string().required(t("fr")),
                    name_fr: Yup.string().required(t("fr")),
                    
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    addForm(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name_es"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("es")}
                        />

                        <ErrorMessage
                          name="name_es"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name_fr"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("frc")}
                        />

                        <ErrorMessage
                          name="name_fr"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal addPais  */}

        {/* <div
          class="modal fade"
          id="staticBackdropDescription"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")}  {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    name: "",
             
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    addForm(val);
                  }}
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                     

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                        {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* modal addDepartment*/}

        {/* <div
          class="modal fade"
          id="staticBackdropDepartment"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    name: "",

                    country: "",
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                    country: Yup.string().required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    addForm(val);
                  }}
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="country"
                          name="country"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl8")}
                          </option>

                          {country.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="country"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div> */}



        {/* modal addMunicipality*/}

        {/* <div
          class="modal fade"
          id="staticBackdropMunicipality"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    name: "",
                    departmen: "",
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                   
                    departmen: Yup.string().required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    addForm(val);
                  }}
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                   

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl9")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="departmen"
                          name="departmen"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl10")}
                          </option>

                          {department.map((i) => {
                            return (
                              <option
                                className="fondo fw-bolder"
                                value={i.id}
                              >
                                {i.name}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="departmen"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* modal addInstitutions*/}

        <div
          class="modal fade"
          id="staticBackdropInstitutions"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    name: "",
                    country: "",
                    departmen: "",
                    phone: "",
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                    country: Yup.string().required(t("fr")),
                    departmen: Yup.string().required(t("fr")),
                    phone: Yup.number()
                      .typeError(t("fn"))
                      .required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    addForm(val);
                  }}
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl11")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="country"
                          name="country"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl8")}
                          </option>

                          {country.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="country"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="departmen"
                          name="departmen"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl10")}
                          </option>

                          {department.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="departmen"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="phone"
                          type="text"
                          name="phone"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl12")}
                        />

                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal addTiempos*/}

        <div
          class="modal fade"
          id="staticBackdropTiempos"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>

              <div class="modal-body">
                <Formik
                  initialValues={{
                    duration: "",
                    unit_measurement: "",
                  }}
                  validationSchema={Yup.object().shape({
                    duration: Yup.number()
                      .typeError(t("fn"))
                      .required(t("fr")),
                    unit_measurement: Yup.string().required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    addForm(val);
                  }}
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          id="duration"
                          type="text"
                          name="duration"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl13")}
                        />

                        <ErrorMessage
                          name="duration"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="unit_measurement"
                          name="unit_measurement"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl14")}
                          </option>

                          {Units.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="unit_measurement"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal Company*/}

        <div
          class="modal fade"
          id="staticBackdropCompany"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>

              <div class="modal-body">
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    phone_number: "",
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email(t("er"))
                      .required(t("fr")),
                    phone_number: Yup.number()
                      .typeError(t("fn"))
                      .min(1)
                      .positive(t("er"))
                      .required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    addForm(val);
                  }}
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl15")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="email"
                          type="text"
                          name="email"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl16")}
                        />

                        <ErrorMessage
                          name="email"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="phone_number"
                          type="text"
                          name="phone_number"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl17")}
                        />

                        <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal update  */}
        <div
          class="modal fade"
          id="staticBackdropUpdate"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal"
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    name: form.name,
                    name_es: form.name_es,
                    name_fr: form.name_fr,
                    status: status,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");
                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    // else if (!pattern.test(values.name))
                    //   errors.name = t("ot");

                    return errors;
                  }}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    updateFormName(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl18")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name_es"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl18") + " " + t("es")}
                        />

                        <ErrorMessage
                          name="name_es"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name_fr"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl18") + " " + t("frc")}
                        />

                        <ErrorMessage
                          name="name_fr"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal update decription */}
        <div
          class="modal fade"
          id="staticBackdropUpdateDescription"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal"
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    code: form.code,
                    description: form.description,
                    description_es: form.description_es,
                    description_fr: form.description_fr,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.description)
                      errors.description = t("fr");
                    else if (!pattern.test(values.description))
                      errors.description = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                    code: Yup.number()
                      .typeError(t("fn"))
                      .required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    updateFormDescriptionRegister(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          id="code"
                          type="text"
                          name="code"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl19")}
                        />

                        <ErrorMessage
                          name="code"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="description"
                          type="text"
                          name="description"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl18")}
                        />

                        <ErrorMessage
                          name="description"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="description_es"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("es")}
                        />

                        <ErrorMessage
                          name="description_es"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="description_fr"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("frc")}
                        />

                        <ErrorMessage
                          name="description_fr"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal update duration*/}

        <div
          class="modal fade"
          id="staticBackdropUpdateDuration"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal"
                  id="staticBackdropLabel"
                >
                  {t("ttl5")}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    duration: form.duration,
                    status: status,
                    measurement: measurement,
                  }}
                  validationSchema={Yup.object().shape({
                    duration: Yup.number()
                      .typeError(t("fn"))
                      .required(t("fr")),
                    unit_measurement: Yup.string().required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();

                    updateFormDurationRegister(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          id="duration"
                          type="text"
                          name="duration"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl13")}
                        />

                        <ErrorMessage
                          name="duration"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="measurement"
                          name="measurement"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" selected>
                            {t("lbl20")}
                          </option>

                          {Units.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="departmen"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                        {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal update estado civil*/}

        <div
          class="modal fade"
          id="staticBackdropUpdateEstados"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal"
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    description: form.description,
                    description_es: form.description_es,
                    description_fr: form.description_fr,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.description)
                      errors.description = t("fr");
                    else if (!pattern.test(values.description))
                      errors.description = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                    id: Yup.string().required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    updateFormDurationEstados(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          id="description"
                          type="text"
                          name="description"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl18")}
                        />

                        <ErrorMessage
                          name="description"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="description_es"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("es")}
                        />

                        <ErrorMessage
                          name="description_es"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="description_fr"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("frc")}
                        />

                        <ErrorMessage
                          name="description_fr"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal update pais */}

        {/* <div
          class="modal fade"
          id="staticBackdropUpdateContry"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal"
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                   
                    name: form.name,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                   
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    updateFormDescriptionRegisterCountry(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl21")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* modal updateDepartment*/}

        {/* <div
          class="modal fade"
          id="staticBackdropUpdateDeparment"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    name: form.name,
                    country: idCountry,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                   
                    country: Yup.string().required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    updateFormDeparmentRegister(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
            
                   

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="country"
                          name="country"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl8")}
                          </option>

                          {country.map((i) => {
                            return (
                              <option
                                className="fondo fw-bolder"
                                value={i.id}
                              >
                                {i.name}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="country"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* modal addMunicipality*/}

        {/* <div
          class="modal fade"
          id="staticBackdropUpdateMunicipality"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    name: form.name,
                    departmen: idDepartment,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                   
                    departmen: Yup.string().required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();

                    updateFormMunicipalityRegister(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl9")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="departmen"
                          name="departmen"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("bl10")}
                          </option>

                          {department.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="departmen"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* modal updateInstitutions*/}

        <div
          class="modal fade"
          id="staticBackdropUpdateeducation"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    name: form.name,
                    country: idCountry,
                    departmen: idDepartment,
                    phone: form.number_phone,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                    country: Yup.string().required(t("fr")),
                    departmen: Yup.string().required(t("fr")),
                    phone: Yup.number()
                      .typeError(t("fn"))
                      .required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    updateFormEducationRegister(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl11")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="country"
                          name="country"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl8")}
                          </option>

                          {country.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="country"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          as="select"
                          id="departmen"
                          name="departmen"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl7")}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("bl10")}
                          </option>

                          {department.map((i) => {
                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name="departmen"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="phone"
                          type="text"
                          name="phone"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl12")}
                        />

                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal update Company*/}

        <div
          class="modal fade"
          id="staticBackdropUpdateCompany"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>

              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    name: form.name,
                    email: form.email,
                    phone_number: form.phone_number,
                    status: form.status,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(t("fr")),
                    email: Yup.string()
                      .email(t("er"))
                      .required(t("fr")),
                    phone_number: Yup.number()
                      .typeError(t("fn"))
                      .min(1)
                      .positive(t("er"))
                      .required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    updateFormCompanyRegister(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl15")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="email"
                          type="text"
                          name="email"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl16")}
                        />

                        <ErrorMessage
                          name="email"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          id="phone_number"
                          type="text"
                          name="phone_number"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl17")}
                        />

                        <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal update Nivel  */}

        <div
          class="modal fade"
          id="staticBackdropUpdateNivel"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    description: form.description,
                    description_es: form.description_es,
                    description_fr: form.description_fr,
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(t("fr")),
                  })}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    updateFormNivelRegister(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          id="description"
                          type="text"
                          name="description"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3")}
                        />

                        <ErrorMessage
                          name="description"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="description_es"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("es")}
                        />

                        <ErrorMessage
                          name="description_es"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="description_fr"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("frc")}
                        />

                        <ErrorMessage
                          name="description_fr"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {/* modal update Nivel  */}

        <div
          class="modal fade"
          id="updateModalActividadesmodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    name: form.name,
                    name_es: form.name_es,
                    name_fr: form.name_fr,
                    status: form.status,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
                    else if (!pattern.test(values.name))
                      errors.name = t("ot");

                    return errors;
                  }}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    updateFormActividadesRegister(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name_es"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("es")}
                        />

                        <ErrorMessage
                          name="name_es"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name_fr"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3") + " " + t("frc")}
                        />

                        <ErrorMessage
                          name="name_fr"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>
                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>


          {/* modal update Rangos  */}

          <div
          class="modal fade"
          id="updateModalRangos"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5
                  class="modal-title txtSmallerGrisModal "
                  id="staticBackdropLabel"
                >
                  {t("ttl4")} {tableName}
                </h5>
                <button
                  // id="clocedModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => reset()}
                ></button>
              </div>
              <div class="modal-body">
                <Formik
                  initialValues={{
                    id: form.id,
                    name: form.name,
                    status: form.status,
                  }}
                  validate={(values) => {
                    const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");

                    const errors = {};

                    if (!values.name) errors.name = t("fr");
     

                    return errors;
                  }}
                  onSubmit={(val, { resetForm }) => {
                    resetForm();
                    closedmodal();
                    updateFormRangosRegister(val);
                  }}
                  enableReinitialize
                  render={({ errors, status, touched }) => (
                    <Form class="container">
                      <div class="form-group mb-4">
                        <Field
                          // id="name"
                          type="text"
                          name="name"
                          className="form-control imputText"
                          aria-describedby="emailHelp"
                          placeholder={t("lbl3")}
                        />

                        <ErrorMessage
                          name="name"
                          component="div"
                          className="msgerror mx-2"
                        />
                      </div>

                      <div class="divider d-flex align-items-center my-2">
                        <button
                          type="submit"
                          class="btn btn-warning  btn-block  buttons "
                        >
                          {t("lbl4")}
                        </button>
                      </div>
                      <div class="divider d-flex align-items-center my-2">
                        <button id="resetFor" type="reset" class="ocultarBtn  ">
                          {t("lbl5")}
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

export default DatosAdmin;
