import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import UserProfile from "../../Login/UserProfile";
import axios from "axios";
import Swal from "sweetalert2";

import Table from 'react-bootstrap/Table'

import ReactPaginate from "react-paginate";
import ver from "../../img/ver.png";
import listimg from "../../img/list.png";
import dollaricon from "../../img/dllar.png";



const CandidatosCompany = (props) => {

const [t, i18n] = useTranslation("applscomp")
// i18n.changeLanguage("")

    const [ prueba, setprueba ] = useState(0)
    const [ applicants, setApplicants ] = useState()
    const [ oitem, setoitem ] = useState()
    const [ targetmodel, settargetmodel ] = useState()
    const [ identificador, setidentificador ] = useState()
    // const [ pageNex, setpageNex ] = useState()
    // const [ pageEmpresa, setpageEmpresa ] = useState(1);
    
    
    const [ purchases, setpurchases ] = useState([]);
    
    //buy process
    const [ services, setlstServices ] = useState([]);
    const [ serviceselect, setserviceselect ] = useState([]);
    const [ applicantselect, setapplicantselect ] = useState(0);
    const [ amounttopay, setamounttopay ] = useState(0);
    
    

    //selects
    const [ countryfilter, setcountryfilter ] = useState([]);
    const [ departments, setdepartments ] = useState([]);
    const [ cargo, setcargo ] = useState([]);
    const [ typeidentification, settypeidentification ] = useState([]);
    const [ country_location, setcountry_location ] = useState([]);
    const [ civilstatus, setcivilstatus ] = useState([]);
    const [ level_study, setlevel_study ] = useState([]);
    const [ languages, setlanguages ] = useState([]);

    //filtros
    const [ filname, setfilname ] = useState("");
    const [ filcountry, setfilcountry ] = useState()
    const [ fildepartment, setfildepartment ] = useState()
    const [ filcountrynac, setfilcountrynac ] = useState()
    // const [ fildepartmentloc, setfildepartmentloc ] = useState()
    const [ fildoctype, setfildoctype ] = useState()
    const [ filcivilstatus, setfilcivilstatus ] = useState()
    // const [ filstatus, setfilstatus ] = useState()

    // paginacion
    const [ selectedPage, setselectedPage ] = useState(0);
    const [ pageCount, setpageCount ] = useState(0);
    const [ pageNex, setpageNex ] = useState()
    const [ offset, setoffset ] = useState(0);
    const [ currentPage, setcurrentPage ] = useState(0);
    const [ perPage, setperPage ] = useState(10);


  const resetList  = async () => {
    var search = {}
    setfilname("")
    setfilcountry(0)
    setfildepartment(0)
    setfilcountrynac(0)
    // setfildepartmentloc(0)
    setfildoctype(0)
    setfilcivilstatus(0)
    // setfilstatus(0)
    list(search)
  }

  const handleClickFilter = async () => {
    var search = {}
    if (filname.length > 0) search.search_1 = filname
    if (filcountry > 0) search.country = filcountry
    if (fildepartment > 0) search.department = fildepartment
    // if (filcity > 0) search.city = filcity
    if (filcountrynac > 0) search.country_nacionality = filcountrynac
    if (fildoctype > 0) search.typedocument = fildoctype
    if (filcivilstatus > 0) search.civilstatus = filcivilstatus
    // if (filstatus > 0) search.status = filstatus
    list(search)
  }

  const handlePageClick = (val) => {
      setselectedPage(val.selected+1)
      setpageNex(val.selected)
  }

 useEffect(() => {
  handleClickFilter()
}, [filname, filcountry, fildepartment, filcountrynac, fildoctype, filcivilstatus])

     
      const getDeparmentByCountry = ({id}) => {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`)
          .then((res) => {
            setdepartments(res.data.data.department)
          });
      }

    const getCountry = async () => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`)
          .then((res) => {
            setcountryfilter(res.data.data.country );
          });
      }

      const getCountryNacionality = async () => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`,
          {      
            headers: {
              Authorization: `Bearer  ${localStorage.getItem('token')}`,
            }
          })
          .then((res) => {
            setcountry_location(res.data.data.country );
          });
      }

      
      const getsetcivil_status = async () => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/civil_status`,
          {      
            headers: {
              Authorization: `Bearer  ${localStorage.getItem('token')}`,
            }
          })
          .then((res) => {
            setcivilstatus(res.data.data.civil_status );
          });
      }

      const getIdentificationType = async () => {
        await axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/identification_type`)
          .then((res) => {
            settypeidentification(res.data.data.identification_type);
          });
      };
    
    
      const getLanguages = async () => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/languages`,
          {      
            headers: {
              Authorization: `Bearer  ${localStorage.getItem('token')}`,
            }
          })
          .then((res) => {
            setlanguages(res.data.data.languages );
          });
      }

      
      const getlevel_study = async () => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/level_study`,
          {      
            headers: {
              Authorization: `Bearer  ${localStorage.getItem('token')}`,
            }
          })
          .then((res) => {
            setlevel_study(res.data.data.level_study );
          });
      }
    
      const getCargo = async () => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, 
          {      
            headers: {
              Authorization: `Bearer  ${localStorage.getItem('token')}`,
            }
          })
          .then((res) => {
            setcargo(res.data.data.positions );
          });
      }

    const seeApplDetails = ({id}) =>{
      props.changeOpc({opc:"21", data:id})
    }
    
    const listPurchases = (idApp) => {
      setpurchases([])
      axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/company_services/my_services`, {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        },
        params: {
          applicant: idApp
        }
      })
        .then((res) => {
          if (!res.data.status) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: t("msg1"),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            return
          }
          setpurchases(res.data.data.company_services)
        })
        .catch((error) => console.log(error));
    }

    const sumpay = ({service, price}) => {
      var delet = serviceselect.findIndex(item => item.service == service)

      var temp = serviceselect
      if (delet >= 0) {
        temp.splice(delet, 1)
      }else{
        temp.push({
          "applicant" : applicantselect,
          "service" : service,
          "price" : price
        })
      }
      var sum = 0
      temp.map((i)=>{
        sum = parseInt(sum) + parseInt(i.price)
      })
      setserviceselect(temp)
      setamounttopay(sum)
    }

    const buyservices = async () => {
      var data = {service_select:serviceselect}
  
      axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/company_services`, data, {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => {
          console.log(res);
          if (!res.data.status) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: t("msg1"),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            return
          }
          window.open(res.data.data.paypal_url)
        })
        .catch((error) => console.log(error));
    }

    
    const listServices = async () => {
      setlstServices([])
      setserviceselect([])
      setamounttopay(0)
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/services`,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
          params:{
            page: 1
          }
        })
        .then((res) => {
          setlstServices(res.data.data.services)
        })
        .catch((error) => console.log(error));
    };

    const list = (search) => {
        setApplicants([])
        let newsearch = {...search, page: selectedPage}
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/my_applicant_pays`, {
            headers: {
              Authorization: `Bearer  ${localStorage.getItem('token')}`,
            },
            params: newsearch
          })
          .then((res) => {
            if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
            setApplicants(res.data.data.applicant_pays)
            setoitem(0)
            setpageCount(res.data.data.pages)
          })
          .catch((error) => console.log(error));
    }

      useEffect(() => {
        UserProfile.setToken(localStorage.getItem('token'));
        getCountry()
        getCountryNacionality()
        getsetcivil_status()
        getIdentificationType()
        getLanguages()
        getlevel_study()
        getCargo()
        list()
        setserviceselect([])
        setamounttopay(0)

      }, [])

        let paginationElement
        {
            if(pageCount > 1) {
                paginationElement = (
                    <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Sig"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
                    onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
                    forcePage={pageNex}
                    containerClassName={"pagination justify-content-center"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    />
                ) 
            }
        }

      return (
      <>
        <div class="item-group" xyz="fade">
          <div class="square xyz-in">
            <div class="row  d-flex  h-100  filtrosfondoxz">
              <div class="col-12 col-sm-12 col-md-2 filtrosopcioes  ">
                <div class=" row filtrosfondo">
                    <div class="filtros mx-2 my-4">{t("lbl1")}</div>
                </div>

                <div class="row my-3">
                  <div class="form-group mb-4 col-md-12">
                    <input
                      id="name_applicant"
                      type="text"
                      name="name_applicant"
                      className="form-control imputTextSelect mx-1"
                      aria-describedby="emailHelp"
                      placeholder="Applicant"
                      value={filname}
                      onChange={(e) => setfilname(e.target.value)}
                    />
                  </div>

                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#countr"}
                    aria-expanded="false"
                    aria-controls={"countr"}
                  >
                    {t("lbl2")}
                  </a>

                  <ul className="collapse mx-3" id={"countr"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="filcountry"
                            name="filcountry"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label="Default select example"
                            onChange={async (e) => (await setfilcountry(e.target.value), getDeparmentByCountry({id: e.target.value}))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                              {t("lbl2")}
                          </option>

                          {countryfilter.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.name}
                              </option>
                          );
                          })}
                        </select>
                      </div>
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="fildepartment"
                            name="fildepartment"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label="Default select example"
                            onChange={async (e) => (setfildepartment(e.target.value))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                              {t("lbl3")}
                          </option>

                          {departments.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.name}
                              </option>
                          );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#countrloc"}
                    aria-expanded="false"
                    aria-controls={"countrloc"}
                  >
                    {t("lbl4")}
                  </a>

                  <ul className="collapse mx-3" id={"countrloc"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="filcountrynac"
                            name="filcountrynac"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label="Default select example"
                            onChange={async (e) => (setfilcountrynac(e.target.value))}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                              {t("lbl4")}
                          </option>

                          {countryfilter.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.name}
                              </option>
                          );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#doctype"}
                    aria-expanded="false"
                    aria-controls={"doctype"}
                  >
                    {t("lbl5")}
                  </a>

                  <ul className="collapse mx-3" id={"doctype"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="fildoctyp"
                            name="fildoctyp"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label="Default select example"
                            onChange={async (e) => (setfildoctype(e.target.value) )}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl5")}
                          </option>

                          {typeidentification.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.description}
                              </option>
                          );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#civilstatus"}
                    aria-expanded="false"
                    aria-controls={"civilstatus"}
                  >
                    {t("lbl6")}
                  </a>

                  <ul className="collapse mx-3" id={"civilstatus"}>
                    <li class="mt-2">
                      <div class="form-group mb-4 col-md-11">
                        <select
                            id="civilstatuss"
                            name="civilstatuss"
                            className="form-select fondoamarillo imputTextSelect"
                            aria-label="Default select example"
                            onChange={async (e) => (await setfilcivilstatus(e.target.value) )}
                        >
                          <option className="fondo fw-bolder" value="" selected>
                            {t("lbl6")}
                          </option>

                          {civilstatus.map((i) => {
                          return (
                              <option className="fondo fw-bolder" value={i.id}>
                                  {i.description}
                              </option>
                          );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>

                <div class="row my-3">
                  <a
                    href="#"
                    className="mx-2  items-center txtlargeAzul"
                    data-bs-toggle="collapse"
                    data-bs-target={"#reset"}
                    aria-expanded="false"
                    aria-controls={"reset"}
                    onClick={() => resetList()}
                  >
                    {t("lbl11")}
                  </a>

                  <hr />
                </div>
              </div>
            {/* END FILTER */}
          
            <div class="col-12 col-sm-12 col-md-10 center-block ">
              <div className="my-2 table-responsive">
                <Table striped bordered size="sm">
                  <thead>
                    <tr>
                      {/* <th className="fondoi funeteblanca"># </th> */}
                      <th className="fondoi funeteblanca">{t("tbl1")} </th>
                      <th className="fondoi funeteblanca">{t("tbl2")} </th>
                      <th className="fondoi funeteblanca">{t("tbl3")} </th>
                      <th className="fondoi funeteblanca">{t("tbl4")} </th>
                      <th className="fondoi funeteblanca">{t("tbl5")} </th>
                      <th className="fondoi funeteblanca">{t("tbl6")}</th>
                      <th className="fondoi funeteblanca">{t("tbl7")}</th>
                      <th className="fondoi funeteblanca">{t("tbl8")}</th>
                      <th className="fondoi funeteblanca">{t("tbl9")}</th>
                      <th className="fondoi funeteblanca">{t("tbl10")}</th>
                      {/* <th className="fondoi funeteblanca">STATUS</th> */}
                      <th className="fondoi funeteblanca">{t("tbl11")}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    applicants ? 
                      applicants.map((i) => {
                        return (
                          <>
                            <tr>
                              {/* <td>{i.applicant.id}</td> */}
                              <td>{i.applicant.first_name}</td>
                              <td>{i.applicant.first_lastname}</td>
                              <td>{i.applicant.title}</td>
                              <td>{i.applicant.identification_type ? 
                                      props.lng == 'en' ?
                                        i.applicant.identification_type.description :
                                      props.lng == 'es' ?
                                        i.applicant.identification_type.description_es :
                                      props.lng == 'fr' ?
                                        i.applicant.identification_type.description_fr : ""
                                        : 
                                      ""}</td>
                              <td>{i.applicant.identification}</td>
                              <td>{i.applicant.email}</td>
                              <td>{i.applicant.country.name}</td>
                              <td>{i.applicant.department.name}</td>
                              <td>{i.applicant.country_location ? i.applicant.country_location.name : ""}</td>
                              <td>{
                              props.lng == 'en' ?
                                i.applicant.civil_status.description :
                              props.lng == 'es' ?
                                i.applicant.civil_status.description_es :
                              props.lng == 'fr' ?
                                i.applicant.civil_status.description_fr : ""
                              }</td>
                              <td>
                                <div class="row">
                                  <div class="col">
                                    {" "}
                                    <a
                                      href="javascript:void(0)"
                                      title={t("lbl12")}
                                      onClick={() => {
                                        seeApplDetails({
                                          id: i.applicant.id
                                        });
                                      }}
                                    >
                                      {"   "}
                                      <img
                                        width="30"
                                        height="30"
                                        class=" mx-3"
                                        src={ver}
                                      />{" "}
                                    </a>
                                  </div>

                                  <div class="col">
                                    {" "}
                                    <a
                                      href="javascript:void(0)"
                                      title={t("lbl13")}
                                      data-bs-toggle="modal"
                                      data-bs-target="#mdlservices"
                                      onClick={() => {setapplicantselect(i.applicant.id); listServices()}}
                                    >
                                      {"   "}
                                      <img
                                        width="25"
                                        height="25"
                                        class=" mx-3"
                                        src={dollaricon}
                                      />{" "}
                                    </a>
                                  </div>

                                  <div class="col">
                                    {" "}
                                    <a
                                      href="javascript:void(0)"
                                      title={t("lbl14")}
                                      data-bs-toggle="modal"
                                      data-bs-target="#mdlservicesbuys"
                                      onClick={() => { setapplicantselect(i.applicant.id); listPurchases(i.applicant.id)}}
                                    >
                                      {"   "}
                                      <img
                                        width="25"
                                        height="25"
                                        class=" mx-3"
                                        src={listimg}
                                      />{" "}
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr> 
                          </>
                        );
                      })
                    : ""
                  }
                  </tbody>
                </Table>
              </div>
              <div>{paginationElement}</div>
            </div>
          </div>
        </div>
      </div>    

        {/* list modal for services to buy */}
        <div class="modal fade" id="mdlservices" tabindex="-1" aria-labelledby="mdlservicesLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <div class="col">
                    <h5 class="modal-title" id="mdlservicesLabel">{t("ttl1")} {amounttopay}</h5>
                </div>
                <div class="col">
                    <button
                      type="button"
                      class={amounttopay <= 0 ? "btn btn-warning  btn-block  buttons mb-3 d-none" : "btn btn-warning  btn-block  buttons mb-3"}
                      onClick={buyservices.bind()}
                    >
                      {t("lbl15")}
                    </button>
                  </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
              <div className="my-2 table-responsive">
                <Table striped bordered size="sm">
                  <thead>
                    <tr>
                      <th className="fondoi funeteblanca">{t("tbl12")}</th>
                      <th className="fondoi funeteblanca">{t("tbl13")} </th>
                      <th className="fondoi funeteblanca">{t("tbl14")} </th>
                      <th className="fondoi funeteblanca">{t("tbl15")}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    services ? 
                      services.map((i) => {
                        if (i.visible == 1 && i.id != 1 && i.id != 2) {
                          return (
                            <>
                              <tr>
                                <td>
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" 
                                      onClick={sumpay.bind(this, {
                                                                  service: i.id, 
                                                                  price: i.price
                                                                })}
                                    />
                                  </div>
                                </td>
                                <td>{i.name}</td>
                                <td>{i.description}</td>
                                <td>{i.price}</td>
                              </tr> 
                            </>
                          );
                        }
                      })
                    : ""
                  }
                  </tbody>
                </Table>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl16")}</button>
              </div>
            </div>
          </div>
        </div>


        {/* modal list for purchases x applicant */}
        <div class="modal fade" id="mdlservicesbuys" tabindex="-1" aria-labelledby="mdlservicesbuysLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <div class="col">
                    <h5 class="modal-title" id="mdlservicesbuysLabel">{t("ttl2")}</h5>
                </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
              <div className="my-2 table-responsive">
                <Table striped bordered size="sm">
                  <thead>
                    <tr>
                      <th className="fondoi funeteblanca">{t("tbl13")} </th>
                      <th className="fondoi funeteblanca">{t("tbl14")} </th>
                      <th className="fondoi funeteblanca">{t("tbl15")}</th>
                      <th className="fondoi funeteblanca">{t("tbl16")}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    purchases ? 
                      purchases.map((i) => {
                        console.log(i.service);
                        return (
                          <>
                            <tr>
                              <td>{i.service.name}</td>
                              <td>{i.service.description}</td>
                              <td>{i.service.price}</td>
                              <td>{i.status ? i.status.name : ""}</td>
                            </tr> 
                          </>
                        );
                      })
                    : "..."
                  }
                  </tbody>
                </Table>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{t("lbl16")}</button>
              </div>
            </div>
          </div>
        </div>
    </> 
    );
}


export default CandidatosCompany
