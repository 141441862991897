import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import axios from "axios";

import basura from "../../img/Basura.png";
import editar from "../../img/edita.png";
import listplas from "../../img/listplas.png";

import imgmodal from "../../img/imgmodal.png";
import sendimg from "../../img/send.png";
import userimg from "../../img/user.png";
import ver from "../../img/ver.png";
import userchat from "../../img/userchat.png";
import listicon from "../../img/list.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const EmpresaApplicant = (props) => {

    const [t, i18n] = useTranslation("companiesapplicant")
  
    const [ loading, setloading ] = useState(0);
    const [ companies, setcompanies ] = useState([]);
    const [ representantes, setrepresentantes ] = useState([]);
    
    const [ comentarios, setcomentarios ] = useState([]);

    const [ datos, setdatos ] = useState([]);
    const [ countryName, setcountryName ] = useState("");
    const [ deparmetName, setdeparmetName ] = useState("");
    const [ estadoName, setestadoName ] = useState("");
    
    const [ idEmpresa, setidEmpresa ] = useState("");
    
    const [ countries, setcountries ] = useState([]);
    const [ departments, setdepartments ] = useState([]);
    const [ activities, setactivities ] = useState([]);

    const [ messagechat, setmessagechat ] = useState("");
  
    // filtros aplicados
    const [ filname, setfilname ] = useState("");
    const [ filnit, setfilnit ] = useState("");
    const [ filcountry, setfilcountry ] = useState("");
    const [ fildepartment, setfildepartment ] = useState("");
    const [ filactivity, setfilactivity ] = useState("");
    const [ filstatus, setfilstatus ] = useState("");
  
    //     /*paginacion*/
    const [ pageCount, setpageCount ] = useState(0);
    const [ selectedPage, setselectedPage ] = useState(1);
    const [ pageNex, setpageNex ] = useState(0);
  
    
    const listChat = async () => {
      setcomentarios([])
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/commentcompanyapplicant/${idEmpresa}`,
          {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          }
        )
        .then((res) => {
          setcomentarios(res.data.data.comment_company_applicant)
        });
    }
  
    const send = async () => {
      if (messagechat != "") {
        var data = {
          comment: messagechat,
          to: idEmpresa,
        };
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/api/comment_company_applicant`, data, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            if (res.data.status === true) {
              listChat();
            }
          })
          .catch((error) => console.log(error));
          setmessagechat("")
      }
    }
  
    const getCountry = async () => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/attrs/country`,
          {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          }
        )
        .then((res) => {
          setcountries(res.data.data.country)
        });
    };
  
    const getActivity = async () => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/attrs/activities`)
        .then((res) => {
          setactivities(res.data.data.activities)
        });
    };
  
  
    const openModal = async (value, pais, departamento, estado, empresa) => {
      setdatos([])
      setrepresentantes([])
  
      setcountryName("")
      setdeparmetName("")
      setestadoName("")
  
      setdatos(value)
      setrepresentantes(value.legal_representative)
      setcountryName(pais)
      setidEmpresa(empresa)
      setdeparmetName(departamento)
      setestadoName(estado)
    };
  
    const getDeparmentByCountry = ({id}) => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`)
        .then((res) => {
          setdepartments(res.data.data.department)
        });
    }
  
    const list = async (search) => {
      setcompanies([])
      setloading(1)
      let newsearch = {...search, page: selectedPage}
      if (props.dataxtr > 0) {
        newsearch = {id: props.dataxtr}
      }
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/mycompanies`,
          {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
            params: newsearch
          }
        )
        .then((res) => {
          if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
          setcompanies(res.data.data.companies )
          setpageCount(res.data.data.pages)
          setloading(0)
        })
        .catch((error) => {
          console.log(error)
          setloading(0)
        });
    };
  
    const resetList  = async () => {
      var search = {}
      setfilname("")
      setfilnit("")
      setfilcountry(0)
      setfildepartment(0)
      setfilactivity(0)
      setfilstatus(0)
      list(search)
  }
  
  const handleClickFilter = async () => {
      var search = {}
      if (filname.length > 0) search.name = filname 
      if (filnit.length > 0) search.nit = filnit
      if (filcountry > 0) search.country = filcountry 
      if (fildepartment > 0) search.department = fildepartment 
      if (filactivity > 0) search.company_activities_activity = filactivity
      if (filstatus > 0) search.status = filstatus
      list(search)
  }
  
  const handlePageClick = async (val) => {
      setselectedPage(val.selected+1)
      setpageNex(val.selected)
  }
  
  useEffect(() => {
    handleClickFilter()
  },[filname, filnit, filcountry, fildepartment, filactivity, filstatus, selectedPage])
  
    useEffect(() => {
      if (navigator.onLine) {
        UserProfile.setToken(localStorage.getItem('token'));
        list();
        getCountry();
        getActivity()
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("msg1"),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      }
    }, [])
  
    useEffect(() => {
      list();
    }, [props.timeseg])
  
  
      let paginationElement;
      if (pageCount > 1) {
        paginationElement = (
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Sig"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
            onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
            forcePage={pageNex}
            containerClassName={"pagination justify-content-center"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        );
      }
  
      return (
        <>
          <div class="item-group" xyz="fade">
            <div class="square xyz-in">
              <div class="row  d-flex  h-100  filtrosfondoxz">
                <div class="col-12 col-sm-12 col-md-2 filtrosopcioes  ">
                  <div class="row filtrosfondo">
                    <div class="filtros mx-2 my-4">{t("ttl1")}</div>
                  </div>
  
                  <div class="row my-3">
                    <div class="form-group mb-4 mx-1 col-md-12">
                    <label htmlFor="namecomp">{t("ttl2")}</label>
                      <input
                        id="namecomp"
                        type="text"
                        name="namecomp"
                        className="form-control imputTextSelect mx-1"
                        aria-describedby="emailHelp"
                        placeholder={t("ttl2")}
                        onChange={(e) => setfilname(e.target.value)}
                      />
                    </div>
  
                    <div class="form-group mb-4 mx-1 col-md-12">
                      <label htmlFor="nitcompany">{t("ttl3")}</label>
                      <input
                        id="nitcompany"
                        type="text"
                        name="nitcompany"
                        className="form-control imputTextSelect mx-1"
                        aria-describedby="emailHelp"
                        placeholder={t("ttl3")}
                        onChange={(e) => setfilnit(e.target.value)}
                      />
                    </div>
  
                    <hr />
                  </div>
  
                  <div class="row my-3">
                          <a
                          href="#"
                          className="mx-2  items-center txtlargeAzul"
                          data-bs-toggle="collapse"
                          data-bs-target={"#countr"}
                          aria-expanded="false"
                          aria-controls={"countr"}
                          >
                            {t("ttl4")}
                          </a>
  
                          <ul className="collapse mx-3" id={"countr"}>
                              <li class="mt-2">
                                  <div class="form-group mb-4 col-md-11">
                                      <select
                                          id="filcountry"
                                          name="filcountry"
                                          className="form-select fondoamarillo imputTextSelect"
                                          aria-label={t("ttl4")}
                                          onChange={(e) => (setfilcountry(e.target.value), getDeparmentByCountry({id: e.target.value}))}
                                      >
                                          <option className="fondo fw-bolder" value="" selected>
                                          {t("ttl4")}
                                          </option>
  
                                          {countries.map((i) => {
                                          return (
                                              <option className="fondo fw-bolder" value={i.id}>
                                                  {
                                                    props.lng == 'en' ?
                                                      i.name :
                                                    props.lng == 'es' ?
                                                      i.name_es :
                                                    props.lng == 'fr' ?
                                                      i.name_fr : ""
                                                  }
                                              </option>
                                          );
                                          })}
                                      </select>
                                  </div>
                                  <div class="form-group mb-4 col-md-11">
                                      <select
                                          id="fildepartment"
                                          name="fildepartment"
                                          className="form-select fondoamarillo imputTextSelect"
                                          aria-label={t("ttl5")}
                                          onChange={(e) => (setfildepartment(e.target.value))}
                                      >
                                          <option className="fondo fw-bolder" value="" selected>
                                              {t("ttl5")}
                                          </option>
  
                                          {departments.map((i) => {
                                          return (
                                              <option className="fondo fw-bolder" value={i.id}>
                                                  {
                                                    props.lng == 'en' ?
                                                      i.name :
                                                    props.lng == 'es' ?
                                                      i.name_es :
                                                    props.lng == 'fr' ?
                                                      i.name_fr : ""
                                                  }
                                              </option>
                                          );
                                          })}
                                      </select>
                                  </div>
                              </li>
                          </ul>
                          <hr />
                      </div>
  
                      <div class="row my-3">
                          <a
                          href="#"
                          className="mx-2  items-center txtlargeAzul"
                          data-bs-toggle="collapse"
                          data-bs-target={"#activities"}
                          aria-expanded="false"
                          aria-controls={"activities"}
                          >
                            {t("ttl6")}
                          </a>
  
                          <ul className="collapse mx-3" id={"activities"}>
                              <li class="mt-2">
                                  <div class="form-group mb-4 col-md-11">
                                      <select
                                          id="filactivity"
                                          name="filactivity"
                                          className="form-select fondoamarillo imputTextSelect"
                                          aria-label={t("ttl6")}
                                          onChange={(e) => (setfilactivity(e.target.value))}
                                      >
                                          <option className="fondo fw-bolder" value="" selected>
                                          {t("ttl6")}
                                          </option>
  
                                          {activities.map((i) => {
                                          return (
                                              <option className="fondo fw-bolder" value={i.id}>
                                                  {
                                                    props.lng == 'en' ?
                                                      i.name :
                                                    props.lng == 'es' ?
                                                      i.name_es :
                                                    props.lng == 'fr' ?
                                                      i.name_fr : ""
                                                  }
                                              </option>
                                          );
                                          })}
                                      </select>
                                  </div>
                              </li>
                          </ul>
                          <hr />
                      </div>
  
                  <div class="row my-3">
                    <a
                      href="#"
                      className="mx-2  items-center txtlargeAzul"
                      data-bs-toggle="collapse"
                      data-bs-target={"#reset"}
                      aria-expanded="false"
                      aria-controls={"reset"}
                      onClick={() => resetList()}
                    >
                      {t("ttl7")}
                    </a>
                    <hr />
                  </div>
                </div>
  
                <div class="col-12 col-sm-12 col-md-10 center-block ">
                  {/* tabla de bits */}
                  <div className="my-2">
                    <div class="col text-center  ">
                      {
                        (loading == 1) ? 
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        : ""
                      }
                    </div>
                    {companies.length > 0 && (
                      <div>
                        <div> 
                          {
                            companies.map((companie, i) => {
                              return (
                                <div className="container my-2">
                                  <div className="accordion-item card sombra my-2">
                                    <div className="accordion-header" id="headingOne">
                                      <div className="row  my-2 p-2">
                                        {/* <div className="col-12 col-sm-12 col-md-1 text-center text-movil">
                                          <div class="row txtlargeAzul">
                                            <br />
                                            <div class="text-center txtSmallAzul ">{companie.id}</div>
                                          </div>
                                        </div> */}
                      
                                        <div className="col-12 col-sm-12 col-md-3 text-center">
                                          <div class="row txtlargeAzul">
                                            <div class="text-center txtSmallerGris">{t("lbl1")}</div>
                                            <div class="text-center txtSmallAzul">
                                              {companie.name}
                                            </div>
                                          </div>
                                        </div>
                      
                                        <div className="col-12 col-sm-12 col-md-4 text-center">
                                          <div class="row txtlargeAzul">
                                            <div class="text-center txtSmallerGris">{t("lbl2")}</div>
                                            <div class="text-center txtSmallAzul">
                                              {companie.description}
                                            </div>
                                          </div>
                                        </div>
                      
                                        <div className="col-12 col-sm-12 col-md-3 text-center">
                                          <div class="row txtlargeAzul">
                                            <div class="text-center txtSmallerGris">{t("lbl3")}</div>
                                            <div class="text-center txtSmallAzul">
                                              {companie.phone_number}
                                            </div>
                                          </div>
                                        </div>
                      
                                        <div className="col-6 col-sm-6 col-md-2 text-center">
                                          <div class="row justify-content-center align-items-center ">
                                            <div class="col-3 col-sm-3 col-md-2">
                                              {" "}
                                              <a
                                                href="javascript:void(0)"
                                                title="Details"
                                                data-bs-toggle="modal"
                                                data-bs-target="#mdlcompany"
                                                onClick={()=>openModal(companie, companie.countryname, companie.departmentname, 1, companie.idcompany )}
                                                // value, pais, departamento, estado, empresa, usuario
                                              >
                                                {"   "}
                                                <img
                                                  width="30"
                                                  height="30"
                                                  class="icoOpciones mx-3"
                                                  src={ver}
                                                />{" "}
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                        <div>{paginationElement}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          {/* <button
            id="openModal"
            type="button"
            class="ocultarBtn"
            data-bs-toggle="modal"
            data-bs-target="#mdlcompany"
          ></button> */}
          
          {/* modal add  */}
  
          <div
            class="modal fade bd-example-modal-lg"
            id="mdlcompany"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog  modal-lg modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5
                    class="modal-title txtSmallerGrisModal "
                    id="staticBackdropLabel"
                  ></h5>
                  <button
                    id="clocedModal"
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="container-fluid  my-2 mb-5">
                    <div class="row ">
                      <div class="col-md-5 ocultarMovil">
                        <div class="text-center">
                          <br />
                          <br />
                          <img
                            className="img-fluid center-block "
                            width="80%"
                            height="80%"
                            src={datos.logo ? datos.logo : imgmodal}
                          />
                        </div>
                      </div>
  
                      <div class="col-md-7">
                        <div class="row mb-3 my-1 align-items-center">
                          <div class="col-sm-12 col-md-6 col-lg-6">
                            <button
                                class="btn buttonAzulModal"
                                type="button"
                                id="dropdownMenuButton2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={()=>{listChat()}}
                            >
                                <span>
                                    <img
                                    className="img-fluid "
                                    width="12px"
                                    height="12px"
                                    src={listplas}
                                    />
                                </span>
                                {t("lbl4")}
                            </button>

                            <div
                            class="dropdown-menu fondoAzulModal"
                            aria-labelledby="themes"
                            >
                              <div class="container">
                                  <div class="form-group mb-1 text-center ">
                                  <img
                                      className=" mt-2 img-fluid"
                                      width="20px"
                                      height="20px"
                                      src={userimg}
                                  />
                                  </div>
                                  <div class="form-group mb-2 text-center ">
                                  {t("lbl4")}
                                  </div>
                              </div>

                              <div className="linea lineamovil"></div>
                                <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                                    <div className="anyClass anyClassMovil" id="topabajo">
                                    {comentarios.map((i) => {
                                        if (i.type == 3) {
                                        return (
                                            <>
                                            <div class="form-group mb-2 text-left mt-2 ">
                                                <div class="row  my-1 ">
                                                <div class="col-2 col-sm-2 col-md-2 ">
                                                    <img
                                                    className=" mt-2 img-fluid ingMpvilchat"
                                                    width="50px"
                                                    height="50px"
                                                    src={userchat}
                                                    />
                                                </div>

                                                <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                                                    <div className="txtSmallGris">
                                                    {i.name}
                                                    </div>

                                                    <p className="text-left"> {i.comment} </p>
                                                    <div class="txtSmallGris"> {i.created_at} </div>
                                                </div>
                                                </div>
                                            </div>
                                            </>
                                        );
                                        } else {
                                        return (
                                            <>
                                            <div class="form-group mb-2  mt-2 ">
                                                <div class="row  my-1   justify-content-end    ">
                                                <div class=" col-5 col-sm-5 col-md-5 text-end mt-1 globo">
                                                    <div className="mt-2 txtSmallGris">
                                                    {i.name}
                                                    </div>

                                                    <p className="">
                                                    {i.comment}
                                                    </p>
                                                    <div class="txtSmallGris"> {i.created_at} </div>
                                                </div>

                                                <div class="col-2 col-sm-2 col-md-2 ">
                                                    <img
                                                    className=" mt-2 img-fluid ingMpvilchat"
                                                    width="50px"
                                                    height="50px"
                                                    src={userchat}
                                                    />
                                                </div>
                                                </div>
                                            </div>
                                            </>
                                        );
                                        }
                                    })}
                                    </div>
                                </div>

                                <div class="container">
                                  <div class="row  my-1 ">
                                    <div class="col-10 ">
                                        <input
                                        id="mensaje"
                                        type="text"
                                        name="mensaje"
                                        className="mt-1 form-control imputTextModal"
                                        aria-describedby="emailHelp"
                                        placeholder={t("lbl5")}
                                        value={messagechat}
                                        onChange={(e)=>setmessagechat(e.target.value)}
                                        />
                                    </div>

                                    <div class="col-2 text-left ">
                                        <a
                                        href="javascript:void(0)"
                                        onClick={() => {
                                            send();
                                        }}
                                        >
                                        <img
                                            className=" mt-2 img-fluid"
                                            width="20px"
                                            height="20px"
                                            src={sendimg}
                                        />
                                        </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        
                        <div class="row  my-1 text-left mb-2">
                            <div class="col-6 text-left txtSmallAzulModal ">
                            {t("lbl6")}
                            </div>

                            <div class="col-4 text-left txtSmallAzulModalLeter ">
                            {datos.nit}
                            </div>
                        </div>
                        
                        <div class="row  my-1 text-left mb-2">
                            <div class="col-6 text-left txtSmallAzulModal ">
                            {t("lbl1")}
                            </div>

                            <div class="col-4 text-left txtSmallAzulModalLeter ">
                            {" "}
                            {datos.name}
                            </div>
                        </div>
                        
                        <div class="row  my-1 text-left mb-2">
                            <div class="col-6 text-left txtSmallAzulModal ">
                            {t("lbl3")}
                            </div>

                            <div class="col-4 text-left txtSmallAzulModalLeter ">
                            {" "}
                            {datos.phone_number}
                            </div>
                        </div>
                        
                        <div class="row  my-1 text-left mb-2">
                            <div class="col-6 text-left txtSmallAzulModal ">
                            {t("lbl7")}
                            </div>

                            <div class="col-4 text-left txtSmallAzulModalLeter ">
                            {" "}
                            {datos.address}
                            </div>
                        </div>
                        
                        <div class="row  my-1 text-left mb-2">
                            <div class="col-6 text-left txtSmallAzulModal ">
                            {t("lbl8")}
                            </div>

                            <div class="col-4 text-left txtSmallAzulModalLeter ">
                            {" "}
                            {countryName}
                            </div>
                        </div>
                        
                        <div class="row  my-1 text-left mb-2">
                            <div class="col-6 text-left txtSmallAzulModal ">
                            {t("lbl9")}
                            </div>

                            <div class="col-4 text-left txtSmallAzulModalLeter ">
                            {" "}
                            {deparmetName}
                            </div>
                        </div>

                        <div class="row  my-1 text-left mb-2">
                            <div class="col-6 text-left txtSmallAzulModal ">
                            {t("lbl10")}
                            </div>

                            <div class="col-4 text-left txtSmallAzulModalLeter ">
                            {" "}
                            {datos.postal_code}
                            </div>
                        </div>

                        {/* <div class="row  my-1 text-left mb-2">
                            <div class="col-6 text-left txtSmallAzulModal ">
                            {t("lbl11")}
                            </div>

                            <div class="col-4 text-left txtSmallAzulModalLeter ">
                            {" "}
                            {estadoName}
                            </div>
                        </div> */}
                      </div>
                    </div>
  
                    <div className="container my-2  mb-2">
                      <div class="row  my-1 ">
                        <div class="col-12 text-left  txtSmallAzulModal">
                          {t("lbl12")}
                        </div>
  
                        <div class="col-12 text-left txtSmallGris">
                          {representantes.map((i) => {
                            return (
                              <>
                                {i.first_name} {i.last_name} {i.email}{" "}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
  
                    <div className="container my-2  mb-5">
                      <div class="row  my-1 ">
                        <div class="col-12 text-left  txtSmallAzulModal">
                          {t("lbl2")}
                        </div>
  
                        <div class="col-12 text-left txtSmallGris">
                          {datos.description}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    
}


export default EmpresaApplicant