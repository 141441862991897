import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import PasswordRecoveryform from "../PasswordRecovery/PasswordRecoveryform"
import geojobscanada from "../img/formhrh.png";

const PasswordRecovery = (props) => {

  const [t, i18n] = useTranslation("login")

  // const [ form, setform ] = useState({
  //                                     email: ""
  //                                   })

  // const handleChangeLogin = (e) => {
  //   setform({
  //           ...this.state.form,
  //           [e.target.name]: e.target.value,
  //         })
  // };

  return (
    <>
      <section class="">
        <div class="container py-5">
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-md-12 col-lg-5 col-xl-5 offset-xl-1">
              <div class="square xyz-in" xyz="fade up big">

              <div class="form-group  row justify-content-center ">
                    <img src={geojobscanada}  class="img-fluid  imgform"  />
                </div>

              <div class="divider d-flex align-items-center  ">
                  <div
                    class="color-fuente-gris textbold"
                  >
                    <br/>
                    {t("ttl5")}
                  </div>
                  </div>
                  <PasswordRecoveryform history={props.history} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PasswordRecovery;
